import { map } from 'lodash/fp'
import Decimal from 'decimal.js'

const d = (n) => new Decimal(n)

export const rotateSnapshot = (canvas, bool) =>
  map(
    map(({ x, y, ...data }) => {
      if (bool) {
        return {
          ...data,
          x: d(canvas.width).minus(y).toNumber(),
          y: x,
        }
      }
      return {
        ...data,
        x: y,
        y: d(canvas.height).minus(x).toNumber(),
      }
    })
  )

export const fromDataSnapShot = (signPad, canvas, bool, snapData) => {
  const data = rotateSnapshot(canvas, bool)(snapData)
  signPad?.fromData(data)
}

export const reSizeWrap = (bool, idName) => {
  const content = document.querySelector(`#modal-dialog-content-signPad${idName}`)

  if (content) {
    if (bool) {
      content.style.transform = 'rotate(90deg)'
      content.style.width = '100vh'
    } else {
      content.style.transform = 'none'
      content.style.width = '100%'
    }
  }
}

export const reSizeBackButton = () => {
  const content = document.body

  return {
    top: isPortrait() ? `${content.clientHeight - content.clientWidth + 50}` : '20',
  }
}

export const reSizeBodyContainer = () => {
  const content = document.body

  return {
    marginTop: isPortrait() ? `${content.clientHeight - content.clientWidth}px` : '0px',
  }
}

export const toBase64 = (canvas) => {
  const newCanvas = document.createElement('canvas')
  const ctx = newCanvas.getContext('2d')

  const width = canvas?.props?.canvasProps?.width
  const height = canvas?.props?.canvasProps?.height
  if (height > width) {
    newCanvas.width = height
    newCanvas.height = width

    ctx.setTransform(
      0,
      -1, // x axis up the screen
      1,
      0, // y axis across the screen from left to right
      0, // x origin is on the left side of the canvas
      width // y origin is at the bottom
    )
  } else {
    newCanvas.width = width
    newCanvas.height = height
  }

  ctx.drawImage(canvas?.getCanvas(), 0, 0)
  const dataUrl = newCanvas.toDataURL('image/png')

  return dataUrl
}

export const isPortrait = () => {
  const content = document.body
  return content?.clientWidth < content.clientHeight
}

export default {
  rotateSnapshot,
  fromDataSnapShot,
  reSizeWrap,
  isPortrait,
  toBase64,
  reSizeBackButton,
  reSizeBodyContainer,
}
