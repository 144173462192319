import { useEffect, useState } from 'react'
import { PendingECBRListEntity } from 'domain/entities/ecbr'

import { format, date } from 'utils/date'
import { toCurrency, numberWords } from 'utils/number-to-thai-words'

import staticContentPage from '../static-content-page'

import { pdfService, ecbrService } from 'services/ecbr-middleware'
import { getToggles } from 'core/config'
import _ from 'lodash'

type AttachItem = {
  status: string
  filename: string
  id: number
}
export type attachmentItem = {
  status?: string
  filename: string
  alt?: string
  src: string
  type: string
  base64?: string
  id?: number
}

export type cancelState = {
  infoIssue: any
  method: string
  attachment: [attachmentItem]
}

const initAttachObject = (index, status) => ({ status, filename: '', id: index })
const mappingStatus = {
  done: (item) => item.status === 'done',
  uploading: (item) => item.status === 'uploading',
  blank: (item) => item.status === 'blank',
}

export const handleAttachList = (list, selectedItem) => {
  const isAllDone = list.every((item) => mappingStatus.done(item))

  const resetStatus = list.map((item, index) => {
    if (selectedItem === index) {
      item = initAttachObject(index, isAllDone ? 'uploading' : 'blank')
    }
    return item
  })

  const doneStatus = resetStatus.filter((item) => mappingStatus.done(item))
  const updatingStatus = resetStatus.filter((item) => mappingStatus.uploading(item))
  const blankStatus = resetStatus.filter((item) => mappingStatus.blank(item))

  const reOrderList = doneStatus.concat(updatingStatus.concat(blankStatus)).map((item, index) => {
    item.id = index
    return item
  })

  return reOrderList
}

export const handleInitAttach = (props) => {
  const { maxAttachment = 4 } = props
  const initAttach: Array<AttachItem> = new Array()
  initAttach[0] = initAttachObject(0, 'uploading')

  for (let i = 0; i < maxAttachment - 1; i++) {
    const attachItem = initAttachObject(i + 1, 'blank')
    initAttach.push(attachItem)
  }
  return initAttach
}

export const useUploadAttachment = (props) => {
  const { base64, attachment, item, attachName, alt, src, type, setState, state, setAttachName } = props
  useEffect(() => {
    if (base64) {
      let attachItem: any = attachment.find((a) => a.id === item.id)
      const newAttach = {
        status: 'done',
        filename: attachName,
        alt,
        src,
        type,
        base64,
      }

      // Set next attachment item to UPLOADING
      attachment[item.id] = { ...attachItem, ...newAttach }
      if (item.id + 1 < attachment.length) {
        attachment[item.id + 1] = { ...attachment[item.id + 1], status: 'uploading' }
      }

      setState({ ...state, attachment })
      setAttachName('')
    }
  }, [base64])
}

export const getCancelFormEcbr = async (data) => {
  const res = getToggles().ENABLE_ECBRSYS_ECBR ? await pdfService.GenPdfSigning('ecbr-cancel-form', data) : await pdfService.GenPdf('cancel-form-ecbr', data)
  return getToggles().ENABLE_ECBRSYS_ECBR ? _.get(res, 'data', '') : res
}

export const useCreateCase = async ({ infoIssue }) => {
  const data = {
    agentType: 'CBR',
    agentSubType: 'Cancelled eCBR',
    agentCode: infoIssue.agentCode,
    ecbrNumber: infoIssue.cbrNumber,
    caller: 'Agency',
    subject: `AZ: ขอยกเลิก eCBR เลขที่ ${infoIssue.cbrNumber}`,
    source: 'AdvisorZone',
    description: `AZ: ขอยกเลิก eCBR เลขที่ ${infoIssue.cbrNumber}`,
    policyId: infoIssue.policyNumber,
    party: {
      firstName: infoIssue.insuredName,
      lastName: infoIssue.insuredSurName,
    },
    contactChannel: 'email',
    contactMethods: {
      emailContact: {
        url: infoIssue.insureEmail,
      },
      phoneContacts: [
        {
          localNumber: infoIssue.insureMobile,
        },
      ],
    },
  }
  const result = await ecbrService.CreateCase(JSON.stringify(data))
  return result
}

export const useUpLoadDocument = async (data) => {
  const body = {
    Title: data.Title,
    PathOnClient: data.PathOnClient,
    ContentLocation: 'S', //default
    OwnerId: '00528000006ffqqAAA', //fix
    VersionData: data.VersionData,
    Visibility: 'AllUsers',
    LinkedEntityId: data.caseId,
  }
  const result = await ecbrService.UploadDocument(JSON.stringify(body))
  return result
}

export const useUpdateStatusEcbrSys = async (data) => {
  const body = {
    ecbrNo: data.ecbrNo,
    ecbrStatus: data.ecbrStatus,
    managerCode: data.managerCode,
    inputManagerCode: data.inputManagerCode,
    createBy: data.createBy,
    remark: data.remark,
    ecbrCancelPDF: data.ecbrCancelPDF,
    additionalDoc: data.additionalDoc,
    policyNo: data.policyNo,
    insuredCitizenId: data.insuredCitizenId,
    insuredFirstName: data.insuredFirstName,
    insuredSurname: data.insuredSurname,
    agentFullCode: data.agentFullCode,
    paymentMethod: data.paymentMethod,
  }
  const result = await ecbrService.UseUpdateStatusEcbrSys(JSON.stringify(body))
  return result
}

export const useGetEcbrCancelFrom = async (data, state) => {
  const { infoIssue } = state

  const fullName = `${data?.object?.[staticContentPage.cancelFormSection.textInputs[0].id]} ${
    data?.object?.[staticContentPage.cancelFormSection.textInputs[1].id]
  }`

  const ecbrDate = date(infoIssue?.createdAt).toISOString()
  const rawData = {
    ...data?.object,
    date: format(data?.object?.date, 'DD/MM/YYYY'),
    amount: toCurrency(infoIssue?.paymentAmount),
    amountText: numberWords(infoIssue?.paymentAmount),
    fullName,
    ecbrDate: format(ecbrDate, 'DD/MM/YYYY'),
    sign3: getToggles().ENABLE_ECBRSYS_ECBR? data?.object?.sign2 :data?.object?.sign3
  }
  const res = await getCancelFormEcbr(rawData)
  return res
}

export const useRetrieveCase = async () => {
  const { data } = await ecbrService.RetrieveCase()
  return data
}

export const mappingEcbrWithCaseNumber = (listEcbr, listCase) => {
  listEcbr.map((ecbr) => {
    const matchingCaseNumber = listCase.records.findLast((c) => c.eCBR_Number__c === ecbr.cbrNumber)
    if (matchingCaseNumber) {
      ecbr.caseNumber = matchingCaseNumber.CaseNumber
      ecbr.iProStatus = matchingCaseNumber.Status
    }
  })
  return listEcbr
}

export const useMappingCase = (pendingList) => {
  let { data, isLoaded } = pendingList
  const [combineEcbrCase, setData] = useState({ total: 0, data: [], isLoaded: false } as PendingECBRListEntity)
  useEffect(() => {
    async function fetchCase() {
      if (isLoaded) {
        if (data && data.length > 0) {
          try {
            const listCase = await useRetrieveCase()
            setData({
              total: data.length,
              data: mappingEcbrWithCaseNumber(data, listCase),
              isLoaded: true,
            })
          } catch (error) {
            setData({
              total: 0,
              data: [],
              isLoaded: true,
            })
          }
        } else {
          setData({
            total: 0,
            data: [],
            isLoaded: true,
          })
        }
      }
    }

    fetchCase()
  }, [data])
  return combineEcbrCase
}
