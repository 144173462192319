import React from 'react'
import { DatePicker } from 'reactstrap-date-picker'
import styled from '@emotion/styled'

// TODO: Investigate why can use `Icon.Type` for .tsx
import { Icons } from 'ktaxa-ui-components/index'

const Container = styled.div`
  display: flex;
  align-items: center;
  .rdp-popover .popover {
    z-index: 99;
    background: #fff;
  }
  .input-group {
    width: 100%;
  }
`
const IconContainer = styled.div`
  color: #fff;
  background-color: #2425aa;
  padding: 0.53rem;
`

type DatePickerProps = {
  showIcon?: boolean
  disabled?: boolean
  dateFormat: 'DD/MM/YYYY' | 'MM/DD/YYYY'
  id: string
  onChange?: (v: string, f: string) => void
  value?: string
  minDate?: string
  readOnly?: boolean
}

const DatePickerComponent = (props: DatePickerProps) => {
  const { onChange, value = '', id, disabled = false, dateFormat, minDate, readOnly, showIcon = true } = props
  if (readOnly) document.getElementById(`rdp-form-control-${id}`)?.setAttribute('readonly', 'readonly')

  return (
    <Container>
      {showIcon && (
        <IconContainer>
          <Icons type={'CALENDAR'} />
        </IconContainer>
      )}

      <DatePicker
        className="date-picker"
        dateFormat={dateFormat}
        disabled={disabled}
        value={value}
        id={id}
        style={{ background: disabled ? '#eceeef' : 'white' }}
        minDate={minDate}
        onChange={onChange}
        showClearButton={false}
        showTodayButton={true}
      />
    </Container>
  )
}

export default DatePickerComponent
