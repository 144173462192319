/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'

import DialogTitle from './dialog-title'
import DialogFooter from './dialog-footer'
import styles from './dialog-styles'

const propTypes = {
  /**
   * One or more class names to be added to the dialog element (not root), i.e. `"class-foo class-bar"`.
   */
  styleOptions: PropTypes.object,
  className: PropTypes.string,
  /**
   * Override the inline-styles of the dialog element.
   */
  style: PropTypes.shape({}),
  /**
   * The title of dialog.
   */
  title: PropTypes.string,
  /**
   * Elements to be rendered as children (dialog content) of this component.
   */
  children: PropTypes.node,
  /**
   * Hide the dialog if set to true.
   */
  show: PropTypes.bool,
  /**
   * Modal dialog can only be closed by selecting one of the actions.
   *
   * Dialog won't closed by clicking on overlay and close button won't displayed
   */
  modal: PropTypes.bool,
  /**
   * Vertically center the modal.
   */
  centered: PropTypes.bool,
  /**
   * Show dialog in full screen if set to true.
   */
  fullScreen: PropTypes.bool,
  /**
   * 	A callback function that is triggered when cancel button clicked.
   *
   * ```() => {}```
   */
  onClose: PropTypes.func,
}

const defaultProps = {
  className: '',
  children: null,
  show: false,
  modal: false,
  fullScreen: false,
  onClose: () => {},
}

/**
 * Show text and UI controls focused on a specific task. Or to inform users about critical information, require users actions, or involve multiple tasks.
 * Content (children) in Dialog could be element.
 *
 * Dialog could be closed when dialog get focus and pressing `ESC`.
 */
function Dialog(props) {
  const { children, className, centered, show, modal, style, onClose, styleOptions } = props

  let dialogDialog

  useEffect(() => {
    if (show) {
      dialogDialog.focus()
      toggleDialogOverlay(true)
    } else {
      toggleDialogOverlay(false)
    }

    // Cleanup function to reset styles when component unmounts
    return () => toggleDialogOverlay(false)
  }, [show])

  const getBrowserScrollbarSize = () => {
    return window.innerWidth - document.documentElement.clientWidth
  }

  const doOnClose = () => (!modal ? onClose() : false)

  const onKeyDown = (e) => {
    if (e.keyCode === 27 && !modal) {
      doOnClose()
    }
  }

  const toggleDialogOverlay = (show) => {
    if (show) {
      document.body.style.paddingRight = `${getBrowserScrollbarSize()}px`
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.body.style.paddingRight = 0
      document.body.style.overflow = 'scroll'
      document.documentElement.style.overflow = 'unset'
    }
  }

  const contentComponent = children ? (
    <div css={styles(styleOptions).body}>
      <div
        id={
          styleOptions?.idNameContent ? `modal-dialog-content-${styleOptions?.idNameContent}` : `modal-dialog-content`
        }
        css={styles(styleOptions).bodyContent}
      >
        {children}
      </div>
    </div>
  ) : (
    <div css={styles(styleOptions).body} />
  )

  const dialogStyles = [styles(styleOptions).dialog]
  return (
    <div
      tabIndex="0"
      role="dialog"
      onKeyDown={onKeyDown}
      ref={(el) => {
        dialogDialog = el
      }}
      data-show={show}
      css={dialogStyles}
      className={className}
    >
      <div css={styles(styleOptions).display} data-display-centered={centered} onClick={() => doOnClose()}>
        <div data-show={show} css={styles(styleOptions).box} style={style} onClick={(e) => e.stopPropagation()}>
          {contentComponent}
        </div>
      </div>
    </div>
  )
}

Dialog.displayName = 'Dialog'
Dialog.propTypes = propTypes
Dialog.defaultProps = defaultProps
Dialog.Title = DialogTitle
Dialog.Footer = DialogFooter

export default Dialog
