import React from 'react'
import styled from '@emotion/styled'

import { Dialog } from 'ktaxa-ui-components/index'

const Content = styled.div`
  padding: 15px 0;
  text-align: center;
`

const LoadingModal = (props) => {
  const { show } = props

  let ShowDialog = (
    <Dialog centered modal show={show}>
      <Content>Loading...</Content>
    </Dialog>
  )

  return ShowDialog
}

export default LoadingModal
