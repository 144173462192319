import EcbrRepository from 'domain/repositories/ecbr'
import { SalesforceCreateCaseEntity } from 'domain/entities/ecbr'
import { PendingECBRListEntity, ECBRItemEntity, VerifyBlockIssue, CreateEcbrPaidEntity } from 'domain/entities/ecbr'

import { IConnect } from '../connect'
import { getToggles } from 'core/config'

export default class EcbrMiddlewareRepositoryImp implements EcbrRepository {
  connect: IConnect

  constructor(connect: IConnect) {
    this.connect = connect
  }

  async CreateEcbrPaid(payload: CreateEcbrPaidEntity, cbrNumber: string): Promise<{ data: any; error: any }> {
    try {
      let body = JSON.stringify(payload)
      const res = await this.connect.post(`/ecbr/${cbrNumber}/paid`, body)
      if (res.error) {
        return { data: null, error: res.error }
      }
      return { data: res?.receipt, error: null }
    } catch (e) {
      return { data: null, error: e }
    }
  }

  async GetPendingECBR(query?: string): Promise<PendingECBRListEntity> {
    try {
      const res = await this.connect.get(`/ecbr/advisor/pending${query ? `?${query}` : ''}`)

      return res
    } catch (error) {
      return { total: 0, data: [], isLoaded: true }
    }
  }

  async SearchECBR(data: any): Promise<ECBRItemEntity[]> {
    try {
      const res = await this.connect.post('/ecbr/search', data)
      const jsonData = res.data.map((j: ECBRItemEntity) => {
        return j
      })
      return jsonData
    } catch (error) {
      return []
    }
  }

  async SearchECBRSys(data: any): Promise<{ jsonData: ECBRItemEntity[]; error: any }> {
    try {
      const res = await this.connect.post('/ecbrsys/search', data)
      const jsonData = res.data.map((j: ECBRItemEntity) => {
        return j
      })
      jsonData.agentEmail = res.agentEmail
      jsonData.agentMobilephone = res.agentMobilephone
      return { jsonData, error: null }
    } catch (error) {
      return { jsonData: [], error }
    }
  }

  async SendEmailECBRSys(data: any): Promise<{ resp: any }> {
    try {
      return await this.connect.post('/ecbrsys/sendEmailEcbr', data)
    } catch (error) {
      return error
    }
  }

  async SendSmsECBR(data: any): Promise<{ resp: any }> {
    try {
      return await this.connect.post('/ecbr/notification/sendSms', data)
    } catch (error) {
      return error
    }
  }

  async VerifyBlockIssueECBR(): Promise<VerifyBlockIssue> {
    const token = localStorage.getItem('ecbr:token')
    let timeoutCheckToken
    let counterCheck = 0

    // waiting for best solution !fix cannot load token
    try {
      await new Promise(async (resolve, reject) => {
        timeoutCheckToken = setInterval(() => {
          counterCheck++
          if (counterCheck === 3 || token !== '') {
            resolve(counterCheck)
            clearInterval(timeoutCheckToken)
          }
        }, 2000)
      })
      const requestUrl = getToggles().ENABLE_ECBRSYS_ECBR
        ? '/ecbr/verify/pending-limit?enable_ecbr_sys=true'
        : '/ecbr/verify/pending-limit'
      const res = await this.connect.get(requestUrl)

      return res
    } catch (error) {
      return {
        pendingBlock: getToggles().ENABLE_ECBRSYS_ECBR ? false : true,
        suspendBlock: false,
        warningMessage:
          'ท่านไม่สามารถออกใบรับเงินชั่วคราวอิเล็กทรอนิกส์ได้ในขณะนี้ เนื่องจากระบบ ecbr มีปัญหา กรุณาลองใหม่',
      } as VerifyBlockIssue
    }
  }

  async GetEcbrByCbrNumber(cbrNumber: string): Promise<{ data: ECBRItemEntity; error: any }> {
    try {
      const jsonData = await this.connect.get(`/ecbr/${cbrNumber}`)
      // need fix
      if (jsonData.error) {
        return { data: {} as ECBRItemEntity, error: jsonData.error }
      }
      return { data: jsonData as ECBRItemEntity, error: {} }
    } catch (error) {
      return { data: {} as ECBRItemEntity, error }
    }
  }

  async CreateCase(data: any): Promise<{ data: SalesforceCreateCaseEntity; error: any }> {
    try {
      const result = await this.connect.post('/case', data)
      if (result.error) {
        return { data: {} as SalesforceCreateCaseEntity, error: result.error }
      }
      return { data: result as SalesforceCreateCaseEntity, error: result.error }
    } catch (error) {
      return { data: {} as SalesforceCreateCaseEntity, error }
    }
  }

  async UploadDocument(data: any): Promise<{ data: any; error: any }> {
    try {
      const result = await this.connect.post('/uploadDocument', data)
      if (result.error) {
        return { data: {} as any, error: result.error }
      }
      return { data: result as any, error: result.error }
    } catch (error) {
      return { data: {} as any, error }
    }
  }

  async UseUpdateStatusEcbrSys(data: any): Promise<{ data: any; error: any }> {
    try {
      const result = await this.connect.post('/ecbrsys/updateStatus', data)
      if (result.error) {
        return { data: {} as any, error: result.error }
      }
      return { data: result as any, error: result.error }
    } catch (error) {
      return { data: {} as any, error }
    }
  }

  async RetrieveCase(): Promise<{ data: any; error: any }> {
    try {
      const result = await this.connect.get('/retrieve/case')
      if (result.error) {
        return { data: {} as any, error: result.error }
      }
      return { data: result as any, error: result.error }
    } catch (error) {
      return { data: {} as any, error }
    }
  }
}
