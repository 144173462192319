import React from 'react'
import { Input } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { BlockText, ContentContainer, SectionContainer } from 'components/layouts'
import { Cards } from 'components'

import { formatCitizenId } from 'utils/string-helper'

import { ecbrService } from 'services/ecbr-middleware'
import { schemaTransferInfoSection } from '../../hooks/useForm'

import staticContentPage from '../../static-content'
import { PropsSectionType } from '../../type'
import AmountBox from '../../components/amount-box'
import { renderErrorMessageBlock } from 'components/message-blocks'

import { useHandleDataForm } from './hook'

const PayByServiceComponent = (props) => {
  const { getValues, errors, control } = props
  if (
    getValues('object.payBy') === staticContentPage.paymentMethod.options[0].value ||
    getValues('object.payBy') === staticContentPage.paymentMethod.options[1].value
  ) {
    return (
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <h2>ธนาคารผู้รับชำระ</h2>
          <Controller
            name="object.payByService"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                className="custom-select form-control"
                onChange={(v) => field.onChange(v.target.value)}
              >
                <option value="" disabled>
                  โปรดระบุธนาคารผู้รับชำระ
                </option>
                {getValues('object.payBy') === staticContentPage.paymentMethod.options[0].value
                  ? staticContentPage.paymentMethod.options[0].options.map((v) => (
                      <option key={v.value} value={v.value}>
                        {v.text}
                      </option>
                    ))
                  : staticContentPage.paymentMethod.options[1].options.map((v) => (
                      <option key={v.value} value={v.value}>
                        {v.text}
                      </option>
                    ))}
              </Input>
            )}
          />
          {errors?.object?.payByService && renderErrorMessageBlock(errors?.object?.payByService?.message)}
        </div>
      </SectionContainer>
    )
  } else if (getValues('object.payBy') === staticContentPage.paymentMethod.options[2].value) {
    return (
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <h2>เคาน์เตอร์ผู้รับชำระ</h2>

          <Controller
            name="object.payByService"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                className="custom-select form-control"
                onChange={(v) => field.onChange(v.target.value)}
              >
                <option value="" disabled>
                  โปรดระบุเคาน์เตอร์ผู้รับชำระ
                </option>
                {staticContentPage.paymentMethod.options[2].options.map((v) => (
                  <option key={v.value} value={v.value}>
                    {v.text}
                  </option>
                ))}
              </Input>
            )}
          />
          {errors?.object?.payByService && renderErrorMessageBlock(errors?.object?.payByService?.message)}
        </div>
      </SectionContainer>
    )
  }
  return null
}

const TransferInfoSection = (props: PropsSectionType) => {
  const { formState, control, reset, handleSubmit, getValues, watch, setValue } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaTransferInfoSection),
  })
  const { errors, isDirty } = formState

  const { state, tabState } = props
  const { ECBRCard } = Cards
  const { pendingList } = state

  const paymentType = ecbrService.getPaymentTypeByPamentTypeCode(pendingList?.paymentType)
  const paymentMethod = ecbrService.getPaymentMethodByPaymentMethodCode(pendingList?.paymentMethod)
  const paymentMode = ecbrService.getPaymentModeByPaymentModeCode(pendingList?.paymentMode)

  watch('object.payBy')
  useHandleDataForm({ ...props, reset, handleSubmit, isDirty, watch })
  const ActiveCondition = tabState === staticContentPage.tabsProgress[1].id
  if (!ActiveCondition) return null
  return (
    <ContentContainer>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
          }
        }}
      >
        <h1>{staticContentPage.transferInfo.title}</h1>
        <SectionContainer>
          <ECBRCard
            name={`${pendingList?.insuredName} ${pendingList?.insuredSurName}`}
            policyNumber={pendingList?.policyNumber}
            amount={pendingList?.paymentAmount}
            status={pendingList?.cbrStatus}
            createdAt={pendingList?.payInDate}
            daySinceCreated={pendingList?.daySinceCreated}
            paymentNo={pendingList?.cbrNumber}
          />
        </SectionContainer>
        <SectionContainer>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <BlockText>ประเภทการชำระเงิน</BlockText>
            <BlockText right>
              {paymentType}/{paymentMode}/{paymentMethod}
            </BlockText>

            <BlockText>เลขที่กรมธรรม์</BlockText>
            <BlockText right>{pendingList?.policyNumber}</BlockText>

            <BlockText>เลขที่บัตรประชาชนผู้เอาประกัน</BlockText>
            <BlockText right>{formatCitizenId(pendingList?.personalId)}</BlockText>
          </div>
        </SectionContainer>
        <SectionContainer>
          <AmountBox title={'จำนวนเงินที่ชำระ'} amount={pendingList?.paymentAmount} />
        </SectionContainer>
        <SectionContainer>
          <div className="name-input" style={{ paddingBottom: 20 }}>
            <h2>วิธีการชำระเงิน</h2>
            <Controller
              name="object.payBy"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="select"
                  className="custom-select form-control"
                  onChange={(v) => {
                    setValue(
                      'object',
                      { payBy: v.target.value, payByService: '' },
                      { shouldDirty: true, shouldValidate: true }
                    )
                  }}
                >
                  <option value="" disabled>
                    โปรดระบุวิธีการชำระเงิน
                  </option>
                  {staticContentPage.paymentMethod.options.map((v) => (
                    <option key={v.value} value={v.value}>
                      {v.text}
                    </option>
                  ))}
                </Input>
              )}
            />
            {errors?.object?.['payBy'] && renderErrorMessageBlock(errors?.object?.['payBy']?.message)}
          </div>
        </SectionContainer>
        {PayByServiceComponent({ errors, getValues, control })}
      </form>
    </ContentContainer>
  )
}

export default TransferInfoSection
