import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color, customIcon } from 'components/theme'
import { renderWarningMessageBlock } from 'components/message-blocks'

const DialogContainer = styled.div`
  text-align: center;
`
const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`
const SuccessIcon = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
  border: solid 10px ${color.type.success};
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &::before {
    display: block;
    width: 70px;
    height: 70px;
    background-color: ${color.type.success};
    content: '';
    mask-image: url(${customIcon('dialogSuccess')});
    mask-size: cover;
    mask-repeat: no-repeat;
  }
`
const WarningIcon = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;

  &::before {
    display: block;
    width: 120px;
    height: 120px;
    background-image: url(${customIcon('dialogWarning')});
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
  }
`
const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 15px;
`
const SubTitle = styled(Title)`
  margin: 5px 0;
  font-size: 1.2rem;
  font-weight: 400;
`
const Message = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 1rem;
  color: #333333;
`
const WarningMsgContainer = styled.div`
  max-width: 395px;
  text-align: justify;
  margin: 15px auto 15px;
`

type ECBRData = {
  cbrNumber: string
  policyNumber: string
  downloadEcbrStatus: string
  sendNotificationsStatus: string
}

type NewECBRCreatedDialogProps = {
  show: boolean
  onToggle: () => void
  ecbrData: ECBRData
}

const NewECBRCreatedDialog = (props: NewECBRCreatedDialogProps) => {
  const { show, onToggle, ecbrData } = props

  const isDownloadEcbrError = ecbrData?.downloadEcbrStatus === 'ERROR'
  const isSendNotificationsError = ecbrData?.sendNotificationsStatus === 'ERROR'

  const successContent = (
    <Content>
      <SuccessIcon />
      <Title>ดำเนินการเรียบร้อย</Title>
      <SubTitle>eCBR หมายเลข {ecbrData?.cbrNumber}</SubTitle>
      <SubTitle>หมายเลขกรมธรรม์: {ecbrData?.policyNumber}</SubTitle>
      <Message>ระบบได้ส่งใบรับเงินชั่วคราวให้ทาง SMS และ อีเมล</Message>
    </Content>
  )
  const errorContent = (
    <Content>
      <WarningIcon />
      <Title>กรุณาตรวจสอบ Email/SMS</Title>
      <WarningMsgContainer>
        {renderWarningMessageBlock(
          'หากท่านยังไม่ได้รับใบรับเงินชั่วคราว สามารถดำเนินการส่งใหม่ได้อีกครั้งที่เมนูประวัติการออกใบรับเงินชั่วคราว'
        )}
      </WarningMsgContainer>
      <SubTitle>eCBR หมายเลข {ecbrData?.cbrNumber}</SubTitle>
      <SubTitle>หมายเลขกรมธรรม์: {ecbrData?.policyNumber}</SubTitle>
    </Content>
  )

  return (
    <DialogContainer>
      <Dialog centered modal show={show} onClose={onToggle}>
        {isDownloadEcbrError || isSendNotificationsError ? errorContent : successContent}
        <Footer>
          <Button type={Button.Type.Primary} onClick={onToggle}>
            ยืนยัน
          </Button>
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default NewECBRCreatedDialog
