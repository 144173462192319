import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color } from 'components/theme'
import { attachmentItem } from '../hooks/service'
import PdfViewer from './pdf-viewer'

const DialogContainer = styled.div`
  text-align: center;
`
const Footer = styled.div<FooterDialog>`
  text-align: center;
  margin: 20px 0 10px;
  display: flex;
  justify-content: ${(props) => (props.isRemove ? 'space-between' : 'flex-end')};
`
const Title = styled.span`
  display: block;
  overflow-wrap: anywhere;
  color: ${color.type.primary};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 15px;
`

const Preview = styled.div`
  background-color: black;
  max-height: 550px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow-y: scroll;

  &::after {
    display: block;
    padding-bottom: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`
type PreviewDialogProps = {
  show: boolean
  onToggle: () => void
  onRemove?: () => void
  attachItem: attachmentItem
  onClickSubmit?: () => void
}

type FooterDialog = {
  isRemove?: boolean
}

const PreviewDialog = (props: PreviewDialogProps) => {
  const { show, onToggle, onRemove, attachItem, onClickSubmit } = props
  console.log(`PreviewDialog`)
  return (
    <DialogContainer>
      <Dialog centered modal show={show} onClose={onToggle} style={{ maxWidth: '100%' }}>
        <div>
          <Title>{attachItem.filename}</Title>
          <Preview>
            {attachItem.type.match(/pdf/gi) ? (
              <PdfViewer url={attachItem.src} />
            ) : (
              <img src={attachItem.src} alt={attachItem.alt} />
            )}
          </Preview>
        </div>
        <Footer isRemove={onRemove ? true : false}>
          {onRemove && (
            <Button type={Button.Type.Secondary} onClick={onRemove}>
              ลบ
            </Button>
          )}
          <Button type={Button.Type.Primary} onClick={onClickSubmit ? onClickSubmit : onToggle}>
            ตกลง
          </Button>
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default PreviewDialog
