import { useEffect, useContext } from 'react'

import { RouterContext, NavConfigPropsType } from '../../../contexts/router'

export const useRouterForHandleSummit = (currentSection, isActive, props, navConfig: NavConfigPropsType) => {
  const RouterContextProps = useContext(RouterContext)
  const setNavConfigByPage = RouterContextProps.setNavConfigByPage || props.setNavConfigByPage

  useEffect(() => {
    if (isActive) setNavConfigByPage(navConfig)
  }, [currentSection, props?.state])
}
