/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'

import styles from './message-block-styles'

const MESSAGE_BLOCK_TYPE = {
  Info: 'info',
  Warning: 'warning',
  Error: 'error',
}

function MessageBlock(props) {
  const { children, className, type } = props

  const blockStyles = [styles.block]

  if (Object.values(MESSAGE_BLOCK_TYPE).includes(type)) blockStyles.push(styles.type[type])

  return (
    <div css={blockStyles} className={className} data-type={type}>
      {children ? children : <>&nbsp;</>}
    </div>
  )
}

const propTypes = {
  /**
   * Elements to be rendered as children of this component.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * One or more class names to be added to the root element of this component, i.e. `"class-foo class-bar"`.
   */
  className: PropTypes.string,
  /**
   * Message Block type:
   *
   * * `info`
   * * `warning`
   * * `error`
   */ type: PropTypes.string,
}
const defaultProps = {
  children: null,
  className: '',
  type: MESSAGE_BLOCK_TYPE.Info,
}

MessageBlock.propTypes = propTypes
MessageBlock.defaultProps = defaultProps
MessageBlock.Type = { ...MESSAGE_BLOCK_TYPE }

export default MessageBlock
