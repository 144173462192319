import { useEffect, useRef, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'

import { useCreateCase, useGetEcbrCancelFrom, useUpdateStatusEcbrSys, useUpLoadDocument } from '../../hooks/service'

import staticContentPage from '../../static-content-page'
import { getToggles } from 'core/config'

const CANCEL_REASON = {
  ONE: '1',
  TWO: '2.1',
  THREE: '2.2',
  FOUR: '2.3',
  FIVE: 'other',
};

const PAYMENTMETHOD = {
  '01': 'ibanking',
  '03': 'quickpay',
  '05': 'cheque',
}

export const useSubmit = (props) => {
  const [data, setData] = useState({} as any)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { history, currentSection, setCurrentSection, getValues, state } = props

  const toggle = () => setModal(!modal)
  const closeModal = () => {
    toggle()
    history.push('/')
  }

  const handleSubmitData = async (dataFromLastTab) => {
    setLoading(true)
    const { attachment, infoIssue } = state
    let data = {}
    let file
    let uploadCancelFormAdditionalDocs: any = []
    if (currentSection === staticContentPage.cancelationSequence[2]){
      file = await useGetEcbrCancelFrom(dataFromLastTab, state)
    } else {
      const cancelDoc = attachment.filter((a) => a.status === 'done')[0]
      const doneDoc = attachment.filter((a) => a.status === 'done')
      for(let i=1; i < doneDoc.length; i++){
        if (doneDoc[i] != null){
          uploadCancelFormAdditionalDocs.push(doneDoc[i])
        }
      }
      file = cancelDoc.base64
    }
    const res = getToggles().ENABLE_ECBRSYS_ECBR ? await useUpdateStatusEcbrSys({
      ecbrNo: dataFromLastTab.object.ecbrNo,
      ecbrStatus: "CANCELLED",
      managerCode: dataFromLastTab.object.managerCode,
      inputManagerCode: dataFromLastTab.object.managerCode,
      createBy: "CB",
      remark: getCancelEcbrRemark(dataFromLastTab.object),
      ecbrCancelPDF: file.replace(/^data:application\/(png|gif|jpeg|pdf);base64,/, ''),
      additionalDoc: currentSection === staticContentPage.cancelationSequence[2] ? attachment.filter((a) => a.status === 'done'): uploadCancelFormAdditionalDocs,
      policyNo: infoIssue.policyNumber,
      insuredCitizenId: infoIssue.personalId,
      insuredFirstName: infoIssue.insuredName,
      insuredSurname: infoIssue.insuredSurName,
      paymentMethod: PAYMENTMETHOD[`${infoIssue.paymentChannel}`],
    }) : await useCreateCase(state)

    if (!getToggles().ENABLE_ECBRSYS_ECBR){
      const isAttachedFile = attachment.filter((a) => a.status === 'done')
      const uploadOtherFile = isAttachedFile.map(async (a) => {
        return await useUpLoadDocument({
          VersionData: a.base64,
          caseId: res.data.caseId,
          Title: a.filename,
          PathOnClient: a.alt,
        })
      })
      let upLoad
      if (currentSection === staticContentPage.cancelationSequence[2]) {
        const uploadEcbrCancelForm = await useUpLoadDocument({
          VersionData: file.replace(/^data:application\/(png|gif|jpeg|pdf);base64,/, ''),
          caseId: res.data.caseId,
          Title: 'จดหมายแจ้งยกเลิกใบรับเงินชั่วคราวอิเล็กทรอนิกส์',
          PathOnClient: 'จดหมายแจ้งยกเลิกใบรับเงินชั่วคราวอิเล็กทรอนิกส์.pdf',
        })
        upLoad = await Promise.all([...uploadOtherFile, uploadEcbrCancelForm])
      } else {
        upLoad = await Promise.all([...uploadOtherFile])
      }
      data = {
        cbrNumber: res.data.ecbrNumber,
        policyNumber: res.data.policyId,
      } as any
      if (res.error) {
        data = res
      }
    } else {
      data = {
        cbrNumber: res?.data?.data?.ecbrNo,
        status: res?.data?.data?.status
      }
      if (res.error) {
        data = res
      }
    }
    setData(data)
    setLoading(false)
    toggle()
  }
  const handleNextSection = () => {
    if (currentSection === staticContentPage.cancelationSequence[1]) {
      setCurrentSection(
        staticContentPage.mappingNextSection[currentSection][
          getValues(`object.${staticContentPage.cancelMethodSection.id}`)
        ]
      )
    } else {
      setCurrentSection(staticContentPage.mappingNextSection[currentSection])
    }
  }
  const getCancelEcbrRemark = (data)=> {
    const reasonEcbr = data.reasonEcbr
    let text = ''
      switch (reasonEcbr) {
        case CANCEL_REASON.ONE:
          text = `ขอยกเลิกเนื่องจากออกซ้ำกับเลขที่ ${data.reasonEcbrText1}`
          break;
        case CANCEL_REASON.TWO:
          text = `ขอยกเลิกเนื่องจากเช็คไม่ผ่านการเรียกเก็บ`
          break;
        case CANCEL_REASON.THREE:
          text = `ขอยกเลิกเพื่อขอเงินคืน (เอกสารขอคืนเงินส่งแผนก Payment Service)`
          break;
        case CANCEL_REASON.FOUR:
          text = `ขอยกเลิกเนื่องจากลูกค้าเปลี่ยนใจไม่ชำระเงิน (กรณียังไม่ได้มีการรับเงินจากลูกค้า)`
          break;
        case CANCEL_REASON.FIVE:
          text = `อื่นๆ ${data.reasonEcbrTextOther}`
          break;
        default:
          break;
      }
      return text
  }
  return { handleNextSection, handleSubmitData, loading, data, closeModal, modal }
}
