import { css } from '@emotion/react'
import { color, padding } from '../../theme'

const defaultStyle = {
  container: css`
    display: flex;
    cursor: pointer;
    height: 50px;
  `,
  side: (props) => css`
    min-width: 2.4rem;
    color: white;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-right: none !important;
    border: ${props.border};
    background: ${props.background ? props.background : color.type.primary};

    &:hover {
      background: ${color.type.primaryDark};
    }
  `,
  icon: css`
    width: 1.4rem;
    height: auto;
  `,
  description: css`
    border: 1px solid #2425aa;
    padding: ${padding.xs};
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex: 1 1 0%;
    background: white;
    color: ${color.type.primary};
  `,
}

const largeStyle = {
  container: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 2rem 0 2rem 0;
    height: 105px;
  `,
  icon: css`
    width: 4rem;
    height: auto;
  `,
  description: css`
    &:hover {
      text-decoration: underline;
    }
    color: ${color.type.primary};
  `,
}
export { defaultStyle, largeStyle }
