import * as yup from 'yup'
import _isEmpty from 'lodash/isEmpty'

import { validateCitizenId } from 'utils/validate'

const messageError = {
  general: 'โปรดระบุข้อมูล',
  policyNo: 'โปรดตรวจสอบข้อมูล [เลขที่กรมธรรม์ ในรูปแบบ 123-4567890]',
  personalId: 'โปรดระบุข้อมูล',
  policyEffectiveDate: 'โปรดระบุวันที่กรมธรรม์มีผลบังคับ',
  regEmail: 'โปรดระบุอีเมล',
  regMobile: 'โปรดระบุเบอร์โทรศัพท์',
}
const validateError = {
  general: 'โปรดตรวจสอบข้อมูล',
  citizenId: 'โปรดระบุเลขที่บัตรประชาชนให้ถูกต้อง',
  policy: 'โปรดตรวจสอบข้อมูล [เลขที่กรมธรรม์ ในรูปแบบ 123-4567890]',
  policyEffectiveDate: 'โปรดระบุวันที่กรมธรรม์มีผลบังคับให้ถูกต้อง',
}
export const personNameRegExp = /[^A-Za-z.\u0E01-\u0E5B]/gi
export const personNameWithSpaceRegExp = /[^A-Za-z.\u0E01-\u0E5B\s]/gi
export const policyNoReExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{7})$/
export const citizenIdReExp = /^\(?([0-9]{1})\)?[-. ]?([0-9]{4})?[-. ]?([0-9]{5})?[-. ]?([0-9]{2})?[-. ]?([0-9]{1})$/
const policyEffectiveDate = /^\(?([0-9]{4})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/
const passportExp = /^[a-z0-9]+$/gi

export const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

// TODO: ** GIFT ** change field name relate to textInputs.id from static-content
export const schemaRegisterInfoSection = yup.object().shape({
  //
  isAccountChecked: yup.boolean(),
  //
  daFormType: yup.string().required(messageError.general),
  bookBank: yup.object().test('not-empty', messageError.general, (value) => value && Object.keys(value).length > 0),
  insuredName: yup.string().required(messageError.general),
  insuredSurname: yup.string().required(messageError.general),
  payerName: yup.string().required(messageError.general),
  payerSurname: yup.string().required(messageError.general),
  bankName: yup.string().required(messageError.general),
  bankAccountType: yup.string().required(messageError.general),
  bankAccountNumber: yup.string().required(messageError.general),
  // bankAccountName: yup.string().required(messageError.general), // wait for remove
  bankAccountName: yup.string().when('isAccountChecked', {
    is: true,
    then: yup.string().required(messageError.general), // Need to fill after account checked
  }),
  regPersonalIdType: yup.string().required(messageError.general),
  regPersonalId: yup
    .string()
    .when('regPersonalIdType', {
      is: 'passport',
      then: yup.string().required(messageError.personalId).min(5, validateError.general).max(13, validateError.general),
    })
    .when('regPersonalIdType', {
      is: 'citizen',
      then: yup
        .string()
        .required(messageError.personalId)
        .test('validateCitizenId', validateError.citizenId, (value) => validateCitizenId(value)),
    }),
  regRelationship: yup.string().required(messageError.general),
  regRelationshipRemark: yup.string().when('regRelationship', {
    is: 'others',
    then: yup.string().required(messageError.general),
  }),
  regRelationshipDoc: yup.object().when(['regRelationship', 'regRelationshipRemark'], {
    is: (regRelationship, regRelationshipRemark) => regRelationship === 'others' && regRelationshipRemark !== '',
    then: yup.object().test('not-empty', messageError.general, (value) => value && Object.keys(value).length > 0),
  }),
  regMobile: yup.string().required(messageError.regMobile).matches(phoneRegExp, validateError.general),
  regEmail: yup.string().email(messageError.regEmail),
})

export const schemaInformationSection = yup.object().shape({
  searchMethod: yup.string().required(messageError.general),
  personalId: yup
    .string()
    .when('searchMethod', {
      is: 'passport',
      then: yup
        .string()
        .required(messageError.personalId)
        .matches(passportExp, validateError.general)
        .min(5, validateError.general)
        .max(13, validateError.general),
    })
    .when('searchMethod', {
      is: 'citizenId',
      then: yup
        .string()
        .required(messageError.personalId)
        .test('validateCitizenId', validateError.citizenId, (value) => validateCitizenId(value)),
    }),
  policyEffectiveDate: yup.string().when('searchMethod', {
    is: 'policyId',
    then: yup
      .string()
      .required(messageError.policyEffectiveDate)
      .matches(policyEffectiveDate, validateError.policyEffectiveDate),
  }),

  policyNo: yup
    .string()
    .matches(policyNoReExp, { message: validateError.policy, excludeEmptyString: true })
    .when('searchMethod', {
      is: 'policyId',
      then: yup.string().required(messageError.policyNo).matches(policyNoReExp, validateError.policy),
    }),
})

export const handleSetValue = (key, val, hookFormAPI, validateOption?) => {
  const { setValue, setError, clearErrors, formState } = hookFormAPI
  const { isSubmitted } = formState
  if (val) {
    if (validateOption) {
      const { regex, errorMessage } = validateOption
      if (!regex.test(val)) {
        setError(key, {
          type: 'manual',
          message: errorMessage,
        })
      } else {
        clearErrors(key)
        setValue(key, val)
      }
    } else {
      clearErrors(key)
      setValue(key, val)
    }
  } else {
    if (isSubmitted) {
      setError(key, {
        type: 'manual',
        message: messageError.general,
      })
    } else {
      clearErrors(key)
    }
  }
}

export const handleResetDataField = (reset: Function, fieldObject?: any) => {
  if (fieldObject) {
    reset(fieldObject)
  } else {
    reset({
      personalId: '',
      searchMethod: 'citizenId',
      policyNo: '',
      policyEffectiveDate: '',
    })
  }
}

export const validateRegisterFormInput = (obj) => {
  // Some fields not include regRelationshipRemark and regEmail are empty
  const isEmptyField = Object.keys(obj)
    .filter(
      (key) =>
        ![
          'regEmail',
          'regRelationshipRemark',
          'isAccountChecked',
          'bankAccountName',
          'regRelationshipDoc',
          'verifyAccStatus',
        ].includes(key)
    )
    .some((key) => _isEmpty(obj[key]))

  // Check regRelationshipRemark is empty when regRelationship is others
  const isRemarkForOtherRelationshipEmpty = obj.regRelationship === 'others' && _isEmpty(obj.regRelationshipRemark)
  const isCheckedAccountNameEmpty = obj.isAccountChecked && _isEmpty(obj.bankAccountName)
  const isCheckRelationshipDoc = obj.regRelationship === 'others' && _isEmpty(obj.regRelationshipDoc)

  if (isEmptyField || isRemarkForOtherRelationshipEmpty || isCheckedAccountNameEmpty || isCheckRelationshipDoc) {
    return false
  }

  return true
}
