import React, { useRef, useEffect } from 'react'
import WebViewer from '@pdftron/pdfjs-express-viewer'
import styled from '@emotion/styled'
import { getConfig } from 'core/config'

type pdfViewerType = {
  url: string
}

const ViewerContainer = styled.div`
  height: 100%;
`
const Container = styled.div`
  width: 100%;
  height: 550px;
`

const PdfViewer = ({ url }: pdfViewerType) => {
  const viewer = useRef(null)
  const { licensePDFlib } = getConfig()

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        licenseKey: licensePDFlib,
      },
      viewer.current
    ).then((instance) => {
      // now you can access APIs through the WebViewer instance
      instance.UI.loadDocument(url, { filename: 'myfile.pdf' })
      const { Core } = instance

      // adding an event listener for when a document is loaded
      Core.documentViewer.addEventListener('documentLoaded', () => {
        console.log('document loaded')
      })

      // adding an event listener for when the page number has changed
      Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
        console.log(`Page number is: ${pageNumber}`)
      })
    })
  }, [])

  return (
    <Container>
      <ViewerContainer className="webviewer" ref={viewer} />
    </Container>
  )
}

export default PdfViewer
