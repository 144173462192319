import { Connect } from 'adapters/repositories/ecbr-middleware/connect'
import { useCallback, useEffect, useState } from 'react'
import { useECBRPending } from '../../../../hooks/useServices/ecbr/index'
import { getToggles } from 'core/config'


export type EcbrList = {
  ecbrNo: string
  ecbrStatus: string
  insuredFirstName: string
  insuredLastName: string
  policyNo: string
  amount: number
  creatDate: string
  insuredEmail: any
  insuredMobilephone: string
}

type FeatureToggleFetchProps = {
  query?: string
  tab?: string
}

type FetchDataResult = {
  total: number
  data: any[] // any for now
  isLoaded: boolean
  isLoading: boolean
  error: string | null
}

export const useFeatureToggleEcbrSys = ({ query, tab = 'DRAFT' }: FeatureToggleFetchProps) => {
  const [data, setData] = useState<FetchDataResult>({
    total: 0,
    data: [],
    isLoaded: false,
    isLoading: true,
    error: null,
  })
  const [oldApiData] = useECBRPending({ query })

  const clearError = useCallback(() => {
    setData((prev) => ({ ...prev, error: null }))
  }, [])

  useEffect(() => {
    let isMounted = true

    const fetchNewApiData = async () => {
      if (!isMounted) return
      setData((prev) => ({ ...prev, isLoading: true, error: null }))

      try {
        let data
        const connectApi = new Connect()

        if (tab === 'DRAFT') {
          data = await connectApi.get('/ecbr/advisor/draft')
        } else if (tab === '') {
          data = await connectApi.post(
            '/ecbrsys/search',
            query
          )
        } else {
          data = await connectApi.post(
            '/ecbrsys/search',
            JSON.stringify({
              ecbrStatus: ['CREATED'],
              createBy: 'CB',
            })
          )
        }

        setData({
          ...data,
          isLoaded: true,
          isLoading: false,
          error: null,
        })
      } catch (error) {
        console.error('Error fetching data:', error)
        if (isMounted) {
          setData((prev) => ({
            total: 0,
            data: [],
            isLoaded: true,
            isLoading: false,
            error: 'API Failed',
          }))
        }
      }
    }

    if (getToggles().ENABLE_ECBRSYS_ECBR) {
      fetchNewApiData()
    } else {
      setData((prev) => ({ ...prev, isLoading: false, isLoaded: true }))
    }

    return () => {
      isMounted = false
    }
  }, [query, tab])

  return getToggles().ENABLE_ECBRSYS_ECBR
    ? ([data, clearError] as const)
    : ([{ ...oldApiData, isLoading: false, error: null }, () => {}] as const)
}
