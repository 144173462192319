// istanbul ignore file
//valueFormat enum = formattedValue | value  base on InputCustomFormat component

import { paymentMode, paymentMethod, paymentType, linkPaymentMethod, payerType } from 'domain/entities/ecbr'

const paymentMethodUpdateOption = (isHideCash, isEnableCCLINK, isHideEDC) => {
  const paymentMethodWithoutCash = paymentMethod.filter((p) => {
    if (isHideCash) {
      return p.value !== 'cash'
    }
    return p
  })
  const paymentMethodWithoutCC = paymentMethodWithoutCash.filter((p) => {
    if (!isEnableCCLINK) {
      return p.value !== 'quickpay'
    }
    return p
  })
  const paymentMethodWithoutEDC = paymentMethodWithoutCC.filter((p) => {
    if (isHideEDC) {
      return p.value !== 'creditcard'
    }
    return p
  })
  const result = paymentMethodWithoutEDC.map((p) => (p.value === 'creditcard' ? { ...p, text: 'บัตรเครดิต EDC' } : p))
  return result
}

export default {
  tabsProgress: [
    {
      iconId: 'ECBR_PAYMENT_INFO',
      id: 'info',
      title: 'ระบุข้อมูลการชำระเงิน',
      disabled: false,
    },
    {
      iconId: 'ECBR_PAYMENT_PROCESS',
      id: 'process',
      title: 'จัดทำใบชำระเงินชั่วคราว',
      disabled: true,
    },
    {
      iconId: 'ECBR_PAYMENT_SUMMARY',
      id: 'summary',
      title: 'ชำระเบี้ยประกัน',
      disabled: false,
    },
  ],

  informationSection: {
    mainTitle: 'ระบุข้อมูลการชำระเงิน',
    newEcbr: {
      text: 'เบี้ยงวดแรก',
      value: '01',
    },
    paymentType: {
      id: 'paymentType',
      title: 'ประเภทการชำระเงิน',
      options: paymentType,
    },
    policyNo: {
      label: 'เลขที่กรมธรรม์',
      id: 'policyNo',
      type: 'text',
      defaultValue: '',
      maxLength: 100,
      mask: 'X',
      format: '###-#######',
      valueFormat: 'formattedValue',
    },
    policyEffectiveDate: {
      label: 'วันที่กรมธรรม์มีผลบังคับ',
      id: 'policyEffectiveDate',
      type: 'text',
      defaultValue: '',
      maxLength: 10,
      mask: 'X',
      format: '####-##-##',
      valueFormat: 'formattedValue',
    },
    productCode: {
      id: 'productCode',
      title: 'ชื่อผลิตภัณฑ์ / แบบประกัน',
    },
    identity: {
      id: 'personalId',
      label: 'เลขบัตรประชาชนหรือหนังสือเดินทาง',
      mask: '_',
      format: '# #### ##### ## #',
      options: [
        {
          text: 'เลขที่บัตรประชาชนผู้เอาประกัน',
          value: 'citizenId',
        },
        {
          text: 'เลขหนังสือเดินทาง',
          value: 'passport',
        },
      ],
    },
    searchByPolicy: {
      id: 'searchByPolicy',
      text: 'ค้นหาจากเลขกรมธรรม์',
      value: 'policyId',
    },
    paymentScheduleMethods: {
      id: 'paymentMode',
      title: 'งวดการชำระเงิน',
      options: paymentMode,
    },
    payerType: {
      id: 'payerType',
      title: 'ระบุข้อมูลผู้ชำระเงิน',
      options: payerType,
    },
    payerFirstName: {
      title: 'ชื่อผู้ชำระเบี้ย',
      id: 'payerFirstName',
      type: 'text',
      defaultValue: '',
      condition: 'textOnly',
      maxLength: 30,
      change: false,
    },
    payerLastName: {
      title: 'นามสกุลผู้ชำระเบี้ย',
      id: 'payerLastName',
      type: 'text',
      defaultValue: '',
      condition: 'textOnly',
      maxLength: 50,
      change: false,
    },
    payerIdType: {
      id: 'payerIdType',
      title: 'เอกสารที่ใช้แสดง',
    },
    payerPersonalId: {
      id: 'payerPersonalId',
      citizenTitle: 'เลขประจำตัวประชาชน',
      passportTitle: 'หมายเลขหนังสือเดินทาง',
      mask: '_',
      format: '# #### ##### ## #',
    },
    payerRelations: {
      id: 'payerRelations',
      title: 'ความสัมพันธ์',
    },
    payerRelationsOthers: {
      id: 'payerRelationsOthers',
      type: 'text',
      defaultValue: '',
      condition: 'textOnly',
      maxLength: 30,
      change: false,
    },
    payerRelationshipDoc: {
      id: 'payerRelationshipDoc',
      title: 'เอกสารพิสูจน์ความสัมพันธ์',
    },
    textInputs: [
      {
        label: 'ชื่อผู้เอาประกัน',
        id: 'insuredName',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 30,
        change: false,
      },
      {
        label: 'นามสกุลผู้เอาประกัน',
        id: 'insuredSurName',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 50,
        change: false,
      },
      {
        label: 'เบอร์โทรศัพท์ผู้เอาประกัน',
        id: 'insureMobile',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
        mask: '_',
        format: '### ### ####',
        valueFormat: 'value',
        change: true,
      },
      {
        label: 'อีเมลผู้เอาประกัน',
        id: 'insureEmail',
        type: 'text',
        defaultValue: '',
        maxLength: 50,
        change: true,
      },
      {
        label: 'จำนวนเงินที่ชำระ (บาท)',
        id: 'paymentAmount',
        type: 'text',
        defaultValue: '',
        maxLength: 19,
        change: true,
        thousandSeparator: true,
        allowNegative: false,
        decimalScale: 2,
        fixedDecimalScale: true,
        isAllowed: ({ formattedValue }) => formattedValue.length <= 19,
      },
    ],
    categoryPaymentMethod: {
      id: 'paymentMethod',
      title: 'วิธีการชำระเงิน',
      options: (isHideCash, isEnableCCLINK, isHideEDC) =>
        paymentMethodUpdateOption(isHideCash, isEnableCCLINK, isHideEDC),
    },
  },

  ccLinkSection: {
    mainTitle: {
      id: 'ccLinkTitle',
      title: 'สร้างลิงก์ในการชำระเบี้ยประกัน',
    },
    ccLinkMode: {
      id: 'ccLinkMode',
      title: 'ท่านต้องการส่งลิงก์ชำระเบี้ยประกันที่',
      options: linkPaymentMethod,
    },
    genLinkMobile: {
      id: 'genLinkMobile',
      type: 'text',
      defaultValue: '',
      maxLength: 100,
      mask: '_',
      format: '### ### ####',
      valueFormat: 'value',
      change: true,
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      isAllowed: ({ formattedValue }) => formattedValue.length <= 19,
    },
    genLinkEmail: {
      id: 'genLinkEmail',
      type: 'text',
      defaultValue: '',
      maxLength: 50,
      change: true,
    },
  },

  checkStatusSection: {
    mainTitle: {
      id: 'checkStatusTitle',
      title: 'ชำระเบี้ยประกันด้วยบัตรเครดิตผ่านลิงก์ในการชำระ',
    },
    detail: {
      paymentType: 'รูปแบบการชำระเงิน',
      ccLink: 'บัตรเครดิต (ชำระผ่านลิงก์)',
      info: (date, time) =>
        `ลิงก์ในการชำระเงินของท่านจะหมดอายุภายในวันที่ ${date} เวลา ${time} น. หรือภายใน 60 นาทีหลังจากได้รับข้อความ กรุณาตรวจสอบการชำระเบี้ยกับผู้เอาประกัน`,
      buttonCheckStatus: 'ตรวจสอบการชำระเงิน',
      buttonCreateLink: 'สร้างลิงก์ชำระเงิน',
    },
  },

  chequeMethod: [
    {
      text: 'ธนาคารกรุงไทย',
      value: 'ธนาคารกรุงไทย',
    },
    {
      text: 'ธนาคารกรุงเทพ',
      value: 'ธนาคารกรุงเทพ',
    },
    {
      text: 'ธนาคารกสิกรไทย',
      value: 'ธนาคารกสิกรไทย',
    },
    {
      text: 'ธนาคารทหารไทยธนชาต',
      value: 'ธนาคารทหารไทยธนชาต',
    },
    {
      text: 'ธนาคารไทยพาณิชย์',
      value: 'ธนาคารไทยพาณิชย์',
    },
    {
      text: 'ธนาคารกรุงศรีอยุธยา',
      value: 'ธนาคารกรุงศรีอยุธยา',
    },
    {
      text: 'ธนาคารเกียรตินาคิน',
      value: 'ธนาคารเกียรตินาคิน',
    },
    {
      text: 'ธนาคารซีไอเอ็มบีไทย',
      value: 'ธนาคารซีไอเอ็มบีไทย',
    },
    {
      text: 'ธนาคารทิสโก้',
      value: 'ธนาคารทิสโก้',
    },
    {
      text: 'ธนาคารยูโอบี',
      value: 'ธนาคารยูโอบี',
    },
    {
      text: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
      value: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
    },
    {
      text: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
      value: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
    },
    {
      text: 'ธนาคารแลนด์ แอนด์ เฮาส์',
      value: 'ธนาคารแลนด์ แอนด์ เฮาส์',
    },
    {
      text: 'ธนาคารไอซีบีซี (ไทย)',
      value: 'ธนาคารไอซีบีซี (ไทย)',
    },
    {
      text: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
      value: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
    },
    {
      text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
      value: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
    },
    {
      text: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
      value: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
    },
    {
      text: 'ธนาคารออมสิน',
      value: 'ธนาคารออมสิน',
    },
    {
      text: 'ธนาคารอาคารสงเคราะห์',
      value: 'ธนาคารอาคารสงเคราะห์',
    },
    {
      text: 'ธนาคารอิสลามแห่งประเทศไทย',
      value: 'ธนาคารอิสลามแห่งประเทศไทย',
    },
    {
      text: 'ธนาคารอื่นๆ',
      value: 'other',
    },
  ],

  moneyReceiptSection: {
    mainTitle: 'เตรียมจัดทำใบรับเงินชั่วคราว',
    receipt: {
      lorem: 'Lorem ipsum dolor sit',
      loremAmet: 'Lorem ipsum dolor sit amet,',
      paymentCategory: 'ประเภทการชำระเงิน',
      paymentScheduleMethod: 'ชำระเบี้ยประกันแบบ',
      paymentMethod: 'วิธีการชำระเงิน',
      policyNumber: 'เลขที่กรมธรรม์',
      productName: 'ชื่อผลิตภัณฑ์ / แบบประกัน',
      passportNumber: 'เลขบัตรประชาชน / หนังสือเดินทางผู้เอาประกัน',
      name: 'ชื่อผู้เอาประกัน',
      lastName: 'นามสกุลผู้เอาประกัน',
      phoneNumber: 'เบอร์โทรศัพท์ผู้เอาประกัน',
      email: 'อีเมล',
      amount: 'จำนวนเงินที่ต้องชำระ',
      payerName: 'ชื่อและนามสกุลผู้ชำระเบี้ย',
      payerPersonalId: 'เลขบัตรประชาชน / หนังสือเดินทางผู้ชำระเบี้ย',
      payerRelations: 'ความสัมพันธ์',
    },
  },

  paymentInsurancePremiumSection: {
    paymentCategory: 'ประเภทการชำระเงิน',
    passportNumber: 'เลขที่บัตรประชาชนผู้เอาประกันภัย',
    fullName: 'ชื่อ-สกุลผู้เอาประกัน',
    ref1: 'Ref 1:',
    ref2: 'Ref 2:',
    paymentDate: 'วันที่ชำระ',
  },
  defaultPolicyNo: '222-2222222',

  paymentStatus: {
    EXPIRED: {
      title: 'ผลการตรวจสอบการชำระเงิน',
      message: (date, time) =>
        `ลิงก์สำหรับชำระเบี้ยประกัยหมดอายุแล้วเมื่อวันที่ ${date} เวลา ${time} น.<br/>กรุณากด ยืนยัน เพื่อสร้างลิงก์ชำระอีกครั้ง`,
    },
    ERROR: {
      title: 'ผลการตรวจสอบการชำระเงิน',
      message: () => `ระบบการตรวจสอบการชำระเบี้ยขัดข้อง<br/>กรุณากด ยืนยัน เพื่อกลับไปทำการตรวจสอบการชำระเงินอีกครั้ง`,
    },
    QP_ERROR: {
      // for systemization
      title: 'ผลการตรวจสอบการชำระเงิน',
      message: () => `ขณะนี้ระบบไม่สามารถตรวจสอบการชำระเงินได้<br/>กรุณาแนบหลักฐานการชำระเงินด้วยตนเอง`,
    },
    PENDING: {
      title: 'ผลการตรวจสอบการชำระเงิน',
      message: () => 'ไม่พบข้อมูลการชำระเงิน กรุณาตรวจสอบสถานะการชำระเบี้ยประกันกับผู้เอาประกันอีกครั้ง',
    },
  },

  createEcbrNoStatus: {
    ERROR: {
      title: 'ระบบไม่สามารถออกใบรับเงินชั่วคราวได้',
      message: () =>
        `ขออภัยในความไม่สะดวก ขณะนี้ระบบไม่สามารถดำเนินการออกใบรับเงินชั่วคราวได้<br/>กรุณาลองใหม่อีกครั้ง`,
    },
    NO_PAYMENT: {
      title: 'ผลการตรวจสอบการชำระเงิน',
      message: () => `ตรวจสอบไม่พบรายการชำระเงิน กรุณากดยืนยันเพื่อกลับสู่หน้าชำระเงินอีกครั้ง`,
    },
    PREMIUM_DOWN: {
      title: 'ระบบไม่สามารถออกใบรับเงินชั่วคราวได้',
      message: () => `ขณะนี้ระบบไม่สามารถตรวจสอบการชำระเงินได้ กรุณาแนบหลักฐานการชำระเงินด้วยตนเอง`,
    },
    SUBMIT_LIMIT_EXCEEDED: {
      title: 'ผลการตรวจสอบการชำระเงิน',
      message: () => `ตรวจสอบไม่พบรายการชำระเงิน กรุณากดยืนยันเพื่อดำเนินการแนบหลักฐานการชำระเงิน`,
    },
  },
}
