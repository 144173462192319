import React, { useEffect, useState, useContext } from 'react'
import { Input as RSInput } from 'reactstrap'
import { useForm, useController, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from '@emotion/styled'
import _isEmpty from 'lodash/isEmpty'
import _omitBy from 'lodash/omitBy'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import { Radio } from 'ktaxa-ui-components/index'
import { InputCustomFormat, Dropdown } from 'components/input'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { createCcpaPaymentLink } from '../hooks/useService'

import { Button } from 'ktaxa-ui-components/index'

import SubmitRegisterSection from './submitRegister'

import { renderInfoMessageBlock, renderErrorMessageBlock } from 'components/message-blocks'

import { schemaRegisterInfoSection, personNameRegExp, validateRegisterFormInput } from '../hooks/useForm'

import { useRouterForHandleSubmit } from '../hooks/useRouter'
import { AppContext } from 'contexts/app'
import staticContentPage from '../static-content'
import CreateCcpaLinkDialog from '../components/create-ccpa-link-dialog'
import { CreateCcpaLinkResultType } from 'types/plan-code'
import LoadingModal from '../components/load-data-dialog'

const MessageBlock = styled.div`
  margin: 30px 0px 30px 0px;
`

const RegisterFormSection = (props) => {
  const { tabState, state, setState, setTabState } = props
  const { ccpaPlanCodeOptions, setCcpaInfo, setIsVerifyFormValidateError, ccpaInfo } = useContext(AppContext)

  const { formState, control, reset, handleSubmit, getValues, watch, setValue, clearErrors, resetField, trigger } =
    useForm({
      mode: 'all',
      resolver: yupResolver(schemaRegisterInfoSection),
    })

  const { errors, isValid } = formState
  const ActiveCondition = tabState === staticContentPage.tabsProgress[1].id

  const defaultValueCCPA = _get(state, 'verify.searchCCPAInfo.data.ccpaDeatil.data', null)
  const defaultValuePolicy = _get(state, 'verify.searchCCPAInfo.data.policyDetail.data', null)
  const defaultSendLinkMode = 'sms'
  const defaultPersonalIdType = 'citizen'
  const regexCitizenId = /^\d{13}$/

  const [selectedSendLinkMode, setSelectedSendLinkMode] = useState(defaultSendLinkMode)
  const [selectedPersonalIdType, setSelectedPersonalIdType] = useState(defaultPersonalIdType)

  // submit for create link
  const [isShowCreateLinkResultModal, setIsShowCreateLinkResultModal] = useState(false)
  const [isCreateLinkSuccess, setIsCreateLinkSuccess] = useState(false)
  const [createCcpaLinkResult, setCreateCcpaLinkResult] = useState<CreateCcpaLinkResultType | null>(null)
  const [fieldInputDisabled, setFieldInputDisabled] = useState<any[]>([])
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  const [isValidateForm, setIsValidateForm] = useState(false)

  const getKeyDisableField = (defaultState) => {
    let enableField = ['regSendLinkType', 'regMobile', 'regEmail']
    if (_isEmpty(defaultState['regPersonalId'])) {
      enableField = ['regPersonalIdType', 'regSendLinkType', 'regMobile', 'regEmail']
    }
    const omitEmptyValue = _omitBy(defaultState, _isEmpty)
    const omitByCase = _omit(omitEmptyValue, [...enableField])
    return Object.keys(omitByCase)
  }

  const checkDisableField = (fieldName) => {
    return !_isEmpty(fieldInputDisabled) ? fieldInputDisabled.includes(fieldName) : false
  }

  // initial state
  useEffect(() => {
    const defaultPersonalId =
      defaultValueCCPA?.citizenId || defaultValuePolicy?.partyIdentifiers[0].idDocumentNumber || ''
    const initPersonalIdType =
      defaultPersonalId === '' || defaultPersonalId.match(regexCitizenId) ? 'citizen' : 'passport'

    //! in case user click back button, then use ccpaInfo to set default value
    if (!_isEmpty(ccpaInfo)) {
      const { policyNo, citizenId, planCode, insuredFirstName, insuredLastName, email, phone, sendMethod } = ccpaInfo
      const initSendLinkMode = sendMethod === 'SMS' ? 'sms' : 'email'
      const initPersonalIdType = citizenId === '' || citizenId.match(regexCitizenId) ? 'citizen' : 'passport'

      reset({
        regName: insuredFirstName || '',
        regSurname: insuredLastName || '',
        regPolicyNo: policyNo || '',
        regPersonalIdType: initPersonalIdType,
        regPersonalId: citizenId || '',
        regPaymentType: '02',
        regProductCode: planCode || '',
        regSendLinkType: initSendLinkMode,
        regEmail: initSendLinkMode === 'email' ? email : '',
        regMobile: initSendLinkMode === 'sms' ? phone : '',
      })

      setSelectedSendLinkMode(initSendLinkMode)
      setSelectedPersonalIdType(initPersonalIdType)
      return
    }

    reset({
      regName: defaultValueCCPA?.insuredFirstName || defaultValuePolicy?.firstName || '',
      regSurname: defaultValueCCPA?.insuredLastName || defaultValuePolicy?.lastName || '',
      regPolicyNo: defaultValueCCPA?.policyNo || defaultValuePolicy?.policyNumber || '',
      regPersonalIdType: initPersonalIdType,
      regPersonalId: defaultPersonalId,
      regPaymentType: '02',
      regProductCode: defaultValueCCPA?.planCode || defaultValuePolicy?.productCode || '',
      regSendLinkType: 'sms',
      regEmail: '',
      regMobile: '',
    })

    setSelectedPersonalIdType(initPersonalIdType)
    setFieldInputDisabled(getKeyDisableField(getValues()))
  }, [state, ActiveCondition])

  const { regInfoSection: formInfo } = staticContentPage

  const onCreateLink = async () => {
    const formValues = getValues()

    // confirm -> loading -> show success -> click confirm -> loading -> redirect
    const payload = {
      policyNo: formValues.regPolicyNo,
      citizenId: formValues.regPersonalId.replace(/ +/g, ''),
      planCode: formValues.regProductCode,
      insuredFirstName: formValues.regName,
      insuredLastName: formValues.regSurname,
      email: formValues.regEmail,
      phone: formValues.regMobile.replace(/ +/g, ''),
      channelPrefix: 'CB',
      sendMethod: formValues.regSendLinkType === 'sms' ? 'SMS' : 'EMAIL',
      policyStatus: defaultValuePolicy?.policyStatusCode || '00',
    }
    const createCcpaLinkResult = await createCcpaPaymentLink(payload)
    if (createCcpaLinkResult.data) {
      setTimeout(() => {
        setIsLoadingModal(false)

        setIsCreateLinkSuccess(true)
        setIsShowCreateLinkResultModal(true)

        setCreateCcpaLinkResult(createCcpaLinkResult.data!)
        setCcpaInfo({ ...createCcpaLinkResult.data, ...payload })
      }, 1500)
    } else {
      setTimeout(() => {
        setIsLoadingModal(false)
        setIsShowCreateLinkResultModal(true)
        setIsCreateLinkSuccess(false)
      }, 1500)
    }
  }

  const onSubmit = () => {
    trigger()
    onCreateLink()
    setIsLoadingModal(true)
  }

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <Button onClick={() => setTabState(staticContentPage.tabsProgress[0].id)} type="secondary">
          กลับ
        </Button>
      ),
      extraButton: () => <SubmitRegisterSection tabState={tabState} handleSubmit={onSubmit} state={state} />,
    }
  )

  useEffect(() => {
    const validateFormResult = validateRegisterFormInput(getValues())

    setIsValidateForm(validateFormResult)
  }, [isValidateForm, getValues()])

  useEffect(() => {
    if (watch('regSendLinkType') === 'sms') {
      clearErrors('regEmail')
    } else {
      clearErrors('regMobile')
    }
  }, [selectedSendLinkMode])

  if (!ActiveCondition) return null
  return (
    <>
      <ContentContainer>
        <MessageBlock>
          {renderInfoMessageBlock(
            'กรุณาตรวจสอบความถูกต้องของข้อมูลของท่าน เพื่อความถูกต้องในการสมัครสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต'
          )}
        </MessageBlock>

        <form
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.shiftKey === false) {
              e.preventDefault()
            }
          }}
        >
          <SectionContainer>
            {formInfo.textInputs.map((ti, key) => {
              return (
                <div key={ti.id} className="name-input" style={{ paddingBottom: 20 }}>
                  <label style={{ display: 'block' }}>{ti.label}</label>
                  <Controller
                    name={ti.id}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => {
                      if (Object.keys(errors).length > 0 || !isValidateForm) {
                        setIsVerifyFormValidateError(true)
                      } else {
                        setIsVerifyFormValidateError(false)
                      }

                      if (ti.id === 'regPersonalIdType') {
                        return (
                          <SectionContainer>
                            <div
                              style={{
                                display: 'block',
                              }}
                            >
                              <SectionContainer margin={'10'}>
                                <Controller
                                  name={ti.id}
                                  control={control}
                                  render={({ field }) => (
                                    <Radio.Group
                                      disabled={checkDisableField(formInfo.textInputs[key].id)}
                                      uiStyle={Radio.UIStyle.ButtonGroup}
                                      onChange={(option) => {
                                        setSelectedPersonalIdType(option.value)
                                        resetField(ti?.subInputId || '')
                                        return field.onChange(option.value)
                                      }}
                                      value={selectedPersonalIdType}
                                    >
                                      {ti.options?.map((option) => (
                                        <Radio key={option.value} value={option.value} label={option.text} />
                                      ))}
                                    </Radio.Group>
                                  )}
                                />
                              </SectionContainer>
                              {selectedPersonalIdType === 'citizen' ? (
                                <Controller
                                  // Citizen case
                                  name={ti?.subInputId || ''}
                                  control={control}
                                  key={'citizen'}
                                  render={({ field }) => {
                                    return (
                                      <InputCustomFormat
                                        {...field}
                                        getInputRef={field.ref}
                                        disabled={checkDisableField(formInfo.textInputs[key].subInputId)}
                                        mask={ti.mask}
                                        format={ti.format}
                                      />
                                    )
                                  }}
                                />
                              ) : (
                                <Controller
                                  // Passport case
                                  name={ti.subInputId || ''}
                                  control={control}
                                  key={'passport'}
                                  render={({ field }) => {
                                    return (
                                      <RSInput
                                        {...field}
                                        innerRef={field.ref}
                                        disabled={checkDisableField(formInfo.textInputs[key].subInputId)}
                                        type="text"
                                      />
                                    )
                                  }}
                                />
                              )}
                              {renderErrorMessageBlock(errors[ti.id])}
                            </div>
                          </SectionContainer>
                        )
                      } else if (ti.type === 'select' && ti.id === 'regProductCode') {
                        const value =
                          getValues(ti.id) && !_isEmpty(ccpaPlanCodeOptions)
                            ? ccpaPlanCodeOptions.find((x) => x.value === getValues(ti.id))
                            : null
                        return (
                          <Dropdown
                            {...field}
                            isClearable
                            placeholder=""
                            onChange={(option) => field.onChange(option?.value)}
                            value={
                              getValues(ti.id) && !_isEmpty(ccpaPlanCodeOptions)
                                ? ccpaPlanCodeOptions.find((x) => x.value === getValues(ti.id))
                                : null
                            }
                            options={ccpaPlanCodeOptions}
                            isDisabled={checkDisableField(formInfo.textInputs[key].id)}
                          />
                        )
                      } else if (ti.type === 'select') {
                        return (
                          <Dropdown
                            {...field}
                            isClearable
                            placeholder={''}
                            onChange={(option) => field.onChange(option?.value)}
                            value={ti.defaultValue}
                            options={ti.options}
                            isDisabled={ti.isDisabled || checkDisableField(formInfo.textInputs[key].id)}
                          />
                        )
                      } else if (ti.format) {
                        //! case have format
                        return (
                          <InputCustomFormat
                            {...field}
                            disabled={checkDisableField(formInfo.textInputs[key].id)}
                            mask={ti.mask}
                            format={ti.format}
                          />
                        )
                      } else {
                        return (
                          <RSInput
                            {...field}
                            id={ti.id}
                            innerRef={field.ref}
                            disabled={checkDisableField(formInfo.textInputs[key].id)}
                            type="text"
                            maxLength={ti.maxLength}
                            onChange={(v) => {
                              let value = v.target.value
                              if (ti.condition === 'textOnly') value = value.replace(personNameRegExp, '')
                              return field.onChange(value)
                            }}
                          />
                        )
                      }
                    }}
                  />
                  {ti.id === 'regPersonalIdType'
                    ? renderErrorMessageBlock(errors[ti.subInputId || ''])
                    : renderErrorMessageBlock(errors[ti.id])}
                </div>
              )
            })}
          </SectionContainer>

          <SectionContainer>
            <div
              style={{
                display: 'block',
              }}
            >
              <SectionContainer margin={'10'}>
                <label>{formInfo.sendLinkSection.sendLinkMode.title}</label>
                <Controller
                  name={formInfo.sendLinkSection.sendLinkMode.id}
                  control={control}
                  render={({ field }) => (
                    <Radio.Group
                      uiStyle={Radio.UIStyle.ButtonGroup}
                      disabled={checkDisableField(formInfo.sendLinkSection.sendLinkMode.id)}
                      onChange={(option) => {
                        setSelectedSendLinkMode(option.value)
                        clearErrors(option.value === 'sms' ? 'email' : 'sms')
                        return field.onChange(option.value)
                      }}
                      value={selectedSendLinkMode}
                    >
                      {formInfo.sendLinkSection.sendLinkMode.options.map((option) => (
                        <Radio key={option.value} value={option.value} label={option.text} />
                      ))}
                    </Radio.Group>
                  )}
                />
              </SectionContainer>
              {selectedSendLinkMode === 'sms' ? (
                <Controller
                  // SMS case
                  name={formInfo.sendLinkSection.sendLinkMobile.id}
                  control={control}
                  key={'sms'}
                  render={({ field }) => {
                    return (
                      <InputCustomFormat
                        {...field}
                        getInputRef={field.ref}
                        id={formInfo.sendLinkSection.sendLinkMobile.id}
                        data-testid={formInfo.sendLinkSection.sendLinkMobile.id}
                        mask={formInfo.sendLinkSection.sendLinkMobile.mask}
                        format={formInfo.sendLinkSection.sendLinkMobile.format}
                        isAllowed={formInfo.sendLinkSection.sendLinkMobile.isAllowed}
                        onValueChange={(numberFormatValues) => field.onChange(numberFormatValues.value)}
                      />
                    )
                  }}
                />
              ) : (
                <Controller
                  // Email case
                  name={formInfo.sendLinkSection.sendLinkEmail.id}
                  control={control}
                  key={'email'}
                  render={({ field }) => {
                    return (
                      <RSInput
                        {...field}
                        innerRef={field.ref}
                        id={formInfo.sendLinkSection.sendLinkEmail.id}
                        data-testid={formInfo.sendLinkSection.sendLinkEmail.id}
                        type="email"
                      />
                    )
                  }}
                />
              )}
              {renderErrorMessageBlock(
                errors[
                  getValues(formInfo.sendLinkSection.sendLinkMode.id) === 'sms'
                    ? formInfo.sendLinkSection.sendLinkMobile.id
                    : formInfo.sendLinkSection.sendLinkEmail.id
                ]
              )}
              {renderInfoMessageBlock('ท่านสามารถเลือกช่องทางในการส่งลิงก์')}
            </div>
          </SectionContainer>
        </form>
      </ContentContainer>
      <CreateCcpaLinkDialog
        onCreateLink={onCreateLink}
        show={isShowCreateLinkResultModal}
        createCCPALinkData={createCcpaLinkResult}
        isSuccess={isCreateLinkSuccess}
        setIsShowCcpaLinkDialog={setIsShowCreateLinkResultModal}
        tabState={tabState}
        setTabState={setTabState}
      ></CreateCcpaLinkDialog>
      <LoadingModal show={isLoadingModal} />
    </>
  )
}

export default RegisterFormSection
