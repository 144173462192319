import styled from '@emotion/styled'

import { device } from 'components/theme'

interface BlockTextProps {
  width?: string
  right?: boolean
  paddingBottom?: string
  line?: boolean
}

type SectionContainerProps = {
  margin?: string
}

export const BlockText = styled.div<BlockTextProps>`
  width: ${(props) => (props.width ? props.width : '50%')};
  text-align: ${(props) => (props.right ? 'right' : 'left')};
  padding-top: 15px;
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : '0px')};
  border-top: ${(props) => (props.line ? 'outset 0.1rem' : 'none')};

  .icon_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  overflow: auto;
  max-width: 620px;
  margin: 0 auto;
  padding: 15px;

  @media (min-width: ${device.screenSize.md}) {
    max-width: 740px;
  }

  h1 {
    margin: 15px 0 30px;
    color: #2425aa;
    font-weight: 700;
    font-size: 1.35rem;
  }

  h2 {
    color: #2425aa;
    font-weight: 700;
    font-size: 1.125rem;
  }
`

export const SectionContainer = styled.div<SectionContainerProps>`
  margin-bottom: ${(props) => (props.margin ? props.margin : '30')}px;
  width: 100%;
`

export const Spacer = styled.div`
  height: 30px;
`

export const HeaderContainer = styled.div`
  display: inline-flex;
  align-items: baseline;

  span {
    margin-left: 0.5rem;
    color: #2425aa;
  }
`

export default { BlockText, ContentContainer, SectionContainer, Spacer, HeaderContainer }
