import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import dayjs from 'dayjs'

import { color } from 'components/theme'
import { formatNumber } from 'utils/string-helper'
import { Button } from 'rsuite'
import { getToggles } from 'core/config'

const STATUS = {
  RESERVED: {
    text: 'รอลูกค้าชำระเงิน',
    color: color.status.pending,
  },
  PENDING: {
    text: 'รอชำระเงินเข้าบริษัท',
    color: color.status.transfer,
  },
  PAID: {
    text: 'ชำระแล้วรอตรวจสอบ',
    color: color.status.verifying,
  },
  COMPLETED: {
    text: 'บริษัทรับชำระเงินเรียบร้อย',
    color: color.status.completed,
  },
  CANCELLED: {
    text: 'ยกเลิก',
    color: color.status.cancelled,
  },
  USED: {
    text: 'บริษัทรับชำระเรียบร้อย',
    color: color.status.done,
  },
}

const Text = styled.p`
  margin: 0 0 4px;
  font-weight: ${(p) => (p.strong ? 700 : null)};
`
const Name = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
`
const Content = styled.div`
  display: flex;
`
const Col = styled.div`
  flex: ${(p) => (p.flex2 ? 2 : 1)};
  overflow: hidden;
  color: ${(p) => (p.info ? color.type.primary : null)};
  text-align: ${(p) => (p.info ? 'right' : null)};
`

const Status = styled.p`
  margin: 0 0 4px;
  color: ${(props) => STATUS?.[props.status]?.color};
`

const Container = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  background-color: ${color.common.white};
  box-shadow: 0 0 0 1px ${color.element.border};
  padding: 15px 10px 15px 15px;
  font-size: 0.8rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    background-color: ${(props) => STATUS?.[props.status]?.color};
    content: '';
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
`

const propTypes = {
  name: PropTypes.string,
  policyNumber: PropTypes.string,
  amount: PropTypes.number,
  status: PropTypes.string,
  paymentNo: PropTypes.string,
  createdAt: PropTypes.string,
  daySinceCreated: PropTypes.number,
  onClick: PropTypes.func,
  caseNumber: PropTypes.string,
  iProStatus: PropTypes.string,
  handleIsOpenResentEcbrModalAgent: PropTypes.func,
  handleIsOpenResentEcbrModalCustomer: PropTypes.func,
  insuredEmail: PropTypes.string,
  insuredMobilephone: PropTypes.string,
  page: PropTypes.string,
  selectedTab: PropTypes.string,
}

const defaultProps = {
  name: '',
  policyNumber: '',
  amount: 0,
  status: STATUS.WAITING_FOR_PAYMENT,
  paymentNo: '',
  createdAt: '',
  daySinceCreated: 0,
  onClick() {},
  caseNumber: '',
  iProStatus: '1',
}

const ECBRCard = (props) => {
  const {
    name,
    policyNumber,
    amount,
    status,
    paymentNo,
    createdAt,
    daySinceCreated,
    onClick,
    caseNumber,
    iProStatus,
    handleIsOpenResentEcbrModalAgent,
    handleIsOpenResentEcbrModalCustomer,
    insuredEmail,
    insuredMobilephone,
    page,
    selectedTab,
  } = props
  const validStatus = Object.keys(STATUS).includes(status) ? status : 'RESERVED'
  const statusText = STATUS[validStatus] ? STATUS[validStatus]?.text : STATUS.RESERVED?.text

  const dateText = dayjs(createdAt).tz('Asia/Bangkok').format('DD/MM/YYYY')
  const timeText = dayjs(createdAt).tz('Asia/Bangkok').format('HH:mm')
  const mappingIProStatus = {
    1: 'New',
    2: 'In progress',
  }

  const isHideEcbrMenu = getToggles().ENABLE_ECBRSYS_ECBR && selectedTab === 'DRAFT'
  const isEnableIssueButton = validStatus === 'USED' || validStatus === 'RESERVED'

  return (
    <Container status={validStatus}>
      <div onClick={onClick}>
        <Content>
          <Col>
            <Name>{name}</Name>
          </Col>
          {caseNumber ? (
            <Col info flex2>
              <Text>
                สถานะบนระบบ iPro: {mappingIProStatus[iProStatus] ? mappingIProStatus[iProStatus] : iProStatus} / Case
                No.
                {caseNumber}
              </Text>
            </Col>
          ) : null}
        </Content>
        <Content>
          <Col>
            <Text>เลขที่กรมธรรม์ {policyNumber || '-'}</Text>
            <Status status={validStatus}>สถานะ: {statusText}</Status>
            {page !== 'history' && <Text>ค้างชำระ {daySinceCreated} วัน</Text>}
          </Col>
          <Col info>
            <Text>จำนวนเงิน {formatNumber(amount)} บาท</Text>
            {isHideEcbrMenu ? (
              <Text
                style={{
                  visibility: 'hidden',
                }}
              >
                No data
              </Text>
            ) : (
              <Text>ใบรับเงินเลขที่ {paymentNo}</Text>
            )}
            <Text>
              วันที่ {dateText} เวลา {timeText} น.
            </Text>
          </Col>
        </Content>
      </div>
      <div>
        {isEnableIssueButton && page === 'history' && getToggles().ENABLE_ECBRSYS_ECBR ? (
          <ButtonContainer>
            <Button
              appearance="ghost"
              style={{ marginRight: '10px', fontSize: '20px' }}
              onClick={() =>
                handleIsOpenResentEcbrModalCustomer({
                  value: true,
                  insuredEmail,
                  insuredMobilephone,
                  ecbrInfo: {
                    ecbrNo: paymentNo,
                    policyNumber: policyNumber,
                    cbrIssueDate: createdAt,
                    paymentAmount: amount,
                  },
                })
              }
            >
              ออกใบรับเงินให้ ลูกค้า
            </Button>
            <Button
              appearance="primary"
              style={{ marginLeft: '10px', fontSize: '20px' }}
              onClick={() => handleIsOpenResentEcbrModalAgent({ value: true, ecbrNo: paymentNo })}
            >
              ออกใบรับเงินให้ ตัวแทน
            </Button>
          </ButtonContainer>
        ) : null}
      </div>
    </Container>
  )
}

const getStatus = (items) => {
  const list = {}
  Object.keys(items).forEach((k) => {
    list[k] = k
  })
  return list
}

ECBRCard.propTypes = propTypes
ECBRCard.defaultProps = defaultProps
ECBRCard.Status = getStatus(STATUS)

export { ECBRCard, STATUS }
