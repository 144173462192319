import { useEffect, useState } from 'react'

import { ecbrService } from 'services/ecbr-middleware'
import { IEcbrUseCase } from 'domain/useCases/ecbr'

interface IblockIssueConfig {
  pendingBlock: boolean
  suspendBlock: boolean
  warningMessage: string
}

export const useVerifyBlockIssue = (props?): [IblockIssueConfig, boolean] => {
  const [loading, setLoading] = useState<boolean>(true)
  const ecbrServiceApi: IEcbrUseCase = props?.ecbrServiceApi || ecbrService
  const [blockIssueConfig, setBlockIssueConfig] = useState<IblockIssueConfig>({
    pendingBlock: false,
    suspendBlock: false,
    warningMessage: '',
  })

  useEffect(() => {
    async function fetchVerify() {
        const res = await ecbrServiceApi.VerifyBlockIssue()
        setBlockIssueConfig({
          pendingBlock: res.pendingBlock,
          suspendBlock: res.suspendBlock,
          warningMessage: res.warningMessage,
        })
        setLoading(false)
    }
    fetchVerify()
  }, [])

  return [blockIssueConfig, loading]
}
