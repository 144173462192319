import PdfRepository from '../../repositories/pdf'

export default class PdfUseCase implements IPdfUseCase {
  pdfRepo: PdfRepository

  constructor(er: PdfRepository) {
    this.pdfRepo = er
  }

  async GenPdf(template: string, data: any): Promise<string> {
    return this.pdfRepo.GenPdf(template, data)
  }
  async GenPdfSigning(template: string, data: any): Promise<string> {
    return this.pdfRepo.GenPdfSigning(template, data)
  }
}

export interface IPdfUseCase {
  GenPdf(template: string, data: any): Promise<string>
  GenPdfSigning(template: string, data: any): Promise<string>
}
