import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { Button } from 'ktaxa-ui-components/index'
import { ErrorDialog } from 'components/index'

import ECBRPaidDialog from 'components/modal/ecbr-systemization/ecbr-created-dialog'
import CreateEcbrNumberStatusDialog from 'components/modal/ecbr-systemization/create-ecbr-number-status-dialog'
import staticContentPage from '../../static-content'

import { useSubmit } from './hook'
import { getToggles } from 'core/config'
import _isEmpty from 'lodash/isEmpty'
import { createEcbrNumber, updateEcbr } from '../../../new-ecbr/hooks/useService'

const SubmitSection = (props) => {
  const { handleSubmit, tabState, history, state, setState } = props
  const { handleSubmitData, onSubmitData, data, setData, loading, modal, closeModal, toggle, setLoading } =
    useSubmit(props)

  const [isDisabled, setIsDisabled] = useState(false)

  const receiptId = state?.pendingList?.receiptId || state?.info?.payload?.receiptId

  const isEnableEcbrSys = getToggles().ENABLE_ECBRSYS_ECBR && !_isEmpty(receiptId)

  const isQrNoPayment = data?.data?.createEcbrNoStatus === 'NO_PAYMENT'
  const isPremiumDown = data?.data?.createEcbrNoStatus === 'PREMIUM_DOWN'
  const isSubmitLimitExceeded = data?.data?.createEcbrNoStatus === 'SUBMIT_LIMIT_EXCEEDED'
  const isCreateEcbrError = data?.data?.createEcbrNoStatus === 'ERROR'

  const isQrUpdateSlip = isPremiumDown || isSubmitLimitExceeded // systemization
  const createEcbrNumberStatusDialogCondition = isQrNoPayment || isQrUpdateSlip || isCreateEcbrError

  const handleToggleCreateEcbrStatus = () => {
    toggle()
    if (isQrNoPayment) {
      setState({ ...state, ecbrNoPayCount: (state.ecbrNoPayCount || 0) + 1 })
    }
    if (isQrUpdateSlip) {
      setState({ ...state, isUpdateQrSlipImg: true })
    }
  }

  useEffect(() => {
    if (state?.pendingList?.cbrStatus === 'CREATED' && _isEmpty(state?.summary?.payload?.imgBase64)) {
      setIsDisabled(true) // case resume premium down || case submit limit exceed
    }
  }, [state?.summary?.payload?.imgBase64])

  const handleLegacySubmission = () => {
    if (tabState === staticContentPage.tabsProgress[3].id || tabState === staticContentPage.tabsProgress[4].id) {
      handleSubmit(onSubmitData)()
    } else {
      handleSubmitData()
    }
  }

  const handleEcbrSysSubmission = async () => {
    let res

    const requestPayload = {
      ...state,
      process: {
        payload: {
          data: {
            ...state?.pendingList,
          },
        },
      },
    }
    if (_isEmpty(state?.summary?.payload?.imgBase64)) {
      res = await createEcbrNumber(requestPayload)
    } else {
      res = await updateEcbr(requestPayload)
    }

    setData(res)
    toggle()
    setLoading(false)
  }

  const handleSubmitNewFeature = () => {
    setLoading(true)
    if (isEnableEcbrSys) {
      handleEcbrSysSubmission()
    } else {
      handleLegacySubmission()
    }
  }

  const submitTextButton = isEnableEcbrSys ? 'ออกใบเสร็จ' : 'ยืนยัน'
  const displayModalCondition = data?.data && !data?.error

  return (
    <>
      <Button disabled={loading || isDisabled} onClick={handleSubmitNewFeature} type="primary">
        {loading ? 'กำลังบันทึกข้อมูล' : submitTextButton}
      </Button>
      {createEcbrNumberStatusDialogCondition ? (
        <CreateEcbrNumberStatusDialog show={modal} ecbrData={data?.data} onToggle={handleToggleCreateEcbrStatus} />
      ) : displayModalCondition ? (
        <ECBRPaidDialog ecbrData={data?.data} show={modal} onToggle={closeModal} />
      ) : (
        <ErrorDialog show={modal} onToggle={() => history?.go('/')} actionLabel={'กลับสู่หน้าหลัก'} />
      )}
    </>
  )
}

export default withRouter(SubmitSection)
