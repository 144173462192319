import React, { useState, useEffect } from 'react'

import { Button } from 'ktaxa-ui-components/index'
import Icon from 'ktaxa-ui-components/components/icons'
import PreviewDialog from './preview-dialog'
import styled from '@emotion/styled'
import { cancelState, handleAttachList } from '../hooks/service'

type SideProps = {
  align?: string
}

const Container = styled.div`
  display: flex;
  height: 50px;
  margin-bottom: 1rem;
`
const Side = styled.div<SideProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align};
  -webkit-box-pack: center;
  justify-content: center;
  flex: 0 0 15%;
`
const Description = styled.div`
  padding: 12px;
  overflow-wrap: anywhere;
  align-items: center;
  -webkit-box-align: center;
  flex: 1 1 0%;
`

type AttachUploadProp = {
  item: any
  state: cancelState
  setState: Function
}
const AttachDone = (props: AttachUploadProp) => {
  const { item, state, setState } = props
  const { attachment } = state
  const [newAttach, setNewAttach] = useState([])
  const [show, setShow] = useState(false)
  const [isRemove, setIsRemove] = useState(false)
  const onClick = () => {
    setShow(!show)
  }

  const handleRemoveAttachment = () => {
    const newAttachList = handleAttachList(attachment, item.id)
    setShow(!show)
    setIsRemove(true)
    setNewAttach(newAttachList)
    URL.revokeObjectURL(item.src)
  }

  useEffect(() => {
    if (isRemove) {
      setState({ ...state, attachment: newAttach })
      setIsRemove(false)
    }
  }, [isRemove])

  return (
    <>
      <Container>
        <Side align="center">
          <Icon style={{ width: '35px', height: 'auto' }} type="CHECK" />
        </Side>
        <Description> {item.filename} </Description>
        <Side align="flex-end">
          <Button type="secondary" onClick={onClick}>
            ดู
          </Button>
        </Side>
      </Container>
      <PreviewDialog show={show} onToggle={onClick} onRemove={handleRemoveAttachment} attachItem={item} />
    </>
  )
}

export default AttachDone
