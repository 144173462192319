import React, { useState, useCallback } from 'react'

export type NavConfigPropsType = {
  showFooter: boolean
  backButton?: {
    toHome?: boolean
    to?: boolean
  }
  nextButton?: {
    to: string
    text: string
  }
  extraButton?: any
  extraBackButton?: any
  isOnlyExtraButtonCenter?: boolean
}

type RouterContextPropsType = {
  navConfig: NavConfigPropsType
  setNavConfigByPage: (obj: NavConfigPropsType) => void
}

const RouterContext = React.createContext<RouterContextPropsType>({} as RouterContextPropsType)

const useRouter = () => {
  const [navConfig, setNavConfig] = useState<NavConfigPropsType>({} as NavConfigPropsType)

  const setNavConfigByPage = (obj: NavConfigPropsType) => {
    setNavConfig(obj)
  }
  return [navConfig, setNavConfigByPage] as const
}

const Router = (props) => {
  const { children, useRouterHook = useRouter } = props
  const [navConfig, setNavConfigByPage] = useRouterHook()

  return (
    <RouterContext.Provider
      value={{
        navConfig,
        setNavConfigByPage,
      }}
    >
      {children}
    </RouterContext.Provider>
  )
}

export { RouterContext, useRouter }
export default Router
