/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'

import styles from './dialog-styles'

function DialogTitle(props) {
  const { children } = props
  return (
    <div css={styles.title}>
      <span css={styles.titleText}>{children}</span>
    </div>
  )
}

DialogTitle.displayName = 'DialogTitle'
DialogTitle.propTypes = {
  children: PropTypes.node,
}
DialogTitle.defaultProps = {
  children: '',
}

export default DialogTitle
