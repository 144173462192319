/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'

import styles from './dialog-styles'

function DialogFooter(props) {
  const { children } = props
  return <div css={styles.footer}>{children}</div>
}

DialogFooter.displayName = 'DialogFooter'
DialogFooter.propTypes = {
  children: PropTypes.node,
}
DialogFooter.defaultProps = {
  children: '',
}

export default DialogFooter
