import React, { useState } from 'react'
import { Input as RSInput } from 'reactstrap'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'
import _isNaN from 'lodash/isNaN'
import _isEmpty from 'lodash/isEmpty'
import { Radio } from 'ktaxa-ui-components/index'
import { SectionContainer } from 'components/layouts'
import DatePickerComponent from 'components/input/date-picker'
import { UploadImage } from 'components/input'
import { getConfig, getToggles } from 'core/config'
import { handleRemoveField } from 'utils/helper'

import {
  renderWarningMessageBlock,
  renderErrorMessageBlock,
  renderInfoMessageBlockForSpecialWordBreak,
} from 'components/message-blocks'

import staticContentPage from '../static-content'

const SelectContainer = styled.div`
  & > div > div {
    display: block;
  }
`

const messageBox = (paymentMethod: string) => {
  if (paymentMethod === 'quickpay') {
    return renderInfoMessageBlockForSpecialWordBreak(
      'กรณีผู้เอาประกันเลือกชำระผ่านลิงก์ด้วยตนเอง ผ่านบัตรเครดิต KTC สามารถใช้สิทธิ์ผ่อนชำระเบี้ยประกันได้ ทั้งนี้เป็นไปตามเงื่อนไข<span style="white-space: nowrap;">ของบริษัทฯ</span>'
    )
  }
  return null
}

const payWithCreditCard = (props) => {
  const {
    control,
    errors,
    getValues,
    handleSecureValueCreditCardNumber,
    isOverLimit,
    setIsOverLimit,
    limitFileSize,
    isHideCash,
    isEnableCCLINK,
    isHideEDC,
  } = props
  const { informationSection: formInfo } = staticContentPage
  const paymentMethodOption = formInfo.categoryPaymentMethod
    .options(isHideCash, isEnableCCLINK, isHideEDC)
    .find((obj) => obj.value === 'creditcard')

  if (_isEmpty(paymentMethodOption)) return
  if (getValues(formInfo.categoryPaymentMethod.id) === paymentMethodOption?.value) {
    return (
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>หมายเลขบัตรเครดิต</label>
          <Controller
            name={'creditCardNumber'}
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                id={'creditCardNumber'}
                type="text"
                maxLength={16}
                style={{ textAlign: 'center' }}
                onChange={(v) => field.onChange(handleSecureValueCreditCardNumber(v.target.value))}
              />
            )}
          />
          {renderWarningMessageBlock(
            'เพื่อความปลอดภัยของข้อมูลบัตรเครดิตของลูกค้า บริษัทขอสงวนสิทธิ์ในการไม่แสดงหมายเลขบัตรเครดิตครบทุกหลัก ทั้งนี้ การทำรายการชำระเบี้ยครั้งนี้ จะดำเนินการได้ตามปรกติ'
          )}
          {renderErrorMessageBlock(errors?.creditCardNumber)}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>รหัสอนุมัติ</label>
          <Controller
            name={'authorizedCode'}
            control={control}
            render={({ field }) => {
              return (
                <RSInput
                  {...field}
                  id={'authorizedCode'}
                  type="text"
                  style={{ textAlign: 'center' }}
                  maxLength={6}
                  onChange={(v) => field.onChange(v.target.value)}
                />
              )
            }}
          />
          {renderErrorMessageBlock(errors?.authorizedCode)}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>แนบหลักฐานการชำระเงิน EDC</label>
          <Controller
            name={'creditCardImage'}
            control={control}
            render={({ field }) => (
              <UploadImage
                {...field}
                label="แนบหลักฐานการชำระเงิน"
                onSelected={(img) => field.onChange(img)}
                value={getValues('creditCardImage')}
                setIsOverLimit={setIsOverLimit}
              />
            )}
          />
          {renderErrorMessageBlock(errors?.creditCardImage)}
          {isOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
        </div>
      </SectionContainer>
    )
  }
  return null
}

const payWithCheque = (props) => {
  const {
    control,
    errors,
    getValues,
    isOverLimit,
    limitFileSize,
    setIsOverLimit,
    isHideCash,
    isEnableCCLINK,
    isHideEDC,
  } = props
  const { informationSection: formInfo } = staticContentPage
  const paymentMethodOption = formInfo.categoryPaymentMethod
    .options(isHideCash, isEnableCCLINK, isHideEDC)
    .find((obj) => obj.value === 'cheque')

  if (_isEmpty(paymentMethodOption)) return
  if (getValues(formInfo.categoryPaymentMethod.id) === paymentMethodOption?.value) {
    return (
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>หมายเลขเช็ค</label>
          <Controller
            name={'chequeNumber'}
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                id={'chequeNumber'}
                type="text"
                style={{ textAlign: 'center' }}
                onChange={(v) => field.onChange(v.target.value)}
              />
            )}
          />
          {renderErrorMessageBlock(errors?.chequeNumber)}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>ธนาคาร</label>
          <Controller
            name="chequeBank"
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                type="select"
                style={{ textAlign: 'center' }}
                className="custom-select form-control"
                onChange={(v) => field.onChange(v.target.value)}
              >
                <option value="">ธนาคารผู้รับชำระ</option>
                {staticContentPage.chequeMethod.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </RSInput>
            )}
          />
          {renderErrorMessageBlock(errors?.chequeBank)}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>สาขา</label>
          <Controller
            name="chequeBankBranch"
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                id={'chequeBankBranch'}
                type="text"
                style={{ textAlign: 'center' }}
                onChange={(v) => field.onChange(v.target.value)}
              ></RSInput>
            )}
          />
          {renderErrorMessageBlock(errors?.chequeBankBranch)}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>วันที่หน้าเช็ค</label>
          <Controller
            name={'chequeDate'}
            control={control}
            render={({ field }) => (
              <DatePickerComponent
                {...field}
                minDate={new Date().toISOString()}
                id="chequeDate"
                dateFormat="DD/MM/YYYY"
                onChange={(v, f) => field.onChange(v)}
                readOnly
              />
            )}
          />
          {renderErrorMessageBlock(errors?.chequeDate)}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>แนบรูปเช็ค</label>
          <Controller
            name={'chequeImage'}
            control={control}
            render={({ field }) => (
              <UploadImage
                {...field}
                onSelected={(img) => field.onChange(img)}
                value={getValues('chequeImage')}
                label={'แนบรูปเช็ค'}
                setIsOverLimit={setIsOverLimit}
              />
            )}
          />
          {renderErrorMessageBlock(errors?.chequeImage)}
          {isOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
        </div>
      </SectionContainer>
    )
  }
  return null
}

const payWithConsentCreditCard = (props) => {
  const { control, errors, getValues, handleSecureValueCreditCardNumber, isHideCash, isEnableCCLINK, isHideEDC } = props

  const { informationSection: formInfo } = staticContentPage
  const paymentMethodOption = formInfo.categoryPaymentMethod
    .options(isHideCash, isEnableCCLINK, isHideEDC)
    .find((obj) => obj.value === 'creditcard')

  if (_isEmpty(paymentMethodOption)) return
  if (getValues(formInfo.categoryPaymentMethod.id) === paymentMethodOption?.value) {
    return (
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>หมายเลขบัตรเครดิต</label>
          <Controller
            name={'creditCardNumber'}
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                id={'creditCardNumber'}
                type="text"
                style={{ textAlign: 'center' }}
                maxLength={16}
                onChange={(v) => field.onChange(handleSecureValueCreditCardNumber(v.target.value))}
              />
            )}
          />
          {renderWarningMessageBlock(
            'เพื่อความปลอดภัยของข้อมูลบัตรเครดิตของลูกค้า บริษัทขอสงวนสิทธิ์ในการไม่แสดงหมายเลขบัตรเครดิตครบทุกหลัก ทั้งนี้ การทำรายการชำระเบี้ยครั้งนี้ จะดำเนินการได้ตามปรกติ'
          )}
          {renderErrorMessageBlock(errors?.creditCardNumber)}
        </div>
      </SectionContainer>
    )
  }
  return null
}

const PaymentMethodSection = (props) => {
  const { control, getValues, watch, errors, selectedPlanCode, planCodeOptions, setValue, isHideEDC } = props
  const [isOverLimit, setIsOverLimit] = useState(false)
  const { limitFileSize } = getConfig()
  const { informationSection: formInfo } = staticContentPage
  const allowListPaymentTypeCCLink = ['01', '02']
  const isHideCash = getToggles().HIDE_UNUSED_PAYMENT
  const isEnableCCLINK =
    getToggles().ENABLE_CCLINK_PAYMENT && allowListPaymentTypeCCLink.includes(getValues('paymentType'))
  watch(formInfo.categoryPaymentMethod.id)
  const watchPaymentType = watch(formInfo.paymentType.id)
  watch('creditCardNumber')

  const checkDisable = (paymentMethod: string): boolean => {
    const isAllowCreditCardNB =
      selectedPlanCode?.acceptCreditCard === 'Y' &&
      selectedPlanCode?.payment === 'Y' &&
      selectedPlanCode?.creditCardNB === 'Y'
    const isAllowCreditCardRenewal =
      selectedPlanCode?.acceptCreditCard === 'Y' &&
      selectedPlanCode?.payment === 'Y' &&
      selectedPlanCode?.creditCardRenewal === 'Y'

    if ((_isEmpty(planCodeOptions) || _isEmpty(selectedPlanCode)) && paymentMethod === 'quickpay') {
      return true
    }

    if (
      !_isEmpty(selectedPlanCode) &&
      watchPaymentType === '01' &&
      paymentMethod === 'quickpay' &&
      !isAllowCreditCardNB
    ) {
      return true
    }
    if (
      !_isEmpty(selectedPlanCode) &&
      watchPaymentType === '02' &&
      paymentMethod === 'quickpay' &&
      !isAllowCreditCardRenewal
    ) {
      return true
    }
    return false
  }

  // For secured creditCardNumber.
  // Copy from old service ecbr.
  const handleSecureValueCreditCardNumber = (value: string): string => {
    if (!value || value.length === 0) {
      return ''
    }

    if (
      (value.length > 0 && value.length < 7 && _isNaN(value[value.length - 1])) ||
      (value.length > 12 && value.length < 17 && _isNaN(value[value.length - 1]))
    ) {
      return value.substr(0, value.length - 1)
    }

    if (value.length > 6 && value.length < 13) {
      return `${value.substr(0, value.length - 1)}X`
    }

    if (value.length > 16) {
      return value.substr(0, 16)
    }
    if (value.length === 16) {
      return `${value.substr(0, 6)}XXXXXX${value.substr(12, 16)}`
    }
    return value
  }

  const propsWithOverLimit = {
    isOverLimit,
    setIsOverLimit,
    limitFileSize,
  }

  return (
    <>
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <h1>{formInfo.categoryPaymentMethod.title}</h1>
          {renderErrorMessageBlock(errors[formInfo.categoryPaymentMethod.id])}
          <SelectContainer>
            <Controller
              control={control}
              name={formInfo.categoryPaymentMethod.id}
              render={({ field }) => {
                return (
                  <Radio.Group
                    uiStyle={Radio.UIStyle.Checked}
                    onChange={(option) => {
                      handleRemoveField(option.value, setValue)
                      return field.onChange(option.value)
                    }}
                    value={getValues(formInfo.categoryPaymentMethod.id)}
                  >
                    {formInfo.categoryPaymentMethod.options(isHideCash, isEnableCCLINK, isHideEDC).map((cate) => {
                      return (
                        <Radio
                          key={cate.value}
                          value={cate.value}
                          label={cate.text}
                          disabled={checkDisable(cate.value)}
                          messengeBox={messageBox(getValues(formInfo.categoryPaymentMethod.id))}
                          isShowMessageBox={getValues(formInfo.categoryPaymentMethod.id) === cate.value}
                        />
                      )
                    })}
                  </Radio.Group>
                )
              }}
            />
          </SelectContainer>
        </div>
      </SectionContainer>
      {payWithCreditCard({
        ...props,
        handleSecureValueCreditCardNumber,
        ...propsWithOverLimit,
        isHideCash,
        isEnableCCLINK,
        isHideEDC,
      })}
      {payWithCheque({ ...props, ...propsWithOverLimit, isHideCash, isEnableCCLINK, isHideEDC })}
    </>
  )
}

export default PaymentMethodSection
