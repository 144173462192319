/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'

import styles from './checkbox-styles'

const UI_STYLE = {
  Filled: 'filled',
  Checked: 'checked',
}

const propTypes = {
  /**
   * Elements to be rendered as children of this component.
   */
  children: PropTypes.node,
  /**
   * One or more class names to be added to the root element of this component, i.e. `"class-foo class-bar"`.
   */
  className: PropTypes.string,
  /**
   * Specifies whether the checkbox is checked.
   */
  checked: PropTypes.bool,
  /**
   * Style of checked item.
   */
  uiStyle: PropTypes.string,
  /**
   * Disables the checkbox if set to true. Disabled checkbox won't trigger the `onChange`.
   */
  disabled: PropTypes.bool,
  /**
   * A callback function, executed when the checkbox is changed.
   *
   * ```() => {}```
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  children: '',
  className: '',
  disabled: false,
  checked: false,
  uiStyle: UI_STYLE.Checked,
  onChange() {},
}

/**
 * Controlled component. Value will be set by prop.
 */
const Checkbox = (props) => {
  const { children, className, checked, uiStyle, disabled, onChange } = props
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const doOnChange = () => {
    if (!disabled) {
      onChange()
    }
  }

  const checkboxStyles = [styles.checkbox]
  const checkboxProps = {}

  if (className) checkboxProps.className = className
  if (disabled) {
    checkboxStyles.push(styles.disabled)
    checkboxProps.disabled = true
  }

  return (
    <div className={className} css={styles.checkboxContainer}>
      <label css={checkboxStyles}>
        <input {...checkboxProps} type="checkbox" checked={isChecked} onChange={doOnChange} />
        <span data-ui-type={UI_STYLE[uiStyle] || UI_STYLE.Filled} data-checked={isChecked} css={[styles.ui]} />
        <div css={styles.label}>{children}</div>
      </label>
    </div>
  )
}

const getUIStyle = (style) => {
  const list = {}
  Object.keys(style).forEach((k) => (list[k] = k))
  return list
}

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps
Checkbox.UIStyle = getUIStyle(UI_STYLE)

export default Checkbox
