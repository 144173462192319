import React, { useEffect, useState, useRef } from 'react'
import { Input as RSInput } from 'reactstrap'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import dayjs from 'dayjs'

import { Radio } from 'ktaxa-ui-components/index'
import { InputCustomFormat } from 'components/input'
import { SectionContainer } from 'components/layouts'
import EcbrEntity from 'domain/entities/ecbr'

import { renderErrorMessageBlock } from 'components/message-blocks'
import { PolicyListDialog } from '../components/search-policy-dialog'
import { NewErrorDialog as SearchPolicyFailedDialog } from '../components/load-policy-dialog'

import { handleResetDataField } from '../hooks/useForm'
import staticContentPage from '../static-content'
import { getToggles } from 'core/config'
import { policyService } from 'services/ecbr-middleware'
import { validateCitizenId } from 'utils/validate'

let intervalPassportInput

const SelectContainer = styled.div`
  margin-bottom: 15px;
  & > div > div {
    display: block;
  }
`

const PersonalIdSection = (props) => {
  const {
    control,
    errors,
    getValues,
    dirtyFields,
    reset,
    clearErrors,
    setCheckFetchPolicy,
    checkFetchPolicy,
    watch,
    checkFetchPolicyWithId,
    setPolicyDetail,
    checkFillInValue,
    setCheckFillInValue,
    enableBySearchPolicyId,
    setEnableBySearchPolicyId,
    textSearchPolicy,
    setFieldDisabledSeachPolicy,
    searchInputDisabled,
    setSearchInputDisabled,
    showDialog,
    setShowDialog,
    isResumePayment,
  } = props

  const { informationSection: formInfo } = staticContentPage

  const [agentPolicyListFromPersonalId, setAgentPolicyListFromPersonalId] = useState<any[]>([] as any[])

  const selectAgentPolicy = (agentPolicy: EcbrEntity) => {
    if (!agentPolicy) return handleResetDataField(reset)
    if (!_isEmpty(agentPolicy)) {
      setPolicyDetail(agentPolicy)
      const isOwner = agentPolicy?.isOwner || false
      handleResetDataField(reset, {
        policyNo: agentPolicy?.policyNumber || '',
        insuredName: isOwner ? agentPolicy?.firstName || '' : '',
        personalId: getValues('personalId'),
        personalIdType: getValues('personalIdType'),
        insureMobile: isOwner ? agentPolicy?.mobileNumber || '' : '',
        insuredSurName: isOwner ? agentPolicy?.lastName || '' : '',
        insureEmail: isOwner ? agentPolicy?.email || '' : '',
        paymentAmount: isOwner ? agentPolicy?.paymentAmount || '' : '',
        productCode: isOwner ? agentPolicy?.productCode || '' : '',
        paymentType: '02',
        paymentMethod: '',
        paymentMode: isOwner ? agentPolicy?.paymentMode || '' : '',
        policyEffectiveDate: agentPolicy?.policyEffectiveDate || '',
      })

      setCheckFetchPolicy(false)
      setEnableBySearchPolicyId(true)

      if (isOwner) {
        const insuredName = agentPolicy?.firstName ? 'insuredName' : null
        const insuredSurName = agentPolicy?.lastName ? 'insuredSurName' : null
        const insureMobile = agentPolicy?.mobileNumber ? 'insureMobile' : null
        const insureEmail = agentPolicy?.email ? 'insureEmail' : null
        const paymentAmount = null

        setFieldDisabledSeachPolicy([insuredName, insuredSurName, insureMobile, insureEmail, paymentAmount])

        const policyEffectiveDate = agentPolicy?.policyEffectiveDate ? 'policyEffectiveDate' : null
        const productCode = agentPolicy?.productCode ? 'productCode' : null

        setSearchInputDisabled(['personalId', 'policyNo', policyEffectiveDate, productCode])
      } else {
        setFieldDisabledSeachPolicy([])
        setSearchInputDisabled(['personalId', 'policyNo'])
      }
    }
  }

  const resetFieldWithSearchPolicyId = () => {
    setEnableBySearchPolicyId(false)
    reset({
      policyNo: '',
      insuredName: '',
      personalId: '',
      personalIdType: 'citizenId',
      insureMobile: '',
      insuredSurName: '',
      insureEmail: '',
      paymentAmount: '',
      productCode: '',
      paymentType: '02',
      paymentMethod: '',
      paymentMode: '',
      policyEffectiveDate: '',
      chequeDate: dayjs().toISOString(),
    })
  }

  const selectPersonalIdType = (personalIdType: string) => {
    if (personalIdType === 'policyId' && getToggles().ENABLE_ENQUIRY_POLICYID && textSearchPolicy) {
      // search by policyId
      setEnableBySearchPolicyId(true)
      setFieldDisabledSeachPolicy(['insuredName', 'insuredSurName', 'insureMobile', 'insureEmail', 'paymentAmount'])
      setSearchInputDisabled([])
    } else if (getToggles().ENABLE_ENQUIRY_POLICYID && textSearchPolicy) {
      // search by personal ID but enable search policy toggle
      setEnableBySearchPolicyId(false)
      setFieldDisabledSeachPolicy(['insuredName', 'insuredSurName', 'insureMobile', 'insureEmail', 'paymentAmount'])
      setSearchInputDisabled(['policyEffectiveDate', 'policyNo', 'productCode'])
    } else {
      // original case (search by personal ID only)
      setEnableBySearchPolicyId(false)
      setFieldDisabledSeachPolicy({})
      setSearchInputDisabled([])
    }

    reset(
      {
        ...getValues(),
        personalIdType,
        personalId: '',
        policyNo: '',
        policyEffectiveDate: '',
        productCode: '',
        paymentAmount: '',
        insuredName: '',
        insureMobile: '',
        insuredSurName: '',
        insureEmail: '',
      },
      {
        keepErrors: true,
      }
    )
    clearErrors()
  }
  const personalIdFormValue = getValues(formInfo.identity.id)
  const isValidCitizenId = validateCitizenId(personalIdFormValue)
  const isValidFormatSearchPolicyID = getValues('personalIdType') === 'policyId' && !isValidCitizenId

  useEffect(() => {
    const isValidCitizenId = validateCitizenId(personalIdFormValue)
    const isValidPassport = _isNil(errors[formInfo.identity.id]) && (personalIdFormValue || '').length >= 5
    const isValidIdentityId = getValues('personalIdType') === 'passport' ? isValidPassport : isValidCitizenId

    const condition =
      dirtyFields[formInfo.identity.id] &&
      isValidIdentityId &&
      getValues('paymentType') === formInfo.paymentType.options[1].value &&
      !checkFetchPolicyWithId &&
      getValues('personalIdType') !== 'policyId' &&
      !checkFillInValue

    async function fetchEcbr() {
      // Need fix
      // base on input-number-format
      const personalId = personalIdFormValue.replace(/ +/g, '')

      try {
        const res = await policyService.GetPolicyByCitizenId(personalId, 'new-ecbr')
        setAgentPolicyListFromPersonalId(res)
      } catch {
        console.log('ERROR: policyService.GetPolicyByCitizenId')
      }

      if (personalId !== '') {
        setShowDialog(true)
      }
    }
    if (condition) {
      if (getValues('personalIdType') === 'passport') {
        clearTimeout(intervalPassportInput)
        intervalPassportInput = setTimeout(() => fetchEcbr(), 8000)
      } else {
        fetchEcbr()
      }
    }
  }, [personalIdFormValue])

  watch('personalIdType')
  watch('personalId')

  const checkDisableField = (fieldName) => {
    if (getToggles().ENABLE_ENQUIRY_POLICYID) {
      const isEmptySearchInputDisabled = _isEmpty(searchInputDisabled)
      const isDisabled = isEmptySearchInputDisabled
        ? enableBySearchPolicyId
        : enableBySearchPolicyId || searchInputDisabled.includes(fieldName)

      return isDisabled
    }

    return checkFetchPolicy
  }

  return (
    <>
      <SectionContainer margin="20">
        <SelectContainer>
          <Controller
            name={'personalIdType'}
            control={control}
            render={() => (
              <Radio.Group
                uiStyle={'checked'}
                disabled={checkFetchPolicy || isResumePayment}
                value={getValues('personalIdType')}
                onChange={(option) => selectPersonalIdType(option.value)}
              >
                {formInfo.identity.options.map((idn, i) => {
                  return (
                    <Radio
                      key={idn.value}
                      value={idn.value}
                      label={
                        textSearchPolicy && getToggles().ENABLE_ENQUIRY_POLICYID ? 'ค้นหาจาก' + idn.text : idn.text
                      }
                    />
                  )
                })}
                {textSearchPolicy && getToggles().ENABLE_ENQUIRY_POLICYID && (
                  <Radio
                    key={formInfo.searchByPolicy.id}
                    value={formInfo.searchByPolicy.value}
                    label={formInfo.searchByPolicy.text}
                  />
                )}
              </Radio.Group>
            )}
          />
        </SelectContainer>
        <div className="name-input">
          <label>{formInfo.identity.label}</label>
          <Controller
            name={formInfo.identity.id}
            control={control}
            render={({ field: { ref, ...field } }) =>
              getValues('personalIdType') === 'passport' || isValidFormatSearchPolicyID ? (
                <RSInput
                  {...field}
                  disabled={checkDisableField(formInfo.identity.id) || isResumePayment}
                  name={formInfo.identity.id}
                  type="text"
                  style={{ textAlign: 'center' }}
                  onChange={(v) => field.onChange(v.target.value)}
                  maxLength={10}
                />
              ) : (
                <InputCustomFormat
                  {...field}
                  disabled={checkDisableField(formInfo.identity.id) || isResumePayment}
                  style={{ textAlign: 'center' }}
                  mask={formInfo.identity.mask}
                  format={formInfo.identity.format}
                />
              )
            }
          />
        </div>
        {renderErrorMessageBlock(errors[formInfo.identity.id])}
      </SectionContainer>

      {getValues('personalIdType') !== 'policyId' &&
        (_isEmpty(agentPolicyListFromPersonalId) ? (
          <SearchPolicyFailedDialog
            // this will show error dialog if can not get any data
            show={showDialog}
            onToggle={() => setShowDialog(!showDialog)}
            // onCancle
            onClick={() => {
              handleResetDataField(reset, resetFieldWithSearchPolicyId)
              setShowDialog(!showDialog)
              setCheckFetchPolicy(false)
            }}
            // onConfirm
            onLoadData={() => {
              setCheckFillInValue(true)
              setSearchInputDisabled([])
              setFieldDisabledSeachPolicy({})
              setShowDialog(!showDialog)
            }}
          />
        ) : (
          <PolicyListDialog
            // this will show policy list after get data from EIP
            show={showDialog && !getValues('policyNo')}
            onToggle={() => {
              setShowDialog(!showDialog)
            }}
            agentPolicyList={agentPolicyListFromPersonalId}
            selectAgentPolicy={selectAgentPolicy}
          />
        ))}
    </>
  )
}

export default PersonalIdSection
