import styled from '@emotion/styled'
import { color } from 'components/theme'
import { Button, Dialog } from 'ktaxa-ui-components/index'
import React from 'react'
import { withRouter } from 'react-router-dom'
import staticContent from '../static-content'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Message = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`

type DynamicDialogProps = {
  apiResult: string | null
}

const CcpaBackButtonDialog = (props: any) => {
  const { show, setTabState, setIsShowBackDialog } = props

  return (
    <DialogContainer>
      <Dialog centered modal show={show}>
        <Title>แก้ไขข้อมูลการสมัคร</Title>
        <Divider />
        <Content>
          <Message>ท่านต้องการแก้ไขข้อมูลการสมัครหักค่าเบี้ยประกันภัยอัตโนมัติผ่านบัตรเครดิตหรือไม่</Message>
        </Content>
        <Footer>
          <Button type={Button.Type.Secondary} onClick={() => setIsShowBackDialog(false)}>
            ไม่ต้องการ
          </Button>
          <Button
            type={Button.Type.Primary}
            onClick={() => {
              setTabState(staticContent.tabsProgress[1].id)
              setIsShowBackDialog(false)
            }}
          >
            ยืนยัน
          </Button>
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default withRouter(CcpaBackButtonDialog)
