/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import Calendar from './svg/calendar.svg'
import ECBRAttachment from './svg/ecbr-attachment.svg'
import ECBRCancellation from './svg/ecbr-cancellation.svg'
import ECBRHistory from './svg/ecbr-history.svg'
import ECBRIssueNewEcbr from './svg/ecbr-issue-new-ecbr.svg'
import ECBRPayment from './svg/ecbr-payment.svg'
import ECBRPaymentInfo from './svg/ecbr-payment-info.svg'
import ECBRPaymentInfoDA from './svg/ecbr-payment-info-da.svg'
import ECBRPaymentProcess from './svg/ecbr-payment-process.svg'
import ECBRPaymentSelection from './svg/ecbr-payment-selection.svg'
import ECBRPaymentSummary from './svg/ecbr-history.svg'
import ECBRPaymentCCPA from './svg/ecbr-payment-ccpa.svg'
import ECBRPaymentStatus from './svg/ecbr-payment-status.svg'
import ECBRPaymentVerify from './svg/ecbr-payment-verify.svg'
import User from './svg/user.svg'
import UserMale from './svg/user-male.svg'
import UserFilled from './svg/user-filled.svg'
import Upload from './svg/upload.svg'
import Check from './svg/check.svg'
import PreviewPDF from './svg/previewPdf.svg'
import Sign from './svg/sign.svg'
import EcbrSuccess from './svg/ecbr-success.svg'
import Back from './svg/back.svg'
import EcbrDA from './svg/ecbr-da.svg'
import EcbrSaveData from './svg/ecbr-save-data.svg'
import ArrowLeft from '@axah/web-toolkit/icons/arrow-left.svg'
import CheckFillCircle from './svg/check-fill-circle.svg'

export const ICON_COMPONENTS = {
  CALENDAR: <Calendar />,
  ECBR_ATTACHMENT: <ECBRAttachment />,
  ECBR_CANCELLATION: <ECBRCancellation />,
  ECBR_HISTORY: <ECBRHistory />,
  ECBR_ISSUE_NEW: <ECBRIssueNewEcbr />,
  ECBR_PAYMENT_INFO: <ECBRPaymentInfo />,
  ECBR_PAYMENT_INFO_DA: <ECBRPaymentInfoDA />,
  ECBR_PAYMENT_PROCESS: <ECBRPaymentProcess />,
  ECBR_PAYMENT_SELECTION: <ECBRPaymentSelection />,
  ECBR_PAYMENT_SUMMARY: <ECBRPaymentSummary />,
  ECBR_PAYMENT: <ECBRPayment />,
  ECBR_PAYMENT_CCPA: <ECBRPaymentCCPA />,
  ECBR_PAYMENT_VERIFY: <ECBRPaymentVerify />,
  ECBR_PAYMENT_STATUS: <ECBRPaymentStatus />,
  USER: <User />,
  USER_MALE: <UserMale />,
  USER_FILLED: <UserFilled />,
  UPLOAD: <Upload />,
  CHECK: <Check />,
  PREVIEW_PDF: <PreviewPDF />,
  SIGN: <Sign />,
  ECBR_SUCCESS: <EcbrSuccess />,
  BACK: <Back />,
  ECBR_DA: <EcbrDA />,
  ECBR_SAVE_DATA: <EcbrSaveData />,
  ARROW_LEFT: <ArrowLeft />,
  CHECK_FILL_CIRCLE: <CheckFillCircle />,
}

const getTypes = (iconComponents) => {
  const list = {}
  Object.keys(iconComponents).forEach((k) => (list[k] = k))
  return list
}

export const ICON_TYPES = getTypes(ICON_COMPONENTS)
