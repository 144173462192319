import React, { useState, useEffect } from 'react'
import { Input as RSInput } from 'reactstrap'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'
import _isNaN from 'lodash/isNaN'
import _isEmpty from 'lodash/isEmpty'
import { Radio } from 'ktaxa-ui-components/index'
import { SectionContainer } from 'components/layouts'
import { UploadDocument } from 'components/input'
import { InputCustomFormat, Dropdown } from 'components/input'
import { payerIdType, payerRelations } from 'domain/entities/ecbr'
import { personNameRegExp } from '../hooks/useForm'

import { getConfig } from 'core/config'
import { handleDefaultPayerInfo } from 'utils/helper'

import { renderErrorMessageBlock, renderInfoMessageBlock } from 'components/message-blocks'

import staticContentPage from '../static-content'

const SelectContainer = styled.div`
  & > div > div {
    display: block;
  }
`

const MessageBox = () => {
  return renderInfoMessageBlock('กรุณาแนบเอกสารพิสูจน์ความสัมพันธ์ เพื่อใช้ประกอบการพิจารณา')
}
const payWithOwner = (props) => {
  const { control, errors, getValues, paymentType, agentPolicyListFromPolicyNo, isResumePayment } = props

  const { informationSection: formInfo } = staticContentPage
  const payerTypeOwner = formInfo.payerType.options.find((obj) => obj.value === 'owner')
  const payerType = getValues(formInfo.payerType.id)

  const isOwnerType = payerType === payerTypeOwner?.value

  if (_isEmpty(payerTypeOwner)) return
  if (isResumePayment) return null
  if (
    (isOwnerType && paymentType === '01') ||
    (isOwnerType && paymentType === '02' && !agentPolicyListFromPolicyNo?.isOwner) ||
    (isOwnerType && _isEmpty(agentPolicyListFromPolicyNo))
  ) {
    return (
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>{formInfo.payerFirstName.title}</label>
          <Controller
            name={formInfo.payerFirstName.id}
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                id={formInfo.payerFirstName.id}
                type="text"
                maxLength={formInfo.payerFirstName.maxLength}
                style={{ textAlign: 'center' }}
                onChange={(v) => field.onChange(v.target.value.replace(personNameRegExp, ''))}
              ></RSInput>
            )}
          />
          {renderErrorMessageBlock(errors[formInfo.payerFirstName.id])}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>{formInfo.payerLastName.title}</label>
          <Controller
            name={formInfo.payerLastName.id}
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                id={formInfo.payerLastName.id}
                type="text"
                maxLength={formInfo.payerLastName.maxLength}
                style={{ textAlign: 'center' }}
                onChange={(v) => field.onChange(v.target.value.replace(personNameRegExp, ''))}
              ></RSInput>
            )}
          />
          {renderErrorMessageBlock(errors[formInfo.payerLastName.id])}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>{formInfo.payerIdType.title}</label>
          <Controller
            name={formInfo.payerIdType.id}
            control={control}
            render={({ field: { ref, ...field } }) => {
              return (
                <Dropdown
                  {...field}
                  isSearchable={false}
                  placeholder=""
                  defaultValue={'citizen'}
                  onChange={(option) => field.onChange(option?.value)}
                  value={
                    getValues(formInfo.payerIdType.id) && !_isEmpty(payerIdType)
                      ? payerIdType.find((x) => x.value === getValues(formInfo.payerIdType.id))
                      : null
                  }
                  options={payerIdType}
                  isDisabled={_isEmpty(payerIdType)}
                />
              )
            }}
          />
          {renderErrorMessageBlock(errors[formInfo.payerIdType.id])}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>
            {getValues(formInfo.payerIdType.id) === 'citizen'
              ? formInfo.payerPersonalId.citizenTitle
              : formInfo.payerPersonalId.passportTitle}
          </label>
          <Controller
            name={formInfo.payerPersonalId.id}
            control={control}
            render={({ field: { ref, ...field } }) =>
              getValues(formInfo.payerIdType.id) === 'passport' ? (
                <RSInput
                  {...field}
                  disabled={false}
                  name={formInfo.payerPersonalId.id}
                  type="text"
                  style={{ textAlign: 'center' }}
                  onChange={(v) => field.onChange(v.target.value)}
                  maxLength={10}
                />
              ) : (
                <InputCustomFormat
                  {...field}
                  disabled={false}
                  style={{ textAlign: 'center' }}
                  mask={formInfo.payerPersonalId.mask}
                  format={formInfo.payerPersonalId.format}
                />
              )
            }
          />
          {renderErrorMessageBlock(errors[formInfo.payerPersonalId.id])}
        </div>
      </SectionContainer>
    )
  }
  return null
}
const payWithOther = (props) => {
  const { control, errors, getValues, setValue, isOverLimit, limitFileSize, setIsOverLimit, isResumePayment } = props

  const { informationSection: formInfo } = staticContentPage
  const payerTypeOther = formInfo.payerType.options.find((obj) => obj.value === 'other')

  if (_isEmpty(payerTypeOther)) return
  if (isResumePayment) return null
  if (getValues(formInfo.payerType.id) === payerTypeOther?.value) {
    return (
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>{formInfo.payerFirstName.title}</label>
          <Controller
            name={formInfo.payerFirstName.id}
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                id={formInfo.payerFirstName.id}
                type="text"
                maxLength={formInfo.payerFirstName.maxLength}
                style={{ textAlign: 'center' }}
                onChange={(v) => field.onChange(v.target.value.replace(personNameRegExp, ''))}
              ></RSInput>
            )}
          />
          {renderErrorMessageBlock(errors[formInfo.payerFirstName.id])}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>{formInfo.payerLastName.title}</label>
          <Controller
            name={formInfo.payerLastName.id}
            control={control}
            render={({ field }) => (
              <RSInput
                {...field}
                id={formInfo.payerLastName.id}
                type="text"
                maxLength={formInfo.payerLastName.maxLength}
                style={{ textAlign: 'center' }}
                onChange={(v) => field.onChange(v.target.value.replace(personNameRegExp, ''))}
              ></RSInput>
            )}
          />
          {renderErrorMessageBlock(errors[formInfo.payerLastName.id])}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>{formInfo.payerIdType.title}</label>
          <Controller
            name={formInfo.payerIdType.id}
            control={control}
            render={({ field: { ref, ...field } }) => {
              return (
                <Dropdown
                  {...field}
                  isSearchable={false}
                  placeholder=""
                  onChange={(option) => field.onChange(option?.value)}
                  value={
                    getValues(formInfo.payerIdType.id) && !_isEmpty(payerIdType)
                      ? payerIdType.find((x) => x.value === getValues(formInfo.payerIdType.id))
                      : null
                  }
                  options={payerIdType}
                  isDisabled={_isEmpty(payerIdType)}
                />
              )
            }}
          />
          {renderErrorMessageBlock(errors[formInfo.payerIdType.id])}
        </div>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <label>
            {getValues(formInfo.payerIdType.id) === 'citizen'
              ? formInfo.payerPersonalId.citizenTitle
              : formInfo.payerPersonalId.passportTitle}
          </label>
          <Controller
            name={formInfo.payerPersonalId.id}
            control={control}
            render={({ field: { ref, ...field } }) =>
              getValues(formInfo.payerIdType.id) === 'passport' ? (
                <RSInput
                  {...field}
                  disabled={false}
                  name={formInfo.payerPersonalId.id}
                  type="text"
                  style={{ textAlign: 'center' }}
                  onChange={(v) => field.onChange(v.target.value)}
                  maxLength={10}
                />
              ) : (
                <InputCustomFormat
                  {...field}
                  disabled={false}
                  style={{ textAlign: 'center' }}
                  mask={formInfo.payerPersonalId.mask}
                  format={formInfo.payerPersonalId.format}
                />
              )
            }
          />
          {renderErrorMessageBlock(errors[formInfo.payerPersonalId.id])}
        </div>
        <div className="name-input">
          <label>{formInfo.payerRelations.title}</label>
          <Controller
            name={formInfo.payerRelations.id}
            control={control}
            render={({ field: { ref, ...field } }) => {
              return (
                <Dropdown
                  {...field}
                  isSearchable={false}
                  placeholder="กรุณาระบุความสัมพันธ์"
                  onChange={(option) => {
                    setValue('payerRelationsOthers', '')
                    return field.onChange(option.value)
                  }}
                  value={
                    getValues(formInfo.payerRelations.id) && !_isEmpty(payerRelations)
                      ? payerRelations.find((x) => x.value === getValues(formInfo.payerRelations.id))
                      : null
                  }
                  options={payerRelations}
                  isDisabled={_isEmpty(payerRelations)}
                />
              )
            }}
          />
          {renderErrorMessageBlock(errors[formInfo.payerRelations.id])}
        </div>
        {getValues(formInfo.payerRelations.id) === 'Others' && (
          <div className="name-input" style={{ paddingTop: 10 }}>
            <Controller
              name={formInfo.payerRelationsOthers.id}
              control={control}
              render={({ field }) => (
                <RSInput
                  {...field}
                  id={formInfo.payerRelationsOthers.id}
                  type="text"
                  maxLength={formInfo.payerRelationsOthers.maxLength}
                  style={{ textAlign: 'center' }}
                  onChange={(v) => field.onChange(v.target.value.replace(personNameRegExp, ''))}
                ></RSInput>
              )}
            />
            {renderErrorMessageBlock(errors[formInfo.payerRelationsOthers.id])}
          </div>
        )}
        <MessageBox />
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <Controller
            name={formInfo.payerRelationshipDoc.id}
            control={control}
            render={({ field }) => (
              <UploadDocument
                {...field}
                onSelected={(img) => field.onChange(img)}
                value={getValues(formInfo.payerRelationshipDoc.id)}
                title={formInfo.payerRelationshipDoc.title}
                setIsOverLimit={setIsOverLimit}
                limitFileSize={limitFileSize}
                setValue={setValue}
              />
            )}
          />
          {renderErrorMessageBlock(errors[formInfo.payerRelationshipDoc.id])}
          {isOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
        </div>
      </SectionContainer>
    )
  }
  return null
}

const PayerInfoSection = (props) => {
  const { control, getValues, watch, errors, setValue, agentPolicyListFromPolicyNo, isResumePayment } = props

  const [isOverLimit, setIsOverLimit] = useState(false)
  const { limitFileSize } = getConfig()
  const { informationSection: formInfo } = staticContentPage

  const paymentType = watch('paymentType')
  const payerType = watch('payerType')
  watch('payerRelations')
  watch('payerIdType')

  useEffect(() => {
    // set default payerType when insured only
    if (
      paymentType === '02' &&
      _isEmpty(payerType) &&
      !_isEmpty(agentPolicyListFromPolicyNo) &&
      agentPolicyListFromPolicyNo?.isOwner &&
      !agentPolicyListFromPolicyNo?.hasPayer
    ) {
      setValue('payerType', 'insured')
    }
    // set default payerType when has payer
    if (
      paymentType === '02' &&
      _isEmpty(payerType) &&
      !_isEmpty(agentPolicyListFromPolicyNo) &&
      agentPolicyListFromPolicyNo?.isOwner &&
      agentPolicyListFromPolicyNo?.hasPayer
    ) {
      setValue('payerType', 'owner')
    }
  }, [agentPolicyListFromPolicyNo, payerType, setValue])

  const propsWithOverLimit = {
    isOverLimit,
    setIsOverLimit,
    limitFileSize,
  }

  const checkDisablePayerType = (payerType: string): boolean => {
    if (
      //Disable owner option when renewal case and there is no payer.
      paymentType === '02' &&
      payerType === 'owner' &&
      !_isEmpty(agentPolicyListFromPolicyNo) &&
      agentPolicyListFromPolicyNo?.isOwner &&
      !agentPolicyListFromPolicyNo?.hasPayer
    ) {
      return true
    }
    return false
  }

  const renderOwnerAndOtherPayerInfo = () => {
    if (isResumePayment) {
      return null
    } else {
      payWithOwner({ ...props, paymentType, agentPolicyListFromPolicyNo })
      payWithOther({ ...props, ...propsWithOverLimit })
    }
  }

  return (
    <>
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <h1>{formInfo.payerType.title}</h1>
          {renderErrorMessageBlock(errors[formInfo.payerType.id])}
          <SelectContainer>
            <Controller
              control={control}
              name={formInfo.payerType.id}
              render={({ field }) => {
                return (
                  <Radio.Group
                    uiStyle={Radio.UIStyle.Checked}
                    onChange={(option) => {
                      handleDefaultPayerInfo(option.value, setValue)
                      return field.onChange(option.value)
                    }}
                    value={getValues(formInfo.payerType.id)}
                  >
                    {formInfo.payerType.options.map((cate) => {
                      return (
                        <Radio
                          key={cate.value}
                          value={cate.value}
                          label={cate.text}
                          disabled={checkDisablePayerType(cate.value) || isResumePayment}
                        />
                      )
                    })}
                  </Radio.Group>
                )
              }}
            />
          </SelectContainer>
        </div>
      </SectionContainer>
      {payWithOwner({
        ...props,
        paymentType,
        agentPolicyListFromPolicyNo,
        isResumePayment,
      })}
      {payWithOther({ ...props, ...propsWithOverLimit, isResumePayment })}
    </>
  )
}

export default PayerInfoSection
