/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useLogin } from '../../../hooks/useServices/login'
import { Icons, Dialog, Button } from 'ktaxa-ui-components'
import { color, customIcon } from 'components/theme'
import { getConfig } from 'core/config'

const styleCSS = {
  main: css`
    z-index: 500;
    background: white;
    color: ${color.type.primary};
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    width: 100%;
    padding-top: env(safe-area-inset-top);
    max-height: 53px;
  `,
  brand: css`
    flex: 1;
    display: inline-block;
    width: 80px;
    height: 40px;
    overflow: hidden;
    position: relative;
    background-image: url('/logo-ktaxa-th.svg');
    background-size: contain;
    background-position: 0 50%;
    background-repeat: no-repeat;
    opacity: 1;
  `,
  home: css`
    flex: 1;
    display: inline-block;
    width: 30px;
    height: 40px;
    overflow: hidden;
    position: relative;
    background-image: url('/home.svg');
    background-size: contain;
    background-position: 0 50%;
    background-repeat: no-repeat;
    opacity: 1;
  `,
  title: css`
    text-align: center;
    font-weight: 400;
    line-height: 1;
    strong {
      font-weight: 700;
    }
  `,
  appTitleText: css`
    line-height: 1;
  `,
  version: css`
    display: block;
    font-size: 0.6rem;
  `,
  login: css`
    width: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn.btn-secondary {
      margin: 0;
      padding: 1px;
      background: transparent;
      border: solid 1px transparent;
      border-radius: 50%;
      outline: none;
      box-shadow: none;
      transition: none;
      &,
      &:hover {
        color: ${color.type.primary};
      }
      i {
        width: 25px;
        height: 25px;
        color: currentColor;
        fill: currentColor;
      }
    }

    .dropdown.show {
      .btn.btn-secondary {
        background: transparent;
        border-color: ${color.type.primary};
        outline: none;
        box-shadow: none;
      }
    }

    .dropdown-divider {
      margin: 0;
    }

    .dropdown-menu {
      margin-top: 6px;
      padding: 0;
      background-clip: unset;
      border: 0;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
      &::after,
      &::before {
        position: absolute;
        display: block;
        content: '';
      }
      a {
        display: block;
        padding: 10px;
        line-height: 1;
        text-decoration: none;
        &:hover {
          color: #ffffff;
          text-decoration: none;
          background-color: ${color.type.primary};
          &::before {
            background-color: #ffffff;
          }
        }
      }
    }
  `,
}
const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 6px 10px;
  margin: 0 auto;
  z-index: 999;
  max-width: 1200px;
  width: 100%;
  background-color: ${color.common.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    max-width: 100%;
    padding: 6px 15px;
  }
`
const LoginUserIcon = styled.div`
  &::before,
  &::after {
    position: absolute;
    display: block;
    content: '';
  }
  &::before {
    right: 2px;
    bottom: 2px;
    width: 8px;
    height: 8px;
    background-color: ${color.type.success};
    border-radius: 50%;
    box-shadow: 0 0 0 1px ${color.common.white};
  }
  &::after {
    right: 3px;
    bottom: 3px;
    width: 6px;
    height: 6px;
    background-color: ${color.common.white};
    mask-image: url(${customIcon('dialogSuccess')});
    mask-size: cover;
    mask-repeat: no-repeat;
  }
`

const Content = styled.div`
  text-align: center;
`

const Footer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

const MainHeader = (props) => {
  const { history } = props
  const { location } = history
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { appVersion, auth, api } = getConfig()
  const { login, logout, userInfo } = useLogin({})
  const isLogin = (userInfo) => !!userInfo.name
  const toggleUserMenu = () => setDropdownOpen((prevState) => !prevState)
  const handleWarningMsg = () => {
    setShowModal(true)
  }
  const onBackToAZ = () => {
    const isMobile = localStorage.getItem('location') === 'mobile'
    if (isMobile) {
      const url = new URL(window.location)
      url.searchParams.set('return-az', 'true')
      window.location = url
    } else {
      window.location.assign(api.advisorZone)
    }
  }
  const onBackToHome = () => {
    setShowModal(false)
    history.push('/')
  }

  return (
    <div id="app-header" css={styleCSS.main}>
      <HeaderContainer>
        <div className="header-home" id={'main-header_home-button'}>
          {location.pathname === '/' ? (
            <div css={styleCSS.brand} onClick={onBackToAZ} />
          ) : (
            <div css={styleCSS.home} onClick={handleWarningMsg} />
          )}
        </div>
        <span css={styleCSS.title}>
          <span css={styleCSS.appTitleText}>
            <strong>Advisor</strong>Zone
          </span>
          <span id="app-version" css={styleCSS.version}>
            eCBR {appVersion}
          </span>
        </span>
        <div id="app-login" css={styleCSS.login}>
          {isLogin(userInfo) ? (
            <Dropdown isOpen={dropdownOpen} toggle={toggleUserMenu}>
              <DropdownToggle>
                <LoginUserIcon>
                  <Icons type={Icons.Type.USER_FILLED} />
                </LoginUserIcon>
              </DropdownToggle>
              <DropdownMenu end>
                <a
                  className="dropdown-item-profile"
                  target="_blank"
                  rel="noreferrer"
                  href={`${auth.keycloak.url}/realms/${auth.keycloak.realm}/account/identity?kc_locale=th`}
                >
                  บัญชีผู้ใช้งาน
                </a>
                <DropdownItem divider />
                <a className="dropdown-item-logout" href="#" onClick={() => logout()}>
                  ออกจากระบบ
                </a>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <span css={styleCSS.icon} onClick={login}>
              <Icons type={Icons.Type.USER} />
            </span>
          )}
        </div>
      </HeaderContainer>
      <Dialog centered onClose={() => {}} show={showModal}>
        <Content>ท่านต้องการกลับไปยังหน้าแรกหรือไม่</Content>
        <Footer>
          <Button type={Button.Type.Primary} onClick={onBackToHome}>
            ต้องการกลับสู่หน้าแรก
          </Button>
          <Button type={Button.Type.Secondary} onClick={() => setShowModal(false)}>
            ยกเลิก
          </Button>
        </Footer>
      </Dialog>
    </div>
  )
}

export { MainHeader }
export default withRouter(MainHeader)
