import { css } from '@emotion/react'

import { color, transition, padding, customIcon, hexToRGB } from '../utils/theme'
import { reset, disabled } from '../utils/state'
import { device } from '../utils/theme'

const boxSize = '22px'
const checkboxSize = '12px'

export default {
  radioGroup: {
    radio: css``,
    checked: css``,
    grid: css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: ${device.screenSize.sm}) {
        row-gap: 10px;
      }
    `,
    'button-group': css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: stretch;
      margin: 5px 0;
      padding: 1px;
    `,
  },
  radioContainer: {
    radio: css`
      display: inline-block;
      padding: 3px 0;
    `,
    checked: css`
      display: inline-block;
      padding: 3px 0;
    `,
    'button-group': css`
      display: flex;
      flex: 1;
      border: solid 1px ${color.type.primary};

      & + & {
        border-left-width: 0;
      }
    `,
  },
  radio: css`
    ${reset};
    position: relative;
    display: inline-flex;

    label {
      display: inline-flex;
    }

    input {
      position: absolute;
      left: 0;
      z-index: -1;
      opacity: 0;

      &:focus + [data-ui-style] {
        border-color: ${color.type.primaryDark};
        box-shadow: 0 0 0 2px ${hexToRGB(color.type.primary, 0.4)};
      }

      &:active + [data-ui-style],
      &:focus + [data-ui-style] {
        border-color: transparent;
        box-shadow: 0 0 0 1px ${color.common.white}, 0 0 0 3px ${hexToRGB(color.type.primary)};
      }

      &[disabled]: {
        ${disabled}
      }
    }
  `,
  radioType: {
    radio: css``,
    checked: css``,
    'button-group': css`
      flex: 1;
      color: ${color.type.primary};
      text-align: center;
      background-color: ${color.common.white};

      input {
        &:active,
        &:focus {
          & + [data-ui-style] + div {
            z-index: 2;
            box-shadow: 0 0 0 3px ${hexToRGB(color.type.primary, 0.4)};
          }
        }
      }
    `,
  },
  radioLabel: css`
    display: inline-block;
    flex: 1;
    padding: 0 10px;
    @media (max-width: ${device.screenSize.sm}) {
      padding: 0px 0px 0px 5px;
      font-size: 0.9rem;
    }
  `,
  ui: css`
    position: relative;
    display: inline-block;
    width: ${boxSize};
    height: ${boxSize};
    cursor: pointer;
    background-color: ${color.common.white};
    border: solid 1px ${color.element.border};
    transition-duration: ${transition.fastest};
    transition-property: box-shadow, background-color;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      background-color: ${color.type.primary};
      border-radius: 50%;
      transform: translate(-50%, -50%);
      content: '';
    }
    &[data-ui-style='radio'] {
      border-radius: 50%;
    }
    &[data-checked='true'] {
      &::before {
        width: ${checkboxSize};
        height: ${checkboxSize};
      }
    }
    &[data-ui-style='checked'][data-checked='true'] {
      background-color: ${color.type.primary};
      &::before {
        display: block;
        background-color: ${color.common.white};
        mask-image: url(${customIcon('checked')});
        mask-size: cover;
        mask-repeat: no-repeat;
      }
    }
    &[data-ui-style='button-group'] {
      display: none;
    }
    /** Label */
    &[data-ui-style='button-group'] + div {
      padding: calc(${padding.m} / 4) ${padding.m};
      white-space: nowrap;
    }
    &[data-ui-style='button-group'][data-checked='true'] + div {
      color: ${color.common.white};
      background-color: ${color.type.primary};
    }
  `,
  disabled: css`
    ${disabled}
  `,
}
