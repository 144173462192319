import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import placeholder from 'assets/upload-image-placeholder.png'
import { Icons } from 'ktaxa-ui-components/index'
import { Theme } from 'components/index'
import { getConfig } from 'core/config'
import _isEmpty from 'lodash/isEmpty'

const Container = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 400px;
  margin: 15px auto;
  text-align: center;
  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
`

const Button = styled.div`
  color: ${Theme.color.type.primary};
`

const Label = styled.span`
  margin-left: 8px;
`

const Preview = styled.div`
  width: 280px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  &::after {
    display: block;
    padding-bottom: 100%;
    content: '';
  }
  & > div,
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  & > div {
    background-image: url(${placeholder});
    background-repeat: no-repeat;
    background-position: center;
  }
  img {
    object-fit: contain;
    object-position: 50% 50%;
  }
`

const UploadImage = (props) => {
  const { onSelected, label, value, setIsOverLimit } = props
  const [img, setImg] = useState({})
  const { limitFileSize } = getConfig()

  // initial data
  useEffect(() => {
    if (value) {
      setImg(value)
    }
  }, [value])

  const onSetFileData = (fileData) => {
    onSelected(fileData)
    setImg(fileData)
  }

  const handleImg = async (e) => {
    const file = e.target.files[0]
    const fileSize = file.size
    const fileName = file.name
    const contentType = file.type
    const url = URL.createObjectURL(file)
    const maximumSize = limitFileSize * 1024 * 1024
    if (file) {
      if (file.size > maximumSize) {
        setIsOverLimit(true)
      } else {
        const reader = new FileReader()
        setIsOverLimit(false)
        reader.onload = (e) => {
          const binaryString = e.target.result
          const base64String = btoa(binaryString)
          const fileData = {
            base64: base64String,
            contentType,
            size: fileSize,
            src: url,
            alt: fileName,
          }
          onSetFileData(fileData)
        }
        reader.readAsBinaryString(file)
      }
    }
  }

  return (
    <Container>
      <label htmlFor="upload-image">
        {!_isEmpty(img) ? (
          <Preview>
            {!_isEmpty(img.base64) ? <img src={`data:image/png;base64, ${img?.base64}`} alt={'uploadfile'} /> : <div />}
          </Preview>
        ) : (
          <Preview>{!_isEmpty(img.src) ? <img src={img?.src} alt={img?.alt} /> : <div />}</Preview>
        )}
        <input type="file" accept="image/*" id="upload-image" onChange={handleImg} />
        <Button>
          <Icons type={Icons.Type.UPLOAD} />
          <Label>{label}</Label>
        </Button>
      </label>
    </Container>
  )
}

UploadImage.propTypes = {
  onSelected: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.object,
  setIsOverLimit: PropTypes.func,
}

UploadImage.defaultProps = {
  onSelected() {},
  label: 'Upload image',
}

export default UploadImage
