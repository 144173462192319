const apiInstant = async (url, options = {}) => {
  try {
    const response = await fetch(url, options)

    if (response.status === 401) {
      window.location.reload()
      return response
    }

    return response
  } catch (error) {
    throw error
  }
}

export default apiInstant
