import React, { useEffect, useState, useContext } from 'react'
import { Input as RSInput } from 'reactstrap'
import { useForm, useController, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from '@emotion/styled'
import _isEmpty from 'lodash/isEmpty'
import { Radio, Checkbox } from 'ktaxa-ui-components/index'
import { InputCustomFormat, Dropdown } from 'components/input'
import { ContentContainer, SectionContainer } from 'components/layouts'
import _ from 'lodash'
import SubmitSection from './submit'
import PolicySection from './policy'
import PersonalIdSection from './personal-id'
import PaymentMethodSection from './payment-method'
import PayerInfoSection from './payerInfo'
import { renderErrorMessageBlock } from 'components/message-blocks'

import { schemaInformationSection, personNameRegExp } from '../hooks/useForm'

import { useRouterForHandleSubmit } from '../hooks/useRouter'
import { useLogin } from '../../../hooks/useServices/login'
import { AppContext } from 'contexts/app'
import staticContentPage from '../static-content'
import dayjs from 'dayjs'
import { getToggles } from 'core/config'
import EcbrEntity from 'domain/entities/ecbr'
import { PlanCode } from '../../../types/plan-code'
import { validateCitizenId } from 'utils/validate'

const SelectContainer = styled.div`
  & > div > div {
    display: block;
  }
`

const InformationSection = (props) => {
  const { tabState, state, setState, setTabState } = props

  // in case change payment method
  const receiptId =
    state?.pendingList?.receiptId || state?.process?.payload?.data?.receiptId || state?.info?.payload?.receiptId
  const cbrNumber =
    state?.pendingList?.cbrNumber || state?.process?.payload?.data?.cbrNumber || state?.info?.payload.cbrNumber

  const isChangePaymentMethod = state?.isChangePaymentMethod || false
  const isResumePayment = !_isEmpty(receiptId) || !_isEmpty(cbrNumber) || false

  const { formState, control, reset, handleSubmit, getValues, watch, setValue, clearErrors } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaInformationSection(isResumePayment)),
  })
  const { planCodeOptions } = useContext(AppContext)

  const { errors, dirtyFields } = formState
  const { userInfo, isHideEDC } = useLogin({})
  const initPolicyDetail = {
    policyNumber: '',
    policyEffectiveDate: '',
    policyExpirationDate: '',
    productCode: '',
    isOwner: false,
  }

  const [showDialog, setShowDialog] = useState(false)
  const [checkFetchPolicy, setCheckFetchPolicy] = useState(false)
  const [checkFillInValue, setCheckFillInValue] = useState(false) // this state use for disable 'fetchECBR()' when user want to manage data manual
  const [policyDetail, setPolicyDetail] = useState(initPolicyDetail)
  const [checkFetchPolicyWithId, setCheckFetchPolicyWithId] = useState(false)
  const [checkMemo, setCheckMemo] = useState(false)
  const [enableBySearchPolicyId, setEnableBySearchPolicyId] = useState(false)
  const [fieldDisabledSeachPolicy, setFieldDisabledSeachPolicy] = useState({})
  const [searchInputDisabled, setSearchInputDisabled] = useState<any[]>([])
  const [textSearchPolicy, setTextSearchPolicy] = useState(false)
  const [agentPolicyOwner, setAgentPolicyOwner] = useState(false)
  const ActiveCondition =
    tabState === staticContentPage.tabsProgress[0].id ||
    (tabState === staticContentPage.tabsProgress[0].id && isResumePayment) ||
    isChangePaymentMethod
  const [selectedPlanCode, setSelectedPlanCode] = useState<PlanCode | null | undefined>(null)
  const [agentPolicyListFromPolicyNo, setAgentPolicyListFromPolicyNo] = useState<EcbrEntity>({} as EcbrEntity)
  const { informationSection: formInfo } = staticContentPage
  const paymentType = watch('paymentType')
  const personalIdType = watch('personalIdType')
  const memo = watch('memo')

  const checkEnabledSystemization = () => {
    if (getToggles().ENABLE_ECBRSYS_ECBR && isResumePayment && _isEmpty(receiptId)) {
      // for tab 3 will hide payerInfo section and hide new paymentType (Topup ILP)
      return false
    }
    return getToggles().ENABLE_ECBRSYS_ECBR
  }

  const checkDisableField = (fieldName) => {
    const isEmptySearchInputDisabled = _isEmpty(searchInputDisabled)

    if (!isEmptySearchInputDisabled) {
      const isDisabledField = searchInputDisabled.includes(fieldName)

      return isDisabledField
    }

    return false
  }

  const paymentTypeOptions = checkEnabledSystemization() // systemization will add new paymentType
    ? formInfo.paymentType?.options
    : formInfo.paymentType?.options.filter((item) => item.value !== '07')

  useEffect(() => {
    //case state is empty
    if (_isEmpty(state)) {
      reset({
        paymentType: '02',
        paymentMethod: '',
        productCode: '',
        personalIdType: 'citizenId',
        chequeDate: dayjs().toISOString(),
        policyEffectiveDate: '',
        policyNo: '',
        insuredName: '',
        personalId: '',
        insureMobile: '',
        insuredSurName: '',
        insureEmail: '',
        paymentAmount: '',
        memo: '',
      })

      setPolicyDetail(initPolicyDetail)
      setCheckFetchPolicy(false)
      setCheckFillInValue(false)
      setCheckFetchPolicyWithId(false)
      setCheckMemo(false)
      if (getToggles().ENABLE_ENQUIRY_POLICYID) {
        setEnableBySearchPolicyId(false)
        setFieldDisabledSeachPolicy(['insuredName', 'insuredSurName', 'insureMobile', 'insureEmail', 'paymentAmount'])
        setSearchInputDisabled(['policyEffectiveDate', 'policyNo', 'productCode'])
      }
    }
    if (state?.process?.payload) {
      // case change payment method when create cc link fail
      const {
        cbrNumber,
        receiptId,
        paymentType,
        paymentMethod,
        productCode,
        policyNumber,
        insuredName,
        insuredSurName,
        personalId,
        insureMobile,
        insureEmail,
        paymentAmount,
        paymentMode,
        policyEffectiveDate,
      } = state?.process?.payload?.data
      const isValidCitizenId = validateCitizenId(personalId)

      //! Here is how to set the selected payer type if resume from pending and use it in reset method below
      //! To be honest, I still don't understand why
      const { payerInfo } = state?.process?.payload?.data || {}
      const {
        payerType,
        payerFirstName,
        payerLastName,
        payerCitizenID: payerPersonalId,
        payerRelationshipOthers: payerRelations,
      } = payerInfo || {}

      reset({
        cbrNumber,
        receiptId,
        paymentType,
        paymentMethod,
        productCode,
        personalIdType: isValidCitizenId ? 'citizenId' : 'passport',
        chequeDate: '',
        policyEffectiveDate,
        policyNo: policyNumber === staticContentPage.defaultPolicyNo ? '' : policyNumber,
        insuredName,
        personalId,
        insureMobile,
        insuredSurName,
        insureEmail,
        paymentAmount,
        paymentMode,
        memo: '',
        payerType,
        payerFirstName,
        payerLastName,
        payerPersonalId,
        payerRelations,
      })

      setCheckFetchPolicy(false)
      setCheckFillInValue(false)
      setCheckFetchPolicyWithId(false)
      setCheckMemo(false)

      if (getToggles().ENABLE_ENQUIRY_POLICYID) {
        setEnableBySearchPolicyId(false)
        setFieldDisabledSeachPolicy(['insuredName', 'insuredSurName', 'insureMobile', 'insureEmail', 'paymentAmount'])
        setSearchInputDisabled(['personalId', 'policyEffectiveDate', 'policyNo', 'productCode'])
      }
    }
  }, [state, ActiveCondition, planCodeOptions])

  // initial state
  useEffect(() => {
    if (state?.info?.payload) {
      reset({
        ...state?.info?.payload,
        chequeDate: dayjs().toISOString(),
      })
      state?.info?.payload?.paymentType === '02' && setCheckFetchPolicy(true)
    } else {
      reset({
        paymentType: '02',
        paymentMethod: '',
        productCode: '',
        personalIdType: 'citizenId',
        chequeDate: dayjs().toISOString(),
        policyEffectiveDate: '',
        policyNo: '',
        insuredName: '',
        personalId: '',
        insureMobile: '',
        insuredSurName: '',
        insureEmail: '',
        paymentAmount: '',
        memo: '',
        sumInsuredAmount: '',
        ownerFirstName: '',
        ownerLastName: '',
        ownerPersonalId: '',
      })
      if (getToggles().ENABLE_ENQUIRY_POLICYID) {
        setSearchInputDisabled(['policyEffectiveDate', 'policyNo', 'productCode'])
        setFieldDisabledSeachPolicy(['insuredName', 'insuredSurName', 'insureMobile', 'insureEmail', 'paymentAmount'])
      }
    }
  }, [reset])

  // useEffect(() => {
  //   if (isDirty) {
  //     setState({
  //       [staticContentPage.tabsProgress[0].id]: {
  //         isDirty,
  //       },
  //     })
  //   }
  // }, [isDirty])

  useEffect(() => {
    if (formInfo.paymentType.options[1].value === '02' && getToggles().ENABLE_ENQUIRY_POLICYID) {
      setTextSearchPolicy(true)
    } else {
      setTextSearchPolicy(false)
    }
  }, [getToggles().ENABLE_ENQUIRY_POLICYID, formInfo.paymentType.options[1].value])

  useEffect(() => {
    if (getToggles().ENABLE_ECBRSYS_ECBR && getValues('memo') === '') {
      setSearchInputDisabled(['policyNo'])
    } else {
      setSearchInputDisabled([])
    }
  }, [checkMemo,getValues('paymentType')])

  const onSelectPaymentType = (value) => {
    reset({
      policyNo: '',
      insuredName: '',
      personalId: '',
      insureMobile: '',
      insuredSurName: '',
      insureEmail: '',
      paymentAmount: '',
      productCode: '',
      memo: '',
      personalIdType: 'citizenId',
      paymentMethod: '',
      policyEffectiveDate: '',
      payerType: '',
    })
    setValue('paymentType', value, { shouldValidate: true, shouldDirty: true })
    setCheckFetchPolicy(false)
    setCheckFetchPolicyWithId(false)
    setCheckFillInValue(false)

    if (getToggles().ENABLE_ENQUIRY_POLICYID && formInfo.paymentType.options[1].value === value) {
      setTextSearchPolicy(true)
      setFieldDisabledSeachPolicy(['insuredName', 'insuredSurName', 'insureMobile', 'insureEmail', 'paymentAmount'])
      setSearchInputDisabled(['policyEffectiveDate', 'policyNo', 'productCode'])
    } else {
      setEnableBySearchPolicyId(false)
      setTextSearchPolicy(false)
      setFieldDisabledSeachPolicy({})
      setSearchInputDisabled([])
    }
  }

  const onSubmit = (data) => {
    if (data.chequeBank === 'ธนาคารผู้รับชำระ') {
      data.chequeBank = ''
    }

    setState({
      [staticContentPage.tabsProgress[0].id]: {
        payload: data,
      },
      agentId: userInfo?.agent_code,
    })
    setTabState(staticContentPage.tabsProgress[1].id)
  }

  const onChangeCheckBox = (field) => {
    setCheckMemo(!checkMemo)
    if (getValues('memo') && getValues('memo') !== '') {
      reset(
        {
          ...getValues(),
          memo: '',
        },
        {
          keepErrors: true,
        }
      )
    } else {
      field.onChange('on')
    }
  }

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      backButton: { toHome: true },
      extraButton: () => <SubmitSection tabState={tabState} handleSubmit={handleSubmit(onSubmit)} state={state} />,
    }
  )

  watch(({ productCode }) =>
    setSelectedPlanCode(
      productCode && !_isEmpty(planCodeOptions) ? planCodeOptions.find((x) => x.value === productCode) : null
    )
  )

  if (!ActiveCondition) return null
  return (
    <ContentContainer>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
          }
        }}
      >
        <h1>{formInfo.mainTitle}</h1>
        <SectionContainer>
          <label>{formInfo.paymentType.title}</label>
          <SelectContainer>
            <Controller
              name={formInfo.paymentType.id}
              control={control}
              render={() => (
                <Radio.Group
                  uiStyle={Radio.UIStyle.Checked}
                  disabled={isResumePayment}
                  onChange={(option) => onSelectPaymentType(option.value)}
                  value={getValues(formInfo.paymentType.id)}
                >
                  {paymentTypeOptions.map((cate) => (
                    <Radio key={cate.value} value={cate.value} label={cate.text} />
                  ))}
                </Radio.Group>
              )}
            />
          </SelectContainer>
        </SectionContainer>
        {getValues(formInfo.paymentType.id) === '01' && (
          <SectionContainer>
            <Controller
              name={'memo'}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Checkbox
                  disabled={isResumePayment}
                  uiStyle={Checkbox.UIStyle.Checked}
                  onChange={() => onChangeCheckBox(field)}
                  checked={checkMemo}
                  children="Memo"
                />
              )}
            />
          </SectionContainer>
        )}
        <SectionContainer>
          <label>{formInfo.paymentScheduleMethods.title}</label>
          <Controller
            name={formInfo.paymentScheduleMethods.id}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Radio.Group
                disabled={isResumePayment}
                uiStyle={Radio.UIStyle.ButtonGroup}
                onChange={(option) => field.onChange(option.value)}
                value={getValues(formInfo.paymentScheduleMethods.id)}
              >
                {formInfo.paymentScheduleMethods.options.map((option) => (
                  <Radio key={option.value} value={option.value} label={option.text} />
                ))}
              </Radio.Group>
            )}
          />
          {renderErrorMessageBlock(errors[formInfo.paymentScheduleMethods.id])}
        </SectionContainer>
        <PersonalIdSection
          control={control}
          errors={errors}
          getValues={getValues}
          dirtyFields={dirtyFields}
          setCheckFetchPolicy={setCheckFetchPolicy}
          setCheckFetchPolicyWithId={setCheckFetchPolicyWithId}
          checkFetchPolicyWithId={checkFetchPolicyWithId}
          checkFillInValue={checkFillInValue}
          setCheckFillInValue={setCheckFillInValue}
          setPolicyDetail={setPolicyDetail}
          policyDetail={policyDetail}
          checkFetchPolicy={checkFetchPolicy}
          clearErrors={clearErrors}
          reset={reset}
          watch={watch}
          enableBySearchPolicyId={enableBySearchPolicyId}
          setEnableBySearchPolicyId={setEnableBySearchPolicyId}
          setValue={setValue}
          textSearchPolicy={textSearchPolicy}
          setFieldDisabledSeachPolicy={setFieldDisabledSeachPolicy}
          searchInputDisabled={searchInputDisabled}
          setSearchInputDisabled={setSearchInputDisabled}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          isResumePayment={isResumePayment}
        />

        <PolicySection
          control={control}
          errors={errors}
          getValues={getValues}
          dirtyFields={dirtyFields}
          setCheckFetchPolicy={setCheckFetchPolicy}
          setCheckFetchPolicyWithId={setCheckFetchPolicyWithId}
          checkFetchPolicyWithId={checkFetchPolicyWithId}
          checkFetchPolicy={checkFetchPolicy}
          setPolicyDetail={setPolicyDetail}
          policyDetail={policyDetail}
          reset={reset}
          checkFillInValue={checkFillInValue}
          setCheckFillInValue={setCheckFillInValue}
          enableBySearchPolicyId={enableBySearchPolicyId}
          setValue={setValue}
          agentPolicyOwner={agentPolicyOwner}
          setAgentPolicyOwner={setAgentPolicyOwner}
          textSearchPolicy={textSearchPolicy}
          setFieldDisabledSeachPolicy={setFieldDisabledSeachPolicy}
          setEnableBySearchPolicyId={setEnableBySearchPolicyId}
          agentPolicyListFromPolicyNo={agentPolicyListFromPolicyNo}
          setAgentPolicyListFromPolicyNo={setAgentPolicyListFromPolicyNo}
          searchInputDisabled={searchInputDisabled}
          setSearchInputDisabled={setSearchInputDisabled}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
        />
        <SectionContainer margin="20">
          <div className="name-input">
            <label>{formInfo.productCode.title}</label>
            <Controller
              name={formInfo.productCode.id}
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <Dropdown
                    {...field}
                    isClearable
                    placeholder=""
                    onChange={(option) => field.onChange(option?.value)}
                    value={
                      getValues(formInfo.productCode.id) && !_isEmpty(planCodeOptions)
                        ? planCodeOptions.find((x) => x.value === getValues(formInfo.productCode.id))
                        : null
                    }
                    options={planCodeOptions}
                    isDisabled={
                      _isEmpty(planCodeOptions) ||
                      enableBySearchPolicyId ||
                      checkDisableField('productCode') ||
                      (checkFetchPolicy && policyDetail.isOwner) ||
                      isResumePayment
                    }
                  />
                )
              }}
            />
            {renderErrorMessageBlock(errors?.productCode)}
          </div>
        </SectionContainer>
        <SectionContainer>
          {formInfo.textInputs.map((ti, key) => {
            return (
              <div key={ti.id} className="name-input" style={{ paddingBottom: 20 }}>
                <label style={{ display: 'block' }}>{ti.label}</label>
                {ti.format || ti.thousandSeparator ? (
                  // insureMobile & paymentAmount
                  <Controller
                    name={ti.id}
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <InputCustomFormat
                          {...field}
                          disabled={
                            fieldDisabledSeachPolicy[key]
                              ? formInfo.textInputs[key].id === fieldDisabledSeachPolicy[key]
                              : checkFetchPolicy && policyDetail.isOwner && !ti.change
                          }
                          style={{ textAlign: 'center' }}
                          mask={ti.mask}
                          allowNegative={ti.allowNegative}
                          format={ti.format}
                          maxLength={ti.maxLength}
                          thousandSeparator={ti.thousandSeparator}
                          decimalScale={ti.decimalScale}
                          fixedDecimalScale={ti.fixedDecimalScale}
                          isAllowed={ti.isAllowed}
                        />
                      )
                    }}
                  />
                ) : (
                  <Controller
                    name={ti.id}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <RSInput
                        {...field}
                        id={ti.id}
                        disabled={
                          fieldDisabledSeachPolicy[key]
                            ? formInfo.textInputs[key].id === fieldDisabledSeachPolicy[key]
                            : checkFetchPolicy && policyDetail.isOwner && !ti.change
                        }
                        type="text"
                        maxLength={ti.maxLength}
                        style={{ textAlign: 'center' }}
                        onChange={(v) => {
                          let value = v.target.value
                          if (ti.condition === 'textOnly') value = value.replace(personNameRegExp, '')
                          return field.onChange(value)
                        }}
                      />
                    )}
                  />
                )}
                {renderErrorMessageBlock(errors[ti.id])}
              </div>
            )
          })}
        </SectionContainer>
        {checkEnabledSystemization() && (
          <PayerInfoSection
            agentPolicyListFromPolicyNo={agentPolicyListFromPolicyNo}
            control={control}
            errors={errors}
            getValues={getValues}
            dirtyFields={dirtyFields}
            selectedPlanCode={selectedPlanCode}
            reset={reset}
            watch={watch}
            setValue={setValue}
            isResumePayment={isResumePayment}
          />
        )}
        <PaymentMethodSection
          control={control}
          errors={errors}
          getValues={getValues}
          dirtyFields={dirtyFields}
          selectedPlanCode={selectedPlanCode}
          planCodeOptions={planCodeOptions}
          reset={reset}
          watch={watch}
          setValue={setValue}
          isHideEDC={isHideEDC}
        />
      </form>
    </ContentContainer>
  )
}

export default InformationSection
