import React from 'react'
import styled from '@emotion/styled'

import { formatNumber } from 'utils/string-helper'
import { color } from 'components/theme'

const Amount = styled.div`
  padding: 8px 15px;
  background-color: ${color.type.primary};
  text-align: center;
  font-weight: 700;
  color: ${color.common.white};
`

type AmountBoxProps = {
  title: string
  amount: number
}

const AmountBox = (props: AmountBoxProps) => {
  const { title, amount } = props
  return (
    <>
      <h2>{title}</h2>
      <Amount>{formatNumber(amount)}</Amount>
    </>
  )
}

export default AmountBox
