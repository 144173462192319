import React from 'react'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { Button } from 'ktaxa-ui-components/index'
import AttachContainer from '../../components/attach-container'
import { useRouterForHandleSummit } from '../../hooks/useRouter'
import { cancelState } from '../../hooks/service'
import staticContentPage from '../../static-content-page'

type propsType = {
  currentSection: string
  setCurrentSection: Function
  state: cancelState
  setState: () => void
}

const AttechList = (props: propsType) => {
  const { currentSection, setCurrentSection, state, setState } = props
  const { cancelationSequence, mappingNextSection } = staticContentPage
  const isActive = currentSection === cancelationSequence[4]
  const someItemUploaded = state.attachment.find((item) => item.status === 'done')

  useRouterForHandleSummit(
    currentSection,
    isActive,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <Button onClick={() => setCurrentSection(mappingNextSection.cancelMethod[state.method])} type="secondary">
          กลับ
        </Button>
      ),
      extraButton: () => (
        <Button
          disabled={!someItemUploaded}
          onClick={() => setCurrentSection(mappingNextSection.cancelMethod[state.method])}
          type="primary"
        >
          ยืนยัน
        </Button>
      ),
    }
  )

  if (!isActive) return null
  return (
    <ContentContainer>
      <h1>{staticContentPage.cancelAttachSection.mainTitle}</h1>
      <SectionContainer>
        <AttachContainer state={state} setState={setState} />
      </SectionContainer>
    </ContentContainer>
  )
}

export default AttechList
