import React from 'react'
import styled from 'styled-components'
import { Button } from 'ktaxa-ui-components/index'
import { color } from 'components/theme'
import { getIcon } from 'ktaxa-ui-components/components/icons/normalIcon'

const Content = styled.div`
  background-color: ${color.common.white};
  border: 1px solid ${color.common.silver};
  padding: 8px 16px;
`

const Title = styled.div`
  display: flex;
  padding: 5px 0;
  label {
    flex: 1;
    align-self: center;
    margin: 0;
  }
`

const CustomBtn = styled(Button)`
  height: fit-content;
  align-self: center;
  svg {
    position: relative;
    top: 4px;
    left: -3px;
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }
`
const EditCard = ({ onClick, ...props }) => (
  <Content {...props}>
    <Title>
      <label>
        <strong>ข้อมูลที่กรอกไปถูกนำมาสร้างหนังสือยินยอม</strong>
      </label>

      <CustomBtn type="primary" onClick={onClick}>
        {getIcon('edit')}
        แก้ไข
      </CustomBtn>
    </Title>
  </Content>
)

export default EditCard
