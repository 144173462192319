import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  overflow: auto;

  .button_tab_disabled {
    color: #999;
    cursor: no-drop !important;
  }
  .button_tab {
    margin: 0 10px 0 10px;
    padding: 10px;
    white-space: nowrap;
    text-align: center;
    border-bottom: 2px solid #f5f5f5;
    cursor: pointer;
  }
  .button_tab_active {
    color: #2425aa;
    border-bottom: 2px solid #2425aa;
  }
`

type TabsValueType = {
  id: string
  title: string
  disabled?: boolean
}

type NoWrapTabsPropsType = {
  tabs: TabsValueType[]
  tabSelected: string
  selectTab: (ar1: string) => void
}

const NoWrapTabs = (props: NoWrapTabsPropsType) => {
  const { tabs, selectTab, tabSelected } = props
  const active = (tab) => (tab === tabSelected ? 'button_tab_active' : '')
  const isDisabled = (disabled: boolean | undefined) => (disabled ? 'button_tab_disabled' : '')
  const onClick = (selectedItem) => {
    if (selectedItem) selectTab('')
    return !selectedItem.disabled && selectTab(selectedItem.id)
  }
  return (
    <Container>
      {tabs.map((s) => (
        <div
          key={s.id}
          onClick={() => onClick(s)}
          className={`button_tab ${!s.disabled && active(s.id)} ${isDisabled(s.disabled)}`}
        >
          {s.title}
        </div>
      ))}
    </Container>
  )
}

export default NoWrapTabs
