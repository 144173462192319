import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputCustomFormat } from 'components/input'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { renderErrorMessageBlock } from 'components/message-blocks'
import { AppContext } from 'contexts/app'
import { Radio } from 'ktaxa-ui-components/index'
import _isEmpty from 'lodash/isEmpty'
import _uniqBy from 'lodash/uniqBy'
import _set from 'lodash/set'
import _get from 'lodash/get'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Input as RSInput } from 'reactstrap'
import { useLogin } from '../../../hooks/useServices/login'
import { getPlanCode } from '../../new-ecbr/hooks/useService'
import LoadingModal from '../components/load-data-dialog'
import { schemaInformationSection } from '../hooks/useForm'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import {
  verifyCCPAExisting,
  verifyPendingDA,
  getEIPApplicationList,
  getEIPPolicyWithIDList,
  getEIPApplicationDetails,
  getEIPPolicyDetails,
  updateRecurringData,
} from '../hooks/useService'
import staticContentPage, { DA_STATUS, UPDATE_TYPES } from '../static-content'
import SubmitSection from './submit'
import DAConsentDialog from '../components/da-consent-dialog'

import {
  getIsShowReviewForm,
} from '../utils'

const SelectContainer = styled.div`
  margin-top: 20px;
  & > div > div {
    display: block;
  }
`

const LoadingContent = styled.div`
  text-align: center;
`

const VerifyPolicySection = (props) => {
  // FYI : tab index 1
  const { tabState, state, setState, setTabState, isNeedToReload, selectedRecurringInfo, setSelectedRecurringInfo } =
    props
  const { setCcpaPlanCodeOptions } = useContext(AppContext)

  const history = useHistory()

  const { token } = useLogin({})
  const [isLoadingFetchPlanCode, setIsLoadingFetchPlanCode] = useState(false)
  const [isGetPlanCodeError, setIsGetPlanCodeError] = useState(false)
  const [planCodeList, setPlanCodeList] = useState<any[]>([])
  const [showConsentDialog, setShowConsentDialog] = useState(false)

  const { formState, control, reset, handleSubmit, getValues, setValue, trigger } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaInformationSection),
  })
  const { errors, isDirty, dirtyFields, isValid } = formState

  const [fieldInputDisabled, setFieldInputDisabled] = useState<any[]>([])
  // first tab (index 0)
  const ActiveCondition = tabState === staticContentPage.tabsProgress[0].id

  useEffect(() => {
    let isMount = false
    setIsLoadingFetchPlanCode(true)

    const fetchPlanCode = async () => {
      const result = await getPlanCode(token, 'ccpa')
      if (!result.data) {
        setIsLoadingFetchPlanCode(false)
        setIsGetPlanCodeError(true)
      } else {
        //! save to context
        setIsLoadingFetchPlanCode(false)
        setPlanCodeList(result.data)
        setCcpaPlanCodeOptions(result.data)
        setIsGetPlanCodeError(false)
      }
    }

    fetchPlanCode()

    return () => {
      isMount = true
    }
  }, [])

  useEffect(() => {
    if (isNeedToReload) {
      history.push('/')
      window.location.reload()
      return
    }
  }, [isNeedToReload])

  useEffect(() => {
    if (_isEmpty(state)) {
      reset({
        searchMethod: 'citizenId',
        policyEffectiveDate: '',
        policyNo: '',
        personalId: '',
      })
    }
  }, [state, ActiveCondition])

  // initial state
  useEffect(() => {
    if (state?.info?.payload) {
      reset({
        ...state?.info?.payload,
      })
    } else {
      reset({
        searchMethod: 'citizenId',
        policyEffectiveDate: '',
        policyNo: '',
        personalId: '',
      })
      setFieldInputDisabled(['policyNo', 'policyEffectiveDate'])
    }
  }, [reset])

  useEffect(() => {
    if (isDirty) {
      setState({
        [staticContentPage.tabsProgress[0].id]: {
          isDirty,
        },
      })
    }
  }, [isDirty])

  const { informationSection: formInfo } = staticContentPage

  const selectAgentPolicy = async (agentPolicy: any) => {
    const { policyEffectiveDate, policyNumber: policyNo, isDataByPolicy } = agentPolicy
    let resultPolicyDetails = await verifyPendingDA({ policyEffectiveDate, policyNo, planCodeList, isDataByPolicy })
    // NOTE: resultPolicyDetails
    // ---- format is here ----
    // {
    //   data: {
    //     resultPolicyDetails,
    //     RetrieveDebit,
    //     resultRecurringPayments,
    //     ccpaDetail,
    //     searchStatus,
    //   },
    //   error: null,
    // }
    // ------------------------
    const dataResultPolicyDetails = _get(resultPolicyDetails, 'data',null)
    setSelectedRecurringInfo(dataResultPolicyDetails)
    const searchDAInfo = _get(dataResultPolicyDetails,'resultRecurringPayments.data',undefined)
    const searchStatus = _get(dataResultPolicyDetails,'searchStatus','')

    if (resultPolicyDetails.data.searchStatus === 'FOUND_DA_EXISTING') {
      // searchDAInfo
      // NOTE: for me searchDAInfo should have format like "mockPendingDAInfoRes" from --> mock.tsx
      // const searchDAInfo = mockPendingDAInfoRes
      await _set(state,'verify.searchStatus', searchStatus)
      await setState({
        ...state,
        [staticContentPage.tabsProgress[0].id]: {
          // searchCCPAInfo: res,
          // mock NAJA *****
          searchDAInfo: searchDAInfo, // --> should change field data
          //
          // searchDAInfo: resultPolicyDetails.data.policyDetail,
          searchStatus: searchStatus,

        },
      })
    } else {
      await _set(state,'verify.searchStatus', searchStatus)
      await setState({
        [staticContentPage.tabsProgress[0].id]: {
          // searchCCPAInfo: res,
          // mock NAJA *****
          searchDAInfo: searchDAInfo, // --> should change field data
          //
          // searchDAInfo: resultPolicyDetails.data.policyDetail,
          searchStatus: searchStatus,
          ...resultPolicyDetails.data,

        },
      })

    }
  }

  const onSelectSearchMethod = (value) => {
    reset({
      searchMethod: '',
      personalId: '',
      policyNo: '',
      policyEffectiveDate: '',
    })

    setValue('searchMethod', value, { shouldValidate: true, shouldDirty: true })

    if (formInfo.searchMethod.options[2].value === value) {
      setFieldInputDisabled(['personalId'])
    } else {
      setFieldInputDisabled(['policyEffectiveDate', 'policyNo'])
    }
  }


  // GIFT : refactor done (?) ---> wating for remove comment
  const onSubmit = async (data) => {

    try {
      const { searchMethod, policyEffectiveDate, policyNo, personalId } = data
      const isSearchByPolicyNumber = searchMethod === 'policyId'

      if (isSearchByPolicyNumber) {
        const resultVerifyPendingDA = await verifyPendingDA({
          policyEffectiveDate,
          policyNo,
          planCodeList,
          isDataByPolicy: true,
        })

        if (resultVerifyPendingDA.error === 'POLICY_NOT_FOUND') {
          setState({
            [staticContentPage.tabsProgress[0].id]: {
              searchPolicyStatus: 457,
              searchStatus: 'POLICY_NOT_FOUND',
            },
          })
        } else {
          setSelectedRecurringInfo(resultVerifyPendingDA.data)

          const searchDAInfo = resultVerifyPendingDA?.data?.resultRecurringPayments?.data || undefined

          setState({
            [staticContentPage.tabsProgress[0].id]: {
              // searchCCPAInfo: res,
              // mock NAJA *****
              // searchDAInfo: resultVerifyPendingDA.data.policyDetail,
              searchDAInfo,
              searchStatus: resultVerifyPendingDA.data?.searchStatus,
            },
          })
        }
      } else {
        const personalIdFormat = personalId.replace(/ +/g, '')
        let listPolicy
        const [resultPolicyDA, resultApplication] = await Promise.all([
          getEIPPolicyWithIDList(personalIdFormat),
          getEIPApplicationList({ idDocumentNumber: personalIdFormat }),
        ])
        listPolicy = [
          ...(resultPolicyDA.searchPolicyStatus === 200 ? resultPolicyDA.data : []),
          ...(resultApplication.searchPolicyStatus === 200 ? resultApplication.data : []),
        ]
        if (resultPolicyDA.searchPolicyStatus === 500 && resultApplication.searchPolicyStatus === 500) {
          setState({
            [staticContentPage.tabsProgress[0].id]: {
              payload: data,
              agentPolicyList: listPolicy,
              searchPolicyStatus: !_isEmpty(listPolicy) ? 200 : 457,
              searchStatus: 'SERVER_ERROR',
            },
          })
        } else {
          listPolicy = _uniqBy(listPolicy, 'policyNumber')
          setState({
            [staticContentPage.tabsProgress[0].id]: {
              payload: data,
              agentPolicyList: listPolicy,
              searchPolicyStatus: !_isEmpty(listPolicy) ? 200 : 457,
              searchStatus: 'SHOW_LIST_POLICY',
            },
          })
        }
      }
    } catch (error) {
      setState({
        [staticContentPage.tabsProgress[0].id]: {
          payload: data,
          agentPolicyList: [],
          searchPolicyStatus: 500,
        },
      })
    }
  }

  const checkDisableField = (fieldName) => {
    if (!_isEmpty(fieldInputDisabled)) {
      const isDisabledField = fieldInputDisabled.includes(fieldName)
      return isDisabledField
    }
    return false
  }

  const beforeSubmit = () => {
    trigger()
    handleSubmit(onSubmit)()
  }
  const doneSubmit = () => {
    trigger()
    handleSubmit(onSubmitDA)()
  }

  const onSubmitDA = () => {

  }

  const ConfirmConsent = async (data) => {
    try {
      const resultUpdate = await updateRecurringData({
        updateType: UPDATE_TYPES.UPDATE,
        data: {
          policyId: data.policyId,
          _id: data._id,
          daStatus: DA_STATUS.CREATE,
          daConsent: true,
        },
      })
      await setTabState(staticContentPage.tabsProgress[1].id)
    } catch (error) {
      console.error('ConfirmConsent error', error.message)
    }

    setShowConsentDialog(false)
  }

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      backButton: { toHome: true },
      extraButton: () => (
        <SubmitSection
          tabState={tabState}
          handleSubmit={beforeSubmit}

          setTabState={setTabState}
          setShowConsentDialog={setShowConsentDialog}
          state={state}
          setState={setState}
          selectedRecurringInfo={selectedRecurringInfo}
          setSelectedRecurringInfo={setSelectedRecurringInfo}
          selectAgentPolicy={selectAgentPolicy}
        />
      ),
      // isOnlyExtraButtonCenter: true,
    }
  )

  const { setIsVerifyFormValidateError } = useContext(AppContext)

  if (!ActiveCondition) return null
  return (
    <ContentContainer>
      <DAConsentDialog
        show={showConsentDialog}
        onToggle={() => {
          setShowConsentDialog(false)
        }}
        onConfirm={() => {
          // save flag consent to db
          ConfirmConsent(selectedRecurringInfo.resultRecurringPayments.data)
          // setState({
          //   [staticContentPage.tabsProgress[0].id]: {
          //     ...state[staticContentPage.tabsProgress[0].id],
          //     isAcceptConsent: true,
          //   },
          // })
        }}
      />
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
          }
        }}
      >
        <SelectContainer>
          <Controller
            name={formInfo.searchMethod.id}
            control={control}
            render={() => (
              <Radio.Group
                uiStyle={Radio.UIStyle.Checked}
                onChange={(option) => onSelectSearchMethod(option.value)}
                value={getValues(formInfo.searchMethod.id)}
              >
                {formInfo.searchMethod.options.map((cate) => (
                  <Radio key={cate.value} value={cate.value} label={cate.text} />
                ))}
              </Radio.Group>
            )}
          />
        </SelectContainer>
        <SectionContainer>
          <div className="name-input" style={{ paddingTop: 20 }}>
            <label>{formInfo.identity.label}</label>
            <Controller
              name={formInfo.identity.id}
              control={control}
              render={({ field }) => {
                if (Object.keys(errors).length > 0 || !isValid) {
                  setIsVerifyFormValidateError(true)
                } else {
                  setIsVerifyFormValidateError(false)
                }
                return getValues('searchMethod') === 'passport' ? (
                  <RSInput
                    {...field}
                    disabled={checkDisableField(formInfo.identity.id)}
                    name={formInfo.identity.id}
                    type="text"
                    style={{ textAlign: 'center' }}
                    onChange={(v) => field.onChange(v.target.value)}
                    maxLength={13}
                  />
                ) : (
                  <InputCustomFormat
                    {...field}
                    // name={formInfo.identity.id}
                    disabled={checkDisableField(formInfo.identity.id)}
                    style={{ textAlign: 'center' }}
                    mask={formInfo.identity.mask}
                    format={formInfo.identity.format}
                  />
                )
              }}
            />
            {renderErrorMessageBlock(errors[formInfo.identity.id])}
          </div>
          <div className="name-input" style={{ paddingTop: 20 }}>
            <label>{formInfo.policyNo.label}</label>
            <Controller
              name={formInfo.policyNo.id}
              control={control}
              render={({ field }) => (
                <InputCustomFormat
                  {...field}
                  disabled={checkDisableField(formInfo.policyNo.id)}
                  style={{ textAlign: 'center' }}
                  mask={formInfo.policyNo.mask}
                  format={formInfo.policyNo.format}
                  required={true}
                />
              )}
            />
            {renderErrorMessageBlock(errors[formInfo.policyNo.id])}
          </div>
          <div className="name-input" style={{ paddingTop: 20 }}>
            <label>{formInfo.policyEffectiveDate.label}</label>
            <Controller
              name={formInfo.policyEffectiveDate.id}
              control={control}
              render={({ field }) => (
                <InputCustomFormat
                  {...field}
                  disabled={checkDisableField(formInfo.policyEffectiveDate.id)}
                  style={{ textAlign: 'center' }}
                  mask={formInfo.policyEffectiveDate.mask}
                  format={formInfo.policyEffectiveDate.format}
                />
              )}
            />
            {renderErrorMessageBlock(errors[formInfo.policyEffectiveDate.id])}
          </div>
        </SectionContainer>
      </form>
      {/* TODO: need plan code for process */}
      <LoadingModal show={isLoadingFetchPlanCode} />
      {/* <DASystemErrorDialog
        show={isGetPlanCodeError}
        onClose={() => {
          history.push('/')
          setIsGetPlanCodeError(false)
        }}
      /> */}
    </ContentContainer>
  )
}

export default VerifyPolicySection
