import React, { useEffect, useContext, useState } from 'react'
import { Input } from 'reactstrap'

import { ContentContainer, SectionContainer } from 'components/layouts'
import { InputCustomFormat } from 'components/input'
import DateRangePickerComponent from 'components/input/date-range-picker'
import { NoWrapTabs } from 'components/tabs'

import { BlankCard, ECBRCard } from 'components/cards'
import { RouterContext } from '../../contexts/router'
import staticContentPage from './static-content-page'
import { useEcbr, useEcbrSys } from './hook/useService'
import styled from '@emotion/styled'
import { getToggles } from 'core/config'
import InputCustomSearch from 'components/input/input-custom-search'
import InputCustomFormatSearch from 'components/input/input-custom-format-search'
import ResentEcbrModal from './components/resent-ecbr-dialog'
import './select.css'
import dayjs from 'dayjs'
import ApiDownDialog from 'components/api-down-dialog'
import _, { isEmpty } from 'lodash'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0 0;
`
const DateRangeContainer = styled.div`
  margin: -15px 0 15px;
  display: flex;
  flex-direction: column;
`
const Message = styled.div`
  color: #333;
`

const searchDropdown = {
  searchByPolicyNo: 'searchByPolicyNo',
  searchByEcbrNo: 'searchByEcbrNo',
  searchByCitizenId: 'searchByCitizenId',
  searchByInsuredName: 'searchByInsuredName',
  searchByStatus: 'searchByStatus',
} as const

const HistoricalPage = (props) => {
  // set navconfig
  const RouterContextProps = useContext(RouterContext)
  const { setNavConfigByPage } = RouterContextProps
  useEffect(() => {
    setNavConfigByPage({
      showFooter: true,
      backButton: { toHome: true },
    })
    return () => {
      setNavConfigByPage({
        showFooter: false,
      })
    }
  }, [])

  const [valSearch, setValSearch] = useState('')
  const [searchByVal, setSearchByVal] = useState('')
  const [clearSearch, setClearSearch] = useState(false)
  const [isFirstRender, setFirstRender] = useState(true)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [isOpenResentEcbrModalAgent, setIsOpenResentEcbrModalAgent] = useState(false)
  const [isOpenResentEcbrModalCustomer, setIsOpenResentEcbrModalCustomer] = useState(false)
  const [phoneAgent, setPhoneAgent] = useState('')
  const [phoneCustomer, setPhoneCustomer] = useState('')
  const [emailAgent, setEmailAgent] = useState('')
  const [emailCustomer, setEmailCustomer] = useState('')
  const [createDateFrom, setcreateDateFrom] = useState(dayjs(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)).format('DD/MM/YYYY'))
  const [createDateTo, setcreateDateTo] = useState(dayjs(new Date()).format('DD/MM/YYYY'))
  const [selectedEcbrNo, setSelectedEcbrNo] = useState('')
  const [selectedEcbrInfo, setSelectedEcbrInfo] = useState({})
  const { ecbrs, error, fetchSearchData,  setError, loading } = useEcbrSys(debouncedSearchTerm, searchByVal)
  let newEcbrs
  if (getToggles().ENABLE_ECBRSYS_ECBR){
    if (clearSearch){
      newEcbrs = []
    } else {
      newEcbrs = ecbrs
    }
  } else {
    newEcbrs = useEcbr(debouncedSearchTerm, searchByVal)
  }
  useEffect(() => {
    if (!getToggles().ENABLE_ECBRSYS_ECBR || searchByVal === searchDropdown.searchByStatus) {
      const timerId = setTimeout(() => {
        setDebouncedSearchTerm(valSearch)
      }, 1000)

      return () => {
        clearTimeout(timerId)
      }
    }
  }, [valSearch])

  const handleSearchBy = (e) => {
    setClearSearch(true)
    setValSearch('')
    setSearchByVal(e.target.value)
    setFirstRender(true)
    if (getToggles().ENABLE_ECBRSYS_ECBR) {
      setDebouncedSearchTerm('')
    }
  }

  const handleClickEcbrCard = (ec) => {
    const { cbrStatus, cbrNumber } = ec
    if (
      cbrStatus === staticContentPage.mappingStatus.waitingForCheckin ||
      cbrStatus === staticContentPage.mappingStatus.waitingForPay
    ) {
      props?.history?.push(`/pending/${cbrNumber}`)
    }
  }

  const handleDebouncedSearchTerm = (val) => {
    setClearSearch(false)
    const esysSerchTerm = {
      createDateFrom,
      createDateTo,
      val
    }
    setValSearch(val)
    setDebouncedSearchTerm(JSON.stringify(esysSerchTerm))
    if (!isEmpty(val)) {
      fetchSearchData(esysSerchTerm, searchByVal)
    }
  }

  const handleDateChange = (value: [Date, Date]) => {
    setcreateDateFrom(dayjs(value[0]).format('DD/MM/YYYY'))
    setcreateDateTo(dayjs(value[1]).format('DD/MM/YYYY'))
  }

  const handleIsOpenResentEcbrModalAgent = ({ value, ecbrNo })=> {
    setPhoneAgent('')
    setEmailAgent('')
    setPhoneAgent(String(_.get(ecbrs, 'agentMobilephone')))
    setEmailAgent(String(_.get(ecbrs, 'agentEmail')))
    setSelectedEcbrNo(ecbrNo)
    setIsOpenResentEcbrModalAgent(value)
  }

  const handleIsOpenResentEcbrModalCustomer = ({ value, insuredEmail, insuredMobilephone, ecbrInfo })=> {
    setPhoneCustomer('')
    setEmailCustomer('')
    setPhoneCustomer(insuredMobilephone)
    setEmailCustomer(insuredEmail)
    setSelectedEcbrNo(ecbrInfo.ecbrNo)
    setSelectedEcbrInfo(ecbrInfo)
    setIsOpenResentEcbrModalCustomer(value)
  }

  useEffect(() => {
    if (searchByVal === searchDropdown.searchByStatus && isFirstRender) {
      setValSearch('all')
      setFirstRender(false)
    }
  }, [])

  const searchHistoryDropdown = getToggles().ENABLE_ECBRSYS_ECBR
    ? staticContentPage.searchHistoryDropdownNew
    : staticContentPage.searchHistoryDropdown
  return (
    <ContentContainer>
      <HeaderContainer>
        <div>
          <h1>{staticContentPage.mainTitle}</h1>
        </div>
        {getToggles().ENABLE_ECBRSYS_ECBR && (
          <DateRangeContainer>
            <p style={{ color: '#2425aa', fontSize: '20px', marginBottom: '0rem' }}>วันที่ออกใบ</p>
            <DateRangePickerComponent handleDateChange={handleDateChange} createDateFrom={createDateFrom} createDateTo={createDateTo}/>
          </DateRangeContainer>
        )}
      </HeaderContainer>
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <Input
            type="select"
            className="custom-select form-control custom-select-adjusted"
            value={searchByVal}
            onChange={handleSearchBy}
          >
            <option key={'default-1'} value="" disabled>
              ต้องการค้นหาใบรับเงินชั่วคราว
            </option>
            {searchHistoryDropdown.map((sh) => (
              <option key={sh.id} value={sh.id}>
                {sh.title}
              </option>
            ))}
          </Input>
        </div>
        {(searchByVal === searchDropdown.searchByPolicyNo && !getToggles().ENABLE_ECBRSYS_ECBR) && (
          <InputCustomFormat mask="" format="###-#######" onValueChange={(v) => setValSearch(v.formattedValue)} />
        )}
        {(searchByVal === searchDropdown.searchByEcbrNo && !getToggles().ENABLE_ECBRSYS_ECBR) && (
          <Input type="text" maxLength={10} value={valSearch} onChange={(v) => setValSearch(v.target.value)} />
        )}
        {(searchByVal === searchDropdown.searchByCitizenId && !getToggles().ENABLE_ECBRSYS_ECBR) && (
          <Input type="text" maxLength={13} value={valSearch} onChange={(v) => setValSearch(v.target.value)} />
        )}
        {(searchByVal === searchDropdown.searchByInsuredName && !getToggles().ENABLE_ECBRSYS_ECBR) && (
          <Input type="text" maxLength={100} value={valSearch} onChange={(v) => setValSearch(v.target.value)} />
        )}
        {(searchByVal === searchDropdown.searchByPolicyNo && getToggles().ENABLE_ECBRSYS_ECBR) && (
          <InputCustomFormatSearch mask={[/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]} value={valSearch} onChange={setValSearch} onClick={handleDebouncedSearchTerm}/>
        )}
        {(searchByVal === searchDropdown.searchByEcbrNo && getToggles().ENABLE_ECBRSYS_ECBR) && (
          <InputCustomSearch value={valSearch} onChange={setValSearch} onClick={handleDebouncedSearchTerm} maxLength={10}/>
        )}
        {(searchByVal === searchDropdown.searchByCitizenId && getToggles().ENABLE_ECBRSYS_ECBR) && (
          <InputCustomSearch value={valSearch} onChange={setValSearch} onClick={handleDebouncedSearchTerm} maxLength={13}/>
        )}
        {(searchByVal === searchDropdown.searchByInsuredName && getToggles().ENABLE_ECBRSYS_ECBR) && (
          <InputCustomSearch value={valSearch} onChange={setValSearch} onClick={handleDebouncedSearchTerm} maxLength={100}/>
        )}
        {(searchByVal === searchDropdown.searchByStatus && !getToggles().ENABLE_ECBRSYS_ECBR) && (
          <NoWrapTabs
            tabSelected={valSearch}
            selectTab={setValSearch}
            tabs={searchHistoryDropdown[4].status}
          />
        )}
        {(searchByVal === searchDropdown.searchByStatus && getToggles().ENABLE_ECBRSYS_ECBR) && (
          <NoWrapTabs
            tabSelected={valSearch}
            selectTab={handleDebouncedSearchTerm}
            tabs={searchHistoryDropdown[4].status}
          />
        )}
      </SectionContainer>
      <SectionContainer>
        { loading? (<Message>กำลังค้นหา...</Message>): newEcbrs.length > 0 ? newEcbrs.map((ec, i) => (
          <ECBRCard
            onClick={() => handleClickEcbrCard(ec)}
            key={`${ec.policyNumber}-${i}`}
            name={`${ec.insuredName} ${ec.insuredSurName}`}
            amount={+ec.paymentAmount}
            status={ec.cbrStatus}
            createdAt={ec.createdAt?.toString()}
            daySinceCreated={ec.daySinceCreated}
            paymentNo={ec.cbrNumber}
            policyNumber={ec.policyNumber}
            insuredEmail={ec?.insuredEmail}
            insuredMobilephone={ec?.insuredMobilephone}
            handleIsOpenResentEcbrModalAgent={handleIsOpenResentEcbrModalAgent}
            handleIsOpenResentEcbrModalCustomer={handleIsOpenResentEcbrModalCustomer}
            page={'history'}
          />
        )): getToggles().ENABLE_ECBRSYS_ECBR && debouncedSearchTerm !== '' && searchByVal !== '' ? <BlankCard/>: ''}
      </SectionContainer>
      <ResentEcbrModal
        isOpen={isOpenResentEcbrModalCustomer}
        forAgent={false}
        onClose={()=>setIsOpenResentEcbrModalCustomer(false)}
        phone={phoneCustomer}
        setPhone={setPhoneCustomer}
        email={emailCustomer}
        setEmail={setEmailCustomer}
        ecbrNo={selectedEcbrNo}
        ecbrInfo={selectedEcbrInfo}
      />
      <ResentEcbrModal
        isOpen={isOpenResentEcbrModalAgent}
        forAgent={true}
        onClose={()=>setIsOpenResentEcbrModalAgent(false)}
        phone={''}
        setPhone={setPhoneAgent}
        email={emailAgent}
        setEmail={setEmailAgent}
        ecbrNo={selectedEcbrNo}
        ecbrInfo={selectedEcbrInfo}
      />
      <ApiDownDialog show={error != null} onToggle={()=>setError(null)} />
    </ContentContainer>
  )
}

export default HistoricalPage
