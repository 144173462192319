import React, { useState } from 'react'
import styled from '@emotion/styled'

import { Dialog, Button, Radio, Icons } from 'ktaxa-ui-components/index'
import { color, device } from 'components/theme'

const DialogContainer = styled.div`
  text-align: center;
`
const Content = styled.div`
  padding: 15px;
  padding-bottom: 30px;
  background: ${color.common.wildSand};
  min-height: 86vh;
  @media (max-width: ${device.screenSize.sm}) {
  padding: 10px;
  }
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 30px;
`
const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 15px;
`
const Header = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px 15px 15px;
  height: 50px;
  @media (max-width: ${device.screenSize.sm}) {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
    font-size: 1.1rem;
    span {
      font-size: 1.1rem;
    }
  }
`
const HeaderTitle = styled(Title)`
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  margin-bottom: 0;
  font-size: 1.2rem;
`
const SubTitle = styled(Title)`
  margin: 5px 0;
  font-size: 1.2rem;
  font-weight: 600;
  @media (max-width: ${device.screenSize.sm}) {
      font-size: 1.1rem;
    span {
      font-size: 1.1rem;
    }
  }
`
const Remark = styled(Title)`
  margin: 5px 0;
  font-size: 1rem;
  font-weight: 600;
`
const Message = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`
const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
`
const RadioConsentBox = styled.div`
  margin: auto;
  margin-top: 1.2rem;
`
const ConsentDetailBox = styled.div`
  text-align: left;
  padding: 30px;
  background: ${color.common.white};

  @media (min-width: ${device.screenSize.sm}) {
    max-width: 630px;
    margin: auto;
  }
  @media (min-width: ${device.screenSize.lg}) {
    max-width: 880px;
    margin: auto;
  }
`

const styleOptions = {
  box: {
    maxWidth: '100%',
    margin: '0px',
    border: 'none',
    borderRadius: '0px',
    top: '0px',
  },
  body: {
    minHeight: '98vh',
  },
  display: {
    fullScreen: true,
  },
  bodyContent: {
    padding: '0px',
  },
  idNameContent: 'daConsent',
}

type DAConsentDialogProps = {
  show: boolean
  onToggle: () => void
  onConfirm: () => void
}

const DAConsentDialog = (props: DAConsentDialogProps) => {
  const { show, onToggle, onConfirm } = props

  const [isAcceptConsent, setIsAcceptConsent] = useState(false)

  const handleBack = () => {
    setIsAcceptConsent(false)
    onToggle()
  }

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <DialogContainer>
      <Dialog
        styleOptions={{ ...styleOptions, idNameContent: `${styleOptions.idNameContent}` }}
        centered
        modal
        fullScreen
        show={show}
        // onClose={onToggle}
      >
        <div>
          <Header>
            <div style={{
              width: '25px',
              height: '25px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }} onClick={handleBack}>
              <Icons
                type="ARROW_LEFT"
                style={{ width: 'fit-content',
                  height: 'fit-content',
                  color: color.type.primary,
                  cursor: 'pointer',  }}
              />
            </div>
            <HeaderTitle>เงื่อนไขหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร</HeaderTitle>
          </Header>
          <Divider />
          <Content>
            <ConsentDetailBox>
              <SubTitle>ข้าพเจ้ายอมรับว่า</SubTitle>
              <Message>
                (ก) ธนาคารจะหักเงินเบี้ยประกันรายงวดเต็มจำนวนก็ต่อเมื่อเงินในบัญชีมีเพียงพอในขณะนั้นเท่านั้น
              </Message>
              <Message>
                (ข) หากไม่สามารถหักเงินตามข้อ (ก) ได้ไม่ว่าสาเหตุใดก็ตาม ให้ถือว่ายังไม่มีการชำระเบี้ยประกันภัย
              </Message>
              <Message>
                (ค) หากมีความเสียหาย หรือ ความผิดพลาดใด ๆ เกิดขึ้นแก่ธนาคาร หรือบริษัท ฯ เนื่องมาจากการกระทำใด ๆ
                ของข้าพเจ้า ข้าพเจ้ายินยอมรับชดใช้ค่าเสียหายแก่ธนาคารหรือบริษัท ฯ ตามความเสียหายที่เกิดขึ้นจริงทุกประการ
              </Message>
              <Message>
                (ง) หากจำนวนเงินที่บริษัท ฯ แจ้งแก่ธนาคารไม่ถูกต้อง
                แต่ธนาคารได้ทำการหักเงินจากบัญชีดังกล่าวตามจำนวนเงินที่บริษัท ฯ แจ้ง
                ข้าพเจ้าตกลงที่จะเรียกร้องเงินจำนวนดังกล่าวจากบริษัท ฯ
                โดยตรงและขอสละสิทธิ์ในการเรียกร้องหรือฟ้องร้องให้ธนาคารชดใช้เงินจำนวนนั้น
              </Message>
              <Message>(จ) ธนาคารไม่มีหน้าที่ตรวจสอบความถูกต้องของจำนวนเงินที่ได้รับแจ้งจากบริษัท ฯ</Message>
              <Message>
                (ฉ)
                ข้าพเจ้าไม่ประสงค์ให้ธนาคารแจ้งการหักบัญชีแต่อย่างใดเนื่องจากรายการดังกล่าวปรากฏอยู่ในสมุดคู่ฝาก/Statement
                ของธนาคารหรือเอกสารการรับเบี้ยประกันภัยของบริษัท ฯ
              </Message>
              <Message>
                (ช) ข้าพเจ้ายินยอมให้บริษัท ฯ
                เรียกเก็บเงินด้วยวิธีหักบัญชีเงินฝากธนาคารตามรอบการชำระของกรมธรรม์ของข้าพเจ้าข้างต้น
                และกรณีหักบัญชีไม่ผ่านเนื่องจากจำนวนเงินไม่พอชำระ บริษัท ฯ
                จะทำการส่งข้อมูลให้ธนาคารทำการเรียกเก็บเบี้ยประกันอีกครั้งภายใน 4 วันทำการ
              </Message>
              <Message>
                (ซ) ข้าพเจ้ายอมรับและยืนยันว่าข้าพเจ้าเป็นเจ้าของบัญชีธนาคารที่ประสงค์ให้หักชำระค่าเบี้ยฯ
                หรือเป็นผู้ชำระแทนในกรณีผู้เยาว์ หรือมีความสัมพันธ์โดยชอบตามทางกฎหมายเป็น บิดา-มารดา สามี-ภรรยา
                หรือบุตรของ ผู้เอาประกัน ทั้งนี้ ข้าพเจ้าจะไม่ปฏิเสธการทำรายการที่เกิดขึ้นในทุกกรณีกับธนาคารหรือบริษัท ฯ
                และหากพบข้อผิดพลาดหรือต้องการขอเงินคืน ข้าพเจ้าจะเป็นผู้ติดต่อไปยัง บริษัท กรุงไทย-แอกซ่า ประกันชีวิต
                จำกัด (มหาชน) โดยตรง
              </Message>
              <Message>
                (ญ)
                ข้าพเจ้ารับทราบและยอมรับว่าการทำรายการเพื่อสมัครหักชำระค่าเบี้ยประกันด้วยบัญชีธนาคารและ/หรือให้ความยินยอมหลังเวลา
                19:30 น.ของวันทำการ บริษัทฯ จะดำเนินการสมัครหักชำระเบี้ยฯภายในวันทำการถัดไป
              </Message>
              <br />
              <Message>
                หากบัญชีเงินฝากออมทรัพย์ หรือบัญชีกระแสรายวัน หรือค่าเบี้ยประกันภัย
                ได้เปลี่ยนแปลงไม่ว่าโดยสาเหตุใดก็ตามหนังสือแจ้งความประสงค์ฉบับนี้ ยังคงมีผลบังคับใช้กับบัญชีใหม่ หรือ
                ค่าเบี้ยประกันภัยที่ได้เปลี่ยนแปลงนั้นๆทุกประการ ทั้งนี้ข้าพเจ้าตกลงสละสิทธิ์ยกเลิก /แก้ไข /
                ลบล้างความยินยอมดังกล่าวจนกว่าความคุ้มครองตามสัญญาประกันภัยของข้าพเจ้า หรืออายุความในการฟ้องคดีสิ้นสุดลง
                แล้วแต่ระยะเวลาใดสิ้นสุดภายหลัง ทั้งนี้ หากมีการเปลี่ยนแปลงเลขที่บัญชีธนาคาร
                หรือยกเลิกความยินยอมตามหนังสือฉบับนี้ข้าพเจ้าจะแจ้งเป็นลายลักษณ์อักษรแก่บริษัท ฯ ล่วงหน้าอย่างน้อย 30
                วัน และให้ถือความยินยอมตามหนังสือฉบับล่าสุดของข้าพเจ้ามีผลบังคับใช้และยกเลิก ความยินยอมเดิมโดยอัตโนมัติ
              </Message>
              <br />
              <Remark>
                ข้าพเจ้ายินยอมให้บริษัท ฯ จัดเก็บ รวบรวม ใช้
                และเปิดเผยข้อมูลหมายเลขบัญชีเงินฝากของข้าพเจ้าให้แก่ธนาคารเจ้าของบัญชี
                เพื่อวัตถุประสงค์ในการหักชำระค่าเบี้ยประกันภัยของข้าพเจ้าเพื่อชำระให้แก่บริษัท ฯ
                ภายใต้เงื่อนไขของกรมธรรม์
              </Remark>
              <br />
            </ConsentDetailBox>
            <RadioConsentBox>
              <Radio.Group uiStyle={Radio.UIStyle.Checked} value={isAcceptConsent ? 'accept' : ''}>
                <Radio
                  value="accept"
                  label="ยอมรับ"
                  onClick={() => {
                    setIsAcceptConsent(!isAcceptConsent)
                  }}
                />
              </Radio.Group>
            </RadioConsentBox>
          </Content>
          <Footer>
            <Button type={Button.Type.Primary} onClick={handleConfirm} disabled={!isAcceptConsent}>
              ถัดไป
            </Button>
          </Footer>
        </div>
      </Dialog>
    </DialogContainer>
  )
}

export default DAConsentDialog
