import React from 'react'
import _get from 'lodash/get'
import styled from '@emotion/styled'
import cashPayment from '../../assets/images/payment/bills_dollar_blue.svg'
import edcPayment from '../../assets/images/payment/edc-mpos.svg'
import creditCardPayment from '../../assets/images/payment/credit_card_blue.svg'
import ktaxaLogo from '../../assets/images/logo-ktaxa-th.svg'
import omiseLogo from '../../assets/images/omise-logo.svg'

import groupSignature from '../../assets/images/form/group_signature.svg'
import sign from '../../assets/images/form/sign.svg'
import camera from '../../assets/images/form/camera.svg'
import arrowLeft from '../../assets/images/icons/arrow-left.svg'
import arrowRight from '../../assets/images/icons/arrow-right.svg'
import cross from '../../assets/images/icons/cross.svg'

import {
  MdCheckCircle as checkCircle,
  MdAdd as add,
  MdRadioButtonChecked as radioChecked,
  MdLens as radioUnChecked,
  MdAttachFile as attach,
  MdEdit as edit,
  MdPlayArrow as playArrow,
  MdWifi as wifi,
  MdClear as clear,
  MdDelete as _delete,
} from 'react-icons/md'

import _illustrate from '../../assets/images/nav/icon-Illustrate-Idle.svg'
import _check from '../../assets/images/form/check.svg'
import _keys from '../../assets/images/form/keys.svg'
import _agreement from '../../assets/images/form/agreement.svg'
import _padlock from '../../assets/images/form/padlock.svg'

const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 200px;
  ${({ theme }) => theme.media.md`max-width: 300px;`}
`
const baseIcon = (Icon) => styled(({ className, ...props }) => <Icon className={className} />)`
  width: 100%;
  max-width: 40px;
  max-height: 40px;
`
const illustrate = baseIcon(_illustrate)
const check = baseIcon(_check)
const agreement = baseIcon(_agreement)
const padlock = baseIcon(_padlock)
const keys = styled(({ className, ...props }) => (
  <div className={className}>
    {' '}
    <div className="keys">
      <_keys />
    </div>
  </div>
))`
  color: ${({ theme }) => theme.variables['$color-crimson']};
  width: 100%;
  margin: 0 auto;
  max-width: 200px;
  ${({ theme }) => theme.media.md`max-width: 300px;`}

  .keys {
    width: 100%;
    height: 100%;
    padding: 34px;
    background: white;
    border-radius: 50%;
  }

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: currentColor;
    }
  }
`

const Icon = {
  cashPayment,
  edcPayment,
  creditCardPayment,
  checkCircle,
  radioChecked,
  radioUnChecked,
  add,
  remove: _delete,
  check,
  groupSignature,
  sign,
  camera,
  edit,
  attach,
  arrowLeft,
  arrowRight,
  illustrate,
  playArrow,
  wifi,
  keys,
  agreement,
  padlock,
  clear,
  cross,
  ktaxaLogo,
  omiseLogo,
}

export default Icon
export const getIcon = (iconName) => {
  const GotIcon = _get(Icon, iconName)
  return GotIcon ? <GotIcon /> : 'Icon is not found.'
}
