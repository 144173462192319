export type TabeItemsType = {
  iconId: string
  id: string
  title: string
  disabled?: boolean
}

export const useTabStateActive = (id: string, tabItems: TabeItemsType[]): TabeItemsType[] => {
  const newTabItems = tabItems.map((t) => (t.id === id ? { ...t, disabled: false } : t))
  return newTabItems
}

export const useTabStateActiveMultiple = (ids: string[], tabItems: TabeItemsType[]): TabeItemsType[] => {
  const newTabItems = tabItems.map((t) => {
    const isTabActive = ids.includes(t.id)
    return { ...t, disabled: !isTabActive }
  })
  return newTabItems
}

export const reStateAfterTabActive = (id: string, tabItems: TabeItemsType[]): TabeItemsType[] => {
  let ac = false
  const newTabItems = tabItems.map((t) => {
    let item = {}
    if (t.id !== id) {
      item = { ...t, disabled: ac }
    } else {
      item = { ...t, disabled: ac }
      ac = true
    }
    return item
  })
  return newTabItems as TabeItemsType[]
}

export const reStateฺBeforeTabActive = (id: string, tabItems: TabeItemsType[]): TabeItemsType[] => {
  let ac = true
  const newTabItems = tabItems.map((t) => {
    let item = {}
    if (t.id !== id) {
      item = { ...t, disabled: ac }
    } else {
      ac = false
      item = { ...t, disabled: ac }
    }
    return item
  })
  return newTabItems as TabeItemsType[]
}

export const initFirstTab = (tabsProgress) => {
  const initTabsProcess = tabsProgress.map((t, k) => ({
    ...t,
    disabled: k === 0 ? false : true,
  }))

  const initTabsProcessWithOutPaymentTab = initTabsProcess.slice(0, initTabsProcess.length - 1)
  const firstTab = initTabsProcess.slice(0, 1)
  const firstTabWithPayment = [
    ...firstTab,
    ...initTabsProcess
      .slice(initTabsProcess.length - 1, initTabsProcess.length)
      .map((i) => ({ ...i, disabled: false })),
  ]
  return {
    firstTabWithPayment,
    initTabsProcessWithOutPaymentTab,
    firstTab,
    initTabsProcess,
  }
}
