import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color } from 'components/theme'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 0;
    padding-left: 0;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px 0;
`

const ContentBody = styled.div`
  padding: 0 30px 15px;
  text-align: left;
`

const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Subtitle = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`
const Message = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
  font-weight: bold;
`

const dialogTitle = {
  CCPA_SUCCESS: 'ระบบพบข้อมูลการสมัครของท่าน',
  POLICY_INVALID: 'กรมธรรม์ไม่ตรงตามเงื่อนไขการสมัคร',
  POLICY_NOT_FOUND: 'ระบบตรวจสอบไม่พบข้อมูลกรมธรรม์',
  POLICY_DETAIL_SERVER_ERROR: 'ระบบตรวจสอบไม่พบข้อมูลกรมธรรม์',
}

const SearchStatusDialog = (props: any) => {
  const { show, onToggle, onSubmit, ecbrData } = props
  const isPolicyInvalid = ecbrData?.searchStatus === 'POLICY_INVALID'

  let dialogContent

  switch (ecbrData?.searchStatus) {
    case 'CCPA_SUCCESS':
      dialogContent = (
        <>
          <ContentBody>
            <Subtitle>ระบบพบข้อมูลการสมัครของท่านโดยมีข้อมูล</Subtitle>
            <Message>ชื่อ : {ecbrData?.ccpaDetail?.data?.insuredFirstName}</Message>
            <Message>นามสกุล : {ecbrData?.ccpaDetail?.data?.insuredLastName} </Message>
            <Message>เลขที่บัตรเครดิตลงท้าย : {ecbrData?.ccpaDetail?.data?.creditCardNumber.slice(-4)} </Message>
          </ContentBody>
          <Subtitle>ท่านต้องการทำการสมัครใหม่ เพื่อแทนข้อมูลเดิมหรือไม่ ? </Subtitle>
        </>
      )
      break
    case 'POLICY_INVALID':
      dialogContent = (
        <Content>
          <Subtitle>กรุณาตรวจสอบกรมธรรม์อีกครั้ง ก่อนทำการสมัคร</Subtitle>
        </Content>
      )
      break
    case 'POLICY_NOT_FOUND' || 'POLICY_DETAIL_SERVER_ERROR':
      dialogContent = (
        <Content>
          <Subtitle>ท่านต้องการกรอกข้อมูลด้วยตนเองหรือไม่</Subtitle>
        </Content>
      )
      break
    default:
      dialogContent = (
        // error server case
        <Content>
          <Subtitle>ระบบมีข้อผิดพลาด ท่านต้องการกรอกข้อมูลด้วยตนเองหรือไม่</Subtitle>
        </Content>
      )
  }

  return (
    <DialogContainer>
      <Dialog centered modal show={show}>
        <>
          <Title>
            {ecbrData?.searchStatus ? dialogTitle[ecbrData?.searchStatus] : 'ระบบตรวจสอบไม่พบข้อมูลกรมธรรม์'}
          </Title>
          <Divider />
          {dialogContent}
          <Footer>
            {!isPolicyInvalid && (
              <Button type={Button.Type.Secondary} onClick={onToggle}>
                ไม่ต้องการ
              </Button>
            )}
            <Button type={Button.Type.Primary} onClick={onSubmit}>
              ยืนยัน
            </Button>
          </Footer>
        </>
      </Dialog>
    </DialogContainer>
  )
}

export default SearchStatusDialog
