import mapValues from 'lodash/mapValues'

let CONFIG = {}

export const loadConfig = async () => {
  const res = await fetch('/config.json')
  const configJson = await res.json()

  CONFIG = configJson
  CONFIG.toggles = mapToggles(configJson.toggles)
  CONFIG._loaded = true

  return CONFIG
}

const mapToggles = (toggles) => {
  return mapValues(toggles, (toggle) => {
    return toggle
  })
}

export const getConfig = () => {
  if (CONFIG._loaded) {
    return CONFIG
  }
  return loadConfig()
}

export const getToggles = () => {
  return CONFIG.toggles || {}
}
