import React, { useContext } from 'react'
import { Button } from 'ktaxa-ui-components/index'
import { AppContext } from 'contexts/app'
import staticContentPage from '../static-content'

const ConfirmButtonCheckStatusPage = (props) => {
  const { setTabState, setState, setIsSuccessCcpaStatus, setIsNeedToReload } = props
  const { isDisabledConfirmButton, setCcpaInfo, setIsDisabledBackButton, setIsDisabledConfirmButton } =
    useContext(AppContext)

  const handleClearState = () => {
    setState({})
    setCcpaInfo({})
    setIsSuccessCcpaStatus(false)
    setIsNeedToReload(true)
    setIsDisabledBackButton(false)
    setIsDisabledConfirmButton(true)
    setTabState(staticContentPage.tabsProgress[0].id)
  }

  return (
    <>
      <Button onClick={handleClearState} type="primary" disabled={isDisabledConfirmButton}>
        ยืนยัน
      </Button>
    </>
  )
}

export default ConfirmButtonCheckStatusPage
