import { css } from '@emotion/react'

export default {
  icon: css`
    display: inline-block;
    color: currentColor;
    fill: currentColor;
    line-height: 0;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;

    svg {
      width: 100%;
      height: 100%;
      color: currentColor;
    }
  `,
}
