import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import staticContentPage from '../../static-content-page'
import { Radio } from 'ktaxa-ui-components/index'
import { Input as RSInput } from 'reactstrap'
import { InputCustomFormat } from 'components/input'
import { renderErrorMessageBlock } from 'components/message-blocks'
import { SectionContainer } from 'components/layouts'
import { textAndNumberOnlyRegExp } from '../../hooks/useForm'
import { getToggles } from 'core/config'
import newStaticContentPage from '../../new-static-content-page'

const CancelReasonSection = (props) => {
  const { errors, control, setValue, getValues, resetForm } = props
  const { cancelFormSection } = getToggles().ENABLE_ECBRSYS_ECBR? newStaticContentPage: staticContentPage
  return (
    <>
      <SectionContainer
        margin={
          getValues(`object.${cancelFormSection.reason.id}`) === '0' ||
          getValues(`object.${cancelFormSection.reason.id}`) === undefined
            ? '10'
            : '0'
        }
      >
        <Controller
          name={`object.${cancelFormSection.reason.id}`}
          control={control}
          render={() => (
            <Radio.Group
              uiStyle={Radio.UIStyle.Checked}
              value={getValues(`object.${cancelFormSection.reason.id}`)}
              onChange={(option) => {
                resetForm()
                setValue(
                  'object',
                  {
                    [cancelFormSection.reason.id]: option.value,
                    [cancelFormSection.reason.ecbrNo.radio[1].textInputs.id]: '',
                    [cancelFormSection.reason.other.radio[0].textInputs.id]: '',
                  },
                  { shouldDirty: true, shouldValidate: true }
                )
              }}
            >
              {!getToggles().ENABLE_ECBRSYS_ECBR && cancelFormSection.reason.ecbrNo.radio[0].groups.map((group, index) => (
                <Radio key={index} value={group.value} label={group.text} />
              ))}
            </Radio.Group>
          )}
        />
        {getToggles().ENABLE_ECBRSYS_ECBR? null :getValues(`object.${cancelFormSection.reason.id}`) === '0' ||
        getValues(`object.${cancelFormSection.reason.id}`) === undefined ? (
          <Controller
            name={`object.${cancelFormSection.reason.ecbrNo.radio[0].textInputs.id}`}
            control={control}
            render={({ field }) => (
              <InputCustomFormat
                {...field}
                maxLength={cancelFormSection.reason.ecbrNo.radio[0].textInputs.maxLength}
                decimalSeparator=""
                allowNegative={false}
                onChange={(v) => field.onChange(v.target.value)}
              />
            )}
          />
        ) : null}

        {renderErrorMessageBlock(errors?.object?.[cancelFormSection.reason.ecbrNo.radio[0]?.textInputs?.id])}
      </SectionContainer>
      <SectionContainer margin={getValues(`object.${cancelFormSection.reason.id}`) === '1' ? '10' : '0'}>
        <Controller
          name={`object.${cancelFormSection.reason.id}`}
          control={control}
          render={() => (
            <Radio.Group
              uiStyle={Radio.UIStyle.Checked}
              value={getValues(`object.${cancelFormSection.reason.id}`)}
              onChange={(option) => {
                resetForm()
                setValue(
                  'object',
                  {
                    [cancelFormSection.reason.id]: option.value,
                    [cancelFormSection.reason.ecbrNo.radio[0].textInputs.id]: '',
                    [cancelFormSection.reason.other.radio[0].textInputs.id]: '',
                  },
                  { shouldDirty: true, shouldValidate: true }
                )
              }}
            >
              {cancelFormSection.reason.ecbrNo.radio[1].groups.map((group, index) => (
                <Radio key={index} value={group.value} label={group.text} />
              ))}
            </Radio.Group>
          )}
        />
        {getValues(`object.${cancelFormSection.reason.id}`) === '1' ? (
          <>
            <Controller
              name={`object.${cancelFormSection.reason.ecbrNo.radio[1].textInputs.id}`}
              control={control}
              render={({ field }) => (
                <InputCustomFormat
                  {...field}
                  maxLength={cancelFormSection.reason.ecbrNo.radio[1].textInputs.maxLength}
                  decimalSeparator=""
                  allowNegative={false}
                  onChange={(v) => {
                    v.preventDefault()
                    field.onChange(v.target.value)
                  }}
                />
              )}
            />
            {renderErrorMessageBlock(errors?.object?.[cancelFormSection.reason.ecbrNo.radio[1]?.textInputs?.id])}
            {getToggles().ENABLE_ECBRSYS_ECBR? '(กรณีออกซ้ำ eCBR / Advisor Zone)': '(กรณีออกซ้ำ eCBR / Advisor Zone / CBR กระดาษ)'}
          </>
        ) : null}
      </SectionContainer>
      <SectionContainer margin={'0'}>
        <Controller
          name={`object.${cancelFormSection.reason.id}`}
          control={control}
          render={() => (
            <Radio.Group
              uiStyle={Radio.UIStyle.Checked}
              value={getValues(`object.${cancelFormSection.reason.id}`)}
              onChange={(option) => {
                resetForm()
                setValue(
                  'object',
                  {
                    [cancelFormSection.reason.id]: option.value,
                    [cancelFormSection.reason.ecbrNo.radio[0].textInputs.id]: '',
                    [cancelFormSection.reason.ecbrNo.radio[1].textInputs.id]: '',
                    [cancelFormSection.reason.other.radio[0].textInputs.id]: '',
                  },
                  { shouldDirty: true, shouldValidate: true }
                )
              }}
            >
              {cancelFormSection.reason.ecbrNo.radio[2].groups.map((group, index) => (
                <Radio key={index} value={group.value} label={group.text} />
              ))}
            </Radio.Group>
          )}
        />
      </SectionContainer>
      <SectionContainer margin={getValues(`object.${cancelFormSection.reason.id}`) === '3' ? '10' : '0'}>
        <Controller
          name={`object.${cancelFormSection.reason.id}`}
          control={control}
          render={() => (
            <Radio.Group
              uiStyle={Radio.UIStyle.Checked}
              value={getValues(`object.${cancelFormSection.reason.id}`)}
              onChange={(option) => {
                resetForm()
                setValue(
                  'object',
                  {
                    [cancelFormSection.reason.id]: option.value,
                    [cancelFormSection.reason.ecbrNo.radio[0].textInputs.id]: '',
                    [cancelFormSection.reason.ecbrNo.radio[1].textInputs.id]: '',
                    [cancelFormSection.reason.other.radio[0].textInputs.id]: '',
                  },
                  { shouldDirty: true, shouldValidate: true }
                )
              }}
            ></Radio.Group>
          )}
        />
      </SectionContainer>
      <SectionContainer margin={getValues(`object.${cancelFormSection.reason.id}`) === 'other' ? '10' : '0'}>
        <div>
          <SectionContainer>
            <Controller
              name={`object.${cancelFormSection.reason.id}`}
              control={control}
              render={() => (
                <Radio.Group
                  uiStyle={Radio.UIStyle.Checked}
                  value={getValues(`object.${cancelFormSection.reason.id}`)}
                  onChange={(option) => {
                    resetForm()
                    setValue(
                      'object',
                      {
                        [cancelFormSection.reason.id]: option.value,
                        [cancelFormSection.reason.ecbrNo.radio[0].textInputs.id]: '',
                        [cancelFormSection.reason.ecbrNo.radio[1].textInputs.id]: '',
                      },
                      { shouldDirty: true, shouldValidate: true }
                    )
                  }}
                >
                  {cancelFormSection.reason.other.radio[0].groups.map((group, index) => (
                    <Radio key={index} value={group.value} label={group.text} />
                  ))}
                </Radio.Group>
              )}
            />
            {getValues(`object.${cancelFormSection.reason.id}`) === 'other' ? (
              <Controller
                name={`object.${cancelFormSection.reason.other.radio[0].textInputs.id}`}
                control={control}
                render={({ field }) => (
                  <RSInput
                    {...field}
                    maxLength={cancelFormSection.reason.other.radio[0].textInputs.maxLength}
                    id={`object.${cancelFormSection.reason.other.radio[0].textInputs.id}`}
                    onChange={(v) => {
                      v.preventDefault()
                      let value = v.target.value
                      if (cancelFormSection.reason.other.radio[0].textInputs.condition === 'textAndNumberOnly')
                        value = value.replace(textAndNumberOnlyRegExp, '')
                      return field.onChange(value)
                    }}
                  />
                )}
              />
            ) : null}
            {renderErrorMessageBlock(errors?.object?.[cancelFormSection.reason.other.radio[0].textInputs.id])}
          </SectionContainer>
        </div>
        {renderErrorMessageBlock(errors?.object?.[cancelFormSection.reason.id])}
      </SectionContainer>
    </>
  )
}
export default CancelReasonSection
