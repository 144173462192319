import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContentContainer, SectionContainer } from 'components/layouts'
import staticContentPage from '../../static-content-page'
import { useRouterForHandleSummit } from '../../hooks/useRouter'
import { schemaCancelFormUploadSection } from '../../hooks/useForm'
import SubmitSection from '../submit'
import { Button } from 'ktaxa-ui-components/index'
import { IconButton } from 'components/buttons'
import CancelReasonSection from '../cancel-reason'
import { usePrevious } from '../../../../hooks/useServices/utils'
import { cancelState, handleInitAttach } from '../../hooks/service'
import { renderErrorMessageBlock } from 'components/message-blocks'
import { IconSuccess } from 'components/icons'
import { getToggles } from 'core/config'
import newStaticContentPage from '../../new-static-content-page'
import { Input as RSInput } from 'reactstrap'

type FromUploadProps = {
  currentSection: string
  setCurrentSection: Function
  state: cancelState
  setState: Function
}
const FormUpload = (props: FromUploadProps) => {
  const { currentSection, setCurrentSection, state, setState } = props
  const { formState, control, getValues, setValue, handleSubmit, reset, trigger } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaCancelFormUploadSection),
  })
  const { errors } = formState

  const isUploaded = state.attachment.find((att) => att.filename)
  setValue('object.isUploaded', isUploaded ? true : false)

  const { cancelationSequence, cancelFormUploadSection, cancelFormSection } = getToggles().ENABLE_ECBRSYS_ECBR
    ? newStaticContentPage
    : staticContentPage
  const isActive = currentSection === cancelationSequence[3]

  const onClick = () => {
    setCurrentSection(cancelationSequence[4])
    reset({ object: { ...getValues('object'), isUploaded: false } })
  }
  const onClickBackButton = () => {
    const initAttachmentList = handleInitAttach({ maxAttachment: 4 })
    setState({ ...state, attachment: initAttachmentList })
    setCurrentSection(cancelationSequence[1])
  }

  const newHandleSubmit = async () => {
    const isValid = await trigger()
    const data = getValues()
    return { isValid, data }
  }

  useRouterForHandleSummit(
    currentSection,
    isActive,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <Button onClick={onClickBackButton} type="secondary">
          กลับ
        </Button>
      ),
      extraButton: () => <SubmitSection currentSection={currentSection} handleSubmit={newHandleSubmit} state={state} />,
    }
  )

  const resetObject = {
    [cancelFormSection.reason.id]: getToggles().ENABLE_ECBRSYS_ECBR ? '1' : '0',
    [cancelFormSection.reason.ecbrNo.radio[0].textInputs.id]: '',
    [cancelFormSection.reason.ecbrNo.radio[1].textInputs.id]: '',
    [cancelFormSection.reason.other.radio[0].textInputs.id]: '',
    [cancelFormUploadSection.validate.id]: false,
    managerCode: state?.infoIssue?.managerAgentFullCode,
    isUploaded: false,
  }
  const resetForm = () => {
    reset({
      object: resetObject,
    })
  }

  const conditionInitForm = currentSection !== cancelationSequence[3] && currentSection !== cancelationSequence[4]
  useEffect(() => {
    resetForm()
  }, [conditionInitForm, reset])

  const prevProps = usePrevious(props)
  useEffect(() => {
    if (currentSection === cancelationSequence[4]) {
      const isUploaded = state.attachment.find((att) => att.filename)

      if (isUploaded) {
        setValue('object', { [cancelFormUploadSection.validate.id]: !!isUploaded })
        setValue('object', { ecbrNo: state?.infoIssue?.cbrNumber })
      } else {
        setValue('object', { [cancelFormUploadSection.validate.id]: false })
      }
    }
  }, [currentSection, state])

  if (!isActive) return null
  return (
    <ContentContainer>
      <h1>{cancelFormUploadSection.mainTitle}</h1>
      <SectionContainer>
        <Controller
          name={`object.${cancelFormSection.reason.id}`}
          control={control}
          render={() => (
            <>
              <CancelReasonSection
                state={state}
                errors={errors}
                control={control}
                getValues={getValues}
                setValue={setValue}
                reset={reset}
                prevProps={prevProps}
                resetObject={resetObject}
                resetForm={resetForm}
              />
              {getToggles().ENABLE_ECBRSYS_ECBR && (
                <div key={'managerCode'} className="name-input" style={{ paddingBottom: 20 }}>
                  <label style={{ minWidth: 250 }}>{'รหัสผู้จัดการ'}</label>
                  <Controller
                    name={`object.managerCode`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <RSInput
                          {...field}
                          type="text"
                          id={`object.managerCode`}
                          onChange={(v) => {
                            v.preventDefault()
                            let value = v.target.value
                            value = value.replace(/[^\d]/gm, '')
                            if (value.length < 15) return field.onChange(value)
                          }}
                        />
                      )
                    }}
                  />
                  {renderErrorMessageBlock(errors?.object?.['managerCode'])}
                </div>
              )}
              <IconButton
                OverideIcon={
                  state.attachment.find((att) => att.filename)
                    ? {
                        Icon: IconSuccess,
                        side: {
                          border: '1px solid #2425aa',
                          background: '#ffffff',
                        },
                      }
                    : null
                }
                icon="ECBR_ATTACHMENT"
                text="แนบแบบฟอร์มขอยกเลิก"
                onClick={onClick}
              />
            </>
          )}
        />
        {renderErrorMessageBlock(errors?.object?.[cancelFormUploadSection.validate.id])}
      </SectionContainer>
    </ContentContainer>
  )
}

export default FormUpload
