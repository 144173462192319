import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'

import { Button } from 'ktaxa-ui-components/index'
import CanceledDialog from '../../components/cancelation-dialog'
import { ErrorDialog } from 'components/index'
import staticContentPage from '../../static-content-page'
import { useSubmit } from './hook'

const SubmitSection = (props: any) => {
  const { history, handleSubmit, currentSection } = props
  const { handleSubmitData, handleNextSection, loading, data, closeModal, modal } = useSubmit(props)
  const [show, setShow] = useState(false)
  const submit = async () => {
    try {
      const { data, isValid } = await handleSubmit()
      if (
        currentSection === staticContentPage.cancelationSequence[2] ||
        currentSection === staticContentPage.cancelationSequence[3]
      ) {
        if (isValid) {
          await handleSubmitData(data)
        }
      } else {
        handleNextSection()
      }
    } catch (error) {
      setShow(true)
    }
  }
  return (
    <>
      <Button disabled={loading} type="primary" onClick={submit}>
        {loading ? 'กำลังบันทึกข้อมูล' : 'ยืนยัน'}
      </Button>
      <ErrorDialog show={show} onToggle={() => history.go('/')} actionLabel={'กลับสู่หน้าหลัก'} />
      {data && !data?.error ? (
        <CanceledDialog ecbrData={data} show={modal} onToggle={closeModal} />
      ) : (
        <ErrorDialog show={modal} onToggle={() => history.go('/')} actionLabel={'กลับสู่หน้าหลัก'} />
      )}
    </>
  )
}

export default withRouter(SubmitSection)
