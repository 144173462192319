import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import { Button } from 'ktaxa-ui-components/index'
import LoadingModal from '../components/load-data-dialog'
import {DA_STATUS,DA_TYPE} from '../static-content'
import { updateRecurringData} from '../hooks/useService'

const submitCheckStatus = (props) => {
  const { state, onDAFormSuccess } = props
  const [loading, setLoading] = useState(false)

    const onSubmitReview = async () => {
      try {
        await setLoading(true)
        let recurringData = _get(state, 'verify.searchDAInfo', null)
        let payload = {
          _id: recurringData._id,
          daStatus: DA_STATUS.SUCCESS,
          edaResponseDateTime: recurringData.edaResponseDateTime,
        }
        await updateRecurringData({
          updateType: DA_STATUS.SUCCESS,
          data: payload,
        })
        await setLoading(false)
        await onDAFormSuccess()
      } catch (error) {
        console.error('error onSubmitReview', error.message)
        await setLoading(false)
      }
    }


  return (
    <>
      <Button onClick={onSubmitReview} type="primary" >
        {'ตกลง'}
      </Button>
      <LoadingModal show={loading} />

    </>
  )
}

export default withRouter(submitCheckStatus)
