import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _isUndefined from 'lodash/isUndefined'
import dayjs from 'dayjs'

import { InputCustomFormat } from 'components/input'
import { SectionContainer } from 'components/layouts'

import { renderErrorMessageBlock } from 'components/message-blocks'
import LoadPolicyDialog from '../components/load-policy-dialog'

import { useLogin } from '../../../hooks/useServices/login'
import { handleResetDataField } from '../hooks/useForm'

import staticContentPage from '../static-content'

import { policyService } from 'services/ecbr-middleware'
import { getToggles } from 'core/config'
import EcbrEntity from 'domain/entities/ecbr'

type DialogContent = {
  isLoaded: Boolean
  isError: Boolean
  isOwner: Boolean
}

const INIT_DIALOG_PROPS = {
  isLoaded: false,
  isError: false,
  isOwner: false,
}

const PolicySection = (props) => {
  const {
    control,
    errors,
    getValues,
    reset,
    setCheckFetchPolicy,
    checkFetchPolicy,
    setCheckFetchPolicyWithId,
    setPolicyDetail,
    policyDetail,
    checkFillInValue,
    setCheckFillInValue,
    enableBySearchPolicyId,
    setEnableBySearchPolicyId,
    setAgentPolicyOwner,
    textSearchPolicy,
    setFieldDisabledSeachPolicy,
    agentPolicyListFromPolicyNo,
    setAgentPolicyListFromPolicyNo,
    searchInputDisabled,
    setSearchInputDisabled,
    showDialog,
    setShowDialog,
  } = props

  const { informationSection: formInfo } = staticContentPage
  const { userInfo } = useLogin({})

  const [dialogContent, setDialogContent] = useState<DialogContent>({ ...INIT_DIALOG_PROPS } as DialogContent)

  const doSetFormValues = () => {
    setShowDialog(false)
    handleResetDataField(reset, {
      policyNo: getValues('policyNo'),
      policyEffectiveDate: getValues('policyEffectiveDate') || '',
      insuredName: agentPolicyListFromPolicyNo?.firstName || '',
      personalId: agentPolicyListFromPolicyNo?.nationalId || getValues('personalId'),
      personalIdType: getValues('personalIdType') || 'citizenId',
      insureMobile: agentPolicyListFromPolicyNo?.mobileNumber || '',
      insuredSurName: agentPolicyListFromPolicyNo?.lastName || '',
      insureEmail: agentPolicyListFromPolicyNo?.email || '',
      paymentAmount: agentPolicyListFromPolicyNo?.paymentAmount || '',
      productCode: agentPolicyListFromPolicyNo?.productCode || '',
      paymentMode: agentPolicyListFromPolicyNo?.paymentMode || '',
      paymentType: '02',
      paymentMethod: '',
      premiumDueDate: agentPolicyListFromPolicyNo?.premiumDueDate || '',
      policyStatusCode: agentPolicyListFromPolicyNo?.policyStatusCode || '',
      sumInsuredAmount: agentPolicyListFromPolicyNo?.sumInsuredAmount || '',
      ownerFirstName: agentPolicyListFromPolicyNo?.ownerFirstName || '',
      ownerLastName: agentPolicyListFromPolicyNo?.ownerLastName || '',
      ownerPersonalId: agentPolicyListFromPolicyNo?.ownerPersonalId || '',
    })

    const insuredName = agentPolicyListFromPolicyNo?.firstName ? 'insuredName' : null
    const insuredSurName = agentPolicyListFromPolicyNo?.lastName ? 'insuredSurName' : null
    const insureMobile = agentPolicyListFromPolicyNo?.mobileNumber ? 'insureMobile' : null
    const insureEmail = agentPolicyListFromPolicyNo?.email ? 'insureEmail' : null
    const paymentAmount = null

    setFieldDisabledSeachPolicy([insuredName, insuredSurName, insureMobile, insureEmail, paymentAmount])
    setEnableBySearchPolicyId(false)

    if (agentPolicyListFromPolicyNo?.isOwner) {
      if (!getToggles().ENABLE_ENQUIRY_POLICYID) {
        setCheckFetchPolicy(true)
        setPolicyDetail({ ...policyDetail, isOwner: true })
      } else {
        setAgentPolicyOwner(true)
        setSearchInputDisabled(['policyEffectiveDate', 'policyNo', 'personalId', 'productCode'])
      }
    } else if (getToggles().ENABLE_ENQUIRY_POLICYID && _isEmpty(agentPolicyListFromPolicyNo)) {
      // enable all field when not found data
      setSearchInputDisabled([])
      // when user want to fill data manual set 'CheckFillInValue' to be true
      setCheckFillInValue(true)
    } else {
      if (getToggles().ENABLE_ENQUIRY_POLICYID) {
        const disabledField = ['policyEffectiveDate', 'policyNo']
        if (!_isEmpty(agentPolicyListFromPolicyNo)) {
          disabledField.push('personalId')
        }
        setSearchInputDisabled(disabledField)
      }
    }
  }

  useEffect(() => {
    let condition = false
    if (!getToggles().ENABLE_ENQUIRY_POLICYID) {
      condition =
        _isNil(errors[formInfo.identity.id]) &&
        getValues(formInfo.identity.id) &&
        _isNil(errors[formInfo.policyNo.id]) &&
        getValues('paymentType') === '02' &&
        !checkFillInValue
    } else {
      condition =
        _isNil(errors[formInfo.policyEffectiveDate.id]) &&
        _isNil(errors[formInfo.policyNo.id]) &&
        _isNil(errors[formInfo.identity.id]) &&
        getValues('paymentType') === '02' &&
        getValues(formInfo.policyEffectiveDate.id) &&
        getValues(formInfo.policyNo.id) &&
        getToggles().ENABLE_ENQUIRY_POLICYID &&
        enableBySearchPolicyId &&
        textSearchPolicy &&
        !checkFillInValue
    }

    async function fetchEcbr() {
      setDialogContent({
        isLoaded: false,
        isError: false,
        isOwner: false,
      })

      const res = await policyService.GetPolicyByPolicyNo(
        getValues(formInfo.policyNo.id),
        !getToggles().ENABLE_ENQUIRY_POLICYID
          ? policyDetail.policyEffectiveDate
          : getValues(formInfo.policyEffectiveDate.id),
        userInfo?.agent_code
      )

      setAgentPolicyListFromPolicyNo(res)
      if (_isEmpty(res)) {
        setShowDialog(true)
        setDialogContent({
          isLoaded: true,
          isError: true,
          isOwner: false,
        })
        setFieldDisabledSeachPolicy({})
        setEnableBySearchPolicyId(false)
        setSearchInputDisabled(['policyEffectiveDate', 'policyNo'])
      } else {
        setShowDialog(true)
        setSearchInputDisabled([])
        if (getToggles().ENABLE_ENQUIRY_POLICYID && !res.isOwner) {
          setFieldDisabledSeachPolicy({})
          setEnableBySearchPolicyId(false)
        }
        setAgentPolicyListFromPolicyNo(res)
        setDialogContent({
          isLoaded: true,
          isError: false,
          isOwner: res.isOwner,
        })
      }
    }

    if (condition) {
      fetchEcbr()
    }
  }, [getValues(formInfo.policyNo.id), getValues(formInfo.policyEffectiveDate.id)])

  const resetFieldWithSearchPolicyId = () => {
    setSearchInputDisabled([])
    setFieldDisabledSeachPolicy({})
    setEnableBySearchPolicyId(false)
    reset({
      policyNo: '',
      insuredName: '',
      personalId: '',
      personalIdType: 'citizenId',
      insureMobile: '',
      insuredSurName: '',
      insureEmail: '',
      paymentAmount: '',
      productCode: '',
      paymentType: '02',
      paymentMethod: '',
      paymentMode: '',
      policyEffectiveDate: '',
      sumInsuredAmount: '',
      ownerFirstName: '',
      ownerLastName: '',
      ownerPersonalId: '',
      chequeDate: dayjs().toISOString(),
    })
  }

  const checkDisableField = (fieldName) => {
    const isEmptySearchInputDisabled = _isEmpty(searchInputDisabled)

    if (!isEmptySearchInputDisabled) {
      const isDisabledField = searchInputDisabled.includes(fieldName)

      return isDisabledField
    }

    return false
  }

  return (
    <SectionContainer margin="20">
      {(getValues('personalIdType') === 'policyId' ||
        (getValues('personalIdType') !== 'policyId' && getValues(formInfo.policyNo.id))) && (
        <LoadPolicyDialog
          show={showDialog}
          onToggle={() => {
            setDialogContent({ ...INIT_DIALOG_PROPS })
            setShowDialog(!showDialog)
          }}
          onClick={() => {
            handleResetDataField(reset, resetFieldWithSearchPolicyId)
            setAgentPolicyListFromPolicyNo({} as EcbrEntity)
            setDialogContent({ ...INIT_DIALOG_PROPS })
            setShowDialog(!showDialog)
            if (getToggles().ENABLE_ENQUIRY_POLICYID) {
              setFieldDisabledSeachPolicy([
                'insuredName',
                'insuredSurName',
                'insureMobile',
                'insureEmail',
                'paymentAmount',
              ])
              setSearchInputDisabled(['policyEffectiveDate', 'policyNo', 'productCode'])
            }
            setCheckFetchPolicyWithId(false)
            setCheckFetchPolicy(false)
          }}
          onLoadData={() => doSetFormValues()}
          content={dialogContent}
        />
      )}
      <div className="name-input">
        <label>{formInfo.policyNo.label}</label>
        <Controller
          name={formInfo.policyNo.id}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputCustomFormat
              {...field}
              disabled={checkFetchPolicy || checkDisableField(formInfo.policyNo.id)}
              style={{ textAlign: 'center' }}
              mask={formInfo.policyNo.mask}
              format={formInfo.policyNo.format}
              required={true}
            />
          )}
        />
        {renderErrorMessageBlock(errors[formInfo.policyNo.id])}
      </div>
      {getToggles().ENABLE_ENQUIRY_POLICYID && getValues('paymentType') === '02' && (
        <div className="name-input" style={{ paddingTop: 20 }}>
          <label>{formInfo.policyEffectiveDate.label}</label>
          <Controller
            name={formInfo.policyEffectiveDate.id}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputCustomFormat
                {...field}
                disabled={checkFetchPolicy || checkDisableField(formInfo.policyEffectiveDate.id)}
                style={{ textAlign: 'center' }}
                mask={formInfo.policyEffectiveDate.mask}
                format={formInfo.policyEffectiveDate.format}
              />
            )}
          />
          {renderErrorMessageBlock(errors[formInfo.policyEffectiveDate.id])}
        </div>
      )}
    </SectionContainer>
  )
}

export default PolicySection
