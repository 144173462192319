import { useEffect } from 'react'
import _isEmpty from 'lodash/isEmpty'

import { IEcbrUseCase } from 'domain/useCases/ecbr'
import { ecbrService } from 'services/ecbr-middleware'
import staticContentPage from '../static-content'

export type RespType = {
  data?: any
  error?: any
}

export const useEcbrByCbrNumber = (props) => {
  // open genlink page from history list
  const cbrNumber = props?.match?.params?.cbrNumber

  const ecbrServiceApi: IEcbrUseCase = props.ecbrServiceApi || ecbrService
  const { setState, setTabState, state } = props

  useEffect(() => {
    async function fetchEcbr() {
      const res = await ecbrServiceApi.getEcbrByCbrNumber(cbrNumber)

      const { data, error } = res
      if (_isEmpty(error)) {
        if (data?.cbrStatus === 'PENDING' || data?.cbrStatus === 'RESERVED') {
          const isQuickPay = data?.paymentMethod === 'quickpay'
          const isChangePaymentMethod = data?.paymentMethod === ''

          if (isQuickPay) {
            const isSendLink = data?.paymentLink?.isSendLink || false

            const payloadData = {
              insureEmail: data?.paymentLink?.email || '',
              insureMobile: data?.paymentLink?.sms || '',
              insuredName: data?.insuredName || '',
              insuredSurName: data?.insuredSurName || '',
              paymentAmount: data?.paymentAmount || 0,
              paymentMode: data?.paymentMode || '',
              paymentType: data?.paymentType || '',
              personalId: data?.personalId || '',
              policyEffectiveDate: data?.policyEffectiveDate || '',
              policyNo: data?.policyNumber || '',
              // policyStatusCode: '',
              premiumDueDate: data?.premiumDueDate || '',
              productCode: data?.productCode || '',
            }

            setState({
              ...state,
              pendingList: data,
              isCheckPaymentStatus: isSendLink,
              info: {
                payload: {
                  ...payloadData,
                  paymentMethod: 'quickpay',
                },
              },
              createPaymentLinkData: {
                expiredDate: data?.paymentLink?.expiredDatetime || '',
              },
              process: {
                payload: {
                  data: {
                    ...payloadData,
                    policyNumber: data?.policyNumber || '',
                    cbrNumber: data?.cbrNumber || '',
                  },
                },
              },
            })
          } else if (isChangePaymentMethod) {
            setState({
              ...state,
              isChangePaymentMethod,
              process: {
                payload: {
                  data,
                },
              },
            })
          } else {
            setState({
              ...state,
              pendingList: data,
            })
          }

          setTabState(
            data?.cbrStatus === 'PENDING'
              ? staticContentPage.tabsProgress[1].id
              : isChangePaymentMethod
              ? staticContentPage.tabsProgressPayment[0].id
              : staticContentPage.tabsProgress[4].id
          )
        }
      }
    }
    if (cbrNumber) {
      fetchEcbr()
    }
  }, [])
}

export const createEcbrPaid = async (props): Promise<RespType> => {
  const { pendingList, transferInfo, attachment, summary, info, process } = props
  const ecbrServiceApi: IEcbrUseCase = props.ecbrServiceApi || ecbrService
  const payload = {
    paymentMethod: pendingList?.paymentMethod || info?.payload?.paymentMethod || '',
    payBy: transferInfo?.payload?.payBy || '',
    channelMethod: transferInfo?.payload?.payByService || '',
    policyNumber: pendingList?.policyNumber || info?.payload?.policyNo || '',
    payInDate: attachment?.payload?.payDate || '',
    slipImage: attachment?.payload?.slipImage || '',
    cbrStatus: pendingList?.cbrStatus || process?.payload?.data?.cbrStatus || '',
    cbrNumber: pendingList?.cbrNumber || process?.payload?.data?.cbrNumber || '',
    imgBase64: summary?.payload?.imgBase64 || '',
  }

  return await ecbrServiceApi.CreateEcbrPaid(payload)
}
