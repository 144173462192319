import React from 'react'
import styled from '@emotion/styled'

import { Description } from 'components/list'
import { color } from 'components/theme'
import { formatNumber, formatCitizenId } from 'utils/string-helper'

const { Label: DT, Value: DD } = Description

const Container = styled.div`
  background-color: ${color.common.white};
  box-shadow: 0 0 0 1px ${color.element.border};
`
const Content = styled.div`
  padding: 15px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
`
const ECBRNumber = styled.div`
  font-size: 1.2rem;
  margin-bottom: 20px;

  & div {
    font-weight: 700;
  }
`
const Amount = styled(Content)`
  padding: 8px 15px;
  background-color: ${color.type.primary};
  color: ${color.common.white};
  text-align: center;
`

type AttachmentCardProps = {
  cbrNumber: string
  paymentType: string
  paymentMode: string
  paymentMethod: string
  policyNumber: string
  personalId: string
  name: string
  payBy: string
  payByService: string
  amount: number
}

const AttachmentCard = (props: AttachmentCardProps) => {
  const {
    cbrNumber,
    paymentType,
    policyNumber,
    personalId,
    name,
    payBy,
    payByService,
    amount,
    paymentMethod,
    paymentMode,
  } = props
  return (
    <Container>
      <Content>
        <ECBRNumber>
          <Description>
            <DT>เลขที่ใบรับเงินชั่วคราว</DT>
            <DD>{cbrNumber}</DD>
          </Description>
        </ECBRNumber>
        <Description>
          <DT>ประเภทการชำระเงิน</DT>
          <DD>
            {paymentType}/{paymentMode}/{paymentMethod}
          </DD>
        </Description>
        <Description>
          <DT>เลขที่กรมธรรม์</DT>
          <DD>{policyNumber}</DD>
        </Description>
        <Description>
          <DT>เลขที่บัตรประชาชนผู้เอาประกัน</DT>
          <DD>{formatCitizenId(personalId)}</DD>
        </Description>
        <Description>
          <DT>ชื่อ-สกุลผู้เอาประกัน</DT>
          <DD>{name}</DD>
        </Description>
        <Description>
          <DT>วิธีการชำระเงิน</DT>
          <DD>{payBy}</DD>
        </Description>
        <Description>
          <DT>{payBy === 'เคาเตอร์สำนักงาน' ? 'เคาเตอร์ผู้รับชำระ' : 'ธนาคารผู้รับชำระ'}</DT>
          <DD>{payByService}</DD>
        </Description>
      </Content>
      <Amount>จำนวนเงินที่ต้องชำระ {formatNumber(amount)} บาท</Amount>
    </Container>
  )
}

export default AttachmentCard
