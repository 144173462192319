import { css } from '@emotion/react'

import { color, device } from '../utils/theme'
import { disabled } from '../utils/state'

export default {
  tabs: css`
    background-color: ${color.common.white};
    border-bottom: 1px solid ${color.element.border};
  `,
  tabsList: css`
    font-size: 1rem;
    display: flex;
    margin: 0 auto;
    padding: 0;
    overflow: scroll hidden;
    position: relative;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent; /* Chrome/Safari/Webkit */
    }

    @media (min-width: ${device.screenSize.md}) {
      max-width: 900px;
      margin: 0 auto;
    }
  `,
  tabsItem: css`
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: inline-block;
    text-align: center;
    position: relative;
    color: ${color.type.primary};
    padding: 10px;
    cursor: default;
    flex: 1 0 auto;
    min-width: 120px;

    @media (min-width: ${device.screenSize.md}) {
      flex-basis: 150px;
    }

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 3px;
      background: ${color.type.primary};
      opacity: 0;
      content: '';
    }
  `,
  tabItemState: {
    disabled: css`
      ${disabled}
    `,
    active: css`
      &::before {
        opacity: 1;
      }
    `,
  },
}
