import React, { useState } from 'react'
import styled from '@emotion/styled'

import { ContentContainer, SectionContainer } from 'components/layouts'
import { BlankCard, ECBRCard } from 'components/cards'

import { useECBRPending } from '../../../../hooks/useServices/ecbr'
import { useRouterForHandleSummit } from '../../hooks/useRouter'
import { useMappingCase } from '../../hooks/service'
import staticContentPage from '../../static-content-page'
import { getToggles } from 'core/config'
import DateRangePickerComponent from 'components/input/date-range-picker'
import dayjs from 'dayjs'
import { useFeatureToggleEcbrSys } from './hook'
import ApiDownDialog from 'components/api-down-dialog'
import _ from 'lodash'
import ResentEcbrModal from '../../../historical/components/resent-ecbr-dialog'

const Message = styled.div`
  color: #333;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0 0;
`
const DateRangeContainer = styled.div`
  margin: -15px 0 15px;
  display: flex;
  flex-direction: column;
`

const CancelationEcbrList = (props) => {
  const { state, setState, currentSection, setCurrentSection } = props
  const [isOpenResentEcbrModalAgent, setIsOpenResentEcbrModalAgent] = useState(false)
  const [isOpenResentEcbrModalCustomer, setIsOpenResentEcbrModalCustomer] = useState(false)
  const [phoneAgent, setPhoneAgent] = useState('')
  const [phoneCustomer, setPhoneCustomer] = useState('')
  const [emailAgent, setEmailAgent] = useState('')
  const [emailCustomer, setEmailCustomer] = useState('')
  const [selectedEcbrNo, setSelectedEcbrNo] = useState('')
  const [selectedEcbrInfo, setSelectedEcbrInfo] = useState({})
  const [createDateFrom, setcreateDateFrom] = useState(dayjs(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)).format('DD/MM/YYYY'))
  const [createDateTo, setcreateDateTo] = useState(dayjs(new Date()).format('DD/MM/YYYY'))
  const [{ data, isLoaded, isLoading, error }, clearError] = useFeatureToggleEcbrSys({
    query: JSON.stringify({
      createDateFrom,
      createDateTo,
      ecbrStatus: ["CREATED","USED"]
    }),
    tab: '',
  })

  let newData
  let newLoading

  if (getToggles().ENABLE_ECBRSYS_ECBR){
    newData = data
    newLoading = isLoading
  } else {
    const [pending] = useECBRPending({ query: 'status=PAID' })
    const { data, isLoaded } = useMappingCase(pending)
    console.log('isLoaded', isLoaded)
    newData = data
    newLoading = !isLoaded
  }

  const onSubmit = (item) => {
    setState({
      ...state,
      infoIssue: item,
    })
    setCurrentSection(staticContentPage.cancelationSequence[1])
  }

  const handleDateChange = (value: [Date, Date]) => {
    setcreateDateFrom(dayjs(value[0]).format('DD/MM/YYYY'))
    setcreateDateTo(dayjs(value[1]).format('DD/MM/YYYY'))
  }

  const handleIsOpenResentEcbrModalAgent = ({ value, ecbrNo })=> {
    setPhoneAgent('')
    setEmailAgent('')
    setPhoneAgent(String(_.get(newData, 'agentMobilephone')))
    setEmailAgent(String(_.get(newData, 'agentEmail')))
    setSelectedEcbrNo(ecbrNo)
    setIsOpenResentEcbrModalAgent(value)
  }

  const handleIsOpenResentEcbrModalCustomer = ({ value, insuredEmail, insuredMobilephone, ecbrInfo })=> {
    setPhoneCustomer('')
    setEmailCustomer('')
    setPhoneCustomer(insuredMobilephone)
    setEmailCustomer(insuredEmail)
    setSelectedEcbrNo(ecbrInfo.ecbrNo)
    setSelectedEcbrInfo(ecbrInfo)
    setIsOpenResentEcbrModalCustomer(value)
  }

  const isActive = currentSection === staticContentPage.cancelationSequence[0]
  useRouterForHandleSummit(
    currentSection,
    isActive,
    { state },
    {
      showFooter: true,
      backButton: { toHome: true },
    }
  )

  if (!isActive) return null
  return (
    <ContentContainer>
      <HeaderContainer><div><h1>{staticContentPage.cancelListSection.mainTitle}</h1></div>{getToggles().ENABLE_ECBRSYS_ECBR && (<DateRangeContainer><p style={{color: '#2425aa', fontSize: '20px', marginBottom: '0rem'}}>วันที่ออกใบ</p><DateRangePickerComponent handleDateChange={handleDateChange} createDateFrom={createDateFrom} createDateTo={createDateTo}/></DateRangeContainer>)}</HeaderContainer>
      <SectionContainer>
        {newLoading ? (
          <Message>กำลังค้นหา...</Message>
        ) : newData.length ? (
          newData.map((ecbrItem) => {
            const {
              insuredName,
              insuredSurName,
              policyNumber,
              paymentAmount,
              cbrStatus,
              createdAt,
              daySinceCreated,
              cbrNumber,
              caseNumber,
              iProStatus,
              insuredEmail,
              insuredMobilephone,
            } = ecbrItem
            return (
            <ECBRCard
              onClick={() => (caseNumber ? null : onSubmit(ecbrItem))}
              key={cbrNumber}
              name={`${insuredName} ${insuredSurName}`}
              amount={paymentAmount}
              status={cbrStatus}
              createdAt={createdAt.toString()}
              daySinceCreated={daySinceCreated}
              paymentNo={cbrNumber}
              policyNumber={policyNumber}
              insuredEmail={insuredEmail}
              insuredMobilephone={insuredMobilephone}
              handleIsOpenResentEcbrModalAgent={handleIsOpenResentEcbrModalAgent}
              handleIsOpenResentEcbrModalCustomer={handleIsOpenResentEcbrModalCustomer}
              caseNumber={caseNumber}
              iProStatus={iProStatus}
            />
            )
          })
        ) : (
          getToggles().ENABLE_ECBRSYS_ECBR? <BlankCard/>: <Message>{staticContentPage.cancelListSection.blankState}</Message>
        )}
      </SectionContainer>
      <ApiDownDialog show={error != null} onToggle={() => clearError()} />
      <ResentEcbrModal
        isOpen={isOpenResentEcbrModalCustomer}
        forAgent={false}
        onClose={()=>setIsOpenResentEcbrModalCustomer(false)}
        phone={phoneCustomer}
        setPhone={setPhoneCustomer}
        email={emailCustomer}
        setEmail={setEmailCustomer}
        ecbrNo={selectedEcbrNo}
        ecbrInfo={selectedEcbrInfo}
      />
      <ResentEcbrModal
        isOpen={isOpenResentEcbrModalAgent}
        forAgent={true}
        onClose={()=>setIsOpenResentEcbrModalAgent(false)}
        phone={phoneAgent}
        setPhone={setPhoneAgent}
        email={emailAgent}
        setEmail={setEmailAgent}
        ecbrNo={selectedEcbrNo}
        ecbrInfo={selectedEcbrInfo}
      />
    </ContentContainer>
  )
}
export default CancelationEcbrList
