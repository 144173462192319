import React, { useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react'
import { useLogin } from '../.././hooks/useServices/login'
import { getPlanCode } from '../.././pages/new-ecbr/hooks/useService'
import { PlanCode, RegistrationEDA } from '../../types/plan-code'

type AppContextPropsType = {
  planCodeOptions: PlanCode[]
  setPlanCodeOptions: Dispatch<SetStateAction<PlanCode[]>>
  isVerifyFormValidateError: boolean
  setIsVerifyFormValidateError: Dispatch<SetStateAction<boolean>>
  ccpaPlanCodeOptions: PlanCode[]
  setCcpaPlanCodeOptions: Dispatch<SetStateAction<PlanCode[]>>
  ccpaInfo: Record<string, any>
  setCcpaInfo: Dispatch<SetStateAction<any>>
  isDisabledBackButton: boolean
  setIsDisabledBackButton: Dispatch<SetStateAction<boolean>>
  isDisabledConfirmButton: boolean
  setIsDisabledConfirmButton: Dispatch<SetStateAction<boolean>>
  isBankAccountVerified: boolean
  setIsBankAccountVerified: Dispatch<SetStateAction<boolean>>
  isShowReviewForm: boolean
  setIsShowReviewForm: Dispatch<SetStateAction<boolean>>
  registrationEDA: any
  setRegistrationEDA: Dispatch<SetStateAction<any>>
  tabDAState: any
  setTabDaState: Dispatch<SetStateAction<any>>
  dataCheckAccount: any
  setDataCheckAccount: Dispatch<SetStateAction<any>>
}

const AppContext = React.createContext<AppContextPropsType>({} as AppContextPropsType)

const AppProvider = (props) => {
  const { children } = props
  const { token } = useLogin({})
  const [planCodeOptions, setPlanCodeOptions] = useState<PlanCode[]>([])
  const [ccpaPlanCodeOptions, setCcpaPlanCodeOptions] = useState<PlanCode[]>([])
  const [isVerifyFormValidateError, setIsVerifyFormValidateError] = useState(false)
  const [ccpaInfo, setCcpaInfo] = useState<Record<string, any>>({})
  const [isDisabledBackButton, setIsDisabledBackButton] = useState(false)
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(true)
  const [isBankAccountVerified, setIsBankAccountVerified] = useState(false)
  const [isShowReviewForm, setIsShowReviewForm] = useState(false)
  const [registrationEDA, setRegistrationEDA] = useState()
  const [tabDAState, setTabDaState] = useState('verify')
  const [dataCheckAccount, setDataCheckAccount] = useState({})

  useEffect(() => {
    async function fetchPlanCode() {
      const res = await getPlanCode(token)
      setPlanCodeOptions(res.data)
    }
    fetchPlanCode()
  }, [])

  return (
    <AppContext.Provider
      value={{
        planCodeOptions,
        setPlanCodeOptions,
        ccpaPlanCodeOptions,
        setCcpaPlanCodeOptions,
        isVerifyFormValidateError,
        setIsVerifyFormValidateError,
        ccpaInfo,
        setCcpaInfo,
        isDisabledBackButton,
        setIsDisabledBackButton,
        isDisabledConfirmButton,
        setIsDisabledConfirmButton,
        isBankAccountVerified,
        setIsBankAccountVerified,
        isShowReviewForm,
        setIsShowReviewForm,
        registrationEDA,
        setRegistrationEDA,
        tabDAState,
        setTabDaState,
        dataCheckAccount,
        setDataCheckAccount
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContext }
export default AppProvider
