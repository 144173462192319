/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'

import MainHeader from './main-header'
import MainFooter from './main-footer'

import { color } from '../../theme'

const StyledMain = styled.div`
  background: ${color.common.wildSand};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: env(safe-area-inset-top);
`

const MainLayout = (props) => {
  const { children } = props
  return (
    <StyledMain>
      <MainHeader />
      <div
        id="main"
        css={css`
          display: flex;
          height: 100%;
          flex: 1;
          flex-direction: column;
          margin-top: 0;
        `}
      >
        {children}
      </div>
      <MainFooter backButton={{ toHome: true }} />
    </StyledMain>
  )
}

export default MainLayout
