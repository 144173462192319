import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button, Checkbox, Dialog } from 'ktaxa-ui-components/index'
import { renderErrorMessageBlock, renderInfoMessageBlock } from 'components/message-blocks'
import { Input, MaskedInput } from 'rsuite'
import CloseIcon from '@rsuite/icons/Close'
import _, { isEmpty } from 'lodash'
import { SendEmailECBRSys, SendSmsECBR } from '../../../hooks/useServices/ecbr'

const Title = styled.p`
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const ConfirmButton = styled(Button)`
  margin: 20px;
  align-self: center;
`

const CustomCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`

const CustomInputContainer = styled.div`
  margin-bottom: 10px;
`

const DialogContainer = styled.div`
  text-align: center;
`
const ResultDialogContent = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Message = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 1rem;
  color: #333333;
`

type ErrorContent = {
  title?: string
  message?: string
}

type ResultDialogProps = {
  show: boolean
  onToggle?: () => void
  message?: string
}

const ResultDialog = (props: ResultDialogProps) => {
  const { show, onToggle, message } = props
  return (
    <DialogContainer>
      <Dialog centered modal show={show} onClose={onToggle}>
        <ResultDialogContent>
          <Message>{message}</Message>
        </ResultDialogContent>
        <Footer>
          <Button type={Button.Type.Primary} onClick={onToggle}>
            ยืนยัน
          </Button>
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

const ResentEcbrModal = (props: any) => {
  const { isOpen, onClose, forAgent, phone, setPhone, email, setEmail, ecbrNo, ecbrInfo } = props

  const [isCheckPhone, setIsCheckPhone] = useState(false)
  const [isCheckEmail, setIsCheckEmail] = useState(false)
  const [openResultDialog, setOpenResultDialog] = useState(false)
  const [resultDialogMessage, setResultDialogMessage] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [validPhone, setValidPhone] = useState(true)
  const [emailErrorMessage, setEmailErrorMessage] = useState('โปรดระบุอีเมล')
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('โปรดระบุเบอร์โทรศัพท์')

  const handleIsCheckEmail = () => {
    setIsCheckEmail(!isCheckEmail)
    setValidEmail(true)
    if (!isCheckEmail && !isEmpty(email)) {
      handelSetEmail()
    }
  }
  const handleIsCheckPhone = () => {
    setIsCheckPhone(!isCheckPhone)
    setValidPhone(true)
    if (!isCheckPhone && !isEmpty(phone)) {
      handelSetPhone()
    }
  }

  const handleOnClose = () => {
    setIsCheckPhone(false)
    setIsCheckEmail(false)
    setPhone('')
    setEmail('')
    onClose()
  }

  const handleConfirm = async () => {
    if (isCheckEmail) {
      let payload
      if (forAgent) {
        payload = {
          toAgentEmail: email,
          ecbrNo,
        }
      } else {
        payload = {
          toCustomerEmail: email,
          ecbrNo,
        }
      }
      const resp = await SendEmailECBRSys(JSON.stringify(payload))
      if (!isCheckPhone) {
        if (_.get(resp, 'sendEmailStatus') === 'SUCCESS') {
          setResultDialogMessage('ส่งใบรับเงินชั่วคราวสำเร็จ')
        } else {
          setResultDialogMessage('ส่งใบรับเงินชั่วคราวไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
        }
      }
      setOpenResultDialog(true)
    }
    if (isCheckPhone) {
      const payload = {
        mobileNo: phone,
        template: 'create-ecbr',
        info: {
          policyNumber: ecbrInfo.policyNumber,
          cbrIssueDate: ecbrInfo.cbrIssueDate,
          paymentAmount: ecbrInfo.paymentAmount,
          cbrNumber: ecbrInfo.ecbrNo,
        },
      }
      const resp = await SendSmsECBR(JSON.stringify(payload))
      if (_.get(resp, 'sendSmsStatus') === 'SUCCESS') {
        setResultDialogMessage('ส่งใบรับเงินชั่วคราวสำเร็จ')
      } else {
        setResultDialogMessage('ส่งใบรับเงินชั่วคราวไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
      }
      setOpenResultDialog(true)
    }
    handleOnClose()
  }

  const handleResultDialogToggle = () => {
    setOpenResultDialog(false)
  }

  const handelSetEmail = (value?, isOnChange?) => {
    const thisEmail = isOnChange ? value : email
    if (isOnChange) {
      setEmail(value)
    }
    setValidEmail(true)
    const valid = String(thisEmail)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    if (!valid) {
      setEmailErrorMessage('โปรดตรวจสอบข้อมูล')
      setValidEmail(false)
    }
    if (isEmpty(thisEmail)) {
      setEmailErrorMessage('โปรดระบุอีเมล')
    }
  }

  const handelSetPhone = (value?, isOnChange?) => {
    const thisPhone = isOnChange ? value : phone
    if (isOnChange) {
      setPhone(value)
    }
    setValidPhone(true)
    const valid = String(thisPhone)
      .toLowerCase()
      .match(/\d{10}/gm)
    if (!valid) {
      setPhoneErrorMessage('โปรดตรวจสอบข้อมูล')
      setValidPhone(false)
    }
    if (isEmpty(thisPhone)) {
      setPhoneErrorMessage('โปรดระบุเบอร์โทรศัพท์')
    }
  }

  useEffect(() => {
    if (isEmpty(email)) {
      setIsCheckEmail(false)
      setValidEmail(true)
    } else {
      setIsCheckEmail(true)
    }
    if (isEmpty(phone)) {
      setIsCheckPhone(false)
      setValidPhone(true)
    } else {
      setIsCheckPhone(true)
    }
  }, [email, phone])

  return (
    <Dialog show={isOpen} onClose={handleOnClose}>
      <ResultDialog show={openResultDialog} onToggle={handleResultDialogToggle} message={resultDialogMessage} />
      <Title>
        {forAgent
          ? 'กรุณาเลือกช่องทางที่ต้องการส่งใบรับเงินชั่วคราวให้ตัวแทน'
          : 'กรุณาเลือกช่องทางที่ต้องการส่งใบรับเงินชั่วคราวให้ลูกค้า'}
        <CloseIcon onClick={handleOnClose} />
      </Title>
      <Content>
        <CustomCheckbox uiStyle={Checkbox.UIStyle.Checked} onChange={() => handleIsCheckEmail()} checked={isCheckEmail}>
          อีเมล์
        </CustomCheckbox>
        <CustomInputContainer>
          <Input
            type="text"
            value={email || ''}
            onChange={(e) => handelSetEmail(e, true)}
            disabled={!isCheckEmail}
            onBlur={(e) => handelSetEmail()}
          />
          {!validEmail && renderErrorMessageBlock(emailErrorMessage)}
        </CustomInputContainer>
        {!forAgent && (
          <div>
            <CustomCheckbox
              uiStyle={Checkbox.UIStyle.Checked}
              onChange={() => handleIsCheckPhone()}
              checked={isCheckPhone}
            >
              เบอร์โทรศัพท์
            </CustomCheckbox>
            <CustomInputContainer>
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                type="text"
                value={phone || ''}
                onChange={(e) => handelSetPhone(e, true)}
                disabled={!isCheckPhone}
                onBlur={(e) => handelSetPhone()}
              />
              {!validPhone && renderErrorMessageBlock(phoneErrorMessage)}
            </CustomInputContainer>
          </div>
        )}
        {renderInfoMessageBlock(
          forAgent
            ? 'ท่านสามารถแก้ไขข้อมูลอีเมล์เพื่อนำส่งใบรับเงินชั่วคราวที่ท่านต้องการ'
            : 'ท่านสามารถแก้ไขข้อมูลอีเมล์หรือเบอร์โทรศัพท์เพื่อนำส่งใบรับเงินชั่วคราวที่ท่านต้องการ'
        )}
        <ConfirmButton
          type="primary"
          onClick={handleConfirm}
          disabled={
            !validEmail ||
            !validPhone ||
            (!isCheckEmail && !isCheckPhone) ||
            (isCheckEmail ? isEmpty(email) : false) ||
            (isCheckPhone ? isEmpty(phone) : false)
          }
        >
          ยืนยัน
        </ConfirmButton>
      </Content>
    </Dialog>
  )
}

export default ResentEcbrModal
