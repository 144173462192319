import React from 'react'
import PropTypes, { string } from 'prop-types'
import styled from '@emotion/styled'

import { Icons } from 'ktaxa-ui-components'
import { color } from '../theme'

const Button = styled.div`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  background: ${(props) => (props.disabled ? color.common.silver : color.common.white)};
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  flex: 1;
  align-items: center;
  text-align: initial;
  color: ${(props) => (props.disabled ? 'white' : color.type.primary)};
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'default')};
  box-shadow: 0 0 0 1px ${color.element.border};
`

const Title = styled.div`
  margin-left: 10px;
`

const PortalButton = (props) => {
  const { title, iconId, onClick, disabled, warningText } = props

  return (
    <>
      <Button data-id={iconId} onClick={onClick} disabled={disabled}>
        <Icons type={iconId} />
        <Title>{title}</Title>
      </Button>
      <span>{warningText}</span>
    </>
  )
}

PortalButton.defaultProps = {
  title: '',
  iconId: '',
  disabled: false,
  warningText: '',
  onClick() {},
}

PortalButton.propTypes = {
  title: PropTypes.string,
  iconId: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  warningText: string,
}

export default PortalButton
