import React, { useState } from 'react'
import { CustomTabs } from 'components/tabs'
import VerifyPolicySection from './sections/verify-policy'
import RegisterFormSection from './sections/registerForm'
import CheckPaymentStatusSection from './sections/check-status'

import { useTabItemsProcess } from './hooks/handleTab'
import staticContentPage from './static-content'

const CCPAPage = (props) => {
  const { history } = props
  const [state, setState] = useState({})
  const [tabState, setTabState] = useState(staticContentPage.tabsProgress[0].id)
  const [tabItems] = useTabItemsProcess({
    tabState,
    state,
    setState,
    ...props,
  })

  const [isSuccessCcpaStatus, setIsSuccessCcpaStatus] = useState(false) // basically, there are 2 cases, IN PROGRESS and SUCCESS
  const [isNeedToReload, setIsNeedToReload] = useState(false) // reload page when user success register and come back from tab3

  return (
    <>
      <CustomTabs active={tabState} onChange={(id) => setTabState(id)} items={tabItems} />
      <VerifyPolicySection
        setTabState={setTabState}
        setState={setState}
        state={state}
        tabState={tabState}
        isNeedToReload={isNeedToReload}
      />
      <RegisterFormSection
        setTabState={setTabState}
        setState={setState}
        state={state}
        tabState={tabState}
        isSuccessCcpaStatus={isSuccessCcpaStatus}
        setIsSuccessCcpaStatus={setIsSuccessCcpaStatus}
      />
      <CheckPaymentStatusSection
        setTabState={setTabState}
        setState={setState}
        state={state}
        tabState={tabState}
        isSuccessCcpaStatus={isSuccessCcpaStatus}
        setIsSuccessCcpaStatus={setIsSuccessCcpaStatus}
        setIsNeedToReload={setIsNeedToReload}
      />
    </>
  )
}

export default CCPAPage
