import React, { useState } from 'react'
import styled from '@emotion/styled'
import _isEmpty from 'lodash/isEmpty'
import { color, customIcon, device } from 'components/theme'

import { Radio, Dialog, Button } from 'ktaxa-ui-components/index'

const DialogContainer = styled.div`
  #modal-dialog-content {
    padding-right: 0;
    padding-left: 0;
  }
`

const Content = styled.div`
  padding: 15px;
  @media (min-width: ${device.screenSize.sm}) {
    padding-right: 24px;
    padding-left: 48px;
  }
`

const Subtitle = styled.div`
  padding: 15px 15px 5px;
`

const WarningContent = styled.div`
  padding: 0px 15px;
  color: red;
`

const Title = styled.span`
  text-align: center;
  display: block;
  color: ${color.type.primary};
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Footer = styled.div`
  text-align: center;
  margin: 15px;
`
const Icon = styled.div`
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background-color: ${color.common.lightGray};
  mask-image: url(${customIcon('closeIcon')});
  mask-size: cover;
  mask-repeat: no-repeat;
`

const cssButton = {
  padding: '30px 20px 30px 20px',
}

const PolicyListModal = (props) => {
  const {
    show,
    onToggle,
    agentPolicyList = [],
    onConfirmSelected,
    // onConfirmFillIn,
    onCancelFillIn,
    onSelectPolicyItem,
    selectedAgentIndex,
    searchStatus,
  } = props

  const PolicyList = (data, selectedIndex, onChange) => {
    return (
      <Radio.Group
        value={`${selectedIndex}`}
        onChange={onChange}
        uiStyle={Radio.UIStyle.Checked}
        className={Radio.UIStyle.Grid}
      >
        {data.map(({ policyNumber, productCode }, listIndex) => (
          <Radio key={policyNumber} value={`${listIndex}`} label={`${productCode} ${policyNumber}`} />
        ))}
      </Radio.Group>
    )
  }

  return (
    <DialogContainer>
      <Dialog centered modal show={show} onClose={onToggle}>
        {_isEmpty(agentPolicyList) ? (
          <>
            <Title>
              {_isEmpty(searchStatus) && searchStatus == 457
                ? 'ระบบตรวจสอบไม่พบข้อมูลกรมธรรม์'
                : 'การตรวจสอบข้อมูลกรมธรรม์ขัดข้อง'}
            </Title>
            <Divider />
            <Content style={{ textAlign: 'center' }}>
              {_isEmpty(searchStatus) && searchStatus == 457
                ? 'กรุณาตรวจสอบข้อมูลกรมธรรม์อีกครั้ง'
                : 'กรุณารอตรวจสอบข้อมูลกรมธรรม์ใหม่ภายหลัง'}
            </Content>
          </>
        ) : (
          <>
            <Icon onClick={onToggle} />
            <Title>โปรดเลือกกรมธรรม์ที่ต้องการทำรายการ</Title>
            <Divider />

            <Content>{PolicyList(agentPolicyList, selectedAgentIndex, onSelectPolicyItem)}</Content>
          </>
        )}
        <Footer>
          {_isEmpty(agentPolicyList) ? (
            <>
              <Button
                type={Button.Type.Primary}
                paddingSizeLeft={'40px'}
                paddingSizeRight={'40px'}
                onClick={onCancelFillIn}
              >
                กลับ
              </Button>
            </>
          ) : (
            <Button
              type={Button.Type.Primary}
              paddingSizeLeft={'40px'}
              paddingSizeRight={'40px'}
              onClick={onConfirmSelected}
            >
              ยืนยัน
            </Button>
          )}
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default PolicyListModal
