import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color } from 'components/theme'
import { getBankNameByCode } from '../utils'
import _ from 'lodash'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 0;
    padding-left: 0;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px 0;
`

const ContentBody = styled.div`
  padding: 15px 30px;
  text-align: center;
`

const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Subtitle = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`
const Message = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
  font-weight: bold;
`

const TITLE = {
  // CCPA_EXISTING: 'ระบบตรวจสอบพบข้อมูลหักค่าเบี้ยประกันอัตโนมัติ',
  // DA_EXISTING: 'ระบบตรวจสอบพบข้อมูลหักค่าเบี้ยประกันอัตโนมัติ',
  // DA_PENDING: 'ระบบตรวจสอบพบข้อมูลการสมัครรอทำรายการ',
  // DA_SUCCESS: 'ระบบพบข้อมูลการสมัครของท่าน',
  INVALID: 'กรมธรรม์ไม่ตรงตามเงื่อนไขการสมัคร',
  NOT_FOUND: 'ระบบตรวจสอบไม่พบข้อมูลกรมธรรม์',
  ERROR: 'การตรวจสอบข้อมูลกรมธรรม์ขัดข้อง',
  CCPA_EXISTING: 'ระบบตรวจสอบพบการสมัครหักค่าเบี้ยประกันอัตโนมัติ',
  NOT_CCPA_EXISTING: 'ระบบตรวจสอบพบข้อมูลหักค่าเบี้ยประกันอัตโนมัติ',
  // ระบบตรวจสอบพบข้อมูลหักค่าเบี้ยประกันอัตโนมัติ',
  PENDING: 'ระบบตรวจสอบพบข้อมูลการสมัครรอทำรายการ',
  SERVER_ERROR: 'การตรวจสอบข้อมูลกรมธรรม์ขัดข้อง',
}

const SearchStatusDialog = (props: any) => {
  const {
    show,
    onToggle,
    // onSubmit,
    // ecbrData,
    // searchStatus,
    onConfirmNewDA,
    // onCancelSearchStatus,
    onConfirmResumePendingDA,
    // onCheckExistingDAorCCPA,
    cancelResume,
    typeForm,
    RetrieveData,
    setLoading,
    ccpaDetail,
    onCancelCCPA,
  } = props

  let dialogContent
  let dialogTitle

  switch (typeForm) {
    case 'POLICY_INVALID':
      dialogTitle = TITLE.INVALID
      dialogContent = (
        <Content>
          <Subtitle>กรุณาตรวจสอบกรมธรรม์อีกครั้ง ก่อนทำการสมัคร</Subtitle>
        </Content>
      )
      break
    case 'POLICY_NOT_FOUND':
      dialogTitle = TITLE.NOT_FOUND
      dialogContent = (
        <Content>
          <Subtitle>กรุณาตรวจสอบข้อมูลกรมธรรม์อีกครั้ง</Subtitle>
        </Content>
      )
      break
    case 'FOUND_CCPA_EXISTING':
      dialogTitle = TITLE.CCPA_EXISTING
      const creditCardNumber = _.get(ccpaDetail, 'creditCardNumber', '')

      dialogContent = (
        <>
          <ContentBody>
            <Subtitle>
              {'ปัจจุบันท่านมีข้อมูลการสมัครหักค่าเบี้ยประกันอัตโนมัติ ผ่านบัตรเครดิตที่ลงท้ายด้วย '}
              {/* gift need to fix mock data */}
              {(creditCardNumber.trim() || '').slice(-4) || 'xxxx'}
            </Subtitle>
            <Subtitle>ท่านต้องการสมัครใหม่หรือไม่</Subtitle>
          </ContentBody>
        </>
      )
      break
    case 'FOUND_RETRIEVE_DEBIT':
      const RLSApplication = _.get(RetrieveData,'RetrieveDebitAuthorizationResponse.RLSApplication',null)
      const accountNumber = _.get(RLSApplication, 'accountNumber', '')
      const bankNumber = _.get(RLSApplication, 'bankNumber', '')
      const genBankNameByCode  = getBankNameByCode(bankNumber.trim())
      dialogTitle = TITLE.NOT_CCPA_EXISTING
      dialogContent = (
        <>
          <ContentBody>
            <Subtitle>
              {'ปัจจุบันท่านมีข้อมูลการสมัครหักค่าเบี้ยประกันอัตโนมัติ ผ่านบัญชี'}
              {genBankNameByCode}
              {' ที่ลงท้ายด้วย xxxx-'}
              {accountNumber.trim().slice(-4) ||
                'xxxx'}
            </Subtitle>
            <Subtitle>ท่านต้องการสมัครใหม่หรือไม่</Subtitle>
          </ContentBody>
        </>
      )
      break
    case 'FOUND_DA_EXISTING':
      dialogTitle = TITLE.PENDING
      dialogContent = (
        <Content>
          <Subtitle>ท่านต้องการทำรายการเดิมต่อหรือไม่</Subtitle>
        </Content>
      )
      break
    case 'SERVER_ERROR':
      dialogTitle = TITLE.SERVER_ERROR
      dialogContent = (
        <Content>
          <Subtitle>กรุณารอตรวจสอบข้อมูลกรมธรรม์ใหม่ภายหลัง</Subtitle>
        </Content>
      )
      break
    default:
      dialogTitle = TITLE.ERROR
      dialogContent = (
        // error server case
        <Content>
          <Subtitle>กรุณารอตรวจสอบข้อมูลกรมธรรม์ใหม่ภายหลัง</Subtitle>
        </Content>
      )
  }

  // const isCCPAorDAExisting = ecbrData?.searchStatus === 'CCPA_EXISTING' || ecbrData?.searchStatus === 'DA_EXISTING'
  const isShowCancel = [
    'FOUND_CCPA_EXISTING',
    'NOTFOUND_CCPA_EXISTING',
    'FOUND_DA_EXISTING',
    'FOUND_RETRIEVE_DEBIT',
  ].includes(typeForm)
  // const isPendingDA = ['DA_PENDING', 'FOUND_DA_EXISTING', 'FOUND_RETRIEVE_DEBIT'].includes(ecbrData?.searchStatus)
  const isResumeCase = ['FOUND_DA_EXISTING'].includes(typeForm)

  return (
    <DialogContainer>
      <Dialog centered modal show={show}>
        <>
          <Title>
            {dialogTitle}
          </Title>
          <Divider />
          {dialogContent}
          <Footer>
            {isShowCancel ? (
              <>
                <Button
                  type={Button.Type.Secondary}
                  onClick={() => {
                    // Go to next step - call API -> check existing DA/CCPA on Policy
                    if (isResumeCase) {
                      cancelResume()
                    } else {
                      //   // TODO: cancel process clear policy data in DB and go to homepage
                      onCancelCCPA()
                      onToggle()
                    }
                  }}
                >
                  ไม่ต้องการ
                </Button>
                <Button
                  type={Button.Type.Primary}
                  onClick={() => {
                    if (isResumeCase) {
                      onConfirmResumePendingDA()
                    } else {
                      // onSubmit()
                      onConfirmNewDA()
                    }
                  }}
                >
                  ยืนยัน
                </Button>
              </>
            ) : (
              <Button type={Button.Type.Primary} paddingSizeLeft={'40px'} paddingSizeRight={'40px'} onClick={onToggle}>
                กลับ
              </Button>
            )}
          </Footer>
        </>
      </Dialog>
    </DialogContainer>
  )
}

export default SearchStatusDialog
