import { useState, useEffect } from 'react'
import _isEmpty from 'lodash/isEmpty'

import { useTabStateActive, reStateAfterTabActive, TabeItemsType } from '../../../hooks/useTabs'

import staticContentPage from '../static-content'
import { PropsSectionType } from '../type'

export const useTabItemsProcess = (props: PropsSectionType) => {
  const { tabsProgress } = staticContentPage
  const { state, tabState } = props
  const initTabsProcess = tabsProgress.map((t, k) => ({
    ...t,
    disabled: k === 0 || k === 1 ? false : true,
  }))
  const initTabs = initTabsProcess.slice(0, 3)

  const [tabItems, setTabItems] = useState<TabeItemsType[]>(initTabs)

  useEffect(() => {

    // enable next tab
    if (state?.info?.payload) {
      const newTabItems = useTabStateActive(staticContentPage.tabsProgress[1].id, tabItems)
      setTabItems(newTabItems)
    }
    // disable tab next tab active
    if (state?.info?.isDirty) {
      const newTabItems = reStateAfterTabActive(staticContentPage.tabsProgress[0].id, tabItems)
      setTabItems(newTabItems)
    }
  }, [state?.info])

  useEffect(() => {
    // enable next tab
    if (state?.process?.payload) {
      const disabledAllTab = initTabsProcess.map((tab) => ({ ...tab, disabled: true }))
      const newTabItems = useTabStateActive(staticContentPage.tabsProgress[2].id, disabledAllTab)
      setTabItems(newTabItems)
    }
    // disable tab next tab active
  }, [state?.process])

  useEffect(() => {
    if (tabState === staticContentPage.tabsProgress[0].id && _isEmpty(state)) {
      const newTabItems = reStateAfterTabActive(staticContentPage.tabsProgress[0].id, tabItems)
      setTabItems(newTabItems)
    }
  }, [tabState])
  return [tabItems]
}
