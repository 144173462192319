import { css } from '@emotion/react'

import { color, transition, padding, device } from '../utils/theme'
import { reset } from '../utils/state'

const boxMaxWidth = '600px'
const boxBGColor = color.common.white
const overlayBGColor = color.element.overlayBG
const overlayBGOpacity = 0.5

const style = (props) => {
  return {
    dialog: css`
      ${reset};
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9900;
      height: 0;
      overflow: hidden;
      outline: none;

      &::before {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: ${overlayBGColor};
        opacity: ${overlayBGOpacity};
        content: '';
        pointer-events: none;
      }

      &[data-show='true'] {
        height: 100%;
      }
    `,
    title: css`
      margin-top: 10px;
    `,
    titleText: css`
      color: ${color.type.primary};
      font-weight: 700;
      font-size: 1.125rem;
    `,
    footer: css`
      margin-top: 30px;
    `,
    display: css`
      width: 100%;
      height: 100%;
      overflow-y: ${props?.display?.overFlowY ? props?.display?.overFlowY : 'scroll'};

      &[data-display-centered] {
        display: ${props?.display?.fullScreen ? 'inherit' : 'flex'};
        align-items: center;
        justify-content: center;
      }
    `,
    box: css`
      position: relative;
      top: -30px;
      width: 100%;
      max-width: ${props?.box?.maxWidth ? props?.box?.maxWidth : boxMaxWidth};
      margin: ${props?.box?.margin ? props?.box?.margin : '30px auto'};
      overflow: hidden;
      background-color: ${boxBGColor};
      border: ${props?.box?.border ? props?.box?.border : 'solid 1px transparent'};
      border-radius: ${props?.box?.borderRadius ? props?.box?.borderRadius : '8px'};
      box-shadow: 0 0.5px 0.9px rgba(0, 0, 0, 0.024), 0 1.3px 2.5px rgba(0, 0, 0, 0.035), 0 3px 6px rgba(0, 0, 0, 0.046),
        0 10px 20px rgba(0, 0, 0, 0.07);
      transition: top ${transition.faster};

      @media (max-width: ${device.screenSize.xs}) {
        width: 100%;
        margin: ${props?.box?.margin ? props?.box?.margin : '30px auto'};
      }

      &[data-show='true'] {
        top: ${props?.box?.top ? props?.box?.top : '15px'};
      }
    `,
    body: css`
      z-index: 9901;
      min-height: ${props?.body?.minHeight ? props?.body?.minHeight : '30px'};
    `,
    bodyContent: css`
      box-sizing: border-box;
      padding: ${props?.bodyContent?.padding
        ? props?.bodyContent?.padding
        : `calc(${padding.m} / 2) calc(${padding.m} / 1.5)`};
    `,
  }
}

export default style
