import { useEffect, useState, useCallback } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { getConfig } from 'core/config'
import { verifyDisabledEDC, verifyDisabledLegacyTab } from 'utils/helper'

declare var window: any

export const useLogin = (props: { getConfigApi?: any; useKeycloakApi?: any }) => {
  const { useKeycloakApi = useKeycloak, getConfigApi = getConfig } = props
  const [keycloak, initialized] = useKeycloakApi()
  const [userInfo, setUserInfo] = useState<any>({ name: '' })
  const [isHideEDC, setIsHideEDC] = useState(false)
  const [isHideLegacyTab, setIsHideLegacyTab] = useState(false)
  const { auth, allowedAgentTypeForEDC, startDisabledEDCPeriod, startDisabledLegacyTab } = getConfigApi()

  useEffect(() => {
    if (keycloak) {
      const user = keycloak.tokenParsed
      if (user) {
        setUserInfo(user)
        setIsHideLegacyTab(verifyDisabledLegacyTab(startDisabledLegacyTab))
        setIsHideEDC(verifyDisabledEDC(user.agent_type, allowedAgentTypeForEDC, startDisabledEDCPeriod))
      }
    }
  }, [])

  const login = useCallback(() => {
    keycloak.login()
  }, [keycloak])

  const logout = () => {
    localStorage.removeItem('ecbr:token')
    localStorage.removeItem('ecbr:refresh-token')
    // TODO: #improvement #auth find a better way to check if native app
    if (!window.cordova) {
      window.open(auth.logout.url, 'logoutIAM', 'width=400,height=400')
    }
    keycloak.logout()
  }

  return {
    login,
    logout,
    userInfo,
    isHideEDC,
    isHideLegacyTab,
    initialized,
    token: keycloak?.token,
  }
}
