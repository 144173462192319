/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'

import { RouterContext } from '../../../contexts/router'
import { Button } from 'ktaxa-ui-components/index'
import { color } from 'components/theme'

const _NextButton = ({ nextButton }) => {
  if (_isEmpty(nextButton)) {
    return null
  }

  if (nextButton.type === 'component') {
    return React.cloneElement(nextButton.component, {
      disabled: nextButton.disabled,
    })
  }

  if (nextButton.disabled) {
    return <Button disabled>{nextButton.text}</Button>
  }

  return (
    <Link to={nextButton.to} className="next" onClick={nextButton.onClick}>
      <Button type="primary">{nextButton.text}</Button>
    </Link>
  )
}

const _BackButton = ({ backButton, history }) => {
  if (backButton && backButton.toHome) {
    return (
      <Button onClick={() => history.push('/')} type="secondary">
        กลับ
      </Button>
    )
  }
  if (backButton && _isNil(backButton.to)) {
    return (
      <Button onClick={() => window.history.back()} type="secondary">
        กลับ
      </Button>
    )
  }
  if (backButton) {
    return (
      <Link to={backButton.to} className="back">
        <Button type="secondary">กลับ</Button>
      </Link>
    )
  } else {
    return null
  }
}

const styles = {
  footer: css`
    border-top: 1px solid ${color.common.white};
    background: white;
    margin-top: auto;
  `,
}

const Left = styled.div`
  flex: 1 0 auto;
`

const Center = styled.div`
  flex: 1;
  text-align: center;
`

const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 15px 25px;
`

const MainFooter = (props) => {
  const { history } = props

  const RouterContextProps = useContext(RouterContext)
  const { navConfig } = RouterContextProps
  const {
    backButton,
    nextButton,
    extraButton: ExtraButton,
    extraBackButton: ExtraBackButton,
    isOnlyExtraButtonCenter,
  } = navConfig

  if (!navConfig.showFooter || _isEmpty(navConfig)) return null

  return (
    <div id="footer-nav" css={styles.footer}>
      {isOnlyExtraButtonCenter ? (
        <FooterContainer>
          <Center>{ExtraButton && <ExtraButton />}</Center>
        </FooterContainer>
      ) : (
        <FooterContainer>
          <Left>
            {ExtraBackButton ? <ExtraBackButton /> : <_BackButton backButton={backButton} history={history} />}
          </Left>
          {ExtraButton && <ExtraButton />}
          <_NextButton nextButton={nextButton} />
        </FooterContainer>
      )}
    </div>
  )
}

export default withRouter(MainFooter)
