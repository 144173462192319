import React from 'react'
import * as yup from 'yup'
import _ from 'lodash'
import { InputCustomFormat } from 'components/input'
import { validateCitizenId } from 'utils/validate'
import { getToggles } from 'core/config'

export const textAndNumberOnlyRegExp = /[^0-9A-Za-z\u0E01-\u0E5B ]/gi
export const schemaCancelMethodSection = yup.object().shape({
  object: yup.object({
    cancelMethod: yup.string().required('โปรดเลือกวิธีที่ต้องการยกเลิก'),
  }),
})

export const schemaCancelFormUploadSection = yup.object().shape({
  object: yup.object({
    reasonEcbr: yup.string().required('โปรดเลือกเหตุผลในการยกเลิก'),
    reasonEcbrText0: yup.string().when('reasonEcbr', {
      is: '0',
      then: yup.string().required('โปรดระบุเลขที่ eCBR'),
    }),
    reasonEcbrText1: yup.string().when('reasonEcbr', {
      is: '1',
      then: yup.string().required('โปรดระบุเลขที่ eCBR'),
    }),
    reasonEcbrTextOther: yup.string().when('reasonEcbr', {
      is: 'other',
      then: yup.string().required('โปรดระบุสาเหตุในการขอยกเลิก eCBR'),
    }),
    managerCode: yup.string().required('โปรดระบุรหัสผู้จัดการ').matches(/\d{14}/gm, 'โปรดตรวจสอบข้อมูล'),
    isUploaded: yup.boolean().oneOf([true], 'โปรดแนบเอกสาร'),
  }),
})
export const schemCancelFormSection = yup.object().shape({
  object: yup.object({
    firstNameAgent: yup.string().required('โปรดระบุชื่อตัวแทน'),
    lastNameAgent: yup.string().required('โปรดระบุนามสกุลตัวแทน'),
    citizenId: yup
      .string()
      .required('โปรดระบุเลขที่บัตรประจำตัวประชาชน')
      .test('validateCitizenId', 'โปรดระบุเลขที่บัตรประชาชนให้ถูกต้อง', (value) => validateCitizenId(value)),
    agentNo: yup.string().required('โปรดระบุเลขที่ตัวแทน'),
    email: yup.string().email('โปรดระบุอีเมลให้ถูกต้อง').required('โปรดระบุอีเมลตัวแทน'),
    phoneNumber: yup
      .string()
      .required('โปรดระบุเบอร์โทรศัพท์ติดต่อ')
      .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'โปรดตรวจสอบข้อมูล'),
    ecbrNo: yup.string().required('โปรดระบุเลขที่ eCBR'),
    date: yup.string().required('โปรดระบุวันที่'),
    amount: yup.string().required('โปรดระบุจำนวนเงิน'),
    reasonEcbr: yup.string().required('โปรดระบุเหตุผลในการยกเลิก'),
    reasonEcbrText0: yup.string().when('reasonEcbr', {
      is: '0',
      then: yup.string().required('โปรดระบุเลขที่ eCBR'),
    }),
    reasonEcbrText1: yup.string().when('reasonEcbr', {
      is: '1',
      then: yup.string().required('โปรดระบุเลขที่ eCBR'),
    }),
    reasonEcbrTextOther: yup.string().when('reasonEcbr', {
      is: 'other',
      then: yup.string().required('โปรดระบุสาเหตุในการขอยกเลิก eCBR'),
    }),
    managerCode: yup.string().required('โปรดระบุรหัสผู้จัดการ').matches(/\d{14}/gm, 'โปรดตรวจสอบข้อมูล'),
    sign1: yup
      .string()
      .when('sign2', {
        is: (sign2) => _.isEmpty(sign2),
        then: yup.string().when('sign3', {
          is: (sign3) => _.isEmpty(sign3),
          then: yup.string().required('โปรดลงนามตัวแทน'),
        }),
      })
      .when('sign2', {
        is: (sign2) => !_.isEmpty(sign2),
        then: yup.string().when('sign3', {
          is: (sign3) => _.isEmpty(sign3),
          then: yup.string().required('โปรดลงนามตัวแทน'),
        }),
      }),
    sign2: yup.string().required('โปรดลงนามผู้จัดการ'),
    sign3: yup
      .string()
      .when('sign1', {
        is: (sign1) => _.isEmpty(sign1),
        then: yup.string().when('sign2', {
          is: (sign3) => _.isEmpty(sign3),
          then: getToggles().ENABLE_ECBRSYS_ECBR? yup.string().required('โปรดลงนามเจ้าของสำนักงาน'): yup.string(),
        }),
      })
      .when('sign1', {
        is: (sign1) => _.isEmpty(sign1),
        then: yup.string().when('sign2', {
          is: (sign3) => !_.isEmpty(sign3),
          then: getToggles().ENABLE_ECBRSYS_ECBR? yup.string().required('โปรดลงนามเจ้าของสำนักงาน'): yup.string(),
        }),
      }),
  }),
})

export const RenderInputDisabledClear = ({ field, ti, defaultState, setState }) => {
  return (
    <InputCustomFormat
      {...field}
      mask={ti.mask}
      format={ti.format}
      maxLength={ti.maxLength}
      thousandSeparator={ti.thousandSeparator}
      decimalScale={ti.decimalScale}
      fixedDecimalScale={ti.fixedDecimalScale}
      isAllowed={ti.isAllowed}
      onBlur={(e) => setState(e.target.value)}
    />
  )
}
