import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import styled from '@emotion/styled'

import { Button } from 'ktaxa-ui-components/index'
import SearchStatusDialog from '../components/search-ccpa-status-dialog'
import PolicyListDialog from '../components/policy-list-dialog'
import LoadingModal from '../components/load-data-dialog'

import staticContentPage from '../static-content'
import { AppContext } from 'contexts/app'

const Loading = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    font-size: 1.4rem;
  }
`

const SubmitRegisterSection = (props) => {
  const [loading, setLoading] = useState(false)
  const { isVerifyFormValidateError } = useContext(AppContext)
  // const [showSearchStatusDialog, setShowSearchStatusDialog] = useState(false)
  // const [showPolicyListDialog, setShowPolicyListDialog] = useState(false)
  // const [selectedAgentIndex, setSelectedAgentIndex] = useState(0)
  const { handleSubmit, tabState, setTabState, setState, state, history } = props
  useEffect(() => {}, [])

  const handleSubmitData = async () => {
    handleSubmit()
  }

  return (
    <>
      <Button onClick={handleSubmitData} type="primary" disabled={isVerifyFormValidateError}>
        ยืนยัน
      </Button>
      <LoadingModal show={loading} />
    </>
  )
}

export default withRouter(SubmitRegisterSection)
