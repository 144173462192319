import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color, device } from 'components/theme'

import { CHECK_ACCOUNT_STATUS } from '../static-content'
import { formatBankAccountNumber } from '../utils'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 0;
    padding-left: 0;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px;
`

const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Subtitle = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`

const DetailSection = styled.div`
  margin: 20px;
  @media (min-width: ${device.screenSize.sm}) {
    margin-left: 80px;
  }
  margin-top: 15px;
  margin-bottom: 0;
  width: fit-content;
  text-align: left;
`

const CheckAccountResultDialog = (props: any) => {
  const { show, onConfirm, status, data } = props

  let dialogTitle = ''
  let dialogContent = ['']

  switch (status) {
    case CHECK_ACCOUNT_STATUS.FAIL:
      dialogTitle = 'กรุณาตรวจสอบข้อมูลบัญชีธนาคาร'
      dialogContent = ['ตรวจสอบเลขที่บัญชีหรือสถานะบัญชีไม่ถูกต้อง', 'โปรดระบุเลขที่บัญชีใหม่อีกครั้ง']
      break
    case CHECK_ACCOUNT_STATUS.SUCCESS:
      dialogTitle = 'ผลการตรวจสอบข้อมูลบัญชีธนาคาร'
      dialogContent = ['ตรวจสอบข้อมูลบัญชีธนาคารของท่านสำเร็จ']
      break
    default:
      // error server / tech error case
      dialogTitle = 'ผลการตรวจสอบข้อมูลบัญชีธนาคาร'
      dialogContent = ['ระบบการตรวจสอบข้อมูลบัญชีธนาคารขัดข้อง', 'กรุณาตรวจสอบและแก้ไขชื่อบัญชีธนาคารของท่านด้วยตัวเอง']
  }

  return (
    <DialogContainer>
      <Dialog centered modal show={show}>
        <>
          <Title>{dialogTitle}</Title>
          <Divider />
          <Content>
            {dialogContent.map((content) => {
              return <Subtitle>{content}</Subtitle>
            })}
            {status === CHECK_ACCOUNT_STATUS.SUCCESS && (
              <DetailSection>
                <Subtitle>
                  ธนาคาร : <b>{data?.bankName}</b>
                </Subtitle>
                <Subtitle>
                  ชื่อบัญชี : <b>{data?.bankAccountName}</b>
                </Subtitle>
                <Subtitle>
                  เลขที่บัญชี : <b>{formatBankAccountNumber(data?.bankAccountNumber)}</b>
                </Subtitle>
              </DetailSection>
            )}
          </Content>
          <Footer>
            <Button
              type={Button.Type.Primary}
              paddingSizeLeft={'40px'}
              paddingSizeRight={'40px'}
              onClick={() => {
                onConfirm()
              }}
            >
              ตกลง
            </Button>
          </Footer>
        </>
      </Dialog>
    </DialogContainer>
  )
}

export default CheckAccountResultDialog
