import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color } from 'components/theme'
import staticContent from '../../../pages/new-ecbr/static-content'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 0;
    padding-left: 0;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px 0;
`

const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Message = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`

const ChequeLoadingDialog = (props: any) => {
  const { show } = props

  return (
    <DialogContainer>
      <Dialog centered modal show={show}>
        <Title>กำลังดำเนินการออกใบเสร็จรับเงินชั่วคราวอิเล็กทรอนิกส์</Title>
        <Divider />
        <Content>
          <Message
            dangerouslySetInnerHTML={{
              __html: 'กรุณารอสักครู่ ระบบกำลังสร้างใบเสร็จรับเงินชั่วคราวอิเล็กทรอนิกส์',
            }}
          />
        </Content>
      </Dialog>
    </DialogContainer>
  )
}

export default ChequeLoadingDialog
