import blankPage from './blank-page'
import errorDialog from './error-dialog'
import layouts from './layouts'
import tabs from './tabs'
import cards from './cards'
import list from './list'
import theme from './theme'
import portalButton from './portal-button'

const BlankPage = blankPage
const ErrorDialog = errorDialog
const Tabs = tabs
const Cards = cards
const List = list
const Theme = theme
const PortalButton = portalButton

export { BlankPage, ErrorDialog, Tabs, Cards, List, Theme, PortalButton }
