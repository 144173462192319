import React, { useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from '@emotion/styled'
import _isEmpty from 'lodash/isEmpty'
import _omitBy from 'lodash/omitBy'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _isEqual from 'lodash/isEqual'

import { AppContext } from 'contexts/app'
import { getConfig } from 'core/config'

import { schemaRegisterInfoSection, validateRegisterFormInput } from '../hooks/useForm'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import staticContentPage, {
  BANKS,
  RELATIONS,
  CHECK_ACCOUNT_STATUS,
  DA_TYPE,
  DA_STATUS,
  REGISTER_STATUS,
} from '../static-content'
import LoadingModal from '../components/load-data-dialog'
import SubmitRegisterSection from './submitRegister'
import CheckAccountResultDialog from '../components/check-account-result-dialog'
import { checkAccountService } from 'services/ecbr-middleware'
import {
  createRegisterFormPayload,
  validateRelationship,
  getBankNameByCode,
  getStringOfNumberOnly,
  getIsShowReviewForm,
} from '../utils'
import { onCheckValueAccountData, checkBankAccount, checkEDARegister, onRegisterEDA } from '../hooks/useService'
import { updateRecurringData, getAttachment } from '../hooks/useService'
import ReviewContentSection from './reviewContent'
import FormContentSection from './formContent'

export const MessageBlock = styled.div`
  margin: 10px 0px;
`
export const SelectContainer = styled.div`
  margin-top: 12px;
  & > div > div {
    display: block;
  }
`

const RegisterFormSection = (props) => {
  // FYI : tab index 2
  const {
    tabState,
    state,
    setState,
    setTabState,
    selectedRecurringInfo,
    recurringInfo,
    onDAFormSuccess,
    isPageReview,
  } = props
  const { setIsBankAccountVerified, isShowReviewForm, setIsShowReviewForm, setRegistrationEDA, dataCheckAccount, setDataCheckAccount } = useContext(AppContext)

  const { formState, control, reset, handleSubmit, getValues, watch, setValue, clearErrors, resetField, trigger } =
    useForm({
      mode: 'all',
      resolver: yupResolver(schemaRegisterInfoSection),
    })

  const { errors, isValid } = formState

  const defaultRegisterFormInfo = _get(state, 'verify.searchDAInfo', null)
  const defaultValueDAofPayor = _get(defaultRegisterFormInfo, 'daOfPayor', null)
  const defaultValuePolicy = _get(defaultRegisterFormInfo, 'policyDetail', null)

  const defaultPersonalIdType = 'citizen'
  const regexCitizenId = /^\d{13}$/
  const defaultRelationshipType = '' // fisrt not set relation before fill in insured and payer data

  // const isPolicyCase = false //TODO policyYear = RNW (policy case API) can choose only EDA form
  const isPolicyCase = defaultValuePolicy?.policyYear === 'RNW' //TODO policyYear = RNW (policy case API) can choose only EDA form
  const defaultFormType = defaultRegisterFormInfo?.daType
  const isDefaultEDAFormType = defaultFormType === DA_TYPE.ONLINE
  const initIsSelectedEDA = defaultFormType ? isDefaultEDAFormType : isPolicyCase

  const [selectedPersonalIdType, setSelectedPersonalIdType] = useState(defaultPersonalIdType)
  const [selectedRelationshipType, setSelectedRelationshipType] = useState(defaultRelationshipType)

  const [isLoadingModal, setIsLoadingModal] = useState(false)

  const [isValidateForm, setIsValidateForm] = useState(false)

  const [isSelectedEDA, setIsSelectedEDA] = useState(initIsSelectedEDA)

  const [isBookbankOverLimit, setIsBookbankOverLimit] = useState(false)
  const [isRelationDocOverLimit, setIsRelationDocOverLimit] = useState(false)

  const [isCheckedAccount, setIsCheckedAccount] = useState(false) // init value from DA info
  const [checkedAccountStatus, setCheckedAccountStatus] = useState('')
  const [checkedAccountData, setCheckedAccountData] = useState<any>()
  const [isShowCheckedAccountResultModal, setIsShowCheckedAccountResultModal] = useState(false)

  const [isManualRelationship, setIsManualRelationship] = useState(false) // init value from DA info
  const ActiveCondition = [staticContentPage.tabsProgress[1].id].includes(tabState) || isShowReviewForm
  const [isShowNavbar, setIsShowNavbar] = useState(false)

  const watchedFieldsForValidateRelation = watch([
    'insuredName',
    'insuredSurname',
    'payerName',
    'payerSurname',
    'bankAccountName',
  ])

  // initial state
  useEffect(() => {
    // const initDaFormType = isSelectedEDA ? DA_TYPE.ONLINE : DA_TYPE.FORM
    const initDaFormType = isSelectedEDA ? DA_TYPE.ONLINE : DA_TYPE.FORM
    const defaultDaFormType = defaultFormType || initDaFormType
    // defaultValuePolicy?.daType || initDaFormType

    const defaultBankAccountNumber =
      defaultValueDAofPayor?.branchNumber || defaultValueDAofPayor?.accountNumber
        ? `${defaultValueDAofPayor?.branchNumber}${defaultValueDAofPayor?.accountNumber}`
        : ''

    const defaultBankCode = defaultValueDAofPayor?.bankNumber?.value || ''
    const initBankNameKey = getBankNameByCode(defaultBankCode, true)
    // Object.values(BANKS).find((value) => value.code === defaultBankCode)?.key || BANKS.KTB.key
    const defaultPersonalId = defaultValueDAofPayor?.payorId || ''
    const initPersonalIdType =
      defaultPersonalId === '' || defaultPersonalId.match(regexCitizenId) ? 'citizen' : 'passport'
    const verifyAccStatus = _get(defaultValueDAofPayor, 'verifyAccStatus', '')
    const initIsAccountChecked = !_isEmpty(verifyAccStatus) // get isCheckAccount

    const initIsManualRelationship = defaultValueDAofPayor?.manualRelation || false // get isManualRelationship
    const initRelationshipType = defaultValueDAofPayor?.payorRelationship || defaultRelationshipType // get relationShipType

    const verifySearch = _get(state, 'verify.searchDAInfo', null)

    let initCheckedAccountStatus = ''
    const initBookbank = _get(verifySearch, '_attachments.bookbank', null)
    const initRelationship = _get(verifySearch, '_attachments.relationship', null)
    const daStatus = _get(verifySearch, 'daStatus', 'Create')
    //////

    switch (verifyAccStatus.toLocaleLowerCase()) {
      case 'verified':
        initCheckedAccountStatus = CHECK_ACCOUNT_STATUS.SUCCESS
        break
      case 'not verified':
        initCheckedAccountStatus = CHECK_ACCOUNT_STATUS.FAIL
        break
      default:
        initCheckedAccountStatus = ''
    }

    reset({
      // daFormType: initDaFormType, // isEDA = false (init)
      daFormType: defaultDaFormType, // isEDA = false (init)
      //
      insuredName: defaultValuePolicy?.insuredName || '',
      insuredSurname: defaultValuePolicy?.insuredLastname || '',
      payerName: defaultValuePolicy?.ownerName || '',
      payerSurname: defaultValuePolicy?.ownerLastname || '',
      //
      bankName: initBankNameKey,
      // defaultValueDAofPayor?.bankNumber.value || BANKS.KTB.key, // KTB
      bankAccountType: defaultValueDAofPayor?.accountType?.value || '',
      bankAccountNumber: defaultBankAccountNumber,
      bankAccountName: defaultValueDAofPayor?.accountName || '',
      regPersonalIdType: initPersonalIdType,
      regPersonalId: defaultPersonalId,
      regRelationship: initRelationshipType,
      regRelationshipRemark: defaultValueDAofPayor?.relationshipRemark || '',
      regMobile: defaultValueDAofPayor?.payorMobile || '',
      regEmail: defaultValueDAofPayor?.email || '',
      //
      isAccountChecked: initIsAccountChecked,
      //
      regRelationshipDoc: initRelationship
        ? {
            base64: initRelationship?.image || null,
            size: initRelationship?.length || null,
            content_type: 'application/pdf',
          }
        : undefined,
      bookBank: initBookbank
        ? {
            base64: initBookbank?.image || null,
            size: initBookbank?.length || null,
            content_type: 'application/pdf',
          }
        : undefined,
      verifyAccStatus: initIsAccountChecked ? verifyAccStatus : undefined,
    })
    if (initIsAccountChecked) {
      const dataCheckAccount = {
        bankName: defaultValueDAofPayor?.bankNumber?.text || '',
        bankAccountName: defaultValueDAofPayor?.accountName || '',
        bankAccountNumber: defaultBankAccountNumber,
        stausVerify: defaultValueDAofPayor?.verifyAccStatus || '',
      }
      setCheckedAccountData(dataCheckAccount)
      setDataCheckAccount(dataCheckAccount)
    } else {
      setDataCheckAccount({})
    }
    setIsSelectedEDA(defaultDaFormType === DA_TYPE.ONLINE)
    setSelectedPersonalIdType(initPersonalIdType)
    setIsCheckedAccount(initIsAccountChecked)
    setCheckedAccountStatus(initCheckedAccountStatus)
    setIsManualRelationship(initIsManualRelationship)
    setSelectedRelationshipType(initRelationshipType)

    const activeShowReviewForm = getIsShowReviewForm(tabState, isSelectedEDA, defaultFormType, daStatus)
    // tabState === staticContentPage.tabsProgress[2].id && defaultDaFormType === DA_TYPE.FORM
    const daType = _get(verifySearch, 'daType', null)
    onCheckNavbar(daType)
    setIsShowReviewForm(activeShowReviewForm)
    // trigger()
    // gift hack show review first
    // setIsShowReviewForm(true)
  }, [state, ActiveCondition])

  useEffect(() => {

  },[checkedAccountData])

  const onCheckNavbar = async (dataType) => {
    await setIsShowNavbar(dataType === DA_TYPE.FORM)

    // if (tabState === staticContentPage.tabsProgress[1].id) {
    //   delete state.isEditForm
    // }
  }

  useEffect(() => {
    const daStatus = _get(state, 'verify.searchDAInfo.daStatus', 'Create')
    const activeShowReviewForm = getIsShowReviewForm(tabState, isSelectedEDA, defaultFormType, daStatus)

    setIsShowReviewForm(activeShowReviewForm)
  }, [tabState])

  const { regInfoSection: formInfo } = staticContentPage

  const onSubmitRegisterForm = async () => {
    const formValues = getValues()
    const isEDAFormType = formValues.daFormType === DA_TYPE.ONLINE

    const recurringData = _get(state, 'verify.searchDAInfo', null)
    let payload = await createRegisterFormPayload(formValues, isManualRelationship, recurringData)
    if (isEDAFormType) {
      payload.daStatus = DA_STATUS.CREATE
    }

    if (recurringData.daStatus !== DA_STATUS.PENDING) {
      await updateRecurringData({
        updateType: 'UPDATE',
        // updateType: 'SUBMIT',
        data: payload,
      })
    }

    let regStatus = ''

    let payloadUpdateSubmit
    if (!isEDAFormType) {
      payloadUpdateSubmit = {
        _id: recurringData._id,
        daStatus: DA_STATUS.PENDING,
        daType: payload.daType,
      }

      let resultSubmitDa = await updateRecurringData({
        updateType: 'SUBMIT',
        data: payloadUpdateSubmit,
      })
      const getDataATP = _get(resultSubmitDa, 'data._attachments.atp', null)
      if (!_isEmpty(getDataATP)) {
        let resultImage = await getAttachment({
          _attachments: { atp: getDataATP },
        })
        const dataAttachment = _get(state, 'verify.searchDAInfo._attachments', null)
        await _set(state, `verify.searchDAInfo._attachments`, { ...dataAttachment, ...resultImage.data._attachments })
      }
      _set(state, 'verify.searchDAInfo.daStatus', payloadUpdateSubmit.daStatus)
      await setIsLoadingModal(false)
      await setTabState(staticContentPage.tabsProgress[2].id)
      await setIsShowReviewForm(true)
    } else {
      // check eda Status Register
      const payloadEDARegister = {
        accountNo: formValues.bankAccountNumber,
        policyNo: recurringData.policyId,
      }
      const policyStatus = await onRegisterEDA(payloadEDARegister)
      setRegistrationEDA(policyStatus.data)
      regStatus = policyStatus.status
      if (regStatus !== REGISTER_STATUS.TECH_ISSUE && regStatus !== REGISTER_STATUS.EDA_DUPLICATE) {
        // if pass go to reviw / check status
        payloadUpdateSubmit = {
          _id: recurringData._id,
          edaResponseDateTime: policyStatus.data.applyDtm,
          edaRequestDateTime: policyStatus.data.applyDtm,
          daStatus: DA_STATUS.PENDING,
        }
        updateRecurringData({
          updateType: 'UPDATE',
          data: payloadUpdateSubmit,
        })
      }
      await setIsLoadingModal(false)
      await _set(state, 'checkEDAStatus', '')
      await setState({
        ...state,
        [staticContentPage.tabsProgress[1].id]: {
          payload,
        },
        regStatus,
      })
    }
  }

  const handleCheckAccount = async () => {
    //
    const formValues = getValues()
    const regBank = BANKS[formValues.bankName]
    const resAccountNumber = formValues.bankAccountNumber // TODO: format back to get only number string
    const resBankName = regBank.text

    const { insuredName, insuredSurname, payerName, payerSurname } = formValues
    const insuredFullname = `${insuredName.trim()} ${insuredSurname.trim()}`
    const payorFullname = `${payerName.trim()} ${payerSurname.trim()}`
    const isSameInsuredAndPayer = _isEqual(insuredFullname, payorFullname)
    const defaultBankAccName = isSameInsuredAndPayer ? insuredFullname : payorFullname

    try {
      let playload = {
        policyId: _get(recurringInfo, 'resultRecurringPayments.data.policyId', ''),
        bankCode: regBank.code,
        accountNo: resAccountNumber
      }
      let result = await checkBankAccount(playload)

      switch (result.data.checkAccStatus) {
        case 'SUCCESS':
          await setIsCheckedAccount(true)
          const resAccountName = result.data.accountName
          const responseData = {
            bankName: resBankName,
            bankAccountName: resAccountName,
            bankAccountNumber: resAccountNumber,
            stausVerify: 'Verified',
          }
          await setCheckedAccountData(responseData)
          await setDataCheckAccount(responseData)
          await setValue('bankAccountName', resAccountName, { shouldValidate: true, shouldDirty: true })
          await setCheckedAccountStatus(CHECK_ACCOUNT_STATUS.SUCCESS)
          break
        case 'INVALID':
          await setCheckedAccountData(null)
          await setDataCheckAccount({})
          await setIsCheckedAccount(false)
          await setValue('bankAccountName', '', { shouldValidate: true, shouldDirty: true })
          await setCheckedAccountStatus(CHECK_ACCOUNT_STATUS.FAIL)
          break
        default:
          await setIsCheckedAccount(true)
          // const { insuredName, insuredSurname } = formValues
          // const insuredFullname = `${insuredName.trim()} ${insuredSurname.trim()}`
          const responseDataFaild = {
            bankName: resBankName,
            bankAccountName: defaultBankAccName,
            bankAccountNumber: resAccountNumber,
            stausVerify: 'Not verified',
          }
          await setCheckedAccountData(responseDataFaild)
          await setDataCheckAccount(responseDataFaild)
          await setValue('bankAccountName', defaultBankAccName, { shouldValidate: true, shouldDirty: true })
          await setCheckedAccountStatus(CHECK_ACCOUNT_STATUS.TECH_ERR)
          break
      }

      await setIsLoadingModal(false)
      await setIsShowCheckedAccountResultModal(true)
    } catch (error) {
      console.error('error checkAccountService', error.message)
      await setIsCheckedAccount(true)
      await setCheckedAccountData({
        bankName: resBankName,
        bankAccountName: defaultBankAccName,
        bankAccountNumber: resAccountNumber,
        stausVerify: 'Not verified',
      })
      await setValue('bankAccountName', defaultBankAccName, { shouldValidate: true, shouldDirty: true })
      await setCheckedAccountStatus(CHECK_ACCOUNT_STATUS.TECH_ERR)
    }
  }

  const autoSaveDataForm = async () => {
    try {
      let formValues = getValues()

      delete state.isEditForm
      let recurringData = _get(state, 'verify.searchDAInfo', null)
      let payload = await createRegisterFormPayload(formValues, isManualRelationship, recurringData)

      payload.daStatus = recurringData.daStatus
      let resultUpdate = await updateRecurringData({
        updateType: 'UPDATE',
        data: payload,
      })
      if (resultUpdate.data._attachments) {
        delete resultUpdate.data._attachments
      }
      const searchDAInfo = {
        ...state.verify.searchDAInfo,
        ...resultUpdate.data,
      }
      await _set(state, `verify.searchDAInfo`, searchDAInfo)
      const resultDAType = _get(resultUpdate, 'data.daType', null)
      onCheckNavbar(resultDAType)
    } catch (error) {
      console.error(`auto save error`,error.message)
    }
  }

  const onCheckAccount = () => {
    trigger()
    handleCheckAccount()
    setIsLoadingModal(true)
  }

  const onSubmit = () => {
    trigger()
    setIsLoadingModal(true)
    onSubmitRegisterForm()
  }

  const onSubmitReview = async () => {
    try {
      await setIsLoadingModal(true)
      let recurringData = _get(state, 'verify.searchDAInfo', null)
      let payload = {
        _id: recurringData._id,
        daStatus: DA_STATUS.SUCCESS,
        edaResponseDateTime: recurringData.edaResponseDateTime,
      }
      await updateRecurringData({
        updateType: DA_STATUS.SUCCESS,
        data: payload,
      })
      await setIsLoadingModal(false)
      await onDAFormSuccess()
    } catch (error) {
      console.error('error submited review', error.message)
      await setIsLoadingModal(false)
    }
  }

  useEffect(() => {
    if (!isManualRelationship) {
      const formValues = getValues()
      if (!_isEmpty(formValues.bankAccountName)) {
        const newRelationshipType = validateRelationship(isCheckedAccount, formValues)

        const isEditForm = _get(state, 'isEditForm', false)
        if (formValues.regRelationship !== newRelationshipType && isEditForm == false) {
          setSelectedRelationshipType(newRelationshipType)
        } else {
          // if (tabState === staticContentPage.tabsProgress[1].id) {
          //   delete state.isEditForm
          // }
        }
      }
    }
  }, [watchedFieldsForValidateRelation])

  useEffect(() => {
    const currentFormValues = getValues()
    const validateFormResult = validateRegisterFormInput(currentFormValues)
    if (ActiveCondition) {
      setIsValidateForm(validateFormResult)
    }
  }, [isValidateForm, getValues()])

  useEffect(() => {
    setValue('regRelationship', selectedRelationshipType, { shouldValidate: true, shouldDirty: true })
    if (selectedRelationshipType !== RELATIONS.OTHERS.value) {
      setValue('regRelationshipRemark', '', { shouldValidate: true, shouldDirty: true })
    }
  }, [selectedRelationshipType])

  useEffect(() => {
    //need to remove naja dont forget
    setValue('isAccountChecked', isCheckedAccount, { shouldValidate: true, shouldDirty: true })
    setIsBankAccountVerified(isCheckedAccount)
  }, [isCheckedAccount])

  useEffect(() => {
    if (checkedAccountStatus === CHECK_ACCOUNT_STATUS.TECH_ERR) {
      // updateDataCheckAccount()
    }
  }, [checkedAccountStatus])

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraButton: () => (
        <SubmitRegisterSection
          tabState={tabState}
          onSubmit={onSubmit}
          onCheckAccount={onCheckAccount}
          // isShowReviewForm={isShowReviewForm}
          onSubmitReview={onSubmitReview}
          state={state}
        />
      ),
    }
  )

  const isOtherRelationship = selectedRelationshipType === RELATIONS.OTHERS.value

  if (!ActiveCondition) return null

  const handleSelectedUploadDoc = async (field, img, docType) => {
    try {
      await setIsLoadingModal(true)
      let recurringData = _get(state, 'verify.searchDAInfo', null)
      const payload = {
        policyId: recurringData.policyId,
        _id: recurringData._id,
        _attachments: {
          ...recurringData._attachments,
          [docType]: {
            image: img.base64,
            content_type: 'application/pdf',
            length: img.size,
          },
        },
      }
      let resultIMAGE = await updateRecurringData({
        updateType: 'UPLOAD_IMAGE',
        data: payload,
      })
      //Merch Data _attachments new => old
      await _set(state, `verify.searchDAInfo._attachments.${docType}`, {
        image: img.base64,
        content_type: 'application/pdf',
        length: img.size,
        key: resultIMAGE.data.data?._attachments[docType].key,
      })
      await setIsLoadingModal(false)
      field.onChange(img)
    } catch (error) {
      console.error(`error handleSelectedUploadDoc`, error.message)
      await setIsLoadingModal(false)
    }
  }

  const handleRemoveUploadDoc = async (id, docType) => {
    await setIsLoadingModal(true)
    let recurringData = _get(state, 'verify.searchDAInfo', null)
    const payload = {
      policyId: recurringData.policyId,
      _id: recurringData._id,
      _attachments: {
        ...recurringData._attachments,
        [docType]: undefined,
      },
    }
    await updateRecurringData({
      updateType: 'UPDATE',
      data: payload,
    })
    await _set(state, `verify.searchDAInfo._attachments`, payload._attachments)
    await setValue(id, undefined, {
      shouldValidate: true,
      shouldDirty: true,
    })
    await setIsLoadingModal(false)
  }

  const updateDataCheckAccount = async () => {
    try {
      if (!_isEmpty(checkedAccountData)) {
        await setIsShowCheckedAccountResultModal(false)
        await setIsLoadingModal(true)
        const formValues = getValues()
        // await _set(state, `verify.searchDAInfo`, searchDAInfo)

        // let _daOfPayor = _get(recurringInfo, 'daOfPayor', null)
        let _daOfPayor = _get(state, `verify.searchDAInfo.daOfPayor`, null)
        const bankSelected = BANKS[formValues.bankName]
        const resultUpdate = await updateRecurringData({
          updateType: 'UPDATE',
          data: {
            _id: _get(recurringInfo, 'resultRecurringPayments.data._id', ''),
            policyId: _get(recurringInfo, 'resultRecurringPayments.data.policyId', ''),
            daOfPayor: {
              ..._daOfPayor,
              bankNumber: {
                text: bankSelected.text,
                value: bankSelected.code,
              },
              accountType: {
                text: formValues.bankAccountType,
                value: formValues.bankAccountType,
              },
              collectionBank: {
                text: bankSelected.text,
                value: bankSelected.collection,
              },
              branchNumber: checkedAccountData.bankAccountNumber
                ? checkedAccountData.bankAccountNumber.substring(0, 3)
                : '',
              accountNumber: checkedAccountData.bankAccountNumber ? checkedAccountData.bankAccountNumber.slice(-7) : '',
              accountName: checkedAccountData.bankAccountName,
              verifyAccStatus: checkedAccountData.stausVerify,
            },
          },
        })
        setValue('verifyAccStatus', checkedAccountData.stausVerify)
      }
      await setIsShowCheckedAccountResultModal(false)
      await setIsLoadingModal(false)
    } catch (error) {
      console.error(`error save data checkaccount ${error.message}`)
      await setIsShowCheckedAccountResultModal(false)
      await setIsLoadingModal(false)
    }
  }

  return (
    <>
      {/* {isShowReviewForm ? renderReviewContent() : renderFormContent()} */}
      {isShowReviewForm ? (
        <ReviewContentSection
          // -- global state
          state={state}
          setState={setState}
          setTabState={setTabState}
          // -- useForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          // -- local variable
          isOtherRelationship={selectedRelationshipType === RELATIONS.OTHERS.value}
          // -- local state
          isValidateForm={isValidateForm}
          isBookbankOverLimit={isBookbankOverLimit}
          setIsBookbankOverLimit={setIsBookbankOverLimit}
          isRelationDocOverLimit={isRelationDocOverLimit}
          setIsRelationDocOverLimit={setIsRelationDocOverLimit}
          setIsShowReviewForm={setIsShowReviewForm}
          // -- function
          handleSelectedUploadDoc={handleSelectedUploadDoc}
          handleRemoveUploadDoc={handleRemoveUploadDoc}
        />
      ) : (
        <FormContentSection
          // -- global state
          state={state}
          // -- useForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          resetField={resetField}
          errors={errors}
          trigger={trigger}
          // -- local variable
          formInfo={formInfo}
          isPolicyCase={isPolicyCase}
          isOtherRelationship={selectedRelationshipType === RELATIONS.OTHERS.value}
          checkedAccountStatus={checkedAccountStatus}
          setCheckedAccountStatus={setCheckedAccountStatus}
          checkedAccountData={checkedAccountData}
          // -- local state
          isValidateForm={isValidateForm}
          isSelectedEDA={isSelectedEDA}
          setIsSelectedEDA={setIsSelectedEDA}
          isBookbankOverLimit={isBookbankOverLimit}
          setIsBookbankOverLimit={setIsBookbankOverLimit}
          isRelationDocOverLimit={isRelationDocOverLimit}
          setIsRelationDocOverLimit={setIsRelationDocOverLimit}
          isManualRelationship={isManualRelationship}
          setIsManualRelationship={setIsManualRelationship}
          selectedPersonalIdType={selectedPersonalIdType}
          setSelectedPersonalIdType={setSelectedPersonalIdType}
          selectedRelationshipType={selectedRelationshipType}
          setSelectedRelationshipType={setSelectedRelationshipType}
          setIsCheckedAccount={setIsCheckedAccount}
          // -- function
          autoSaveDataForm={autoSaveDataForm}
          handleSelectedUploadDoc={handleSelectedUploadDoc}
          handleRemoveUploadDoc={handleRemoveUploadDoc}
        />
      )}
      <CheckAccountResultDialog
        show={isShowCheckedAccountResultModal}
        onConfirm={updateDataCheckAccount}
        status={checkedAccountStatus} // adjust soon
        data={checkedAccountData}
      />
      <LoadingModal show={isLoadingModal} />
    </>
  )
}

export default RegisterFormSection
