import {isEmpty} from 'lodash'
import { isAfterDate,formatDate } from 'utils/date'

export const handleRemoveField = (paymentMethod, setValue) => {
  if (paymentMethod === 'cheque') {
    setValue('creditCardImage', '')
    setValue('authorizedCode', '')
    setValue('creditCardNumber', '')
    setValue('chequeDate', new Date().toISOString())
  } else if (paymentMethod === 'creditcard') {
    setValue('chequeNumber', '')
    setValue('chequeBank', 'ธนาคารผู้รับชำระ')
    setValue('chequeBankBranch', '')
    setValue('chequeDate', '')
    setValue('chequeImage', {})
  } else {
    setValue('creditCardImage', '')
    setValue('authorizedCode', '')
    setValue('creditCardNumber', '')
    setValue('chequeNumber', '')
    setValue('chequeBank', 'ธนาคารผู้รับชำระ')
    setValue('chequeBankBranch', '')
    setValue('chequeImage', {})
  }
}

export const handleDefaultPayerInfo = (payerType,setValue) => {
  if (payerType === 'insured') {
    setValue('payerFirstName', '')
    setValue('payerLastName', '')
    setValue('payerIdType', '')
    setValue('payerPersonalId', '')
    setValue('payerRelations', '')
    setValue('payerRelationsOthers', '')
    setValue('payerRelationshipDoc', {})
  } else {
    setValue('payerFirstName', '')
    setValue('payerLastName', '')
    setValue('payerIdType', 'citizen')
    setValue('payerPersonalId', '')
    setValue('payerRelations', '')
    setValue('payerRelationsOthers', '')
    setValue('payerRelationshipDoc', {})
  }
}

export const calculatePaymentAmount = (policyDetail) => {
  try {
    const { dividendOptionFlag, totalPremiumAmount, futurePremiumDepositAmount, accumulatedDividendAmount } =
      policyDetail

    const premiumData = [
      Number(totalPremiumAmount),
      Number(futurePremiumDepositAmount),
      Number(accumulatedDividendAmount),
    ] // convert to number for calculate final premium

    const newData = premiumData.map((item) => (isNaN(item) ? 0 : item))

    if (dividendOptionFlag === '2') {
      return (newData[0] - newData[1] - newData[2]).toString()
    }
    return (newData[0] - newData[1]).toString()
  } catch (error) {
    console.error('Error in calculation payment amount:', error)
    return ''
  }
}

export const verifyDisabledEDC = (agentType, allowedAgentTypeForEDC, startDisabledEDCPeriod) => {
  let isDisabledEDC = false
  const isVerifyEDCPeriod = isAfterDate(startDisabledEDCPeriod)  
  const checkAllowedEDC = allowedAgentTypeForEDC.includes(agentType)
  
  try {
    if(!isEmpty(agentType) && !isEmpty(allowedAgentTypeForEDC) && !isEmpty(startDisabledEDCPeriod)){
      if(isVerifyEDCPeriod && !checkAllowedEDC){
        isDisabledEDC = true
      } 
    }
    return isDisabledEDC
  } catch (error) {
    console.error('Error in verify disabled EDC payment method:', error)
    return isDisabledEDC
  }
}

export const verifyDisabledLegacyTab = (startDisabledLegacyTab) => {
  let isDisabledTab = false
  try {
    isDisabledTab = isAfterDate(startDisabledLegacyTab)  
    return isDisabledTab
  } catch (error) {
    console.error('Error in verify disabled legacy tab:', error)
    return isDisabledTab
  }
}

export const formatQRPaymentRef2 = (paymentType) => {
  const channelCode = '81'
  const formatPaymentType = paymentType.slice(-1); //extracts the last character from paymentType
  const formatDateTime = 'DDMMYYYYHHmmssSSS'
  const currentDate = new Date()  
  return `${channelCode}${formatPaymentType}${formatDate(currentDate,formatDateTime)}`
}