import React from 'react'
import { Icons, Button, Dialog } from 'ktaxa-ui-components/index'
import { Theme } from 'components/index'
import styled from '@emotion/styled'

const PreviewImage = styled.img`
  width: 100%;
  height: calc(100vh - 130px);
  object-fit: contain;
  background-color: ${Theme.color.common.black};
  border: 1px solid ${Theme.color.common.grayLighter};
`

const PreviewIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 130px);
  object-fit: contain;
  background-color: ${Theme.color.common.black};
  border: 1px solid ${Theme.color.common.grayLighter};
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding: 15px;
  border: 1px solid ${Theme.color.common.grayLighter};
`

const HeaderTitle = styled.div`
  font-weight: bold;
  color: ${Theme.color.type.primary};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  button: {
    margin-right: 0px;
  }
  .set-right {
    margin-left: auto;
  }
`

const styleOptions = {
  box: {
    maxWidth: '100%',
    margin: '0px',
    border: 'none',
    borderRadius: '0px',
    top: '0px',
  },
  body: {
    minHeight: '100vh',
  },
  display: {
    fullScreen: true,
    overFlowY: 'none',
  },
  bodyContent: {
    padding: '0px',
  },
  idNameContent: 'previewImage',
}

const PreviewDialog = ({ title, show, toggle, imageSrc, alt, onRemove, viewOnly, isPDF }) => {
  console.log(`PreviewDialog`)
  return (
    <Dialog
      show={show}
      onClose={toggle}
      styleOptions={{ ...styleOptions, idNameContent: `${styleOptions.idNameContent}` }}
      fullScreen={true}
    >
      <HeaderContainer>
        <div onClick={toggle}>
          <Icons type="BACK" />
        </div>
        <HeaderTitle>{title}</HeaderTitle>
        <div />
      </HeaderContainer>
      {!isPDF ? <PreviewImage src={imageSrc} alt={alt} /> : <PreviewIframe src={imageSrc} title={alt} />}
      <ButtonContainer>
        {!viewOnly && (
          <Button type="secondary" onClick={onRemove}>
            ลบ
          </Button>
        )}
        <Button className={viewOnly ? 'set-right' : ''} type="primary" onClick={toggle}>
          ตกลง
        </Button>
      </ButtonContainer>
    </Dialog>
  )
}

export default PreviewDialog
