import React from 'react'
import styled from '@emotion/styled'
import { color } from 'components/theme'

const Container = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  background-color: ${color.common.white};
  box-shadow: 0 0 0 1px ${color.element.border};
  padding: 15px 10px 15px 15px;
  font-size: 0.8rem;
  height: 165px;

  @media (min-width: 768px) {
    font-size: 1rem;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    background-color: ${color.status.pending};
    content: '';
  }
`
const Content = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 60px;
`

const BlankCard = () => {
  return (
    <Container>
      <Content>ไม่พบข้อมูล</Content>
    </Container>
  )
}

export { BlankCard }
