import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'
import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color } from 'components/theme'
import { format, date } from 'utils/date'
import staticContent from '../static-content'
import { AppContext } from 'contexts/app'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Message = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`

type DynamicDialogProps = {
  apiResult: string | null
}

const CcpaRegistrationResultDialog = (props: any) => {
  const { show, ccpaPaymentStatus, history, dialogType, setIsShowGenLinkDialogResult } = props
  const { ccpaInfo } = useContext(AppContext)

  const linkExpirationDate = date(ccpaInfo?.expiredDate).toISOString()
  const displayedDate = format(linkExpirationDate, 'DD/MM/YYYY')
  const displayedTime = format(linkExpirationDate, 'HH.mm')

  let message = ''
  switch (dialogType) {
    case 'HTTP_ERROR':
      message = staticContent.genLinkResultDialogContents.HTTP_ERROR.message
      break
    case 'EXPIRED':
      message = staticContent.genLinkResultDialogContents.EXPIRED.message
        .replace('xx/xx/xxxx', displayedDate)
        .replace('xx.xx', displayedTime)
      break
    case 'PENDING':
      message = staticContent.genLinkResultDialogContents.PENDING.message
      break
    default:
      message = ''
  }

  const handleConfirmButton = (event) => {
    //! case null ---> HTTP_ERROR
    if (!ccpaPaymentStatus) {
      setIsShowGenLinkDialogResult(false)
      return
    }

    switch (ccpaPaymentStatus.linkStatus) {
      case 'EXPIRED':
      case 'PENDING':
        setIsShowGenLinkDialogResult(false)
        break
      default:
        history.push('/ccpa')
    }
  }

  return (
    <DialogContainer>
      <Dialog centered modal show={show}>
        <Title>ผลการตรวจสอบการสมัครชำระเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต</Title>
        <Divider />
        <Content>
          <Message>{message}</Message>
        </Content>
        <Footer>
          <Button type={Button.Type.Primary} onClick={handleConfirmButton}>
            ยืนยัน
          </Button>
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default withRouter(CcpaRegistrationResultDialog)
