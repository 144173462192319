import { getToggles } from 'core/config'

const allowPolicyStatusCCPA = ['1', '4', '9', 'F']
const allowPolicyStatusDA = ['1']

export const validateCitizenId = (value) => {
  if (!value || typeof value !== 'string') return false
  const splitValue = value
    .split('')
    .filter((char) => char !== ' ' && char !== '_')
    .join('')
  if (splitValue.length !== 13) return false

  if (getToggles().ALLOW_DEV_MODE) {
    return true
  }

  let sum = 0
  for (let i = 0; i < 12; i++) {
    sum += parseFloat(splitValue.charAt(i)) * (13 - i)
  }
  const result = (11 - (sum % 11)) % 10 !== parseFloat(splitValue.charAt(12)) ? false : true
  return result
}

export default {
  validateCitizenId,
}

export const validatePolicyCCPA = (policyStatus, productCode, planCodeList) => {
  if (allowPolicyStatusCCPA.includes(policyStatus) && planCodeList.some((item) => item.planCode === productCode)) {
    return true
  }
  return false
}

export const validatePolicyDA = (policyStatus, productCode, planCodeList) => {
  if (allowPolicyStatusDA.includes(policyStatus)) {
  // if (allowPolicyStatusDA.includes(policyStatus) && planCodeList.some((item) => item.planCode === productCode)) {
    return true
  }
  return false
}
