import React from 'react'
import styled from '@emotion/styled'

import { Tabs, Icons } from 'ktaxa-ui-components'

const StyledTabs = styled.div`
  background: #fff;
  z-index: 3;
  margin-bottom: 0;
  position: relative;

  & > div {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  li {
    flex: unset;
    font-size: 16px;
    font-size: 19px;

    & > i {
      display: block;
      height: 25px;
      margin: 0 auto 5px;
    }

    & > span {
      display: block;
    }
  }
`

const CustomTabs = (props) => {
  const { active, onChange, items } = props

  return (
    <StyledTabs>
      <Tabs active={active} onChange={onChange}>
        {items.map((item) => (
          <Tabs.Item key={item.id} id={item.id} disabled={item.id !== active}>
            <Icons type={item.iconId} />
            <span>{item.title}</span>
          </Tabs.Item>
        ))}
      </Tabs>
    </StyledTabs>
  )
}

export default CustomTabs
