import { getConfig } from 'core/config'
import { convertBlobToBase64 } from 'utils/file'

const buildURL = (url) => `${[getConfig().api.baseURL, getConfig().api.version].join('/')}${url}`

type RequestOptions = {
  method: string
  url: string
  body?: any
  token?: string
}

type FetchOption = {
  method: string
  headers: any
  body?: any
}
// Add Flex URI
export class Connect implements IConnect {
  async doRequest(options: RequestOptions, file?: boolean): Promise<any> {
    const token = localStorage.getItem('ecbr:token')
    const fetchOptions: FetchOption = {
      method: options.method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: options.token ? options.token : `Bearer ${token}`,
      },
    }
    if (['POST', 'PUT'].includes(options.method)) {
      fetchOptions.body = options.body
    }
    try {
      const res = await fetch(options.url, fetchOptions)
      if (file) {
        const data = await res.blob()
        return await convertBlobToBase64(data)
      }

      if (!res.ok) {
        throw Error('Problem with API')
      }

      return await res.json()
    } catch (err) {
      console.error('post connect', err)
      throw err
    }
  }

  async post(endpoint: string, data: any, doRequestFileEnable?: boolean): Promise<any> {
    return await this.doRequest(
      {
        url: buildURL(endpoint),
        method: 'POST',
        body: data,
      },
      doRequestFileEnable
    )
  }

  async get(endpoint: string, params?: any): Promise<any> {
    const qs = params ? `?${new URLSearchParams(params).toString()}` : ''
    return await this.doRequest({
      url: buildURL(`${endpoint}${qs}`),
      method: 'GET',
    })
  }

  async put(endpoint: string, data): Promise<any> {
    return await this.doRequest({
      url: buildURL(endpoint),
      method: 'PUT',
      body: data,
    })
  }

  async del(endpoint: string): Promise<any> {
    return await this.doRequest({
      url: buildURL(endpoint),
      method: 'DELETE',
    })
  }
}

export interface IConnect {
  doRequest(options: RequestOptions, file?: boolean): Promise<any>
  post(endpoint: string, data: any, doRequestFileEnable?: boolean): Promise<any>
  get(endpoint: string, params?: any): Promise<any>
  put(endpoint: string, data): Promise<any>
  del(endpoint: string): Promise<any>
}
