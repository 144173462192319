import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ContentContainer, SectionContainer, HeaderContainer } from 'components/layouts'
import { UploadImage } from 'components/input'
import DatePickerComponent from 'components/input/date-picker'
import AttachmentCard from '../../components/attachment-card'
import { getConfig } from 'core/config'

import { ecbrService, paymentService } from 'services/ecbr-middleware'
import { schemaAttachmentSection } from '../../hooks/useForm'

import staticContentPage from '../../static-content'
import { PropsSectionType } from '../../type'
import { renderErrorMessageBlock } from 'components/message-blocks'

import { useHandleDataForm } from './hook'

const dateFormat = 'DD/MM/YYYY'

const AttachmentSection = (props: PropsSectionType) => {
  const { state, tabState } = props
  const { pendingList, transferInfo } = state
  const [isOverLimit, setIsOverLimit] = useState(false)
  const { limitFileSize } = getConfig()
  const { formState, control, reset, handleSubmit, getValues } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaAttachmentSection),
  })
  const { errors } = formState

  const paymentType = ecbrService.getPaymentTypeByPamentTypeCode(pendingList?.paymentType)
  const paymentMethod = ecbrService.getPaymentMethodByPaymentMethodCode(pendingList?.paymentMethod)
  const paymentMode = ecbrService.getPaymentModeByPaymentModeCode(pendingList?.paymentMode)

  const payBy = paymentService.getTransferMethodByTransferMethodCode(transferInfo?.payload?.payBy)
  const payByService = paymentService.getChanalMethodByTransferMethodCodeAndChanalMethodCode(
    transferInfo?.payload?.payBy,
    transferInfo?.payload?.payByService
  )

  useHandleDataForm({ ...props, reset, handleSubmit })
  const ActiveCondition = tabState === staticContentPage.tabsProgress[3].id
  if (!ActiveCondition) return null
  return (
    <ContentContainer>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
          }
        }}
      >
        <h1>แนบเอกสารและส่งข้อมูล</h1>
        <SectionContainer>
          <AttachmentCard
            name={`${pendingList?.insuredName} ${pendingList?.insuredSurName}`}
            amount={pendingList?.paymentAmount}
            cbrNumber={pendingList?.cbrNumber}
            personalId={pendingList?.personalId}
            paymentType={paymentType}
            paymentMethod={paymentMethod}
            paymentMode={paymentMode}
            policyNumber={pendingList?.policyNumber}
            payBy={payBy}
            payByService={payByService}
          />
        </SectionContainer>
        <SectionContainer>
          <HeaderContainer>
            <h1>วันที่ชำระเงิน</h1> <span>[วันที่นำเข้าบัญชีบริษัท]</span>{' '}
          </HeaderContainer>
          <Controller
            name={'object.payDate'}
            control={control}
            render={({ field }) => (
              <DatePickerComponent
                {...field}
                minDate={new Date().toISOString()}
                id="payDate"
                dateFormat={dateFormat}
                onChange={(v) => field.onChange(v)}
                readOnly
              />
            )}
          />
          {errors?.object?.['payDate'] && renderErrorMessageBlock(errors?.object?.['payDate']?.message)}
        </SectionContainer>
        <SectionContainer>
          <HeaderContainer>
            <h1>แนบสำเนาใบนำฝาก</h1>
          </HeaderContainer>
          <Controller
            name={'object.slipImage'}
            control={control}
            render={({ field }) => (
              <UploadImage
                label={'แนบสำเนาใบนำฝาก'}
                onSelected={(img) => field.onChange(img)}
                value={getValues('object.slipImage')}
                setIsOverLimit={setIsOverLimit}
              />
            )}
          />
          {errors?.object?.['slipImage'] && renderErrorMessageBlock(errors?.object?.['slipImage']?.message)}
          {isOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
        </SectionContainer>
      </form>
    </ContentContainer>
  )
}

export default AttachmentSection
