import React from 'react'
import { List, Cards } from 'components'
import { Button } from 'ktaxa-ui-components/index'
import { clearFormat, getPayerName, getPayerPersonalId, getPayerRelations } from 'utils/string-helper'
import _isEmpty from 'lodash/isEmpty'
import { getToggles } from 'core/config'

import { ContentContainer, SectionContainer } from 'components/layouts'
import { renderWarningMessageBlock } from 'components/message-blocks'
import SubmitSection from './submit'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import staticContentPage from '../static-content'
import { ecbrService } from 'services/ecbr-middleware'
import { PropsSectionType } from '../type'

const MoneyReceiptSection = (props: PropsSectionType) => {
  const { tabState, state, setState, setTabState } = props
  const ActiveCondition = tabState === staticContentPage.tabsProgress[1].id
  if (!ActiveCondition) return null

  const infoData = {
    ...state.info?.payload,
  }

  const paymentType = ecbrService.getPaymentTypeByPamentTypeCode(infoData.paymentType)
  const paymentMethod = ecbrService.getPaymentMethodByPaymentMethodCode(infoData.paymentMethod)
  const paymentMode = ecbrService.getPaymentModeByPaymentModeCode(infoData.paymentMode)
  const isEnabledSystemization = getToggles().ENABLE_ECBRSYS_ECBR && !_isEmpty(infoData.payerType)
  const isResumePayment = !_isEmpty(infoData.receiptId)

  const IntAmount = !_isEmpty(infoData) ? +clearFormat(infoData?.paymentAmount, ',') : 0
  const { Description } = List
  const { Label: DT, Value: DD } = Description
  const { NewECBRSummary } = Cards

  const onSubmit = (data) => {
    setState({
      ...state,
      paymentMethod,
      paymentMode,
      paymentType,
      [staticContentPage.tabsProgress[1].id]: {
        payload: data,
      },
    })
    setTabState(staticContentPage.tabsProgress[2].id)
  }

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <Button onClick={() => setTabState(staticContentPage.tabsProgress[0].id)} type="secondary">
          กลับ
        </Button>
      ),
      extraButton: () => <SubmitSection tabState={tabState} handleSubmit={onSubmit} state={state} />,
    }
  )

  return (
    <ContentContainer>
      <h1>{staticContentPage.moneyReceiptSection.mainTitle}</h1>
      <SectionContainer>
        <NewECBRSummary
          insured={{
            firstName: infoData.insuredName,
            lastName: infoData.insuredSurName,
          }}
          amount={IntAmount}
        >
          <Description>
            <DT>{staticContentPage.moneyReceiptSection.receipt.paymentCategory}</DT>
            <DD>{paymentType}</DD>
          </Description>

          <Description>
            <DT>{staticContentPage.moneyReceiptSection.receipt.paymentScheduleMethod}</DT>
            <DD>{paymentMode}</DD>
          </Description>
          <Description>
            <DT>{staticContentPage.moneyReceiptSection.receipt.paymentMethod}</DT>
            <DD>{paymentMethod}</DD>
          </Description>

          <Description>
            <DT>{staticContentPage.moneyReceiptSection.receipt.policyNumber}</DT>
            <DD>{infoData.policyNo}</DD>
          </Description>

          <Description>
            <DT>{staticContentPage.moneyReceiptSection.receipt.productName}</DT>
            <DD>{infoData.productCode}</DD>
          </Description>

          <Description>
            <DT>{staticContentPage.moneyReceiptSection.receipt.passportNumber}</DT>
            <DD>{infoData.personalId}</DD>
          </Description>

          <Description>
            <DT>{staticContentPage.moneyReceiptSection.receipt.phoneNumber}</DT>
            <DD>{infoData.insureMobile}</DD>
          </Description>

          <Description>
            <DT>{staticContentPage.moneyReceiptSection.receipt.email}</DT>
            <DD>{infoData.insureEmail}</DD>
          </Description>
          {isEnabledSystemization && (
            <>
              <Description>
                <DT>{staticContentPage.moneyReceiptSection.receipt.payerName}</DT>
                <DD>{getPayerName(infoData)}</DD>
              </Description>

              <Description>
                <DT>{staticContentPage.moneyReceiptSection.receipt.payerPersonalId}</DT>
                <DD>{getPayerPersonalId(infoData)}</DD>
              </Description>

              <Description>
                <DT>{staticContentPage.moneyReceiptSection.receipt.payerRelations}</DT>
                <DD>{getPayerRelations(infoData, isResumePayment)}</DD>
              </Description>
            </>
          )}
        </NewECBRSummary>
        {renderWarningMessageBlock(
          'นายหน้าประกันชีวิต ผู้เสนอขายจะต้องส่งคำร้องขอเอาประกันภัย พร้อมเบี้ยประกันภัย ที่ได้รับจากลูกค้าไปยังบริษัทในโอกาสแรกที่กระทำได้แต่ไม่เกินวันทำการถัดไป'
        )}
      </SectionContainer>
    </ContentContainer>
  )
}

export default MoneyReceiptSection
