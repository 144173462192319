import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { getToggles } from '../core/config'

export function PrivateRoute({ component: Component, ...rest }) {
  if (getToggles().ALLOW_GUEST) {
    return <Route {...rest} render={(props) => <Component {...props} />} />
  }

  const [keycloak] = useKeycloak()
  return (
    <Route
      {...rest}
      render={(props) =>
        !keycloak.authenticated ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}
