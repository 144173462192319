/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx,css } from '@emotion/react'

import styles from './button-styles'

const BUTTON_TYPES = {
  Primary: 'primary',
  Secondary: 'secondary',
  Ghost: 'secondary',
  Button: 'button',
  Link: 'button',
}

const BUTTON_SIZES = {
  ExtraSmall: 'xs',
  Small: 's',
  Medium: 'm',
  Large: 'l',
  CustomWidth: 'custom'
}

const propTypes = {
  /**
   * Elements to be rendered as children of this component.
   */
  children: PropTypes.node,
  /**
   * One or more class names to be added to the root element of this component, i.e. `"class-foo class-bar"`.
   */
  className: PropTypes.string,
  /**
   * Button type:
   *
   * * `primary`
   * * `secondary`
   * * `button`
   */
  type: PropTypes.string,
  /**
   * Button size:
   *
   * * `s`
   * * `m`
   * * `l`
   */
  size: PropTypes.string,
  /**
   * Disables the button if set to true. Disabled button won't trigger the `onClick`.
   */
  disabled: PropTypes.bool,
  /**
   * A callback function that is triggered when button clicked.
   *
   * `e => {}`
   */
  onClick: PropTypes.func,
  /**
   * Option to Set Padding Left and Right
   */
  paddingSizeLeft: PropTypes.string,
  paddingSizeRight: PropTypes.string
}

const defaultProps = {
  children: 'Button',
  className: '',
  type: BUTTON_TYPES.Button,
  size: BUTTON_SIZES.M,
  disabled: false,
  onClick() {},
}

/**
 * To trigger an operation.
 */
function Button(props) {
  const { children, className, type, size, disabled, onClick } = props

  const doOnClick = (e) => {
    e.preventDefault()
    if (!disabled) {
      onClick(e)
    }
  }

  const buttonProps = {}
  const buttonStyles = [styles.button, styles.type.button]

  if (
    Object.values(BUTTON_TYPES)
      .filter((t) => t !== BUTTON_TYPES.Button)
      .includes(type)
  )
    buttonStyles.push(styles.type[type])

  if (Object.values(BUTTON_SIZES).includes(size)) buttonStyles.push(styles.size[size])
  if (className) buttonProps.className = className
  if (disabled) buttonProps.disabled = true

  if (props.paddingSizeLeft && props.paddingSizeRight) {
    let customeCss = css`
    padding-left: ${props.paddingSizeLeft};
    padding-right: ${props.paddingSizeRight};
    `
    buttonStyles.push(customeCss)
  }

  return (
    <button {...buttonProps} css={buttonStyles} onClick={doOnClick}>
      <span css={styles.label}>{children}</span>
    </button>
  )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps
Button.Type = { ...BUTTON_TYPES }
Button.Size = { ...BUTTON_SIZES }

export default Button
