import React from 'react'
import { KeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'

import Route from './routes'
import { getConfig, getToggles } from 'core/config'

const App = () => {
  return <Route />
}

const AuthContainer = () => {
  if (getToggles().ALLOW_GUEST) {
    return <App />
  }

  const LOCAL_STORAGE = {
    TOKEN: 'ecbr:token',
    REFRESH_TOKEN: 'ecbr:refresh-token',
  }
  const appLocalStorage = {
    get: (key) => localStorage.getItem(key),
    set: (key, value) => localStorage.setItem(key, value),
    remove: (key) => localStorage.removeItem(key),
  }
  const appToken = appLocalStorage.get(LOCAL_STORAGE.TOKEN)
  const appRefreshToken = appLocalStorage.get(LOCAL_STORAGE.REFRESH_TOKEN)

  const keycloak = new Keycloak({
    realm: getConfig().auth.keycloak.realm,
    url: getConfig().auth.keycloak.url,
    clientId: getConfig().auth.keycloak.clientId,
  })


  const keycloakProviderInitConfig = {
    onLoad: 'login-required',
    promiseType: 'native',
    token: appToken,
    refreshToken: appRefreshToken,
  }

  const clearLogin = () => {
    appLocalStorage.remove(LOCAL_STORAGE.TOKEN)
    appLocalStorage.remove(LOCAL_STORAGE.REFRESH_TOKEN)
    keycloak.logout()
  }

  const onKeycloakEvent = (event, error) => {
    if (event === 'onReady' && appLocalStorage.get(LOCAL_STORAGE.TOKEN)) {
      keycloak.loadUserInfo().catch(() => {
        clearLogin()
      })
    }
  }

  const onKeycloakTokens = (tokens) => {
    const { token, refreshToken } = tokens
    if (token && refreshToken) {
      appLocalStorage.set(LOCAL_STORAGE.TOKEN, token)
      appLocalStorage.set(LOCAL_STORAGE.REFRESH_TOKEN, refreshToken)
    } else {
      clearLogin()
    }
  }

  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <Route clearLogin={clearLogin} />
    </KeycloakProvider>
  )
}


export { App, AuthContainer }

export default AuthContainer
