import React from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color, customIcon } from 'components/theme'
import { format, date } from 'utils/date'
import { resetPaymentEcbr } from '../hooks/useService'
import staticContentPage from '../static-content'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 0;
    padding-left: 0;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Icon = styled.div`
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background-color: ${color.common.lightGray};
  mask-image: url(${customIcon('closeIcon')});
  mask-size: cover;
  mask-repeat: no-repeat;
`
const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const SubTitle = styled(Title)`
  margin-top: 24px;
  margin-bottom: 0;
  font-size: 1.1rem;
`
const Message = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`

const CreatePaymentLinkDialog = (props: any) => {
  const { show, onToggle, isSuccess, createPaymentLinkData, setState, setTabState, state, history } = props
  const linkExpirationDate = date(createPaymentLinkData?.expiredDate).toISOString()
  const displayedDate = format(linkExpirationDate, 'DD/MM/YYYY')
  const displayedTime = format(linkExpirationDate, 'HH.mm')

  const onChangePaymentMethod = async () => {
    await onToggle()
    await resetPaymentEcbr(state)
    setState((prevState) => ({
      ...prevState,
      process: {
        ...prevState.process,
        payload: {
          ...prevState.process.payload,
          data: { ...prevState.process.payload.data, paymentLink: null, paymentMethod: '' },
        },
      },
      isChangePaymentMethod: true,
    }))
    setTabState(staticContentPage.tabsProgress[0].id)
  }

  const confirmCreateLinkSuccusee = async () => {
    await setState({
      ...state,
      createPaymentLinkData: createPaymentLinkData,
      isCheckPaymentStatus: true,
    })
    await onToggle()
  }

  return (
    <DialogContainer>
      <Dialog centered modal show={show} onClose={onToggle}>
        <Icon onClick={onToggle} />
        <Title>{isSuccess ? 'ยืนยันการสร้างลิงก์' : 'การสร้างลิงก์ไม่สำเร็จ'}</Title>
        <Divider />
        {isSuccess ? (
          <Content>
            <Message>บริษัทฯ ได้สร้างลิงก์เพื่อชำระเบี้ยประกันให้กับผู้เอาประกันเรียบร้อยแล้ว</Message>
            <Message>กรุณากด ยืนยัน เพื่อไปยังขั้นตอนถัดไป</Message>
            <SubTitle>
              ลิงก์ในการสมัครของท่านจะหมดอายุภายในวันที่ {displayedDate} เวลา {displayedTime} น.
            </SubTitle>
          </Content>
        ) : (
          <Content>
            <Message>ระบบไม่สามารถดำเนินการสร้างลิงก์ได้</Message>
            <Message>ท่านต้องการเปลี่ยนวิธีการชำระเงินหรือไม่</Message>
          </Content>
        )}
        <Footer>
          {isSuccess ? (
            <Button type={Button.Type.Primary} onClick={confirmCreateLinkSuccusee}>
              ยืนยัน
            </Button>
          ) : (
            <>
              <Button type={Button.Type.Secondary} onClick={onToggle}>
                ไม่ต้องการ
              </Button>
              <Button type={Button.Type.Primary} onClick={onChangePaymentMethod}>
                ต้องการ
              </Button>
            </>
          )}
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default withRouter(CreatePaymentLinkDialog)
