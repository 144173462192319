import React from 'react'
import styled from '@emotion/styled'

import MessageBlock from 'ktaxa-ui-components/components/message-block'
import { customIcon } from 'components/theme'

const InfoBlock = styled.div`
  position: relative;
  padding-left: 26px;
  font-size: 18px;
  white-space: pre-wrap;
  // min-width: 24.5rem;

  &::before {
    position: absolute;
    top: 0;
    left: -2px;
    display: block;
    width: 20px;
    height: 20px;
    background-color: #00008f;
    content: '';
    mask-image: url(${customIcon('messageInfo')});
    mask-size: cover;
    mask-repeat: no-repeat;
  }
`
const ErrorBlock = styled(InfoBlock)`
  &::before {
    background-color: #e53935;
    mask-image: url(${customIcon('messageError')});
  }
`
const WarningBlock = styled(InfoBlock)`
  color: #cc9900;
  &::before {
    background-color: #ffbf02;
    mask-image: url(${customIcon('messageWarn')});
  }
`

export const renderErrorMessageBlock = (message) => {
  return message ? (
    <MessageBlock type={MessageBlock.Type.Error}>
      <ErrorBlock>{message?.message || message}</ErrorBlock>
    </MessageBlock>
  ) : null
}

export const renderInfoMessageBlock = (message) =>
  message ? (
    <MessageBlock type={MessageBlock.Type.Info}>
      <InfoBlock>{message}</InfoBlock>
    </MessageBlock>
  ) : null

export const renderInfoMessageBlockForSpecialWordBreak = (message) =>
  message ? (
    <MessageBlock type={MessageBlock.Type.Info}>
      <InfoBlock dangerouslySetInnerHTML={{ __html: message }} />
    </MessageBlock>
  ) : null

export const renderWarningMessageBlock = (message) =>
  message ? (
    <MessageBlock type={MessageBlock.Type.Warning}>
      <WarningBlock>{message}</WarningBlock>
    </MessageBlock>
  ) : null
