import button from './components/button'
import dialog from './components/dialog'
import icons from './components/icons'
import tabs from './components/tabs'
import input from './components/input'
import radio from './components/radio'
import messageBlock from './components/message-block'
import checkbox from './components/checkbox'
import theme from './components/utils/theme'

export const Button = button
export const Dialog = dialog
export const Icons = icons
export const Input = input
export const Tabs = tabs
export const Radio = radio
export const MessageBlock = messageBlock
export const Checkbox = checkbox
export const Theme = theme

export default {
  Button,
  Dialog,
  Icons,
  Input,
  Tabs,
  Radio,
  MessageBlock,
  Checkbox,
  Theme,
}
