import React from 'react'
import { getIcon } from '../../../ktaxa-ui-components/components/icons/normalIcon'
import styled from '@emotion/styled'

const ButtonContainer = styled.button`
  padding: 0.53rem;
  margin-left: 1ch;
  width: 2.95rem;
  color: ${(props) => (props.disabled ? '#999' : '#fff')};
  background-color: ${(props) => (props.disabled ? '#fff' : '#2425aa')};
`

type ButtonWithIconProps = {
  disabled?: boolean
  icon?: string
  value?: string
  onClick?: any
}

const ButtonWithIcon = (props: ButtonWithIconProps) => {
  const { icon, disabled, value, onClick } = props
  return (
    <ButtonContainer onClick={onClick} disabled={disabled}>
      {icon ? getIcon(icon) : value ? value : null}
    </ButtonContainer>
  )
}
export default ButtonWithIcon
