import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { renderInfoMessageBlock } from 'components/message-blocks'
import { Button, Icons } from 'ktaxa-ui-components/index'
import staticContent from '../static-content'
import styled from '@emotion/styled'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import staticContentPage from '../static-content'
import CcpaRegistrationResultDialog from '../components/ccpa-registration-result-dialog'
import CcpaBackButtonDialog from '../components/ccpa-back-button-dialog'
import dayjs from 'dayjs'
import CreateCcpaLinkDialog from '../components/create-ccpa-link-dialog'
import { checkCcpaPaymentStatus, createCcpaPaymentLink } from '../hooks/useService'
import { CcpaPaymentStatusResponseType, CreateCcpaLinkResultType } from 'types/plan-code'
import { AppContext } from 'contexts/app'
import BackButtonCheckStatusPage from '../components/back-btn-check-status-page'
import ConfirmButtonCheckStatusPage from '../components/confirm-btn-check-status-page'
import LoadingModal from '../components/load-data-dialog'

type Props = {
  state: any
  setState: Dispatch<SetStateAction<any>>
  tabState: string
  setTabState: Dispatch<SetStateAction<string>>
  isSuccessCcpaStatus: boolean
  setIsSuccessCcpaStatus: Dispatch<SetStateAction<boolean>>
  setIsNeedToReload: Dispatch<SetStateAction<boolean>>
}

const SubTitle = styled.label`
  display: block;
  margin: 4px 0 12px 0;
  font-weight: bold;
`

const ButtonSectionContainer = styled(SectionContainer)`
  display: grid;
  width: fit-content;
  gap: 16px;
  margin: auto;
  margin-bottom: 10px;
`

const TitleAndLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const CheckPaymentStatusSection = (props: Props) => {
  const { tabState, state, setState, setTabState, isSuccessCcpaStatus, setIsSuccessCcpaStatus, setIsNeedToReload } =
    props
  const [, setCheckPaymentStatusData] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [, setShowDialog] = useState(false)
  const ActiveCondition = tabState === staticContentPage.tabsProgress[2].id

  const [dialogType, setDialogType] = useState<string>('')
  const [ccpaPaymentStatus, setCcpaPaymentStatus] = useState<CcpaPaymentStatusResponseType | null>(null)
  const [isShowGenLinkDialogResult, setIsShowGenLinkDialogResult] = useState(false)
  const [isShowBackDialog, setIsShowBackDialog] = useState(false)
  const [showCcpaLinkDialog, setIsShowCcpaLinkDialog] = useState(false)
  const [isCreateLinkSuccess, setIsCreateLinkSuccess] = useState(false)
  const [createCcpaLinkResult, setCreateCcpaLinkResult] = useState<CreateCcpaLinkResultType | null>(null)
  const [isLoadingModalRecreateLink, setIsLoadingModalRecreateLink] = useState(false)

  const { ccpaInfo, setCcpaInfo, setIsDisabledBackButton, setIsDisabledConfirmButton } = useContext(AppContext)

  const handleCheckPaymentStatus = async () => {
    setLoading(true)
    const response = await checkCcpaPaymentStatus(ccpaInfo.linkToken)
    const data = response?.data || null
    const statuses = ['SUCCESS', 'IN PROGRESS', 'FAIL']

    if (data) {
      if (statuses.includes(data?.linkStatus)) {
        //! add loading modal
        setIsSuccessCcpaStatus(true)
        setCcpaPaymentStatus(data)
        setIsDisabledBackButton(true)
        setIsDisabledConfirmButton(false)
      } else {
        setIsShowGenLinkDialogResult(true)
        setDialogType(data?.linkStatus)
        setCcpaPaymentStatus(data)
      }
    } else {
      setIsShowGenLinkDialogResult(true)
      setDialogType('HTTP_ERROR')
    }
    setCheckPaymentStatusData(data)
    setLoading(false)
    setShowDialog(true)
  }

  const handleGoBack = () => {
    setTabState(staticContentPage.tabsProgress[1].id)
    setIsShowBackDialog(true)
  }

  const handleOnReCreateLink = async () => {
    const payload = {
      policyNo: ccpaInfo.policyNo,
      citizenId: ccpaInfo.citizenId,
      planCode: ccpaInfo.planCode,
      insuredFirstName: ccpaInfo.insuredFirstName,
      insuredLastName: ccpaInfo.insuredLastName,
      email: ccpaInfo.email,
      phone: ccpaInfo.phone,
      channelPrefix: ccpaInfo.channelPrefix,
      sendMethod: ccpaInfo.sendMethod,
      policyStatus: ccpaInfo.policyStatus,
    }

    setIsLoadingModalRecreateLink(true)
    const createLinkResult = await createCcpaPaymentLink(payload)

    if (createLinkResult?.data?.messageCode === 'Create Success') {
      setTimeout(() => {
        setIsLoadingModalRecreateLink(false)
        setIsCreateLinkSuccess(true)
        setIsShowCcpaLinkDialog(true)
        setCreateCcpaLinkResult(createLinkResult.data!)
        //! update Global state after re-create link
        setCcpaInfo((prevState) => {
          return {
            ...prevState,
            linkToken: createLinkResult?.data?.linkToken,
            expiredDate: createLinkResult?.data?.expiredDate,
          }
        })
      }, 1000)
    } else {
      setTimeout(() => {
        setIsLoadingModalRecreateLink(false)
        setIsShowCcpaLinkDialog(true)
      }, 1000)
    }
  }

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraBackButton: () => <BackButtonCheckStatusPage handleSubmitData={() => setIsShowBackDialog(true)} />,
      extraButton: () => (
        <ConfirmButtonCheckStatusPage
          setTabState={setTabState}
          setState={setState}
          isSuccessCcpaStatus={isSuccessCcpaStatus}
          setIsSuccessCcpaStatus={setIsSuccessCcpaStatus}
          setIsNeedToReload={setIsNeedToReload}
        />
      ),
    }
  )

  if (!ActiveCondition) return null

  return (
    <ContentContainer>
      <h1>{staticContent?.checkStatusSection.mainTitle.title}</h1>
      <SectionContainer>
        <div
          style={{
            display: 'block',
            background: '#ffffff',
            padding: 20,
          }}
        >
          {isSuccessCcpaStatus ? (
            <>
              <SectionContainer>
                <SubTitle>{staticContent?.checkStatusSection.detail.subTitle}</SubTitle>
                <label>
                  <Icons
                    type="ECBR_SUCCESS"
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  {staticContent?.checkStatusSection.detail.sendLinkMethod} {ccpaInfo.sendMethod} :
                  {ccpaInfo.sendMethod === 'SMS' ? ccpaInfo.phone : ccpaInfo.email}
                </label>
              </SectionContainer>
              <SectionContainer>
                <SubTitle>{staticContent?.checkStatusSection.detail.result}</SubTitle>
                <label>
                  {staticContent?.checkStatusSection.detail.status(
                    dayjs(ccpaPaymentStatus?.successfullDateTime).format('DD/MM/YYYY'),
                    dayjs(ccpaPaymentStatus?.successfullDateTime).format('HH:mm'),
                    ccpaPaymentStatus?.maskedCardNo
                  )}
                </label>
                <br />
                <label>
                  {staticContent?.checkStatusSection.detail.maskedCreditCard(ccpaPaymentStatus?.maskedCardNo)}
                </label>
              </SectionContainer>
            </>
          ) : (
            <SectionContainer>
              <TitleAndLabelContainer>
                <SubTitle>{staticContent?.checkStatusSection.detail.subTitle}</SubTitle>
                <label>
                  <Icons
                    type="ECBR_SUCCESS"
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  {staticContent?.checkStatusSection.detail.sendLinkMethod} {ccpaInfo.sendMethod} :{' '}
                  {ccpaInfo.sendMethod === 'SMS' ? ccpaInfo.phone : ccpaInfo.email}
                </label>
              </TitleAndLabelContainer>
            </SectionContainer>
          )}
          {!isSuccessCcpaStatus ? (
            <ButtonSectionContainer>
              <Button disabled={loading} onClick={handleCheckPaymentStatus} type={Button.Type.Primary}>
                {staticContent?.checkStatusSection.detail.buttonCheckStatus}
              </Button>
              <Button
                onClick={async () => {
                  //! check status before re-create link
                  //! if status either SUCCESS or IN PROGRESS,
                  //! no need to re-create link
                  const response = await checkCcpaPaymentStatus(ccpaInfo.linkToken)
                  const data = response?.data
                  const statuses = ['SUCCESS', 'IN PROGRESS']

                  if (data) {
                    if (statuses.includes(data.linkStatus)) {
                      setIsSuccessCcpaStatus(true)
                      setCcpaPaymentStatus(data)
                      setIsDisabledBackButton(true)
                      setIsDisabledConfirmButton(false)
                      return
                    }
                  }

                  await handleOnReCreateLink()
                }}
                type={Button.Type.Secondary}
              >
                {staticContent?.checkStatusSection.detail.buttonCreateLink}
              </Button>
            </ButtonSectionContainer>
          ) : null}
          <SectionContainer>
            {isSuccessCcpaStatus
              ? //TODO: need to retrieve from tab 2
                renderInfoMessageBlock(staticContent?.checkStatusSection.detail.success)
              : renderInfoMessageBlock(
                  staticContent?.checkStatusSection.detail.info(
                    dayjs(ccpaInfo?.expiredDate).format('DD/MM/YYYY'),
                    dayjs(ccpaInfo?.expiredDate).format('HH:mm')
                  )
                )}
          </SectionContainer>
        </div>
      </SectionContainer>
      <CcpaRegistrationResultDialog
        show={isShowGenLinkDialogResult}
        setIsShowGenLinkDialogResult={setIsShowGenLinkDialogResult}
        dialogType={dialogType}
        ccpaPaymentStatus={ccpaPaymentStatus}
      ></CcpaRegistrationResultDialog>
      <CcpaBackButtonDialog
        show={isShowBackDialog}
        onClick={handleGoBack}
        setIsShowBackDialog={setIsShowBackDialog}
        setTabState={setTabState}
      ></CcpaBackButtonDialog>
      <CreateCcpaLinkDialog
        show={showCcpaLinkDialog}
        createCCPALinkData={createCcpaLinkResult}
        isSuccess={isCreateLinkSuccess}
        setIsShowCcpaLinkDialog={setIsShowCcpaLinkDialog}
        tabState={tabState}
        onCreateLink={handleOnReCreateLink}
      ></CreateCcpaLinkDialog>
      <LoadingModal show={isLoadingModalRecreateLink} />
    </ContentContainer>
  )
}

export default CheckPaymentStatusSection
