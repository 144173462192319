import { linkPaymentMethod, personalIdType } from 'domain/entities/ecbr'

export const UPLOAD_DOC_TYPES = {
  RELATIONSHIP: 'relationship',
  BOOKBANK: 'bookbank',
}

export const UPDATE_TYPES = {
  UPDATE: 'UPDATE',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  SUBMIT: 'SUBMIT',
  SUCCESS: 'SUCCESS',
  BYPASS: 'BYPASS'
}

export const DA_TYPE = {
  ONLINE: 'ONLINE',
  FORM: 'FORM',
}

export const DA_STATUS = {
  CREATE: 'Create',
  PENDING: 'Pending',
  BYPASS: 'Bypass',
  SUCCESS: 'Success',
  REJECT: 'Reject'
}

export const BANKS = {
  KTB: {
    code: '006',
    collection: 'D',
    text: 'ธนาคารกรุงไทย',
    key: 'KTB',
  },
  KBANK: {
    code: '004',
    collection: 'B',
    text: 'ธนาคารกสิกรไทย',
    key: 'KBANK',
  },
  SCB: {
    code: '014',
    collection: 'A',
    text: 'ธนาคารไทยพาณิชย์',
    key: 'SCB',
  },
  BKB: {
    code: '002',
    collection: 'C',
    text: 'ธนาคารกรุงเทพ',
    key: 'BKB',
  },
  BAY: {
    code: '025',
    collection: 'H',
    text: 'ธนาคารกรุงศรีอยุธยา',
    key: 'BAY',
  },
}

export const RELATIONS = {
  SELF: {
    text: 'ตัวเอง',
    value: 'self',
  },
  PAYER: {
    text: 'ผู้ชำระเบี้ย',
    value: 'payor',
  },
  OTHERS: {
    text: 'อื่นๆ',
    value: 'others',
  },
}

export const CHECK_ACCOUNT_STATUS = {
  SUCCESS: 'BANK_ACCOUNT_VALID',
  FAIL: 'BANK_ACCOUNT_INVALID',
  TECH_ERR: 'CHECK_ACCOUNT_SYSTEM_ERROR',
  NOT_VERIFIED: 'Not verified'
}

export const REGISTER_STATUS = {
  EDA_DUPLICATE: 'EDA_DUPLICATE',
  SEND_REQUEST_SUCCESS: 'SEND_REQUEST_SUCCESS',
  NOT_FIND_RECORD: 'NOT_FIND_RECORD',
  // FOUND_SUCCESS_REC: 'FOUND_SUCCESS_REC',  // same as duplicate
  NO_STATUS: 'NO_STATUS',
  EDA_FAIL: 'EDA_FAIL',
  EDA_SUCCESS: 'EDA_SUCCESS',
  EDA_SUCCESS_MANUAL: 'EDA_SUCCESS_MANUAL',
  TECH_ISSUE: 'TECH_ISSUE',
  CHECK_EDA_REJECT: 'CHECK_EDA_REJECT',
}

export default {
  tabsProgress: [
    {
      iconId: 'ECBR_PAYMENT_VERIFY',
      id: 'verify',
      title: 'ตรวจสอบกรมธรรม์',
      disabled: false,
    },
    {
      iconId: 'ECBR_PAYMENT_INFO_DA',
      id: 'info',
      title: 'ระบุข้อมูลการสมัคร',
      disabled: true,
    },
    {
      iconId: 'ECBR_PAYMENT_STATUS',
      id: 'status_eiei',
      title: 'สถานะการสมัคร',
      disabled: true,
    },
  ],

  regInfoSection: {
    formType: {
      label: 'วิธีการสมัครการหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
      id: 'daFormType',
      options: [
        {
          text: 'สมัครออนไลน์ (KTB Next)',
          value: DA_TYPE.ONLINE,
        },
        {
          text: 'ผ่านบัญชีธนาคาร (DA Form)',
          value: DA_TYPE.FORM,
        },
      ],
    },
    textInputs: [
      {
        id: 'bookBank',
        label: 'หน้าสมุดเงินฝาก',
        infoMessageDA: `<b>กรุณานำส่งหนังสือยินยอมหักค่าเบี้ยประกันผ่านบัญชีธนาคารฉบับจริงพร้อมลงลายมือชื่อเซ็นสดเข้ามาที่บริษัทด้วยทุกครั้ง</b> หลังจากทางบริษัทฯ ได้รับเอกสารฉบับจริงจะดำเนินการส่งไปที่ธนาคารเพื่อตรวจสอบลายเซ็น <b>ระยะเวลาในการตรวจสอบลายเซ็นอย่างน้อย 14 วันทำการ</b> นับจากวันที่ธนาคารได้รับเอกสาร จึงจะสามารถดำเนินการหักค่าเบี้ยประกันอัตโนมัติในงวดถัดไปได้`,
        infoMessageEDA:
          'กรุณาตรวจสอบว่าท่านมีแอปพลิเคชั่นของทางธนาคารที่ต้องการตัดบัญชีธนาคารอัตโนมัติออนไลน์หรือไม่ หากยังไม่มีกรุณาติดตั้งพร้อมลงทะเบียนให้เรียบร้อยก่อนทำรายการ',
        infoMessageReviewDA: `กรุณานำส่งเอกสารยินยอมการหักผ่านบัญชีพร้อมหน้าบัญชีธนาคารฉบับจริงมายังบริษัทด้วยทุกครั้ง`,
      },
      //
      {
        label: 'ชื่อผู้เอาประกัน',
        id: 'insuredName',
        type: 'text',
        defaultValue: '',
        condition: 'textWithSpaceOnly',
        maxLength: 30,
        change: false,
      },
      {
        label: 'นามสกุลผู้เอาประกัน',
        id: 'insuredSurname',
        type: 'text',
        defaultValue: '',
        condition: 'textWithSpaceOnly',
        maxLength: 30,
        change: false,
      },
      {
        label: 'ชื่อผู้ชำระเบี้ย',
        id: 'payerName',
        type: 'text',
        defaultValue: '',
        condition: 'textWithSpaceOnly',
        maxLength: 30,
        change: false,
      },
      {
        label: 'นามสกุลผู้ชำระเบี้ย',
        id: 'payerSurname',
        type: 'text',
        defaultValue: '',
        condition: 'textWithSpaceOnly',
        maxLength: 30,
        change: false,
      },
      {
        label: 'ตัดเบี้ยอัตโนมัติจากบัญชีธนาคาร',
        id: 'bankName',
        type: 'select',
        // defaultValue: {
        //   label: 'ธนาคารกรุงไทย',
        //   value: 'KTB',
        // },
        // isDisabled: true, // if form type is EDA should disable field for KTB only
        change: true,
        options: [
          {
            text: BANKS.KTB.text,
            label: BANKS.KTB.text,
            value: BANKS.KTB.key,
          },
          {
            text: BANKS.KBANK.text,
            label: BANKS.KBANK.text,
            value: BANKS.KBANK.key,
          },
          {
            text: BANKS.SCB.text,
            label: BANKS.SCB.text,
            value: BANKS.SCB.key,
          },
          {
            text: BANKS.BKB.text,
            label: BANKS.BKB.text,
            value: BANKS.BKB.key,
          },
          {
            text: BANKS.BAY.text,
            label: BANKS.BAY.text,
            value: BANKS.BAY.key,
          },
        ],
        // msgBlock: `สำหรับธนาคารไทยพาณิชย์ ธนาคารกสิกรไทย <b>กรุณาเก็บงวดแรกสองงวด</b>`,
      },
      {
        label: 'ประเภทบัญชีเงินฝาก',
        id: 'bankAccountType',
        type: 'select',
        placeholder: 'กรุณาเลือกประเภทบัญชี',
        // defaultValue: {
        //   label: 'ธนาคารกรุงไทย',
        //   value: 'KTB',
        // },
        change: true,
        options: [
          {
            text: 'ออมทรัพย์',
            label: 'ออมทรัพย์',
            value: 'ออมทรัพย์',
          },
          {
            text: 'กระแสรายวัน',
            label: 'กระแสรายวัน',
            value: 'กระแสรายวัน',
          },
        ],
      },
      {
        label: 'เลขที่บัญชี',
        id: 'bankAccountNumber',
        type: 'text',
        defaultValue: '',
        mask: '_',
        format: '###-#-#####-#',
        valueFormat: 'formattedValue',
      },
      {
        label: 'ชื่อบัญชีผู้ชำระเบี้ยประกัน',
        id: 'bankAccountName',
        type: 'text',
        defaultValue: '',
        condition: 'textWithSpaceOnly',
        maxLength: 80,
        change: false,
      },
      // {
      //   label: 'เอกสารที่ใช้แสดง',
      //   id: 'refDocument',
      //   type: 'select',
      //   // placeholder: 'กรุณาเลือกประเภทบัญชี',
      //   // defaultValue: {
      //   //   label: 'บัตรประจำตัวประชาชน',
      //   //   value: '01',
      //   // },
      //   change: true,
      //   options: [
      //     {
      //       text: 'บัตรประจำตัวประชาชน',
      //       label: 'บัตรประจำตัวประชาชน',
      //       value: '01',
      //     },
      //     {
      //       text: 'หนังสือเดินทาง',
      //       label: 'หนังสือเดินทาง',
      //       value: '02',
      //     },
      //   ],
      // },
      // {
      //   label: 'เลขบัตรประชาชนหรือหนังสือเดินทาง',
      //   id: 'personalId',
      //   subInputId: 'personalId',
      //   mask: '_',
      //   format: '# #### ##### ## #',
      //   valueFormat: 'formattedValue',
      //   options: personalIdType,
      // },
      {
        id: 'regPersonalIdType',
        subInputId: 'regPersonalId',
        label: 'เลขบัตรประชาชนหรือหนังสือเดินทาง',
        mask: '_',
        format: '# #### ##### ## #',
        valueFormat: 'formattedValue',
        options: personalIdType,
      },
      // {
      //   label: 'เลขที่บัญชี',
      //   id: 'bankAccountNumber',
      //   type: 'text',
      //   defaultValue: '',
      //   maxLength: 100,
      //   mask: '_',
      //   format: '###-#-#####-#',
      //   valueFormat: 'formattedValue',
      // },
      {
        id: 'regRelationship',
        subInputId: 'regRelationshipRemark',
        additionDocid: 'regRelationshipDoc',
        label: 'ความสัมพันธ์กับผู้เอาประกัน',
        additionDocLabel: 'เอกสารพิสูจน์ความสัมพันธ์',
        infoMessage: 'กรุณาแนบเอกสารพิสูจน์ความสัมพันธ์ เพื่อใช้ในการประกอบพิจารณา',
        options: [
          {
            text: RELATIONS.SELF.text,
            value: RELATIONS.SELF.value,
          },
          {
            text: RELATIONS.PAYER.text,
            value: RELATIONS.PAYER.value,
          },
          {
            text: RELATIONS.OTHERS.text,
            value: RELATIONS.OTHERS.value,
          },
        ],
        condition: 'textOnly',
        maxLength: 50,
        placeholder: 'กรุณาระบุความสัมพันธ์',
      },
      {
        label: 'เบอร์โทรศัพท์ผู้ชำระเบี้ยประกัน',
        id: 'regMobile',
        type: 'text',
        defaultValue: '',
        mask: '_',
        format: '### ### ####',
        valueFormat: 'value',
        change: true,
        isAllowed: ({ formattedValue }) => formattedValue.length <= 19,
      },
      {
        label: 'Email (ถ้ามี)',
        id: 'regEmail',
        type: 'text',
        defaultValue: '',
        maxLength: 70,
        change: true,
      },

      // =========
      // {
      //   label: 'ชื่อผู้เอาประกัน',
      //   id: 'regName',
      //   type: 'text',
      //   defaultValue: '',
      //   condition: 'textOnly',
      //   maxLength: 30,
      //   change: false,
      // },
      // {
      //   label: 'นามสกุลผู้เอาประกัน',
      //   id: 'regSurname',
      //   type: 'text',
      //   defaultValue: '',
      //   condition: 'textOnly',
      //   maxLength: 50,
      //   change: false,
      // },
      // {
      //   label: 'เลขที่กรมธรรม์',
      //   id: 'regPolicyNo',
      //   type: 'text',
      //   defaultValue: '',
      //   maxLength: 100,
      //   mask: 'X',
      //   format: '###-#######',
      //   valueFormat: 'formattedValue',
      // },

      // {
      //   label: 'ประเภทของการชำระเงิน',
      //   id: 'regPaymentType',
      //   type: 'select',
      //   defaultValue: {
      //     label: 'ชำระเบี้ยปีต่อ',
      //     value: '02',
      //   },
      //   isDisabled: true,
      //   change: true,
      //   options: [
      //     {
      //       text: 'ชำระเบี้ยปีต่อ',
      //       label: 'ชำระเบี้ยปีต่อ',
      //       value: '02',
      //     },
      //   ],
      // },
      // {
      //   label: 'ชื่อผลิตภัณฑ์ / แบบประกัน',
      //   id: 'regProductCode',
      //   type: 'select',
      //   defaultValue: '',
      //   maxLength: 50,
      //   options: [],
      // },
    ],
    // sendLinkSection: {
    //   sendLinkMode: {
    //     id: 'regSendLinkType',
    //     title: 'ท่านต้องการส่งลิงก์สมัครหักค่าเบี้ยประกันอัตโนมัติผ่าน บัตรเครดิตไปที่',
    //     options: linkPaymentMethod,
    //   },
    //   sendLinkMobile: {
    //     id: 'regMobile',
    //     type: 'text',
    //     defaultValue: '',
    //     mask: '_',
    //     format: '### ### ####',
    //     valueFormat: 'value',
    //     change: true,
    //     isAllowed: ({ formattedValue }) => formattedValue.length <= 19,
    //   },
    //   sendLinkEmail: {
    //     id: 'regEmail',
    //     type: 'text',
    //     defaultValue: '',
    //     maxLength: 50,
    //     change: true,
    //   },
    // },
  },

  informationSection: {
    searchMethod: {
      id: 'searchMethod',
      options: [
        {
          text: 'ค้นหาจากเลขบัตรประชาชน',
          value: 'citizenId',
        },
        {
          text: 'ค้นหาจากเลขหนังสือเดินทาง',
          value: 'passport',
        },
        {
          text: 'ค้นหาจากเลขกรมธรรม์',
          value: 'policyId',
        },
      ],
    },
    identity: {
      id: 'personalId',
      label: 'เลขบัตรประชาชนหรือหนังสือเดินทาง',
      mask: '_',
      format: '# #### ##### ## #',
    },
    policyNo: {
      label: 'เลขที่กรมธรรม์',
      id: 'policyNo',
      type: 'text',
      defaultValue: '',
      maxLength: 100,
      mask: 'X',
      format: '###-#######',
      valueFormat: 'formattedValue',
    },
    policyEffectiveDate: {
      label: 'วันที่กรมธรรม์มีผลบังคับ',
      id: 'policyEffectiveDate',
      type: 'text',
      defaultValue: '',
      maxLength: 10,
      mask: 'X',
      format: '####-##-##',
      valueFormat: 'formattedValue',
    },
    textInputs: [
      {
        label: 'ชื่อผู้เอาประกัน',
        id: 'insuredName',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 30,
        change: false,
      },
      {
        label: 'นามสกุลผู้เอาประกัน',
        id: 'insuredSurName',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 50,
        change: false,
      },
      {
        label: 'เบอร์โทรศัพท์ผู้เอาประกัน',
        id: 'insureMobile',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
        mask: '_',
        format: '### ### ####',
        valueFormat: 'value',
        change: true,
      },
      {
        label: 'อีเมลผู้เอาประกัน',
        id: 'insureEmail',
        type: 'text',
        defaultValue: '',
        maxLength: 50,
        change: true,
      },
      {
        label: 'จำนวนเงินที่ชำระ (บาท)',
        id: 'paymentAmount',
        type: 'text',
        defaultValue: '',
        maxLength: 19,
        change: true,
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        isAllowed: ({ formattedValue }) => formattedValue.length <= 19,
      },
    ],
  },
}
