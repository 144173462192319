import { useEffect, useContext } from 'react'

import { RouterContext, NavConfigPropsType } from '../../../contexts/router'

export const useRouterForHandleSummit = (tabState, ActiveCondition, props, navConfig: NavConfigPropsType) => {
  const RouterContextProps = useContext(RouterContext)
  const setNavConfigByPage = RouterContextProps.setNavConfigByPage || props.setNavConfigByPage
  useEffect(() => {
    if (ActiveCondition) {
      setNavConfigByPage(navConfig)
    }
  }, [tabState, props?.state])
}
