import dayjs from 'dayjs'
import * as yup from 'yup'
import _isEmpty from 'lodash/isEmpty'

import { getToggles } from 'core/config'
import { validateCitizenId } from 'utils/validate'

const messageError = {
  general: 'This field is require.',
  paymentMode: 'โปรดระบุงวดการชำระเงิน',
  policyNo: 'โปรดระบุเลขที่กรมธรรม์',
  personalId: 'โปรดระบุเลขบัตรประชาชน/เลขที่หนังสือเดินทาง',
  insuredName: 'โปรดระบุชื่อผู้เอาประกัน',
  insuredSurName: 'โปรดระบุนามสกุลผู้เอาประกัน',
  insureMobile: 'โปรดระบุเบอร์โทรศัพท์ผู้เอาประกัน',
  insureEmail: 'โปรดระบุอีเมลผู้เอาประกัน',
  paymentAmount: 'โปรดระบุจำนวนเงินที่ชำระ',
  creditCardNumber: 'โปรดระบุหมายเลขบัตรเครดิต',
  creditCardImage: 'โปรดแนบหลักฐานการชำระเงิน',
  authorizedCode: 'โปรดระบุรหัสอนุมัติ',
  chequeNumber: 'โปรดระบุหมายเลขเช็ค',
  chequeDate: 'โปรดระบุวันที่หน้าเช็ค',
  chequeImage: 'โปรดแนบรูปเช็ค',
  chequeBank: 'โปรดระบุธนาคารผู้รับชำระ',
  chequeBankBranch: 'โปรดระบุสาขาผู้รับชำระ',
  policyEffectiveDate: 'โปรดระบุวันที่กรมธรรม์มีผลบังคับ',
  productCode: 'โปรดระบุชื่อผลิตภัณฑ์/แบบประกัน',
  paymentMethod: 'โปรดระบุวิธีการชำระเงิน',
  payerType: 'โปรดระบุข้อมูลผู้ชำระเงิน',
  payerFirstName: 'โปรดระบุชื่อผู้ชำระเบี้ย',
  payerLastName: 'โปรดระบุนามสกุลผู้ชำระเบี้ย',
  payerIdType: 'โปรดระบุเอกสารที่ใช้แสดง',
  payerCitizen: 'โปรดระบุเลขบัตรประชาชน',
  payerPassport: 'โปรดระบุหนังสือเดินทาง',
  payerRelations: 'โปรดระบุความสัมพันธ์',
  payerRelationshipDoc: 'โปรดแนบเอกสารพิสูจน์ความสัมพันธ์',
}
const validateError = {
  general: 'โปรดตรวจสอบข้อมูล',
  citizenId: 'โปรดระบุเลขที่บัตรประชาชนให้ถูกต้อง',
  policy: 'โปรดตรวจสอบข้อมูล [เลขที่กรมธรรม์ ในรูปแบบ 123-4567890]',
  policyEffectiveDate: 'โปรดระบุวันที่กรมธรรม์มีผลบังคับให้ถูกต้อง',
}
export const personNameRegExp = /[^A-Za-z.\u0E01-\u0E5B]/gi
export const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
export const policyNoReExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{7})$/
export const citizenIdReExp = /^\(?([0-9]{1})\)?[-. ]?([0-9]{4})?[-. ]?([0-9]{5})?[-. ]?([0-9]{2})?[-. ]?([0-9]{1})$/
const policyEffectiveDate = /^\(?([0-9]{4})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/
const passportExp = /^[a-z0-9]+$/gi

export const schemaInformationSection = (isResumePayment) =>
  yup.object().shape({
    paymentType: yup.string().required(messageError.general),
    memo: yup.string(),
    personalIdType: yup.string(),
    personalId: yup
      .string()
      .required(messageError.personalId)
      .when('personalIdType', {
        is: 'passport',
        then: yup
          .string()
          .required(messageError.personalId)
          .matches(passportExp, validateError.general)
          .min(5, validateError.general)
          .max(10, validateError.general),
      })
      .when('personalIdType', {
        is: 'citizenId',
        then: yup
          .string()
          .required(messageError.personalId)
          .test('validateCitizenId', validateError.citizenId, (value) => validateCitizenId(value)),
        // then: yup.string().required(messageError.personalId).matches(citizenIdReExp, validateError.general),
      }),

    // QuickPayLink
    policyEffectiveDate: yup.string().when('personalIdType', {
      is: 'policyId',
      then: yup
        .string()
        .required(messageError.policyEffectiveDate)
        .matches(policyEffectiveDate, validateError.policyEffectiveDate),
    }),

    policyNo: yup
      .string()
      .matches(policyNoReExp, { message: validateError.policy, excludeEmptyString: true })
      .when('memo', {
        is: 'on',
        then: yup.string().required(messageError.policyNo).matches(policyNoReExp, validateError.policy),
      })
      .when('paymentType', {
        is: (p) => p !== '01',
        then: yup.string().required(messageError.policyNo).matches(policyNoReExp, validateError.policy),
      })
      .when('paymentType', {
        is: (p) => p === '02',
        then: yup.string().required(messageError.policyNo).matches(policyNoReExp, validateError.policy),
      }),
    paymentMode: yup.string().required(messageError.paymentMode),
    insuredName: yup.string().required(messageError.insuredName),
    insuredSurName: yup.string().required(messageError.insuredSurName),
    insureMobile: yup.string().required(messageError.insureMobile).matches(phoneRegExp, validateError.general),
    insureEmail: yup.string().email(validateError.general),
    paymentAmount: yup.string().required(messageError.paymentAmount).typeError(messageError.general),
    productCode: yup.string().when('paymentMethod', {
      is: 'quickpay',
      then: yup.string().required(messageError.productCode),
    }),

    paymentMethod: yup.string().required(messageError.paymentMethod),

    // creditcard
    creditCardNumber: yup
      .string()
      .when('paymentMethod', {
        is: 'creditcard',
        then: yup.string().required(messageError.creditCardNumber),
      })
      .when('paymentMethod', {
        is: 'consentcreditcard',
        then: yup.string().required(messageError.creditCardNumber),
      }),
    creditCardImage: yup.string().when('paymentMethod', {
      is: 'creditcard',
      then: yup.string().required(messageError.creditCardImage),
    }),
    authorizedCode: yup.string().when('paymentMethod', {
      is: 'creditcard',
      then: yup.string().required(messageError.authorizedCode),
    }),

    // cheque
    chequeBank: yup.string().when('paymentMethod', {
      is: 'cheque',
      then: yup.string().required(messageError.chequeBank),
    }),
    chequeNumber: yup.string().when('paymentMethod', {
      is: 'cheque',
      then: yup.string().required(messageError.chequeNumber),
    }),
    chequeBankBranch: yup.string().when('paymentMethod', {
      is: 'cheque',
      then: yup.string().required(messageError.chequeBankBranch),
    }),
    chequeImage: yup.object().when('paymentMethod', {
      is: 'cheque',
      then: yup.object().test('not-empty', messageError.chequeImage, (value) => value && Object.keys(value).length > 0),
    }),
    chequeDate: yup.string().when('paymentMethod', {
      is: 'cheque',
      then: yup.string().required(messageError.chequeDate),
    }),

    // payerInfo
    payerType: yup.string().when([], {
      is: () => getToggles().ENABLE_ECBRSYS_ECBR && !isResumePayment,
      then: yup.string().required(messageError.payerType),
      otherwise: yup.string().nullable(),
    }),
    payerFirstName: yup.string().when(['payerIdType'], {
      is: (payerIdType) => !_isEmpty(payerIdType),
      then: yup.string().required(messageError.payerFirstName),
    }),
    payerLastName: yup.string().when(['payerIdType'], {
      is: (payerIdType) => !_isEmpty(payerIdType),
      then: yup.string().required(messageError.payerLastName),
    }),
    payerIdType: yup.string().when('payerType', {
      is: (payerType) => payerType === 'other' && !isResumePayment,
      then: yup.string().required(messageError.payerIdType),
      otherwise: yup.string().nullable(),
    }),
    payerPersonalId: yup
      .string()
      .when('payerIdType', {
        is: 'passport',
        then: yup
          .string()
          .required(messageError.payerPassport)
          .matches(passportExp, validateError.general)
          .min(5, validateError.general)
          .max(10, validateError.general),
      })
      .when('payerIdType', {
        is: 'citizen',
        then: yup
          .string()
          .required(messageError.payerCitizen)
          .test('validateCitizenId', validateError.citizenId, (value) => validateCitizenId(value)),
      }),

    payerRelations: yup.string().when('payerType', {
      is: (payerType) => payerType === 'other' && !isResumePayment,
      then: yup.string().required(messageError.payerRelations),
      otherwise: yup.string().nullable(),
    }),
    payerRelationsOthers: yup.string().when('payerRelations', {
      is: 'Others',
      then: yup.string().required(validateError.general),
    }),
    payerRelationshipDoc: yup.object().when(['paymentType', 'payerType'], {
      is: (paymentType, payerType) => paymentType === '01' && payerType === 'other' && !isResumePayment,
      then: yup
        .object()
        .test('not-empty', messageError.payerRelationshipDoc, (value) => value && Object.keys(value).length > 0),
      otherwise: yup.object().nullable(),
    }),

    // create gen link
    ccLinkMode: yup.string(),
    genLinkMobile: yup.string().when('ccLinkMode', {
      is: 'sms',
      then: yup.string().required(messageError.insureMobile).matches(phoneRegExp, validateError.general),
    }),
    genLinkEmail: yup.string().when('ccLinkMode', {
      is: 'email',
      then: yup.string().required(messageError.insureEmail).email(validateError.general),
    }),
  })

export const handleSetValue = (key, val, hookFormAPI, validateOption?) => {
  const { setValue, setError, clearErrors, formState } = hookFormAPI
  const { isSubmitted } = formState
  if (val) {
    if (validateOption) {
      const { regex, errorMessage } = validateOption
      if (!regex.test(val)) {
        setError(key, {
          type: 'manual',
          message: errorMessage,
        })
      } else {
        clearErrors(key)
        setValue(key, val)
      }
    } else {
      clearErrors(key)
      setValue(key, val)
    }
  } else {
    if (isSubmitted) {
      setError(key, {
        type: 'manual',
        message: messageError.general,
      })
    } else {
      clearErrors(key)
    }
  }
}

export const handlePersonalIdTypeIsCitizen = (personalId: string) => citizenIdReExp.test(personalId.replace(/ +/g, ''))
export const handleResetDataField = (reset: Function, fieldObject?: any) => {
  if (fieldObject) {
    reset(fieldObject)
  } else {
    reset({
      policyNo: '',
      insuredName: '',
      personalId: '',
      personalIdType: 'citizenId',
      insureMobile: '',
      insuredSurName: '',
      insureEmail: '',
      paymentAmount: '',
      productCode: '',
      paymentType: '02',
      paymentMethod: '',
      // paymentMethod: getToggles().ENABLE_ENQUIRY_POLICYID ? '' : 'ibanking',
      paymentMode: '',
      chequeDate: dayjs().toISOString(),
      productName: '',
      sumInsuredAmount: '',
      ownerFirstName: '',
      ownerLastName: '',
      ownerPersonalId: '',
    })
  }
}
