export default class PolicyEntity {
  policyNumber: string
  paymentAmount: string
  paymentMode: string
  productCode: string
  firstName: string
  lastName: string
  nationalId: string
  mobileNumber: string
  email: string
  isOwner: Boolean
  premiumDueDate: string
  policyStatusCode: string
  hasPayer?: Boolean
  sumInsuredAmount?: string
  ownerFirstName?: string
  ownerLastName?: string
  ownerPersonalId?: string

  constructor(
    policyNumber: string,
    paymentAmount: string,
    paymentMode: string,
    productCode: string,
    firstName: string,
    lastName: string,
    nationalId: string,
    mobileNumber: string,
    email: string,
    isOwner: Boolean,
    premiumDueDate: string,
    policyStatusCode: string,
    hasPayer: Boolean,
    sumInsuredAmount: string,
    ownerFirstName: string,
    ownerLastName: string,
    ownerPersonalId: string
  ) {
    this.policyNumber = policyNumber
    this.paymentAmount = paymentAmount
    this.paymentMode = paymentMode
    this.productCode = productCode
    this.firstName = firstName
    this.lastName = lastName
    this.nationalId = nationalId
    this.mobileNumber = mobileNumber
    this.email = email
    this.isOwner = isOwner
    this.premiumDueDate = premiumDueDate
    this.policyStatusCode = policyStatusCode
    this.hasPayer = hasPayer
    this.sumInsuredAmount = sumInsuredAmount
    this.ownerFirstName = ownerFirstName
    this.ownerLastName = ownerLastName
    this.ownerPersonalId = ownerPersonalId
  }
}
