/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'

import styles from './input-styles'

const INPUT_STATE = {
  Normal: 'normal',
  Error: 'error',
  Valid: 'valid',
}

const propTypes = {
  /**
   * Elements to be rendered as children of this component.
   */
  children: PropTypes.node,
  /**
   * One or more class names to be added to the root element of this component, i.e. `"class-foo class-bar"`.
   */
  className: PropTypes.string,
  /**
   * Label of input
   */
  label: PropTypes.string,
  /**
   * Disables the input if set to true.
   */
  disabled: PropTypes.bool,
  /**
   * Type of input
   */
  type: PropTypes.string,
  /**
   * The state of the input
   *
   * * `normal`
   * * `error`
   * * `valid`
   */
  inputState: PropTypes.string,
  /**
   * The message to display only when the state is `error`
   */
  errorMessage: PropTypes.string,
}

const defaultProps = {
  children: null,
  className: '',
  label: '',
  disable: false,
  type: '',
  inputState: INPUT_STATE.Normal,
  errorMessage: '',
}

function InputWrapper(props) {
  const { className, disabled, children, label: labelText, type: inputType, inputState, errorMessage } = props

  const inputWrapperProps = {}
  const inputWrapperStyles = [styles.wrapper]
  if (className) inputWrapperStyles.push(className)
  if (disabled) inputWrapperStyles.push(styles.disabled)
  if ([INPUT_STATE.Error, INPUT_STATE.Valid].includes(inputState)) inputWrapperStyles.push(styles.state[inputState])
  // if (['text', 'number', 'email', 'password', 'textarea'].includes(inputType))

  if (className) inputWrapperProps.className = className

  const getLabelEl = (labelText) => {
    return <span>{labelText}</span>
  }

  const getErrorMessageEl = (errorMessage) => {
    return (
      <div className={styles.errorBox}>
        <span className={styles.errorMessage}>{errorMessage}</span>
      </div>
    )
  }

  return (
    <div {...inputWrapperProps} css={inputWrapperStyles}>
      <label css={styles.label}>
        {labelText ? getLabelEl(labelText) : null}
        <div css={styles.el}>{children}</div>
        {inputState === INPUT_STATE.Error ? getErrorMessageEl(errorMessage) : null}
      </label>
    </div>
  )
}

InputWrapper.propTypes = propTypes
InputWrapper.defaultProps = defaultProps

InputWrapper.State = { ...INPUT_STATE }

export default InputWrapper
