import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button } from 'ktaxa-ui-components/index'
import { yupResolver } from '@hookform/resolvers/yup'
import { Radio } from 'ktaxa-ui-components'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { renderInfoMessageBlock, renderErrorMessageBlock } from 'components/message-blocks'
import staticContentPage from '../../static-content-page'
import newStaticContentPage from '../../new-static-content-page'
import { schemaCancelMethodSection } from '../../hooks/useForm'
import { useRouterForHandleSummit } from '../../hooks/useRouter'
import SubmitSection from '../submit'
import { getToggles } from 'core/config'

const CancelMethodSection = (props) => {
  const { currentSection, setCurrentSection, state, setState } = props
  const { cancelMethodSection } = getToggles().ENABLE_ECBRSYS_ECBR? newStaticContentPage: staticContentPage
  const { formState, control, reset, getValues, setValue, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaCancelMethodSection),
  })
  const { errors } = formState
  const isActive = currentSection === staticContentPage.cancelationSequence[1]

  useEffect(() => {
    reset({
      object: { [cancelMethodSection.id]: getToggles().ENABLE_ECBRSYS_ECBR? 'fillForm': 'uploadFile' },
    })
  }, [reset])

  const onClickBackButton = () => {
    reset({ object: { [cancelMethodSection.id]: getToggles().ENABLE_ECBRSYS_ECBR? 'fillForm': 'uploadFile' } })
    setCurrentSection(staticContentPage.cancelationSequence[0])
  }

  useRouterForHandleSummit(
    currentSection,
    isActive,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <Button onClick={() => onClickBackButton()} type="secondary">
          กลับ
        </Button>
      ),
      extraButton: () => (
        <SubmitSection
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          getValues={getValues}
          handleSubmit={handleSubmit}
        />
      ),
    }
  )

  if (!isActive) return null
  return (
    <ContentContainer>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
          }
        }}
      >
        <h1>{cancelMethodSection.mainTitle}</h1>
        <SectionContainer>
          <Controller
            name={`object.${cancelMethodSection.id}`}
            control={control}
            render={() => (
              <Radio.Group
                uiStyle={Radio.UIStyle.Checked}
                value={getValues(`object.${cancelMethodSection.id}`)}
                onChange={(option) => {
                  setState({ ...state, method: option.value })
                  setValue(
                    'object',
                    {
                      [cancelMethodSection.id]: option.value,
                    },
                    { shouldDirty: true, shouldValidate: true }
                  )
                }}
              >
                {!getToggles().ENABLE_ECBRSYS_ECBR && cancelMethodSection.solution.radio.map((r, index) => (
                  <Radio key={index} value={r.value} label={r.text}>
                    <div style={{ minWidth: 500 }}>{r.text}</div>
                    {/* {renderInfoMessageBlock(r.warning)} */}
                  </Radio>
                ))}
                {getToggles().ENABLE_ECBRSYS_ECBR && cancelMethodSection.solution.radio.map((r, index) => (
                  <Radio key={index} value={r.value} label={r.text}>
                    <div style={{ minWidth: 500 }}>{r.text}</div>
                    {/* {renderInfoMessageBlock(r.warning)} */}
                  </Radio>
                ))}
              </Radio.Group>
            )}
          />
        </SectionContainer>
        {renderErrorMessageBlock(errors?.object?.[cancelMethodSection.id])}
      </form>
    </ContentContainer>
  )
}

export default CancelMethodSection
