import { Input, InputGroup } from 'rsuite'
import SearchIcon from '@rsuite/icons/Search';
import React from 'react';
import styled from '@emotion/styled'

const InputGroupCustom = styled(InputGroup)`
  width: 100%;
  line-height: 1.25;
  height: calc(1.97rem + 2px);
  border: 1px solid #333333;
  border-radius: 0;
  background: white;
`
const InputGroupButtonCustom = styled(InputGroup.Button)`
  background-color: rgba(0, 0, 0, 0) !important;
`
const SearchIconCustom = styled(SearchIcon)`
  color: #2425aa;
`

const InputCustomSearch = (props: any) => {
  const { onClick, onChange, value, maxLength } = props

  const innerOnClick = ()=>{
    onClick(value)
  }

  return (
    <InputGroupCustom>
      <Input value={value} onChange={e => onChange(e)} maxLength={maxLength}/>
      <InputGroupButtonCustom onClick={innerOnClick}>
        <SearchIconCustom />
      </InputGroupButtonCustom>
    </InputGroupCustom>
  )
}

export default InputCustomSearch
