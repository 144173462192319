import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { device } from 'components/theme'

const propTypes = {
  children: PropTypes.node,
}
const defaultProps = {
  children: null,
}

const StylesDescription = styled.div`
  padding: 5px 0;
  margin-bottom: 8px;
  display: ${(props) => (props.noWrap ? 'flex' : 'block')};
  @media (min-width: ${device.screenSize.md}) {
    display: flex;
    margin-bottom: 0;
  }
`

const StyledItem = styled.div`
  font-weight: ${(props) => (props.value ? 400 : 700)};
  @media (min-width: ${device.screenSize.md}) {
    flex: 1;
    font-weight: 400;
    text-align: ${(props) => (props.value ? 'right' : 'initial')};
  }
`

const Description = (props) => <StylesDescription>{props.children}</StylesDescription>

const DescriptionLabel = (props) => <StyledItem>{props.children}</StyledItem>

const DescriptionValue = (props) => <StyledItem value>{props.children}</StyledItem>

Description.propTypes = { ...propTypes, noWrap: PropTypes.bool }
Description.defaultProps = { ...defaultProps, noWrap: false }
DescriptionLabel.propTypes = { ...propTypes }
DescriptionLabel.defaultProps = { ...defaultProps }
DescriptionValue.propTypes = { ...propTypes, value: PropTypes.bool }
DescriptionValue.defaultProps = { ...defaultProps, value: false }
Description.Label = DescriptionLabel
Description.Value = DescriptionValue

export default Description
