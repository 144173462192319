import * as React from 'react'
import * as ReactDOM from 'react-dom'

import App from './app'
// TODO: Replaced with only main styles, not the migrated from AZ
import './ktaxa-ui-components/assets/styles/main.scss'
import './ktaxa-ui-components/scss/styles.scss'
import { loadConfig } from './core/config'

const render = async () => {
  await loadConfig()
  ReactDOM.render(<App />, document.getElementById('app'))
}

render()
