import { ChanalMethod, transferMethod } from '../../entities/payment'

export default class PaymentUseCase implements IPaymentUseCase {
  getTransferMethodByTransferMethodCode(tc: string): string {
    return transferMethod.find((t) => t.value === tc)?.text
      ? (transferMethod.find((t) => t.value === tc)?.text as string)
      : tc
  }

  getChanalMethodByTransferMethodCodeAndChanalMethodCode(tc: string, chc: string): string {
    switch (tc) {
      case transferMethod[0].value:
        return ChanalMethod.atmMethod.find((a) => a.value === chc)?.text
          ? (ChanalMethod.atmMethod.find((a) => a.value === chc)?.text as string)
          : chc
      case transferMethod[1].value:
        return ChanalMethod.bankMethod.find((a) => a.value === chc)?.text
          ? (ChanalMethod.bankMethod.find((a) => a.value === chc)?.text as string)
          : chc
      case transferMethod[2].value:
        return ChanalMethod.officeMethod.find((a) => a.value === chc)?.text
          ? (ChanalMethod.officeMethod.find((a) => a.value === chc)?.text as string)
          : chc
    }
    return chc
  }

  getCompCodeByBankMethod(bank: string): string {
    return ChanalMethod.bankMethod.find((a) => a.value === bank)?.compCode
      ? (ChanalMethod.bankMethod.find((a) => a.value === bank)?.compCode as string)
      : bank
  }
}

export interface IPaymentUseCase {
  getTransferMethodByTransferMethodCode(tc: string): string
  getChanalMethodByTransferMethodCodeAndChanalMethodCode(tc: string, chc: string): string
  getCompCodeByBankMethod(bank: string): string
}
