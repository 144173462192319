import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { isEmpty } from 'lodash/fp'
import styled from '@emotion/styled'
import SignatureCanvas from 'react-signature-canvas'

import {
  fromDataSnapShot,
  reSizeWrap,
  isPortrait,
  rotateSnapshot,
  reSizeBackButton,
  reSizeBodyContainer,
  toBase64,
} from 'components/modal/signing-modal/calculate'

import { getIcon } from '../../../ktaxa-ui-components/components/icons/normalIcon'

import { IconButton } from 'components/buttons'
import { IconSuccess } from 'components/icons'
import { Dialog, Button, Icons } from 'ktaxa-ui-components/index'

const DialogContainer = styled.div`
  text-align: center;
`
const Footer = styled.div`
  text-align: center;
  margin: 20px 0 10px;
  display: flex;
  justify-content: space-between;
  height: 10%;
  padding: 20px 100px 40px 100px;
  border-top: 1px solid #7dd320;
  min-height: 100px;
`
const Header = styled.div`
  height: 10%;
  min-height: 100px;
`

const BodyContent = styled.div`
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BodyContainer = styled.div<{ marginTop: string }>`
  box-sizing: border-box;
  label {
    margin-right: 0.5rem;
    font-weight: bold;
  }
  min-width: 900;
  min-height: 500;
  width: 900;
  height: 500;
  margin-top: ${(props) => props.marginTop};
`

const WrapSignaturePad = styled.div`
  border: solid 1px rgb(0, 0, 0);
  position: relative;
  width: 100%;
  height: 75%;

  .label-sign {
    position: absolute;
    top: 5px;
    left: -68px;
    z-index: 99;
    display: inline-block;
    padding: 0 12px;
    font-weight: bold;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .indicator {
    position: absolute;
    top: 30px;
    left: -55px;
    z-index: 99;
    display: inline-block;
    padding: 0 12px;
    color: white;
    background: #7ed322;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: rotate(-10deg);

    .description {
      position: relative;
      font-size: 0.8725rem;
      svg {
        position: absolute;
        top: -8px;
        left: calc(100% - 1px);
        color: #7ed322;
        font-size: 1.725rem;
      }
    }
  }

  .sigCanvas-horizontal {
  }

  .sigCanvas-vertical {
    transform: rotate(-90deg);
    transform-origin: 56px 318px;
  }
`

const NameContainer = styled.div`
  width: 100%;
  height: 25%;
`

const Back = styled.div<{ top: string }>`
  position: fixed;
  top: ${(props) => props.top};
  left: 20;
  cursor: pointer;
  font-size: 30;
  z-index: 100000;
`

type PropsType = {
  icon: string
  text: string
  fullName: string
  position: string
  date: string
  onSign: (sign: any, snapSign: any) => void
  setSnapSignValueisPortrait: (r: any) => void
  value: string
  onClear: () => void
  idName: string
  snapSignValue: any
}

const styleOptions = {
  box: {
    maxWidth: '100%',
    margin: '0px',
    border: 'none',
    borderRadius: '0px',
    top: '0px',
  },
  display: {
    overFlowY: 'none',
  },
  bodyContent: {
    padding: '0px',
  },
  idNameContent: 'signPad',
}

type OptionCanvasType = {
  width?: number
  height?: number
  className?: string
  isPortrait?: boolean
}

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      const content = document.body
      setSize([content.clientWidth, content.clientHeight])
    }
    window.addEventListener('resize', updateSize)

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])
  return size
}

const getSnapSignWithRotate = (snap) => {
  return isPortrait() ? snap : rotateSnapshot({ width: 375 }, true)(snap)
}

const SigingModal = (props: PropsType) => {
  const {
    icon,
    text,
    fullName,
    position,
    date,
    onSign,
    onClear,
    value,
    idName,
    snapSignValue,
    setSnapSignValueisPortrait,
  } = props
  const [show, useShow] = useState(false)
  const [isSign, useIsSign] = useState(false)
  const [snapSign, setSnapSign] = useState<any[][]>()
  const [optionCanvas, setOptionCavase] = useState<OptionCanvasType>()
  const [width, height] = useWindowSize()
  const [styleBackButton, setStyleBackButton] = useState<{ top: string }>({
    top: '20',
  })
  const [styleBodyContainer, setStyleBodyContainer] = useState<{ marginTop: string }>({
    marginTop: '0px',
  })
  let canvas = useRef<SignatureCanvas>(null)

  useEffect(() => {
    const init = async () => {
      if (canvas && value === '') {
        await canvas?.current?.clear()
      }

      if (!isEmpty(snapSignValue?.value)) {
        const snapWithRotate = getSnapSignWithRotate(snapSignValue?.value)
        await setSnapSign(snapWithRotate)
        await canvas?.current?.off()
      } else {
        await canvas?.current?.clear()
        await canvas?.current?.on()
      }
    }
    init()
  }, [])

  useEffect(() => {
    if (show) {
      if (!isEmpty(snapSignValue?.value)) {
        canvas?.current?.off()
      } else {
        canvas?.current?.on()
      }
    }
  }, [show, snapSignValue?.value])

  useEffect(() => {
    setStyleBackButton(reSizeBackButton())
    setStyleBodyContainer(reSizeBodyContainer())
    rotation()
  }, [width, height])

  const getSnapSign = () => (!isEmpty(snapSign) ? snapSign : getSnapSignWithRotate(snapSignValue?.value))
  const clear = () => {
    if (canvas) {
      canvas?.current?.clear()
      onClear()
      useIsSign(true)
      setSnapSign([])
      canvas?.current?.on()
    }
  }
  const sign = async () => {
    if (canvas) {
      await useShow(!show)
      await useIsSign(true)
      await onSign(toBase64(canvas?.current), {
        value: isPortrait() ? rotateSnapshot({ height: 375 }, false)(snapSign) : snapSign,
        isPortrait: optionCanvas?.isPortrait,
      })
    }
  }

  const onDraw = () => {
    if (canvas?.current?.isEmpty() && value === '') {
      useIsSign(true)
    }
  }

  const rotation = async () => {
    if (isPortrait() !== optionCanvas?.isPortrait) {
      if (isPortrait()) {
        await setOptionCavase({
          width: 375,
          height: 900,
          className: 'sigCanvas-vertical',
          isPortrait: true,
        })
        await reSizeWrap(true, idName)
        if (!isEmpty(getSnapSign())) {
          if (snapSignValue.isPortrait !== isPortrait()) {
            await fromDataSnapShot(canvas?.current, { width: 375, height: 375 }, true, getSnapSign())
            await setSnapSignValueisPortrait(true)
          } else {
            await fromDataSnapShot(canvas?.current, { width: 375, height: 375 }, true, getSnapSign())
            await setSnapSignValueisPortrait(true)
          }
          await setSnapSign(canvas?.current?.toData())
        }
      } else {
        await setOptionCavase({
          width: 900,
          height: 375,
          className: 'sigCanvas-horizontal',
          isPortrait: false,
        })
        await reSizeWrap(false, idName)
        if (!isEmpty(getSnapSign())) {
          if (snapSignValue.isPortrait !== isPortrait()) {
            await fromDataSnapShot(canvas?.current, { width: 375, height: 375 }, false, getSnapSign())
            await setSnapSignValueisPortrait(false)
          } else {
            await canvas?.current?.fromData(snapSignValue?.value)
            await setSnapSignValueisPortrait(false)
          }
          await setSnapSign(canvas?.current?.toData())
        }
      }
    }
  }

  const onBackAndOpen = () => {
    if (isEmpty(snapSignValue?.value)) {
      canvas?.current?.clear()
      setSnapSign([])
    }
    useIsSign(false)
    useShow(!show)
  }
  return (
    <div>
      <IconButton
        OverideIcon={
          value
            ? {
                Icon: IconSuccess,
                side: {
                  border: '1px solid #2425aa',
                  background: '#ffffff',
                },
              }
            : null
        }
        onClick={onBackAndOpen}
        icon={icon}
        text={text}
      />
      <DialogContainer>
        <Dialog
          styleOptions={{ ...styleOptions, idNameContent: `${styleOptions.idNameContent}${idName}` }}
          centered
          modal
          show={show}
        >
          {show && (
            <Back top={styleBackButton?.top} className="back-button-signpad" onClick={onBackAndOpen}>
              {getIcon('arrowLeft')}
            </Back>
          )}
          <BodyContent>
            <BodyContainer marginTop={styleBodyContainer.marginTop}>
              <div>
                <WrapSignaturePad onPointerDown={onDraw}>
                  <div className="label-sign">ลงนาม</div>
                  <div className="indicator">
                    <div className="description">
                      เซ็นชื่อ
                      {getIcon('playArrow')}
                    </div>
                  </div>

                  <SignatureCanvas
                    ref={canvas}
                    onEnd={() => {
                      setSnapSign(canvas?.current?.toData())
                    }}
                    canvasProps={optionCanvas}
                  />
                </WrapSignaturePad>
                <NameContainer>
                  <div>
                    <b>{fullName}</b>
                  </div>
                  <div>{position}</div>
                  <div>{date}</div>
                </NameContainer>
              </div>
            </BodyContainer>
          </BodyContent>
          <Footer>
            <Button onClick={clear} type={Button.Type.Secondary}>
              เซ็นใหม่
            </Button>
            <Button disabled={!isSign} onClick={sign} type={Button.Type.Primary}>
              ตกลง
            </Button>
          </Footer>
        </Dialog>
      </DialogContainer>
    </div>
  )
}

export default SigingModal
