import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _set from 'lodash/set'
import styled from '@emotion/styled'

import { Button } from 'ktaxa-ui-components/index'
import SearchStatusDialog from '../components/search-ccpa-status-dialog'
import PolicyListDialog from '../components/policy-list-dialog'
import LoadingModal from '../components/load-data-dialog'
import { updateRecurringData, verifyCCPAExisting, deleteRecurringID } from '../hooks/useService'
import { prepareRecordDataFromPolicy, prepareRecordRetrieveDabit } from '../utils'
import staticContentPage, { DA_STATUS, UPDATE_TYPES } from '../static-content'
import { DA_TYPE } from '../static-content'
import { AppContext } from 'contexts/app'

const Loading = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    font-size: 1.4rem;
  }
`

const SubmitSection = (props) => {
  const [loading, setLoading] = useState(false)
  const [showSearchStatusDialog, setShowSearchStatusDialog] = useState(false)
  const [showPolicyListDialog, setShowPolicyListDialog] = useState(false)
  const [selectedAgentIndex, setSelectedAgentIndex] = useState(0)
  const [isTriggerCancelResume, setIsTriggerCancelResume] = useState(false)

  const {
    handleSubmit,
    tabState,
    setTabState,
    setState,
    state,
    history,
    selectAgentPolicy,
    setShowConsentDialog,
    selectedRecurringInfo,
    setSelectedRecurringInfo,
  } = props

  const { isVerifyFormValidateError, setIsShowReviewForm } = useContext(AppContext)

  useEffect(() => {
    const stateCode = state?.verify?.searchStatus
    switch (stateCode) {
      case 'SHOW_LIST_POLICY':
        togglePolicyList()
        break
      case 'REQUIRED_CONSENT':
        setLoading(false)
        setState({
          verify: {
            ...state.verify,
            searchStatus: null,
          },
        })
        setShowConsentDialog(true)
        break
      case 'FOUND_CCPA_EXISTING':
      case 'NOTFOUND_CCPA_EXISTING':
      case 'FOUND_RETRIEVE_DEBIT':
      case 'FOUND_DA_EXISTING':
      case 'POLICY_NOT_FOUND':
        toggleSearchStatus()
        break
      case 'NOTFOUND_RETRIEVE_DEBIT':
        handleConfirmResumePendingDA()
        break
      case 'SERVER_ERROR':
        toggleSearchStatus()
        break
      default:
        break
    }
    setLoading(false)
    setIsTriggerCancelResume(false)
  }, [])

  const handleConfirmNewDA = async (data) => {
    try {
      let dataActiveRecurring
      if (selectedRecurringInfo.searchStatus === 'FOUND_RETRIEVE_DEBIT') {
        // RETRIEVE_DEBIT
        const genRetrieveDebit = await prepareRecordRetrieveDabit(selectedRecurringInfo.RetrieveDebit)
        dataActiveRecurring = {
            ...genRetrieveDebit
        }
      }
      if (selectedRecurringInfo.searchStatus === 'FOUND_CCPA_EXISTING') {
        // CCPA
        const getPolicyDetails = selectedRecurringInfo.resultPolicyDetails.data.policyDetail
        const getCreditCardNo = _get(
          getPolicyDetails,
          'RetrievePolicyDetailsResponse.RLSPolicy.creditCardAuthorization.creditCardNumber',
          ''
        )
        dataActiveRecurring = {
          activeCCPA: 'Y',
          creditCardNumber: selectedRecurringInfo.isDataByPolicy==true?getCreditCardNo:''
        }
      }
      // Create Payload
      const genDataSaveCCPA = {
        _id: selectedRecurringInfo.resultRecurringPayments.data._id,
        policyId: selectedRecurringInfo.resultRecurringPayments.data.policyId,
        activeRecurring: dataActiveRecurring,
      }
      // Update Data Recurring
      let resultUpdate = await updateRecurringData({
        updateType: UPDATE_TYPES.UPDATE,
        data: genDataSaveCCPA,
      })
      setState({
        verify: {
          ...state.verify,
          searchStatus: 'REQUIRED_CONSENT',
        },
      })
    } catch (error) {
      console.error('handle confirm', error.message)
    }

    setLoading(false)
  }

  const handleSubmitData = async () => {
    handleSubmit()
    setLoading(true)
  }

  const togglePolicyList = () => {
    setShowPolicyListDialog(!showPolicyListDialog)
    // setShowPolicyListDialog(false)
  }

  const toggleSearchStatus = () => {
    setShowSearchStatusDialog(!showSearchStatusDialog)
    // setShowSearchStatusDialog(false)
  }

  const onCloseSearchStatusDialog = () => {
    setShowSearchStatusDialog(false)
    setState({
      ...state,
      [staticContentPage.tabsProgress[0].id]: {
        ...state[staticContentPage.tabsProgress[0].id],
        searchStatus: undefined,
      },
    })
  }

  const onSelectPolicyItem = (item) => {
    const index = parseInt(item.value, 10)
    setSelectedAgentIndex(index)
  }

  const onConfirmSelected = () => {
    selectAgentPolicy(state?.verify?.agentPolicyList[selectedAgentIndex])
    togglePolicyList()
    setLoading(true)
  }

  const onCancelFillIn = () => {
    togglePolicyList()
  }

  const handleConfirmResumePendingDA = async () => {
    // check consent flag
    const _selectedRecurringInfo = _get(selectedRecurringInfo, 'resultRecurringPayments.data', null)
    const isAcceptConsent = _get(_selectedRecurringInfo, 'daConsent', false)
    if (isAcceptConsent) {
      // skip consent page and go to register form
      const isEDAFormType = _selectedRecurringInfo.policyDetail.policyYear === DA_TYPE.ONLINE
      if (selectedRecurringInfo.resultRecurringPayments.data.daStatus === DA_STATUS.CREATE) {
        await _set(state, 'isEditForm', true)
        await setTabState(staticContentPage.tabsProgress[1].id)
      } else {
        toggleSearchStatus()
        await _set(state, 'isEditForm', true)
        await setState({
          ...state,
          [staticContentPage.tabsProgress[0].id]: {
            ...state[staticContentPage.tabsProgress[0].id],
            searchStatus: '',
          },
          [staticContentPage.tabsProgress[1].id]: {
            payload: _selectedRecurringInfo,
          },
        })
        if (!isEDAFormType) {
          await setTabState(staticContentPage.tabsProgress[1].id)
          await setTabState(staticContentPage.tabsProgress[2].id)
          await setIsShowReviewForm(true)
        } else {
          setTabState(staticContentPage.tabsProgress[2].id)
        }
      }
    } else {
      setShowSearchStatusDialog(false)
      setShowConsentDialog(true)
    }
  }

  const handleCheckExistingDAorCCPA = async () => {
    try {
      // call API check existing DA/CCPA
      const genPolicyType = _get(selectedRecurringInfo.resultRecurringPayments.data, 'policyDetail.policyYear', '')
      const genPolicyId = _get(selectedRecurringInfo.resultRecurringPayments.data, 'policyId', '')
      const genEffectiveDate = _get(selectedRecurringInfo.resultRecurringPayments.data, 'effectiveDate', '')
      const genPolicyEffectiveToDate = _get(
        selectedRecurringInfo.resultRecurringPayments.data,
        'policyEffectiveToDate',
        ''
      )
      const genParamsSearch = {
        isDataByPolicy: genPolicyType == 'RNW',
        policyEffectiveDate: genPolicyEffectiveToDate,
        effectiveDate: genEffectiveDate,
        policyNo: genPolicyId,
      }
      const resultVerifyCCPA = await verifyCCPAExisting(genParamsSearch)

      setSelectedRecurringInfo(resultVerifyCCPA.data)
      const searchDAInfo = resultVerifyCCPA.data?.resultRecurringPayments?.data || undefined
      setState({
        [staticContentPage.tabsProgress[0].id]: {
          ...resultVerifyCCPA.data,
          searchDAInfo: searchDAInfo,
          searchStatus: resultVerifyCCPA.data?.searchStatus,
        },
      })
    } catch (error) {
      console.error('handle check existingDA/CCPA', error.message)
    }
  }

  const onCancelResume = async() => {

    setLoading(true)
    setIsTriggerCancelResume(true)
    setShowSearchStatusDialog(false)
    try {
      const recurringId = _get(selectedRecurringInfo.resultRecurringPayments.data, '_id', '')
      let resultDelete = await deleteRecurringID({ _id: recurringId })

      await handleCheckExistingDAorCCPA()
    } catch (error) {
      console.error('cancel resume', error.message)
    }
  }

  const onCancelCCPA = async () => {

    try {

      // TODO : clear data and go to eCBR homepage
      const recurringId = _get(selectedRecurringInfo, 'resultRecurringPayments.data._id', '')
      let resultDelete = await deleteRecurringID({ _id: recurringId })
    } catch (error) {
      console.error('handle ccpa', error.message)
    }

    setShowSearchStatusDialog(false)
    setLoading(false)
    history.push('/')
  }

  return (
    <>
      <Button disabled={isVerifyFormValidateError} onClick={handleSubmitData} type="primary">
        ยืนยัน
      </Button>
      <LoadingModal show={loading} />
      <SearchStatusDialog
        ccpaDetail={state?.verify?.ccpaDetail}
        RetrieveData={state?.verify?.RetrieveDebit}
        typeForm={state?.verify?.searchStatus}
        show={showSearchStatusDialog}
        setLoading={setLoading}
        onToggle={onCloseSearchStatusDialog}
        cancelResume={onCancelResume}
        onCancelCCPA={onCancelCCPA}
        onConfirmNewDA={handleConfirmNewDA}
        onConfirmResumePendingDA={handleConfirmResumePendingDA}
      />
      <PolicyListDialog
        // this will show policy list after get data from EIP
        show={showPolicyListDialog}
        onToggle={togglePolicyList}
        selectedAgentIndex={selectedAgentIndex}
        onSelectPolicyItem={onSelectPolicyItem}
        onConfirmSelected={onConfirmSelected}
        onCancelFillIn={onCancelFillIn}
        agentPolicyList={state?.verify?.agentPolicyList}
        searchStatus={state?.verify?.searchPolicyStatus}
      />
    </>
  )
}

export default withRouter(SubmitSection)
