import _cloneDeep from 'lodash/cloneDeep'
import { Theme as KTAXATheme } from 'ktaxa-ui-components'

export const color = {
  ..._cloneDeep(KTAXATheme.color),
  status: {
    pending: '#333333',
    transfer: '#4976ba',
    verifying: '#f07763',
    completed: '#1cc54f',
    cancelled: '#c91432',
    done: '#19B833',
  },
}

export const device = _cloneDeep(KTAXATheme.device)

export const typography = _cloneDeep(KTAXATheme.typography)

export const transition = _cloneDeep(KTAXATheme.transition)

export const padding = _cloneDeep(KTAXATheme.padding)

export const customIcon = (name) => {
  const icon = {
    messageInfo:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill"><path d="M8 16A8 8 0 108 0a8 8 0 000 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 110-2 1 1 0 010 2z"/></svg>',
    messageWarn:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill"><path d="M8.982 1.566a1.13 1.13 0 00-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 01-1.1 0L7.1 5.995A.905.905 0 018 5zm.002 6a1 1 0 110 2 1 1 0 010-2z"/></svg>',
    messageError:
      '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
    dialogError:
      '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
    dialogSuccess:
      '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>',
    closeIcon:
      '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path id="Path_27631" data-name="Path 27631" d="M28.667,11.887,26.78,10l-7.447,7.447L11.887,10,10,11.887l7.447,7.447L10,26.78l1.887,1.887,7.447-7.447,7.447,7.447,1.887-1.887L21.22,19.333Z" transform="translate(-3.333 -3.333)" fill="#999"/><path id="Path_27632" data-name="Path 27632" d="M0,0H32V32H0Z" fill="none"/></svg>',
    dialogWarning:
      '<svg xmlns="http://www.w3.org/2000/svg" width="134.365" height="120" viewBox="0 0 134.365 120"><path d="M83.216,24a14.443,14.443,0,0,1,12.463,7.187l52.788,91.2A14.4,14.4,0,0,1,136,144H30.428a14.4,14.4,0,0,1-12.463-21.615l52.788-91.2A14.442,14.442,0,0,1,83.216,24ZM136,134.4a4.8,4.8,0,0,0,4.154-7.2L87.37,36a4.8,4.8,0,0,0-8.309,0l-52.788,91.2a4.8,4.8,0,0,0,4.154,7.2Z" transform="translate(-16.034 -24)" fill="#ffbc11"/><g transform="translate(59.035 43.2)"><path d="M-3.2,25.6A4.8,4.8,0,0,1-8,20.8v-24A4.8,4.8,0,0,1-3.2-8,4.8,4.8,0,0,1,1.6-3.2v24A4.8,4.8,0,0,1-3.2,25.6Z" transform="translate(10.816 8)" fill="#ffbc11"/><ellipse cx="7.039" cy="7.039" rx="7.039" ry="7.039" transform="translate(0 41.272)" fill="#ffbc11"/></g></svg>',
  }

  if (icon[name]) {
    return `"data:image/svg+xml;charset=utf8,${encodeURIComponent(icon[name])}"`
  }
  return ''
}

export const hexToRGB = KTAXATheme.hexToRGB

const Theme = {
  color,
  device,
  typography,
  transition,
  padding,
  customIcon,
  hexToRGB,
}

export default Theme
