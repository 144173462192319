import * as yup from 'yup'

export const schemaTransferInfoSection = yup.object().shape({
  object: yup.object({
    payBy: yup.string().required('โปรดระบุวิธีการชำระเงิน'),
    payByService: yup.string().required('โปรดระบุธนาคารหรือเคาน์เตอร์ผู้รับชำระ'),
  }),
})

export const schemaAttachmentSection = yup.object().shape({
  object: yup.object({
    payDate: yup.date().required('โปรดระบุวันที่'),
    slipImage: yup.object().test('not-empty', 'โปรดแนบสำเนาใบนำฝาก', (value) => value && Object.keys(value).length > 0),
  }),
})

export const schemaPaymentSection = yup.object().shape({
  object: yup.object({
    imgBase64: yup.object().test('not-empty', 'โปรดแนบสำเนาใบนำฝาก', (value) => value && Object.keys(value).length > 0),
  }),
})
