import * as yup from 'yup'

import { validateCitizenId } from 'utils/validate'

const messageError = {
  general: 'โปรดระบุข้อมูล',
  policyNo: 'โปรดระบุเลขที่กรมธรรม์',
  personalId: 'โปรดระบุเลขบัตรประชาชน/เลขที่หนังสือเดินทาง',
  policyEffectiveDate: 'โปรดระบุวันที่กรมธรรม์มีผลบังคับ',
  regEmail: 'โปรดระบุอีเมลผู้เอาประกัน',
  regMobile: 'โปรดระบุเบอร์โทรศัพท์ผู้เอาประกัน',
}
const validateError = {
  general: 'โปรดตรวจสอบข้อมูล',
  citizenId: 'โปรดระบุเลขที่บัตรประชาชนให้ถูกต้อง',
  policy: 'โปรดตรวจสอบข้อมูล [เลขที่กรมธรรม์ ในรูปแบบ 123-4567890]',
  policyEffectiveDate: 'โปรดระบุวันที่กรมธรรม์มีผลบังคับให้ถูกต้อง',
}
export const personNameRegExp = /[^A-Za-z.\u0E01-\u0E5B]/gi
export const policyNoReExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{7})$/
export const citizenIdReExp = /^\(?([0-9]{1})\)?[-. ]?([0-9]{4})?[-. ]?([0-9]{5})?[-. ]?([0-9]{2})?[-. ]?([0-9]{1})$/
const policyEffectiveDate = /^\(?([0-9]{4})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/
const passportExp = /^[a-z0-9]+$/gi

export const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

export const schemaRegisterInfoSection = yup.object().shape({
  regName: yup.string().required(messageError.general),
  regSurname: yup.string().required(messageError.general),
  regPolicyNo: yup
    .string()
    .required(messageError.policyNo)
    .matches(policyNoReExp, { message: validateError.policy, excludeEmptyString: true }),
  regPersonalIdType: yup.string().required(messageError.general),
  regPersonalId: yup
    .string()
    .when('regPersonalIdType', {
      is: 'passport',
      then: yup.string().required(messageError.personalId).min(5, validateError.general).max(10, validateError.general),
    })
    .when('regPersonalIdType', {
      is: 'citizen',
      then: yup
        .string()
        .required(messageError.personalId)
        .test('validateCitizenId', validateError.citizenId, (value) => validateCitizenId(value)),
      // then: yup.string().required(messageError.personalId).matches(citizenIdReExp, validateError.general),
    }),
  regPaymentType: yup.string().required(messageError.general),
  regProductCode: yup.string().required(messageError.general),

  regSendLinkType: yup.string(),
  regEmail: yup.string().when('regSendLinkType', {
    is: 'email',
    then: yup.string().required(messageError.regEmail).email(validateError.general),
  }),
  regMobile: yup.string().when('regSendLinkType', {
    is: 'sms',
    then: yup.string().required(messageError.regMobile).matches(phoneRegExp, validateError.general),
  }),
})

export const schemaInformationSection = yup.object().shape({
  searchMethod: yup.string().required(messageError.general),
  personalId: yup
    .string()
    .when('searchMethod', {
      is: 'passport',
      then: yup
        .string()
        .required(messageError.personalId)
        .matches(passportExp, validateError.general)
        .min(5, validateError.general)
        .max(10, validateError.general),
    })
    .when('searchMethod', {
      is: 'citizenId',
      then: yup
        .string()
        .required(messageError.personalId)
        .test('validateCitizenId', validateError.citizenId, (value) => validateCitizenId(value)),
    }),

  policyEffectiveDate: yup.string().when('searchMethod', {
    is: 'policyId',
    then: yup
      .string()
      .required(messageError.policyEffectiveDate)
      .matches(policyEffectiveDate, validateError.policyEffectiveDate),
  }),

  policyNo: yup
    .string()
    .matches(policyNoReExp, { message: validateError.policy, excludeEmptyString: true })
    .when('searchMethod', {
      is: 'policyId',
      then: yup.string().required(messageError.policyNo).matches(policyNoReExp, validateError.policy),
    }),
})

export const handleSetValue = (key, val, hookFormAPI, validateOption?) => {
  const { setValue, setError, clearErrors, formState } = hookFormAPI
  const { isSubmitted } = formState
  if (val) {
    if (validateOption) {
      const { regex, errorMessage } = validateOption
      if (!regex.test(val)) {
        setError(key, {
          type: 'manual',
          message: errorMessage,
        })
      } else {
        clearErrors(key)
        setValue(key, val)
      }
    } else {
      clearErrors(key)
      setValue(key, val)
    }
  } else {
    if (isSubmitted) {
      setError(key, {
        type: 'manual',
        message: messageError.general,
      })
    } else {
      clearErrors(key)
    }
  }
}

export const handleResetDataField = (reset: Function, fieldObject?: any) => {
  if (fieldObject) {
    reset(fieldObject)
  } else {
    reset({
      personalId: '',
      searchMethod: 'citizenId',
      policyNo: '',
      policyEffectiveDate: '',
    })
  }
}

export const validateRegisterFormInput = (obj) => {
  // Some fields not include regMobile and regEmail are empty
  const isEmptyField = Object.keys(obj)
    .filter((key) => key !== 'regMobile' && key !== 'regEmail')
    .some((key) => obj[key] === '')

  // Both regMobile and regEmail are empty
  const areMobileAndEmailEmpty = obj.regMobile === '' && obj.regEmail === ''

  // Either regMobile or regEmail is not empty
  const isMobileOrEmailNotEmpty = obj.regMobile !== '' || obj.regEmail !== ''

  if (isEmptyField || (areMobileAndEmailEmpty && !isMobileOrEmailNotEmpty)) {
    return false
  }

  return true
}
