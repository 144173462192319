import { linkPaymentMethod, personalIdType } from 'domain/entities/ecbr'

export default {
  tabsProgress: [
    {
      iconId: 'ECBR_PAYMENT_VERIFY',
      id: 'verify',
      title: 'ตรวจสอบกรมธรรม์',
      disabled: false,
    },
    {
      iconId: 'ECBR_PAYMENT_INFO',
      id: 'info',
      title: 'ระบุข้อมูลการสมัคร',
      disabled: true,
    },
    {
      iconId: 'ECBR_PAYMENT_STATUS',
      id: 'status',
      title: 'สถานะการสมัคร',
      disabled: true,
    },
  ],

  regInfoSection: {
    textInputs: [
      {
        label: 'ชื่อผู้เอาประกัน',
        id: 'regName',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 30,
        change: false,
      },
      {
        label: 'นามสกุลผู้เอาประกัน',
        id: 'regSurname',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 50,
        change: false,
      },
      {
        label: 'เลขที่กรมธรรม์',
        id: 'regPolicyNo',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
        mask: 'X',
        format: '###-#######',
        valueFormat: 'formattedValue',
      },
      {
        id: 'regPersonalIdType',
        subInputId: 'regPersonalId',
        label: 'เลขบัตรประชาชนหรือหนังสือเดินทาง',
        mask: '_',
        format: '# #### ##### ## #',
        valueFormat: 'formattedValue',
        options: personalIdType,
      },
      {
        label: 'ประเภทของการชำระเงิน',
        id: 'regPaymentType',
        type: 'select',
        defaultValue: {
          label: 'ชำระเบี้ยปีต่อ',
          value: '02',
        },
        isDisabled: true,
        change: true,
        options: [
          {
            text: 'ชำระเบี้ยปีต่อ',
            label: 'ชำระเบี้ยปีต่อ',
            value: '02',
          },
        ],
      },
      {
        label: 'ชื่อผลิตภัณฑ์ / แบบประกัน',
        id: 'regProductCode',
        type: 'select',
        defaultValue: '',
        maxLength: 50,
        options: [],
      },
    ],
    sendLinkSection: {
      sendLinkMode: {
        id: 'regSendLinkType',
        title: 'ท่านต้องการส่งลิงก์สมัครหักค่าเบี้ยประกันอัตโนมัติผ่าน บัตรเครดิตไปที่',
        options: linkPaymentMethod,
      },
      sendLinkMobile: {
        id: 'regMobile',
        type: 'text',
        defaultValue: '',
        mask: '_',
        format: '### ### ####',
        valueFormat: 'value',
        change: true,
        isAllowed: ({ formattedValue }) => formattedValue.length <= 19,
      },
      sendLinkEmail: {
        id: 'regEmail',
        type: 'text',
        defaultValue: '',
        maxLength: 50,
        change: true,
      },
    },
  },

  informationSection: {
    searchMethod: {
      id: 'searchMethod',
      options: [
        {
          text: 'ค้นหาจากเลขบัตรประชาชน',
          value: 'citizenId',
        },
        {
          text: 'ค้นหาจากเลขหนังสือเดินทาง',
          value: 'passport',
        },
        {
          text: 'ค้นหาจากเลขกรมธรรม์',
          value: 'policyId',
        },
      ],
    },
    identity: {
      id: 'personalId',
      label: 'เลขบัตรประชาชนหรือหนังสือเดินทาง',
      mask: '_',
      format: '# #### ##### ## #',
    },
    policyNo: {
      label: 'เลขที่กรมธรรม์',
      id: 'policyNo',
      type: 'text',
      defaultValue: '',
      maxLength: 100,
      mask: 'X',
      format: '###-#######',
      valueFormat: 'formattedValue',
    },
    policyEffectiveDate: {
      label: 'วันที่กรมธรรม์มีผลบังคับ',
      id: 'policyEffectiveDate',
      type: 'text',
      defaultValue: '',
      maxLength: 10,
      mask: 'X',
      format: '####-##-##',
      valueFormat: 'formattedValue',
    },
    textInputs: [
      {
        label: 'ชื่อผู้เอาประกัน',
        id: 'insuredName',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 30,
        change: false,
      },
      {
        label: 'นามสกุลผู้เอาประกัน',
        id: 'insuredSurName',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 50,
        change: false,
      },
      {
        label: 'เบอร์โทรศัพท์ผู้เอาประกัน',
        id: 'insureMobile',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
        mask: '_',
        format: '### ### ####',
        valueFormat: 'value',
        change: true,
      },
      {
        label: 'อีเมลผู้เอาประกัน',
        id: 'insureEmail',
        type: 'text',
        defaultValue: '',
        maxLength: 50,
        change: true,
      },
      {
        label: 'จำนวนเงินที่ชำระ (บาท)',
        id: 'paymentAmount',
        type: 'text',
        defaultValue: '',
        maxLength: 19,
        change: true,
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        isAllowed: ({ formattedValue }) => formattedValue.length <= 19,
      },
    ],
  },

  checkStatusSection: {
    mainTitle: {
      id: 'checkStatusTitle',
      title: 'สร้างลิงก์เพื่อสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต',
    },
    detail: {
      subTitle: 'ช่องทางที่ท่านระบุในการส่งลิงก์สมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต',
      result: 'ผลการตรวจสอบการสมัครชำระเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต',
      sendLinkMethod: 'ท่านเลือกสมัครผ่านช่องทาง',
      status: (successDate, successTime, maskedCreditcard) =>
        `สมัครเรียบร้อยเมื่อวันที่ ${successDate} เวลา ${successTime} น.`,
      maskedCreditCard: (maskedCreditcard) => `ลงท้ายด้วย xxxx-xxxx-xxxx-${maskedCreditcard?.slice(-4)}`,
      info: (date, time) =>
        `ลิงก์สมัครหักค่าเบี้ยประกันของท่านจะหมดอายุภายในวันที่ ${date} เวลา ${time} น. หรือภายใน 60 นาทีหลังจากได้รับข้อความ กรุณาตรวจสอบการสมัครกับผู้อาประกัน`,
      success: 'บัตรเครดิตที่ท่านสมัครหักค่าเบี้ยประกันอัตโนมัติจะทำการหักบัตรเครดิตในรอบถัดไป',
      buttonCheckStatus: 'ตรวจสอบสถานะ',
      buttonCreateLink: 'สร้างลิงก์อีกครั้ง',
    },
  },
  genLinkResultDialogContents: {
    EXPIRED: {
      message:
        'ลิงค์สำหรับการสมัครเพื่อหักค่าเบี้ยประกันอัตโนมัติหมดอายุแล้วเมื่อวันที่ xx/xx/xxxx เวลา xx.xx น. กรุณากด ยืนยัน เพื่อดำเนินการสร้างลิงก์ในการสมัครอีกครั้ง',
    },
    HTTP_ERROR: {
      message: 'ขออภัย ขณะนี้ระบบไม่สามารถทำการตรวจสอบผลการสมัครได้ กรุณากดยืนยันเพื่อไปยังขั้นตอนถัดไป',
    },
    PENDING: {
      message:
        'ไม่พบข้อมูลการสมัคร กรุณาตรวจสอบสถานะการสมัครเพื่อหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิตกับผู้เอาประกันอีกครั้ง',
    },
  },
}
