import { useEffect, useContext } from 'react'

import { RouterContext } from 'contexts/router'

export const useRouterNav = (props?) => {
  const RouterContextProps = useContext(RouterContext)
  const setNavConfigByPage = RouterContextProps.setNavConfigByPage || props?.setNavConfigByPage
  useEffect(() => {
    setNavConfigByPage({
      showFooter: false,
    })
  }, [])
}
