import React from 'react'
import styled from '@emotion/styled'

const SpaceInputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
type BlockSpaceProps = {
  width: number
}

const BlockSpace = styled.div<BlockSpaceProps>`
  width: ${(props) => (props.width ? props.width : '48%')};
`

const SpaceBetweenInput = (props) => {
  const { inputForm1, inputForm2, width1, width2 } = props
  return (
    <SpaceInputContainer>
      <BlockSpace width={width1}>{inputForm1}</BlockSpace>
      <BlockSpace width={width2}>{inputForm2}</BlockSpace>
    </SpaceInputContainer>
  )
}

export default SpaceBetweenInput
