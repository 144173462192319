import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import AttachUpload from './attach-upload'
import AttachDone from './attach-done'
import { textAndNumberOnlyRegExp } from '../hooks/useForm'

const AttachBlank = styled.div`
  background-color: #fff;
  border: 1px dotted;
  padding: 1rem;
  margin-bottom: 1rem;
`

type AttachProps = {
  state: {
    infoIssue: any
    attachment: [any]
    method: string
  }
  setState: () => void
}

function AttachContainer(props: AttachProps) {
  const { state, setState } = props
  const [attachName, setAttachName] = useState('')
  const [disabled, setDisabled] = useState(true)

  const onChange = (v) => {
    let value = v.target.value
    value = value.replace(textAndNumberOnlyRegExp, '')
    setAttachName(value)
  }

  useEffect(() => {
    if (attachName.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [attachName])

  const mapAttachType = {
    blank: (item) => <AttachBlank key={item.id} />,
    uploading: (item) => (
      <AttachUpload
        key={item.id}
        disabled={disabled}
        onChange={onChange}
        state={state}
        setState={setState}
        item={item}
        attachName={attachName}
        setAttachName={setAttachName}
      />
    ),
    done: (item) => <AttachDone key={item.id} item={item} state={state} setState={setState} />,
  }

  return (
    <>
      {state.attachment.map((item) => {
        return mapAttachType[item.status](item)
      })}
    </>
  )
}

export default AttachContainer
