import React, { useState } from 'react'
import dayjs from 'dayjs'
import _isEmpty from 'lodash/isEmpty'

import { UploadImage } from 'components/input'
import { BlockText, ContentContainer, SectionContainer } from 'components/layouts'
import { KTAXAQRCodePayment } from 'components/ktaxa-qr-code'
import { renderErrorMessageBlock } from 'components/message-blocks'
import SubmitSection from './submit'
import { qrBillerId, qrSuffix } from 'domain/entities/ecbr'

import staticContentPage from '../static-content'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import { clearFormat } from 'utils/string-helper'
import { PropsSectionType } from '../type'
import { getConfig, getToggles } from 'core/config'

const PaymentInsurancePremiumSection = (props: PropsSectionType) => {
  const { tabState, state, setState } = props
  const infoData = { ...state?.info?.payload }
  const payloadData = { ...state?.process?.payload?.data }
  const isQuickPay = infoData.paymentMethod === 'quickpay'
  const ref2 = !_isEmpty(payloadData?.ref2) ? payloadData?.ref2 : payloadData?.cbrNumber + infoData?.paymentType
  const ActiveCondition = tabState === staticContentPage.tabsProgress[2].id && !isQuickPay
  if (!ActiveCondition) return null
  const [isSubmit, setIsSubmit] = useState(false)
  const [isOverLimit, setIsOverLimit] = useState(false)
  const { limitFileSize } = getConfig()
  const modifiedPersonalId = !_isEmpty(infoData) && clearFormat(infoData?.personalId, ' ')
  const modifiedPolicyNo = !_isEmpty(infoData) && clearFormat(infoData?.policyNo, '-')
  const IntAmount = !_isEmpty(infoData) ? +clearFormat(infoData?.paymentAmount, ',') : 0
  const isEcbrSystemization = getToggles().ENABLE_ECBRSYS_ECBR
  const isUpdateQrSlipImg = isEcbrSystemization && state?.isUpdateQrSlipImg // case systemization premium down || case submit limit exceed

  const shouldDisableSubmitButton = () => {
    const payloadEmpty = _isEmpty(state?.summary?.payload)

    if (isEcbrSystemization) {
      return isUpdateQrSlipImg && payloadEmpty
    }
    return payloadEmpty
  }

  const qrProps = {
    ref1: modifiedPolicyNo || modifiedPersonalId,
    ref2,
    amount: IntAmount,
    size: 200, // size of qrCode
    showAmount: true,
  }

  const onUpload = (base64) => {
    setState({
      ...state,
      [staticContentPage.tabsProgress[2].id]: {
        payload: {
          imgBase64: base64,
        },
      },
    })
  }

  const handleSubmit = (callBack) => {
    if (_isEmpty(state?.summary?.payload)) {
      // check data is submitted
      setIsSubmit(true)
      return
    }
    callBack()
  }

  const dateFormat = dayjs().format('DD/MM/YYYY')
  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraButton: () => (
        <SubmitSection
          isCreateEcbrNoButton={true}
          isDisabled={shouldDisableSubmitButton()}
          tabState={tabState}
          handleSubmit={handleSubmit}
          state={state}
          setState={setState}
        />
      ),
    }
  )

  const renderInformation = () => {
    return getToggles().ENABLE_ECBRSYS_ECBR ? ( //for systemization
      <>
        <BlockText>Biller ID:</BlockText>
        <BlockText right>{qrBillerId}</BlockText>
        <BlockText>Suffix:</BlockText>
        <BlockText right>{qrSuffix}</BlockText>
        <BlockText>Ref 1:</BlockText>
        <BlockText right>{infoData.policyNo || infoData.personalId}</BlockText>
        <BlockText>Ref 2:</BlockText>
        <BlockText right>{ref2}</BlockText>
      </>
    ) : (
      <>
        <BlockText>{staticContentPage.paymentInsurancePremiumSection.paymentCategory}</BlockText>
        <BlockText right>
          {state.paymentType}/{state.paymentMode}/{state.paymentMethod}
        </BlockText>

        <BlockText>{staticContentPage.paymentInsurancePremiumSection.passportNumber}</BlockText>
        <BlockText right>{infoData.personalId}</BlockText>

        <BlockText>{staticContentPage.paymentInsurancePremiumSection.fullName}</BlockText>
        <BlockText right>{`${infoData.insuredName} ${infoData.insuredSurName}`}</BlockText>

        <BlockText>{staticContentPage.paymentInsurancePremiumSection.ref1}</BlockText>
        <BlockText right>{infoData.policyNo || infoData.personalId}</BlockText>

        <BlockText>{staticContentPage.paymentInsurancePremiumSection.ref2}</BlockText>
        <BlockText right>{ref2}</BlockText>
        <BlockText>{staticContentPage.paymentInsurancePremiumSection.paymentDate}</BlockText>
        <BlockText right>{dateFormat}</BlockText>
      </>
    )
  }

  return (
    <ContentContainer>
      <h1>ชำระเงินผ่าน QR Code</h1>
      <SectionContainer>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            background: '#ffffff',
            padding: 20,
          }}
        >
          <KTAXAQRCodePayment {...qrProps} />
          {renderInformation()}
          {(!isEcbrSystemization || isUpdateQrSlipImg) && (
            <div style={{ width: '100%' }}>
              <UploadImage
                onSelected={onUpload}
                value={state?.summary?.payload?.imgBase64}
                label={'แนบสำเนาใบนำฝาก'}
                setIsOverLimit={setIsOverLimit}
              />
              {isSubmit &&
                !state?.summary?.payload?.imgBase64 &&
                renderErrorMessageBlock({ message: 'โปรดแนบสำเนาใบนำฝาก' })}
              {isOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
            </div>
          )}
        </div>
      </SectionContainer>
    </ContentContainer>
  )
}

export default PaymentInsurancePremiumSection
