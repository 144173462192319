import React, { useState } from 'react'
import styled from '@emotion/styled'

import { ContentContainer, SectionContainer } from 'components/layouts'
import { BlankCard, ECBRCard } from 'components/cards'

import staticContentPage from '../../static-content'
import { useLogin } from '../../../../hooks/useServices/login'
import { useRouterForHandleSummit } from '../../hooks/useRouter'
import { useFeatureToggleEcbrSys, useHandleDataForm } from './hook'
import { getToggles } from 'core/config'
import { NoWrapTabs } from 'components/tabs'
import ApiDownDialog from 'components/api-down-dialog'

const Message = styled.div`
  color: #333;
`

const PendingListSection = (props) => {
  const { state, tabState } = props

  const { onSubmit } = useHandleDataForm({ ...props })
  const { isHideLegacyTab } = useLogin({})
  const [selectedTab, setSelectedTab] = useState('DRAFT')

  const [{ data, isLoaded, isLoading, error }, clearError] = useFeatureToggleEcbrSys({
    query: '',
    tab: selectedTab,
  })

  const pendingSubmenu = isHideLegacyTab
    ? staticContentPage.pendingSubmenu.filter((item) => item.id !== 'LEGACY')
    : staticContentPage.pendingSubmenu

  const ActiveCondition = tabState === staticContentPage.tabsProgress[0].id
  useRouterForHandleSummit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      backButton: { toHome: true },
    }
  )

  if (!ActiveCondition) return null

  const readyToRender = isLoaded && !isLoading && !error && data.length

  const renderPendingList = () => {
    if (isLoading) {
      return <Message>กำลังค้นหา...</Message>
    }

    if (error) {
      return <ApiDownDialog show={error != null} onToggle={() => clearError()} />
    }

    if (data.length === 0) {
      if (getToggles().ENABLE_ECBRSYS_ECBR) {
        return <BlankCard />
      } else {
        return <Message>ไม่มีรายการชำระเบี้ย</Message>
      }
    }

    if (readyToRender) {
      return data.map((ecbrItem) => {
        const {
          insuredName,
          insuredSurName,
          policyNumber,
          paymentAmount,
          cbrStatus,
          createdAt,
          daySinceCreated,
          cbrNumber,
        } = ecbrItem
        return (
          <ECBRCard
            key={cbrNumber}
            onClick={() => onSubmit(ecbrItem)}
            name={`${insuredName} ${insuredSurName}`}
            policyNumber={policyNumber}
            amount={paymentAmount}
            status={cbrStatus}
            createdAt={createdAt.toString()}
            daySinceCreated={daySinceCreated}
            paymentNo={cbrNumber}
            selectedTab={selectedTab}
          />
        )
      })
    }
  }

  return (
    <ContentContainer>
      <h1>{staticContentPage.pendingList.title}</h1>
      {getToggles().ENABLE_ECBRSYS_ECBR ? (
        <>
          <NoWrapTabs tabSelected={selectedTab} selectTab={setSelectedTab} tabs={pendingSubmenu} />{' '}
          <div
            style={{
              marginBottom: '1rem',
            }}
          ></div>
        </>
      ) : null}
      <SectionContainer>{renderPendingList()}</SectionContainer>
    </ContentContainer>
  )
}

export default PendingListSection
