import React from 'react'
import { Dialog } from 'ktaxa-ui-components/index'
import styled from '@emotion/styled'
import { color, customIcon } from 'components/theme'

const Content = styled.div`
  padding: 15px 0;
`

const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
`

const TitleContainer = styled.div`
  position: relative;
  text-align: center;
`

const Icon = styled.div`
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background-color: ${color.common.lightGray};
  mask-image: url(${customIcon('closeIcon')});
  mask-size: cover;
  mask-repeat: no-repeat;
  z-index: 1000;
`

const CCPASystemErrorDialog = (props) => {
  const { show, onClose } = props

  return (
    <>
      <Dialog centered modal show={show} onClose={onClose}>
        <Icon onClick={onClose} />
        <TitleContainer>
          <Title>ระบบไม่พร้อมให้บริการ</Title>
        </TitleContainer>
        <Content>
          <div style={{ textAlign: 'center' }}>ขณะนี้ระบบไม่พร้อมให้บริการ</div>
          <div style={{ textAlign: 'center' }}>
            กรุณาติดต่อ 1159 เพื่อขอรับลิงก์การสมัครชำระเบี้ยอัตโนมัติผ่านบัตรเครดิต
          </div>
        </Content>
      </Dialog>
    </>
  )
}

export default CCPASystemErrorDialog
