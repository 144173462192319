import { Connect } from 'adapters/repositories/ecbr-middleware/connect'
import { useRouterForHandleSummit } from '../../hooks/useRouter'

import staticContentPage from '../../static-content'
import { useCallback, useEffect, useState } from 'react'
import { useECBRPending } from '../../../../hooks/useServices/ecbr/index'
import { getToggles } from 'core/config'

export const useHandleDataForm = (props) => {
  const { setState, state, tabState, setTabState } = props
  const onSubmit = (ecbrItem) => {
    const isChangePaymentMethod = ecbrItem?.paymentMethod === ''
    if (ecbrItem.paymentMethod === 'quickpay') {
      const isSendLink = ecbrItem.paymentLink?.isSendLink || false
      const payloadData = {
        insureEmail: ecbrItem.paymentLink?.email || '',
        insureMobile: ecbrItem.paymentLink?.sms || '',
        // additional Data that useForm need to validate (required field)
        insuredName: ecbrItem.insuredName || '',
        insuredSurName: ecbrItem.insuredSurName || '',
        paymentAmount: (ecbrItem.paymentAmount || '').toString(),
        paymentMode: ecbrItem.paymentMode || '',
        paymentType: ecbrItem.paymentType || '',
        personalId: ecbrItem.personalId || '',
        policyEffectiveDate: ecbrItem.policyEffectiveDate || '',
        policyNo: ecbrItem.policyNumber || '',
        productCode: ecbrItem.productCode || '',
        premiumDueDate: ecbrItem.premiumDueDate || '',
      }
      setState({
        ...state,
        pendingList: ecbrItem,
        // tempData will use for update state in useTabs hook when tabState is changed
        tempData: {
          isCheckPaymentStatus: isSendLink,
          info: {
            payload: {
              paymentMethod: 'quickpay',
              ...payloadData,
            },
          },
          createPaymentLinkData: {
            expiredDate: ecbrItem.paymentLink?.expiredDatetime || '',
          },
          process: {
            payload: {
              data: {
                policyNumber: ecbrItem.policyNumber || '',
                cbrNumber: ecbrItem.cbrNumber || '',
                ...payloadData,
              },
            },
          },
        },
      })
      setTabState(staticContentPage.tabsProgress[4].id)
    } else if (isChangePaymentMethod) {
      setState({
        ...state,
        isChangePaymentMethod,
        pendingList: ecbrItem,
        process: {
          payload: {
            data: ecbrItem,
          },
        },
      })
      setTabState(staticContentPage.tabsProgressPayment[0].id)
    } else {
      setState({
        ...state,
        pendingList: ecbrItem,
      })

      if (ecbrItem.cbrStatus === 'PENDING') {
        setTabState(staticContentPage.tabsProgress[1].id)
      } else {
        setTabState(staticContentPage.tabsProgress[4].id)
      }
    }
  }

  const ActiveCondition = tabState === staticContentPage.tabsProgress[0].id
  useRouterForHandleSummit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: false,
    }
  )

  return { onSubmit }
}

export type PendingListEcbrSys = {
  ecbrList: EcbrList[]
}

export type EcbrList = {
  ecbrNo: string
  ecbrStatus: string
  insuredFirstName: string
  insuredLastName: string
  policyNo: string
  amount: number
  creatDate: string
  insuredEmail: any
  insuredMobilephone: string
}

type FeatureToggleFetchProps = {
  query?: string
  tab?: string
}

type FetchDataResult = {
  total: number
  data: any[] // any for now
  isLoaded: boolean
  isLoading: boolean
  error: string | null
}

export const useFeatureToggleEcbrSys = ({ query, tab = 'DRAFT' }: FeatureToggleFetchProps) => {
  const [data, setData] = useState<FetchDataResult>({
    total: 0,
    data: [],
    isLoaded: false,
    isLoading: true,
    error: null,
  })
  const [oldApiData] = useECBRPending({ query })

  const clearError = useCallback(() => {
    setData((prev) => ({ ...prev, error: null }))
  }, [])

  useEffect(() => {
    let isMounted = true

    const fetchNewApiData = async () => {
      if (!isMounted) return
      setData((prev) => ({ ...prev, isLoading: true, error: null }))

      try {
        let data
        const connectApi = new Connect()

        if (tab === 'DRAFT') {
          data = await connectApi.get(`/ecbr/advisor/draft${getToggles().ENABLE_ECBRSYS_ECBR ? '?date_range=7' : ''}`)
        } else if (tab === 'LEGACY') {
          data = await connectApi.get(`/ecbr/advisor/pending`)
        } else {
          data = await connectApi.post(
            '/ecbrsys/agent/receipts',
            JSON.stringify({
              ecbrStatus: ['CREATED'],
              createBy: 'CB',
            })
          )
        }

        setData({
          ...data,
          isLoaded: true,
          isLoading: false,
          error: null,
        })
      } catch (error) {
        console.error('Error fetching data:', error)
        if (isMounted) {
          setData((prev) => ({
            total: 0,
            data: [],
            isLoaded: true,
            isLoading: false,
            error: 'API Failed',
          }))
        }
      }
    }

    if (getToggles().ENABLE_ECBRSYS_ECBR) {
      fetchNewApiData()
    } else {
      setData((prev) => ({ ...prev, isLoading: false, isLoaded: true }))
    }

    return () => {
      isMounted = false
    }
  }, [query, tab])

  return getToggles().ENABLE_ECBRSYS_ECBR
    ? ([data, clearError] as const)
    : ([{ ...oldApiData, isLoading: false, error: null }, () => {}] as const)
}
