import React, { useState } from 'react'
import { cancelState, handleInitAttach } from './hooks/service'
import CancelationList from './sections/cancelation-ecbr-list'
import CancelMethodSection from './sections/cancel-method-section'
import CancelUploadSection from './sections/cancel-upload-section'
import CancelFormSection from './sections/cancel-form-section'
import staticContentPage from './static-content-page'
import { getToggles } from 'core/config'

const CancelEcbrPage = () => {
  const initAttachmentList = handleInitAttach({ maxAttachment: 4 })
  const [currentSection, setCurrentSection] = useState(staticContentPage.cancelationSequence[0])
  const [state, setState] = useState({
    infoIssue: {},
    method: getToggles().ENABLE_ECBRSYS_ECBR ? 'fillForm' : 'uploadFile',
    attachment: initAttachmentList,
  } as cancelState)

  return (
    <>
      <CancelationList
        setCurrentSection={setCurrentSection}
        setState={setState}
        currentSection={currentSection}
        state={state}
      />
      <CancelMethodSection
        setCurrentSection={setCurrentSection}
        setState={setState}
        currentSection={currentSection}
        state={state}
      />
      <CancelUploadSection
        setCurrentSection={setCurrentSection}
        setState={setState}
        currentSection={currentSection}
        state={state}
      />
      <CancelFormSection
        setCurrentSection={setCurrentSection}
        setState={setState}
        currentSection={currentSection}
        state={state}
      />
    </>
  )
}

export default CancelEcbrPage
