/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'

import styles from './radio-styles'
import RadioGroup, { UI_STYLE } from './radio-group'

const propTypes = {
  /**
   * Group name
   */
  name: PropTypes.string,
  /**
   * Elements to be rendered as children of this component.
   */
  children: PropTypes.node,
  /**
   * Label test for this option.
   */
  label: PropTypes.string,
  /**
   * One or more class names to be added to the root element of this component, i.e. `"class-foo class-bar"`.
   */
  className: PropTypes.string,
  /**
   * Option value
   */
  value: PropTypes.string,
  /**
   * Specifies whether the option is checked.
   */
  checked: PropTypes.bool,
  /**
   * Disables the option if set to true. Disabled option won't trigger the `onChange`.
   */
  disabled: PropTypes.bool,
  /**
   * Style of checked item.
   */
  uiStyle: PropTypes.string,
  /**
   * A callback function, executed when the option is changed.
   *
   * ```(value) => {}```
   */
  onChange: PropTypes.func,
  /**
   * Elements to be rendered as message info of this component.
   */
  messengeBox: PropTypes.node,
  /**
   * Toggle MessageBox
   */
  isShowMessageBox: PropTypes.bool,
  /**
   * A callback function, executed when the option is changed.
   *
   * ```(value) => {}```
   */
  onClick: PropTypes.func,
}

const defaultProps = {
  name: 'radio-button',
  children: '',
  label: '',
  className: '',
  value: '',
  disabled: false,
  checked: false,
  uiStyle: UI_STYLE.Radio,
  messengeBox: '',
  isShowMessageBox: false,
  onChange() {},
  onClick() {},
}

/**
 * `<Radio>` allow the user to choose from the multiple options.
 * It is recommend to have the options  between 5 given answers or less. If you're using more than 5 answers, we recommend using `<Select>`.
 *
 * Controlled component. Value will be set by prop.
 */
const Radio = (props) => {
  const { name, children, label, className, value, checked, disabled, uiStyle, onChange, messengeBox,isShowMessageBox, onClick } = props
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const doOnChange = (e) => {
    const value = e.target.value
    const label = e.target.getAttribute('data-label')
    if (!disabled) {
      onChange({ value, label })
    }
  }

  const radioStyles = [styles.radio, styles.radioType[uiStyle]]
  const radioProps = {}

  if (disabled) {
    radioStyles.push(styles.disabled)
    radioProps.disabled = true
  }

  return (
    // safari cannot read css from css emotion.
    // wating for find best solution than styles={{}}.
    <div className={className} css={styles.radioContainer[uiStyle]}>
      <label css={radioStyles}>
        <input
          {...radioProps}
          type="radio"
          name={name}
          checked={isChecked}
          value={value}
          data-label={label}
          onChange={doOnChange}
          onClick={onClick}
        />
        <span data-checked={isChecked} data-ui-style={uiStyle} css={[styles.ui]} />
        <div css={styles.radioLabel}>{children || label}</div>
      </label>
      {isShowMessageBox && messengeBox }
    </div>
  )
}

Radio.displayName = 'Radio'
Radio.propTypes = propTypes
Radio.defaultProps = defaultProps
Radio.Group = RadioGroup
Radio.UIStyle = RadioGroup.UIStyle

export default Radio
