import React, { useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'

import { Button } from 'ktaxa-ui-components/index'
import { ErrorDialog } from 'components/index'
import { getToggles } from 'core/config'
import { createIssueEcbr, RespType, createEcbrPaid, createEcbrNumber, updateEcbr } from '../hooks/useService'
import { useLogin } from '../../../hooks/useServices/login'
import NewECBRCreatedDialog from 'components/modal/ecbr-systemization/ecbr-created-dialog'
import ChequeLoadingDialog from 'components/modal/ecbr-systemization/cheque-loading-dialog'
import CreateEcbrNumberStatusDialog from 'components/modal/ecbr-systemization/create-ecbr-number-status-dialog'
import { AppContext } from 'contexts/app'
import _ from 'lodash'

import staticContentPage from '../static-content'

const SubmitSection = (props) => {
  const [modal, setModal] = useState(false)
  const [data, setData] = useState<RespType>({} as RespType)
  const [loading, setLoading] = useState(false)

  const { planCodeOptions } = useContext(AppContext)
  const { isHideEDC } = useLogin({})
  const { handleSubmit, tabState, state, setState, history, isDisabled, isCreateEcbrNoButton } = props

  const receiptId =
    state?.pendingList?.receiptId || state?.info?.payload?.receiptId || state?.process?.payload?.data?.receiptId

  const isEnableEcbrSys = getToggles().ENABLE_ECBRSYS_ECBR && !_.isEmpty(receiptId)

  const submitTextButton = isEnableEcbrSys && isCreateEcbrNoButton ? 'ออกใบเสร็จ' : 'ยืนยัน'

  const isQrNoPayment = data?.data?.createEcbrNoStatus === 'NO_PAYMENT'
  const isPremiumDown = data?.data?.createEcbrNoStatus === 'PREMIUM_DOWN'
  const isSubmitLimitExceeded = data?.data?.createEcbrNoStatus === 'SUBMIT_LIMIT_EXCEEDED'
  const isCreateEcbrError = data?.data?.createEcbrNoStatus === 'ERROR'

  const isQrUpdateSlip = isPremiumDown || isSubmitLimitExceeded // systemization
  const isCheque = state?.info?.payload?.paymentMethod === 'cheque'

  const handleToggleCreateEcbrStatus = () => {
    toggle()
    if (isQrNoPayment) {
      setState({ ...state, ecbrNoPayCount: (state.ecbrNoPayCount || 0) + 1 })
    }
    if (isQrUpdateSlip) {
      setState({ ...state, isUpdateQrSlipImg: true })
    }
  }

  const toggle = () => setModal(!modal)
  const closeModal = () => {
    toggle()
    history.push('/')
  }

  const handleSubmitData = async () => {
    setLoading(true)
    const isHideCash = getToggles().HIDE_UNUSED_PAYMENT
    const isEnableCCLINK = getToggles().ENABLE_CCLINK_PAYMENT

    const summitWithoutIbanking =
      tabState === staticContentPage.tabsProgress[1].id &&
      state?.info?.payload?.paymentMethod !==
        staticContentPage.informationSection.categoryPaymentMethod.options(isHideCash, isEnableCCLINK, isHideEDC)[0]
          .value
    const summitWithIbanking =
      tabState === staticContentPage.tabsProgress[1].id &&
      state?.info?.payload?.paymentMethod ===
        staticContentPage.informationSection.categoryPaymentMethod.options(isHideCash, isEnableCCLINK, isHideEDC)[0]
          .value

    const isQuickpay = state?.info?.payload?.paymentMethod === 'quickpay'
    const isQuickPayDown =
      !_.isEmpty(state?.summary?.payload?.imgBase64) && tabState === staticContentPage.tabsProgress[2].id && isQuickpay
    const submitGenLink = tabState === staticContentPage.tabsProgress[1].id && isQuickpay
    const paymentGenLink = tabState === staticContentPage.tabsProgress[2].id && isQuickpay

    const checkPaymentStatus =
      tabState === staticContentPage.tabsProgress[2].id && (state?.isCheckPaymentStatus || false)

    if (isQuickPayDown) {
      // systemization case quickpay service down
      const res = await createEcbrNumber(state)
      setData(res)
      toggle()
      setLoading(false)
    } else if (paymentGenLink) {
      await handleSubmit()

      setLoading(false)
    } else if (submitGenLink) {
      const res = await createIssueEcbr(state, planCodeOptions)
      if (res?.error) {
        toggle()
      } else {
        handleSubmit(res)
      }
      setLoading(false)
    } else if (summitWithoutIbanking) {
      console.log('r u cheque here?')
      const res = await createIssueEcbr(state, planCodeOptions)
      setData(res)

      toggle()
      setLoading(false)
    } else if (summitWithIbanking) {
      const res = await createIssueEcbr(state, planCodeOptions)
      if (res?.error) {
        toggle()
      } else {
        handleSubmit(res)
      }
      setLoading(false)
    } else if (checkPaymentStatus) {
      handleSubmit()
      setLoading(false)
    } else if (state?.isUpdateQrSlipImg) {
      // systemization case premium collection down
      const res = await updateEcbr(state)
      setData(res)
      toggle()
      setLoading(false)
    } else if (tabState === staticContentPage.tabsProgress[2].id) {
      // ibanking case
      let res
      if (isEnableEcbrSys) {
        res = await createEcbrNumber(state)
      } else {
        res = await createEcbrPaid(state)
      }
      setData(res)
      toggle()
      setLoading(false)
    } else {
      handleSubmit()
      setLoading(false)
    }
    // after summit will push next tab
  }

  const renderModal = () => {
    let dialog

    if (loading && isCheque) {
      dialog = <ChequeLoadingDialog show={loading} onToggle={closeModal} />
    } else if (isQrNoPayment || isQrUpdateSlip || isCreateEcbrError) {
      dialog = (
        <CreateEcbrNumberStatusDialog show={modal} ecbrData={data?.data} onToggle={handleToggleCreateEcbrStatus} />
      )
    } else if (data?.data && !data?.error) {
      dialog = <NewECBRCreatedDialog ecbrData={data?.data} show={modal} onToggle={closeModal} />
    } else {
      dialog = <ErrorDialog show={modal} onToggle={() => history.push('/')} actionLabel={'กลับสู่หน้าหลัก'} />
    }

    return dialog
  }

  return (
    <>
      <Button disabled={loading || isDisabled} onClick={handleSubmitData} type="primary">
        {loading ? 'กำลังบันทึกข้อมูล' : submitTextButton}
      </Button>
      {renderModal()}
    </>
  )
}

export default withRouter(SubmitSection)
