export default {
  title: 'ระบบใบรับเงินชั่วคราวแบบอิเล็กทรอนิกส์',
  subTitle: '',
  menus: [
    {
      title: 'ออกใบชำระเงินชั่วคราว',
      iconId: 'ECBR_ISSUE_NEW',
      path: '/new-ecbr',
      disabled: false,
    },
    {
      title: 'รายการรอชำระเงิน',
      iconId: 'ECBR_PAYMENT',
      path: '/pending',
      disabled: false,
    },
    {
      title: 'ประวัติการออกใบรับเงินชั่วคราว',
      iconId: 'ECBR_HISTORY',
      path: '/history',
      disabled: false,
    },
    {
      title: 'แจ้งยกเลิกใบรับเงินชั่วคราว',
      iconId: 'ECBR_CANCELLATION',
      path: '/cancel-ecbr',
      disabled: false,
    },
    {
      title: 'สมัครหักค่าเบี้ยประกันผ่านบัตรเครดิต',
      iconId: 'ECBR_PAYMENT_CCPA',
      path: '/ccpa',
      disabled: false,
    },
    {
      title: 'สมัครหักค่าเบี้ยประกันผ่านบัญชีธนาคาร',
      iconId: 'ECBR_DA',
      path: '/atp',
      disabled: false,
    },
  ],
}
