// istanbul ignore file
export default {
  mappingStatus: {
    waitingForPay: 'RESERVED',
    waitingForCheckin: 'PENDING',
  },
  mappingNextSection: {
    cancelList: 'cancelMethod',
    cancelMethod: {
      fillForm: 'cancelForm',
      uploadFile: 'cancelFormUpload',
    },
    cancelFormUpload: 'cancelAttach',
    cancelAttach: 'cancelFormUpload',
  },
  cancelationSequence: ['cancelList', 'cancelMethod', 'cancelForm', 'cancelFormUpload', 'cancelAttach'],
  cancelFormSection: {
    mainTitle: 'ขอยกเลิกใบชำระเงินชั่วคราวอิเล็กทรอนิกส์',
    textInputs: [
      {
        label: 'ชื่อตัวแทน',
        id: 'firstNameAgent',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 100,
      },
      {
        label: 'นามสกุล',
        id: 'lastNameAgent',
        type: 'text',
        defaultValue: '',
        condition: 'textOnly',
        maxLength: 100,
      },
      {
        label: 'บัตรประจำตัวประชาชนเลขที่',
        id: 'citizenId',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
        format: '# #### ##### ## #',
        mask: '_',
      },
      {
        label: 'รหัสตัวแทน',
        id: 'agentNo',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
      },
      {
        label: 'อีเมล',
        id: 'email',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
      },
      {
        label: 'เบอร์โทรติดต่อตัวแทน',
        id: 'phoneNumber',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
        mask: '_',
        format: '### ### ####',
        valueFormat: 'value',
        change: true,
      },
      {
        label: 'ขอยกเลิก eCBR เลขที่',
        id: 'ecbrNo',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
        mask: 'X',
        format: '##########',
        valueFormat: 'formattedValue',
      },
      {
        label: 'ลงวันที่',
        id: 'date',
        type: 'text',
        defaultValue: '',
        maxLength: 100,
        format: '###-###-####',
        mask: '_',
      },
      {
        label: 'จำนวนเงิน',
        id: 'amount',
        type: 'text',
        defaultValue: '',
        maxLength: 19,
        change: true,
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        isAllowed: ({ formattedValue }) => formattedValue.length <= 19,
      },
    ],
    reason: {
      id: 'reasonEcbr',
      ecbrNo: {
        radio: [
          {
            groups: [
              {
                text: 'ขอยกเลิกเพื่อออกใหม่ - กรณีกรอกข้อมูลรายละเอียดลูกค้าผิด เลขที่ eCBR ที่ออกใหม่คือ',
                value: '0',
              },
            ],
            textInputs: {
              id: 'reasonEcbrText0',
              type: 'number',
              defaultValue: '',
              maxLength: 10,
            },
          },
          {
            groups: [
              {
                text: 'ขอยกเลิกเนื่องจากออกซ้ำกับเลขที่',
                value: '1',
              },
            ],
            textInputs: {
              id: 'reasonEcbrText1',
              type: 'number',
              defaultValue: '',
              maxLength: 10,
            },
          },
          {
            groups: [
              {
                text: 'ขอยกเลิกเนื่องจากเช็คไม่ผ่านการเรียกเก็บ',
                value: '2.1',
              },
              {
                text: 'ขอยกเลิกเพื่อขอเงินคืน (เอกสารขอเงินคืน ส่งแผนก Payment & Policy Benefit Service)',
                value: '2.2',
              },
              {
                text: 'ขอยกเลิกเนื่องจากลูกค้าเปลี่ยนใจไม่ชำระเงิน (กรณียังไม่ได้มีการรับเงินจากลูกค้า)',
                value: '2.3',
              },
            ],
            textInputs: {
              id: 'reasonEcbrText2',
              type: 'text',
              defaultValue: '',
              maxLength: 100,
            },
          },
        ],
      },
      other: {
        radio: [
          {
            groups: [
              {
                text: 'อื่นๆ',
                value: 'other',
              },
            ],
            textInputs: {
              id: 'reasonEcbrTextOther',
              type: 'text',
              defaultValue: '',
              condition: 'textAndNumberOnly',
              maxLength: 100,
            },
          },
        ],
      },
    },
    newReason: {
      id: 'reasonEcbr',
      ecbrNo: {
        radio: [
          {
            groups: [
              {
                text: 'ขอยกเลิกเพื่อออกใหม่ - กรณีกรอกข้อมูลรายละเอียดลูกค้าผิด เลขที่ eCBR ที่ออกใหม่คือ',
                value: '0',
              },
            ],
            textInputs: {
              id: 'reasonEcbrText0',
              type: 'number',
              defaultValue: '',
              maxLength: 10,
            },
          },
          {
            groups: [
              {
                text: 'ขอยกเลิกเนื่องจากออกซ้ำกับ CBR เลขที่',
                value: '1',
              },
            ],
            textInputs: {
              id: 'reasonEcbrText1',
              type: 'number',
              defaultValue: '',
              maxLength: 10,
            },
          },
          {
            groups: [
              {
                text: 'ขอยกเลิกเนื่องจากตัดบัตรเครดิตไม่ผ่าน/ขอเปลี่ยนบัตรใหม่/เช็คไม่ผ่านการเรียกเก็บ',
                value: '2.1',
              },
              {
                text: 'ขอยกเลิกเพื่อขอเงินคืน (เอกสารขอคืนเงินส่งแผนก Payment Service)',
                value: '2.2',
              },
              {
                text: 'ขอยกเลิกเนื่องจากลูกค้าเปลี่ยนใจไม่ชำระเงิน (กรณียังไม่ได้มีการรับเงินจากลูกค้า)',
                value: '2.3',
              },
            ],
            textInputs: {
              id: 'reasonEcbrText2',
              type: 'text',
              defaultValue: '',
              maxLength: 100,
            },
          },
        ],
      },
      other: {
        radio: [
          {
            groups: [
              {
                text: 'อื่นๆ',
                value: 'other',
              },
            ],
            textInputs: {
              id: 'reasonEcbrTextOther',
              type: 'text',
              defaultValue: '',
              condition: 'textAndNumberOnly',
              maxLength: 100,
            },
          },
        ],
      },
    },
  },
  cancelMethodSection: {
    mainTitle: 'กรุณาเลือกวิธีขอยกเลิกใบรับเงินชั่วคราว',
    id: 'cancelMethod',
    solution: {
      radio: [
        {
          text: 'ขอยกเลิกโดยใช้แบบฟอร์มออนไลน์',
          warning: 'ในกรณีท่านเลือกวิธีการกรอกแบบฟอร์มการขอยกเลิกใบรับเงินชั่วคราวบนระบบของ Advisor Zone',
          value: 'fillForm',
        },
        {
          text: 'ขอยกเลิกโดยใช้แบบฟอร์มกระดาษ',
          warning: 'ในกรณีท่านเลือกวิธีการดาวน์โหลดแบบฟอร์มการขอยกเลิกใบรับเงินชั่วคราว',
          value: 'uploadFile',
        },
      ],
    },
  },
  cancelListSection: {
    mainTitle: 'กรุณาเลือกใบรับเงินชั่วคราวที่ต้องการยกเลิก',
    id: 'cancelList',
    blankState: 'ไม่มีรายการที่สามารถทำการยกเลิก',
  },
  cancelFormUploadSection: {
    id: 'cancelFormUpload',
    mainTitle: 'กรุณาเลือกสาเหตุในการขอยกเลิกใบรับเงินชั่วคราว',
    validate: {
      id: 'isUploaded',
    },
  },
  cancelAttachSection: {
    id: 'cancelAttach',
    mainTitle: 'แนบเอกสารการขอยกเลิกใบรับเงินชั่วคราว',
    textInput: [
      {
        id: 'attachUpload',
        placeHolder: 'ระบุชื่อเอกสาร',
        maxLength: 100,
      },
    ],
  },
  cancelDialog: {
    title: 'ดำเนินการส่งเรื่องขอยกเลิกใบรับเงินชั่วคราว',
    detail: 'กรุณาตรวจสอบสถานะยกเลิก บนระบบ iPro ประมาณ 1-2 วันทำการ',
    subtitle: 'กรุณาตรวจสอบสถานะใบรับเงินชั่วคราว',
  },
}
