import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Description } from '../list'
import { Icons } from 'ktaxa-ui-components'
import { color, device } from 'components/theme'
import { formatNumber } from 'utils/string-helper'

const { Label: DT, Value: DD } = Description

const StyledNewECBRSummary = styled.div`
  background-color: #ffffff;
  padding: 15px;
  border-bottom: solid 5px ${color.type.primary};
`

const StyledInsured = styled.div`
  color: ${color.type.primary};
  border-bottom: solid 1px ${color.element.border};
  margin-bottom: 15px;
  padding-bottom: 15px;

  i {
    width: 45px;
    height: 45px;
  }

  @media (min-width: ${device.screenSize.md}) {
    display: flex;
    align-items: center;
    font-size: 1.1rem;

    i {
      margin-left: 20px;
    }
  }
`

const InsuredName = styled.div`
  margin: 10px 0 0;
  @media (min-width: ${device.screenSize.md}) {
    margin: 0 0 0 20px;
  }

  span {
    display: block;
  }
`

const StyledAmount = styled.div`
  border-top: solid 1px ${color.element.border};
  margin-top: 15px;
  padding-top: 15px;
  color: ${color.type.primary};

  div {
    font-weight: 700;
  }
`

const renderInsured = (insured) => {
  return (
    <StyledInsured>
      <Icons type="USER_MALE" />
      <InsuredName>
        <span>ชื่อผู้เอาประกัน: {insured.firstName}</span>
        <span>นามสกุลผู้เอาประกัน: {insured.lastName}</span>
      </InsuredName>
    </StyledInsured>
  )
}

const renderAmount = (amount) => {
  return (
    <StyledAmount>
      <Description>
        <DT>จำนวนเงินที่ต้องชำระ</DT>
        <DD>{formatNumber(amount)} บาท</DD>
      </Description>
    </StyledAmount>
  )
}

const NewECBRSummary = (props) => {
  const { insured, amount, children } = props
  return (
    <StyledNewECBRSummary>
      {renderInsured(insured)}
      {children}
      {renderAmount(amount)}
    </StyledNewECBRSummary>
  )
}
NewECBRSummary.propTypes = {
  insured: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  children: PropTypes.node,
  amount: PropTypes.number,
}

NewECBRSummary.defaultProps = {
  insured: {
    firstName: '',
    lastName: '',
  },
  children: null,
  amount: 0,
}

export default NewECBRSummary
