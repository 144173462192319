import { css } from '@emotion/react'

import { color, transition } from '../utils/theme'
import { reset, disabled } from '../utils/state'

const iconSize = '1.2rem'
const minWidth = '60px'

export default {
  wrapper: css`
    display: block;
    min-width: ${minWidth};
    margin-bottom: 10px;
  `,
  label: css`
    display: block;
    color: ${color.common.text1};
    font-weight: 400;

    span {
      display: block;
      margin-bottom: 5px;
    }
  `,
  el: css`
    display: flex;
    align-items: flex-start;

    &::after {
      display: block;
      width: ${iconSize};
      height: ${iconSize};
      margin-top: 7px;
      margin-left: 12px;
      content: '';
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center center;
    }

    input,
    textarea {
      ${reset};
      width: 100%;
      padding: 8px 15px;
      color: $color-text-1;
      font-size: 0.85rem;
      line-height: 1.25rem;
      background-color: ${color.common.white};
      border: solid 1px ${color.element.border};
      outline: none;
      transition-duration: ${transition.fastest};
      transition-property: box-shadow;

      &:focus,
      &:active {
        border-color: ${color.type.primary};
        box-shadow: 0 0 0 2px rgba(${color.type.primary}, 0.4);
      }
    }

    textarea {
      font-family: inherit;
      resize: none;
    }
  `,
  errorBox: css`
    margin: 10px 0;
    color: ${color.type.error};
    font-size: 0.75rem;
  `,
  errorMessage: css``,
  state: {
    normal: css``,
    error: css`
      input,
      textarea {
        &,
        &:focus,
        &:active {
          border-color: ${color.common.error};
          box-shadow: 0 0 0 2px rgba(${color.common.error}, 0.4);
        }
      }
    `,
    valid: css``,
  },
  disabled: css`
    ${disabled}
  `,
}
