import ApplicationRepository from '../../repositories/application'

export default class ApplicationUseCase implements IApplicationUseCase {
  ApplicationDARepo: ApplicationRepository

  constructor(po: ApplicationRepository) {
    this.ApplicationDARepo = po
  }

  async GetApplicationByCitizenId(
    citicenId: string,
    filterType: string
  ): Promise<
    {
      data: any
      searchPolicyStatus: number
    }
  > {
    return this.ApplicationDARepo.GetApplicationByCitizenId(citicenId, filterType)
  }

  async GetApplicationByPolicyNo(
    policyNo: string,
    policyEffectiveDate: string,
    agentCode: string
  ): Promise<
    {
      data: any,
      searchPolicyStatus: number
    }
  > {
    return this.ApplicationDARepo.GetApplicationByPolicyNo(policyNo, policyEffectiveDate, agentCode)
  }
}

export interface IApplicationUseCase {
  GetApplicationByCitizenId(
    citicenId: string,
    filterType: string
  ): Promise<
    {
      data: any,
      searchPolicyStatus: number
    }
  >
  GetApplicationByPolicyNo(policyNo: string, policyEffectiveDate: string, agentCode: string): Promise<{
    data: any,
    searchPolicyStatus: number
  }>
}
