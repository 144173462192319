import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputCustomFormat } from 'components/input'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { renderErrorMessageBlock } from 'components/message-blocks'
import { AppContext } from 'contexts/app'
import EcbrEntity from 'domain/entities/ecbr'
import { Radio } from 'ktaxa-ui-components/index'
import _isEmpty from 'lodash/isEmpty'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Input as RSInput } from 'reactstrap'
import { policyService } from 'services/ecbr-middleware'
import { validatePolicyCCPA } from 'utils/validate'
import { useLogin } from '../../../hooks/useServices/login'
import { getPlanCode } from '../../../pages/new-ecbr/hooks/useService'
import CCPASystemErrorDialog from '../components/ccpa-system-error-dialog'
import LoadingModal from '../components/load-data-dialog'
import { schemaInformationSection } from '../hooks/useForm'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import { verifyCCPA } from '../hooks/useService'
import staticContentPage from '../static-content'
import SubmitSection from './submit'

const SelectContainer = styled.div`
  margin-top: 20px;
  & > div > div {
    display: block;
  }
`

const LoadingContent = styled.div`
  text-align: center;
`

const VerifyPolicySection = (props) => {
  const { tabState, state, setState, setTabState, isNeedToReload } = props
  const { setCcpaPlanCodeOptions } = useContext(AppContext)

  const history = useHistory()

  const { token } = useLogin({})
  const [isLoadingFetchPlanCode, setIsLoadingFetchPlanCode] = useState(false)
  const [isGetPlanCodeError, setIsGetPlanCodeError] = useState(false)
  const [planCodeList, setPlanCodeList] = useState<any[]>([])

  const { formState, control, reset, handleSubmit, getValues, setValue, trigger } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaInformationSection),
  })
  const { errors, isDirty, dirtyFields, isValid } = formState

  const [fieldInputDisabled, setFieldInputDisabled] = useState<any[]>([])
  const ActiveCondition = tabState === staticContentPage.tabsProgress[0].id

  useEffect(() => {
    let isMount = false
    setIsLoadingFetchPlanCode(true)

    const fetchPlanCode = async () => {
      const result = await getPlanCode(token, 'ccpa')

      if (!result.data) {
        setIsLoadingFetchPlanCode(false)
        setIsGetPlanCodeError(true)
      } else {
        //! save to context
        setIsLoadingFetchPlanCode(false)
        setPlanCodeList(result.data)
        setCcpaPlanCodeOptions(result.data)
        setIsGetPlanCodeError(false)
      }
    }

    fetchPlanCode()

    return () => {
      isMount = true
    }
  }, [])

  useEffect(() => {
    if (isNeedToReload) {
      history.push('/')
      window.location.reload()
      return
    }
  }, [isNeedToReload])

  useEffect(() => {
    if (_isEmpty(state)) {
      reset({
        searchMethod: 'citizenId',
        policyEffectiveDate: '',
        policyNo: '',
        personalId: '',
      })
    }
  }, [state, ActiveCondition])

  // initial state
  useEffect(() => {
    if (state?.info?.payload) {
      reset({
        ...state?.info?.payload,
      })
    } else {
      reset({
        searchMethod: 'citizenId',
        policyEffectiveDate: '',
        policyNo: '',
        personalId: '',
      })
      setFieldInputDisabled(['policyNo', 'policyEffectiveDate'])
    }
  }, [reset])

  useEffect(() => {
    if (isDirty) {
      setState({
        [staticContentPage.tabsProgress[0].id]: {
          isDirty,
        },
      })
    }
  }, [isDirty])

  const { informationSection: formInfo } = staticContentPage

  const selectAgentPolicy = async (agentPolicy: EcbrEntity) => {
    const { policyEffectiveDate, policyNumber: policyNo } = agentPolicy
    const res = await verifyCCPA({ policyEffectiveDate, policyNo, planCodeList })

    setState({
      [staticContentPage.tabsProgress[0].id]: {
        searchCCPAInfo: res,
        searchStatus: res.data.searchStatus,
      },
    })
  }

  const onSelectSearchMethod = (value) => {
    reset({
      searchMethod: '',
      personalId: '',
      policyNo: '',
      policyEffectiveDate: '',
    })

    setValue('searchMethod', value, { shouldValidate: true, shouldDirty: true })

    if (formInfo.searchMethod.options[2].value === value) {
      setFieldInputDisabled(['personalId'])
    } else {
      setFieldInputDisabled(['policyEffectiveDate', 'policyNo'])
    }
  }

  const onSubmit = async (data) => {
    const { searchMethod, policyEffectiveDate, policyNo, personalId } = data
    const isSearchByPolicyNumber = searchMethod === 'policyId'
    if (isSearchByPolicyNumber) {
      const res = await verifyCCPA({ policyEffectiveDate, policyNo, searchMethod, planCodeList })

      setState({
        [staticContentPage.tabsProgress[0].id]: {
          payload: data,
          searchCCPAInfo: res,
          searchStatus: res.data.searchStatus,
        },
      })
    } else {
      const personalIdFormat = personalId.replace(/ +/g, '')
      const res = await policyService.GetPolicyByCitizenId(personalIdFormat, 'ccpa')

      const checkAllowPolicyList = res.filter(
        (item) => validatePolicyCCPA(item.policyStatusCode, item.productCode, planCodeList) === true
      )

      setState({
        [staticContentPage.tabsProgress[0].id]: {
          payload: data,
          agentPolicyList: checkAllowPolicyList,
        },
      })
    }
  }

  const checkDisableField = (fieldName) => {
    if (!_isEmpty(fieldInputDisabled)) {
      const isDisabledField = fieldInputDisabled.includes(fieldName)
      return isDisabledField
    }
    return false
  }

  const beforeSubmit = () => {
    trigger()
    handleSubmit(onSubmit)()
  }

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      backButton: { toHome: true },
      extraButton: () => (
        <SubmitSection
          tabState={tabState}
          handleSubmit={beforeSubmit}
          setTabState={setTabState}
          state={state}
          setState={setState}
          selectAgentPolicy={selectAgentPolicy}
        />
      ),
    }
  )

  const { setIsVerifyFormValidateError } = useContext(AppContext)

  if (!ActiveCondition) return null
  return (
    <ContentContainer>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
          }
        }}
      >
        <SelectContainer>
          <Controller
            name={formInfo.searchMethod.id}
            control={control}
            render={() => (
              <Radio.Group
                uiStyle={Radio.UIStyle.Checked}
                onChange={(option) => onSelectSearchMethod(option.value)}
                value={getValues(formInfo.searchMethod.id)}
              >
                {formInfo.searchMethod.options.map((cate) => (
                  <Radio key={cate.value} value={cate.value} label={cate.text} />
                ))}
              </Radio.Group>
            )}
          />
        </SelectContainer>
        <SectionContainer>
          <div className="name-input" style={{ paddingTop: 20 }}>
            <label>{formInfo.identity.label}</label>
            <Controller
              name={formInfo.identity.id}
              control={control}
              render={({ field }) => {
                if (Object.keys(errors).length > 0 || !isValid) {
                  setIsVerifyFormValidateError(true)
                } else {
                  setIsVerifyFormValidateError(false)
                }
                return getValues('searchMethod') === 'passport' ? (
                  <RSInput
                    {...field}
                    disabled={checkDisableField(formInfo.identity.id)}
                    name={formInfo.identity.id}
                    type="text"
                    style={{ textAlign: 'center' }}
                    onChange={(v) => field.onChange(v.target.value)}
                    maxLength={10}
                  />
                ) : (
                  <InputCustomFormat
                    {...field}
                    // name={formInfo.identity.id}
                    disabled={checkDisableField(formInfo.identity.id)}
                    style={{ textAlign: 'center' }}
                    mask={formInfo.identity.mask}
                    format={formInfo.identity.format}
                  />
                )
              }}
            />
            {renderErrorMessageBlock(errors[formInfo.identity.id])}
          </div>
          <div className="name-input" style={{ paddingTop: 20 }}>
            <label>{formInfo.policyNo.label}</label>
            <Controller
              name={formInfo.policyNo.id}
              control={control}
              render={({ field }) => (
                <InputCustomFormat
                  {...field}
                  disabled={checkDisableField(formInfo.policyNo.id)}
                  style={{ textAlign: 'center' }}
                  mask={formInfo.policyNo.mask}
                  format={formInfo.policyNo.format}
                  required={true}
                />
              )}
            />
            {renderErrorMessageBlock(errors[formInfo.policyNo.id])}
          </div>
          <div className="name-input" style={{ paddingTop: 20 }}>
            <label>{formInfo.policyEffectiveDate.label}</label>
            <Controller
              name={formInfo.policyEffectiveDate.id}
              control={control}
              render={({ field }) => (
                <InputCustomFormat
                  {...field}
                  disabled={checkDisableField(formInfo.policyEffectiveDate.id)}
                  style={{ textAlign: 'center' }}
                  mask={formInfo.policyEffectiveDate.mask}
                  format={formInfo.policyEffectiveDate.format}
                />
              )}
            />
            {renderErrorMessageBlock(errors[formInfo.policyEffectiveDate.id])}
          </div>
        </SectionContainer>
      </form>
      <LoadingModal show={isLoadingFetchPlanCode} />
      <CCPASystemErrorDialog
        show={isGetPlanCodeError}
        onClose={() => {
          history.push('/')
          setIsGetPlanCodeError(false)
        }}
      />
    </ContentContainer>
  )
}

export default VerifyPolicySection
