import CheckAccountRepository from '../../../../domain/repositories/checkAccount'
import { IConnect } from '../connect'

export default class CheckAccountMiddlewareRepositoryImp implements CheckAccountRepository {
  connect: IConnect

  constructor(connect: IConnect) {
    this.connect = connect
  }
  async postCheckAccount(data: any): Promise<{
    data: any
    searchPolicyStatus: number
  }> {
    try {
      const res = await this.connect.post('/checkAccount', data)
      console.log('res',res);
      return {
        data: res,
        searchPolicyStatus: 200
      }
    } catch (error) {
      return {
        data: null,
        searchPolicyStatus: 500,
      }
    }
  }

}
