import React, { useContext, useState } from 'react'
import { Controller } from 'react-hook-form'
import _isEmpty from 'lodash/isEmpty'
import _omitBy from 'lodash/omitBy'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _isEqual from 'lodash/isEqual'

import { ContentContainer, SectionContainer } from 'components/layouts'
import { UploadDocument } from 'components/input'
import { renderErrorMessageBlock, renderInfoMessageBlockForSpecialWordBreak } from 'components/message-blocks'
import { AppContext } from 'contexts/app'
import { getConfig } from 'core/config'

import staticContentPage, { DA_STATUS, UPLOAD_DOC_TYPES } from '../static-content'
import EditCard from '../components/edit-card'
import { updateRecurringData } from '../hooks/useService'
import { MessageBlock } from './registerForm'
import PreviewDialog from '../../cancel-ecbr/components/preview-dialog'
import { IconButton } from 'components/buttons'

const ReviewContentSection = (props) => {
  const {
    // -- global state
    state,
    setState,
    setTabState,
    // -- useForm
    control,
    getValues,
    setValue,
    errors,
    // -- local variable
    isOtherRelationship,
    // -- local state
    isValidateForm,
    isBookbankOverLimit,
    setIsBookbankOverLimit,
    isRelationDocOverLimit,
    setIsRelationDocOverLimit,
    setIsShowReviewForm,
    // -- function
    handleSelectedUploadDoc,
    handleRemoveUploadDoc,
  } = props

  const { setIsVerifyFormValidateError } = useContext(AppContext)
  const [ show, setShow ] = useState(false);
  const { limitFileSize } = getConfig()
  const imageATP = {
    base64: _get(state, 'verify.searchDAInfo._attachments.atp.image', ''),
    size: _get(state, 'verify.searchDAInfo._attachments.atp.length', 0),
    content_type: 'application/pdf'
  }
  const bookbankInputInfo = staticContentPage.regInfoSection.textInputs[0]
  const relationshipInputInfo = staticContentPage.regInfoSection.textInputs[10]
  return (
    <ContentContainer>
      <SectionContainer />
      <SectionContainer>
        <div key={bookbankInputInfo.id} className="name-input">
          <Controller
            name={bookbankInputInfo.id}
            control={control}
            render={({ field }) => {
              if (Object.keys(errors).length > 0 || !isValidateForm) {
                setIsVerifyFormValidateError(true)
              } else {
                setIsVerifyFormValidateError(false)
              }

              return (
                <UploadDocument
                  {...field}
                  onSelected={(img) => handleSelectedUploadDoc(field, img, UPLOAD_DOC_TYPES.BOOKBANK)}
                  value={getValues(bookbankInputInfo.id)}
                  title={bookbankInputInfo.label}
                  setIsOverLimit={setIsBookbankOverLimit}
                  limitFileSize={limitFileSize}
                  setValue={setValue}
                  onRemoveFile={() => handleRemoveUploadDoc(bookbankInputInfo.id, UPLOAD_DOC_TYPES.BOOKBANK)}
                />
              )
            }}
          />
          {renderErrorMessageBlock(errors[bookbankInputInfo.id])}
          {isBookbankOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
          <MessageBlock>
            {renderInfoMessageBlockForSpecialWordBreak(bookbankInputInfo.infoMessageReviewDA || '')}
          </MessageBlock>
        </div>
      </SectionContainer>
      <SectionContainer>
        <UploadDocument title={'หนังสือยินยอมหักค่าเบี้ยประกันอัตโนมัติ'}
        onSelected={(img) => console.log(img)}
        value={imageATP}
        isPDF={true}
        setIsOverLimit={setIsRelationDocOverLimit}
        limitFileSize={limitFileSize}
        onRemoveFile={() =>{}} viewOnly />
      </SectionContainer>
      <SectionContainer>
        <div>
          {/* edit button ==> click for change to register form component */}
          <EditCard
            onClick={async () => {
              // click edit (on review page)
              const selectedDaInfoId = _get(state, 'verify.searchDAInfo._id', null)
              // remove await just call API (dont need resp)
              const resultUpdate = await updateRecurringData({
                updateType: 'UPDATE',
                data: { _id: selectedDaInfoId, daStatus: DA_STATUS.CREATE },
              })

              await _set(state, 'isEditForm', true)
              await _set(state, 'verify.searchDAInfo.daStatus', DA_STATUS.CREATE)
              await setState({
                ...state,
                isEditForm: true,
                [staticContentPage.tabsProgress[0].id]: {
                  ...state[staticContentPage.tabsProgress[0].id],
                  daStatus: DA_STATUS.CREATE,
                },
              })
              await setIsShowReviewForm(false)
              await setTabState(staticContentPage.tabsProgress[1].id)
            }}
          />
        </div>
      </SectionContainer>
      {isOtherRelationship && (
        // {true && (
        <SectionContainer>
          <div className="name-input" style={{ paddingBottom: 16 }}>
            <Controller
              name={relationshipInputInfo.additionDocid || ''}
              control={control}
              render={({ field }) => (
                <UploadDocument
                  {...field}
                  onSelected={(img) => handleSelectedUploadDoc(field, img, UPLOAD_DOC_TYPES.RELATIONSHIP)}
                  value={getValues(relationshipInputInfo.additionDocid || '')}
                  title={relationshipInputInfo.additionDocLabel || ''}
                  setIsOverLimit={setIsRelationDocOverLimit}
                  limitFileSize={limitFileSize}
                  setValue={setValue}
                  onRemoveFile={() =>
                    handleRemoveUploadDoc(relationshipInputInfo.additionDocid || '', UPLOAD_DOC_TYPES.RELATIONSHIP)
                  }
                />
              )}
            />
            {renderErrorMessageBlock(errors[relationshipInputInfo.additionDocid || ''])}
            {isRelationDocOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
          </div>
        </SectionContainer>
      )}
    </ContentContainer>
  )
}

export default ReviewContentSection
