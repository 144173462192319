import { Connect } from 'adapters/repositories/ecbr-middleware/connect'

import EcbrUseCase from 'domain/useCases/ecbr'
import EcbrMiddlewareRepositoryImp from 'adapters/repositories/ecbr-middleware/ecbr'

import PolicyUseCase from 'domain/useCases/policy'
import PolicyMiddlewareRepositoryImp from 'adapters/repositories/ecbr-middleware/policy'

import PdfUseCase from 'domain/useCases/pdf'
import PdfRepositoryImp from 'adapters/repositories/ecbr-middleware/pdf'

import PaymentUseCase from 'domain/useCases/payment'

import ApplicationUseCase from 'domain/useCases/application'
import ApplicationMiddlewareRepositoryImp from 'adapters/repositories/ecbr-middleware/application'

import CheckAccountUseCase from 'domain/useCases/checkAccount'
import CheckAccountMiddlewareRepositoryImp from 'adapters/repositories/ecbr-middleware/checkAccount'

const connectApi = new Connect()

const rpPolicy = new PolicyMiddlewareRepositoryImp(connectApi)
export const policyService = new PolicyUseCase(rpPolicy)


const rpApplication = new ApplicationMiddlewareRepositoryImp(connectApi)
export const applicationService = new ApplicationUseCase(rpApplication)

const rpEcbr = new EcbrMiddlewareRepositoryImp(connectApi)
export const ecbrService = new EcbrUseCase(rpEcbr)

const rpCheckAccount = new CheckAccountMiddlewareRepositoryImp(connectApi)
export const checkAccountService = new CheckAccountUseCase(rpCheckAccount)

const rpPdf = new PdfRepositoryImp(connectApi)
export const pdfService = new PdfUseCase(rpPdf)

export const paymentService = new PaymentUseCase()
