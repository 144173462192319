import React, { useState } from 'react'
import styled from '@emotion/styled'

import { Radio, Dialog, Button } from 'ktaxa-ui-components/index'

const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

export const PolicyListDialog = (props) => {
  const { show, onToggle, agentPolicyList = [], selectAgentPolicy } = props

  const [selectedAgentIndex, setSelectedAgentIndex] = useState(0)

  const onSelectPolicyItem = (item) => {
    const index = parseInt(item.value, 10)
    setSelectedAgentIndex(index)
  }

  const onConfirmSelected = () => {
    const agentPolicy = agentPolicyList[selectedAgentIndex]

    selectAgentPolicy(agentPolicy)
    onToggle()
  }
  const PolicyList = (data, selectedIndex, onChange) => {
    return (
      <Radio.Group value={`${selectedIndex}`} onChange={onChange} uiStyle={Radio.UIStyle.Checked}>
        {data.map(({ policyNumber, productCode }, listIndex) => (
          <Radio key={policyNumber} value={`${listIndex}`} label={`${productCode} ${policyNumber}`} />
        ))}
      </Radio.Group>
    )
  }

  return (
    <Dialog centered modal show={show} onClose={onToggle}>
      <>
        <Dialog.Title>โปรดเลือกกรมธรรม์ที่ต้องการชำระ</Dialog.Title>
        <Content>{PolicyList(agentPolicyList, selectedAgentIndex, onSelectPolicyItem)}</Content>
      </>
      <Footer>
        <Button type={Button.Type.Primary} onClick={onConfirmSelected}>
          ยืนยัน
        </Button>
      </Footer>
    </Dialog>
  )
}
