import React from 'react'
import styled from '@emotion/styled'
import { Dialog, Button } from 'ktaxa-ui-components/index'

const DialogContainer = styled.div`
  text-align: center;
`
const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Message = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 1rem;
  color: #333333;
`

type ErrorContent = {
  title?: string
  message?: string
}

type ErrorDialogProps = {
  show: boolean
  onToggle: () => void
  errorContent?: ErrorContent
  actionLabel?: string
}

const ApiDownDialog = (props: ErrorDialogProps) => {
  const { show, onToggle } = props
  return (
    <DialogContainer>
      <Dialog centered modal show={show} onClose={onToggle}>
        <Content>
          <Message>ขณะนี้ระบบไม่สามารถค้นหาใบรับเงินชั่วคราวได้</Message>
        </Content>
        <Footer>
          <Button type={Button.Type.Primary} onClick={onToggle}>
            ยืนยัน
          </Button>
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default ApiDownDialog
