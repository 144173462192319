import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color } from 'components/theme'
import { REGISTER_STATUS } from '../static-content'
import _ from 'lodash'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 0;
    padding-left: 0;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px 30px;
`

const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

// const Subtitle = styled.span`
const Subtitle = styled.div`
  display: block;
  font-size: 1rem;
  color: #333333;
`

const RegistrationResultDialog = (props: any) => {
  const { show, onToggle, onConfirm, status, data, onBypass = () => {}, isProcess } = props
  // const status = data?.regStatus


  let dialogTitle = 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร'
  let dialogContent = ''

  const getBankNumber = _.get(data, 'verify.searchDAInfo.daOfPayor.accountNumber', '1234')
  const bankAccountNoLast4Digit = getBankNumber.substr(getBankNumber.length - 4)

  switch (status) {
    case REGISTER_STATUS.SEND_REQUEST_SUCCESS:
      // [2987]
      dialogTitle = 'ยืนยันสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร'
      dialogContent =
        'อยู่ระหว่างรอดำเนินการสมัครหักค่าเบี้ยประกันอัติโนมัติผ่านบัญชีธนาคาร<br>กรุณากด ยืนยัน เพื่อตรวจสอบสถานะการสมัคร'
      break
    // case REGISTER_STATUS.FOUND_SUCCESS_REC:
    case REGISTER_STATUS.EDA_DUPLICATE:
      // [2986] --> stamp Bypass
      dialogContent = `ท่านได้มีการทำรายการสมัครหักค่าเบี้ยอัตโนมัติ <br>ผ่านบัญชีธนาคารกรุงไทย <br>เลขที่บัญชี x-${bankAccountNoLast4Digit} (4 ตัวท้าย) สำเร็จ <br>กรุณากดยืนยันเพื่ออัพเดทสถานะ`
      break
    case REGISTER_STATUS.NOT_FIND_RECORD:
      dialogContent = `ระบบตรวจสอบพบลูกค้า<br>ทำรายการสมัครก่อนหน้านี้สำเร็จเเล้ว<br>กรุณากด ยืนยัน เพื่อตรวจสอบสถานะอีกครั้ง`
      break
    case REGISTER_STATUS.NO_STATUS:
      dialogContent =
        'ไม่พบข้อมูลการสมัคร <br>กรุณาตรวจสอบผลการสมัครผ่านธนาคาร <br>ที่ท่านทำรายการอีกครั้ง หรือเปลี่ยนวิธีการสมัคร'
      break
    case REGISTER_STATUS.EDA_FAIL:
      dialogContent = `การสมัครหักค่าเบี้ยประกันอัติโนมัติผ่านบัญชีธนาคารกรุงไทย เลขที่บัญชี x-${bankAccountNoLast4Digit} (4 ตัวท้าย) ไม่สำเร็จ กรุณาตรวจสอบข้อมูลหรือสมัครอีกครั้งด้วยเลขบัญชีใหม่`
      break
    case REGISTER_STATUS.EDA_SUCCESS:
    case REGISTER_STATUS.EDA_SUCCESS_MANUAL:
      // [2990]
      dialogContent = `การสมัครหักค่าเบี้ยประกันอัติโนมัติ<br>ผ่านบัญชีธนาคารกรุงไทย<br>เลขที่บัญชี x-${bankAccountNoLast4Digit} (4 ตัวท้าย) สำเร็จ`
      break
    case REGISTER_STATUS.TECH_ISSUE:
    default:
      // error server case
      // [2985]
      dialogTitle = 'ระบบการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคารขัดข้อง'
      dialogContent = 'กรุณาทำรายการใหม่อีกครั้งในภายหลังหรือเปลี่ยนวิธีการสมัคร'
  }

  return (
    <DialogContainer>
      <Dialog centered modal show={show}>
        {isProcess === false ? (
          <>
            <Title>{dialogTitle}</Title>
            <Divider />
            <Content>
              {/* <Subtitle> */}
              <Subtitle dangerouslySetInnerHTML={{ __html: dialogContent }}></Subtitle>
              {/* {dialogContent} */}
              {/* </Subtitle> */}
            </Content>
            <Footer>
              <Button
                type={Button.Type.Primary}
                paddingSizeLeft={'40px'}
                paddingSizeRight={'40px'}
                onClick={() => {
                  if (
                    [
                      REGISTER_STATUS.EDA_SUCCESS,
                      REGISTER_STATUS.SEND_REQUEST_SUCCESS,
                      REGISTER_STATUS.NOT_FIND_RECORD,
                      REGISTER_STATUS.EDA_FAIL,
                      REGISTER_STATUS.EDA_SUCCESS_MANUAL,
                      REGISTER_STATUS.NO_STATUS
                    ].includes(status)
                  ) {
                    onConfirm()
                  } else if (status === REGISTER_STATUS.EDA_DUPLICATE) {
                    onBypass()
                  } else {
                    onToggle()
                  }
                }}
              >
                ยืนยัน
              </Button>
            </Footer>
          </>
        ) : (
          <>
            <Content>Loading...</Content>
          </>
        )}
      </Dialog>
    </DialogContainer>
  )
}

export default RegistrationResultDialog
