import { css } from '@emotion/react'

import { color, hexToRGB } from '../utils/theme'
import { reset } from '../utils/state'

const styles = {
  block: css`
    ${reset};
    font-size: 0.75rem;
    padding: 6px 8px;
    margin: 3px 0;
    padding-left: 12px;
    position: relative;
    box-shadow: 0 0 0 1px #e5e5e5;
  `,
  type: {
    info: css`
      color: ${color.type.primaryLight};
      background-color: ${hexToRGB(color.type.primaryLight, 0.1)};
      box-shadow: 0 0 0 1px ${color.type.primaryLight};
    `,
    warning: css`
      color: ${color.type.warning};
      background-color: ${hexToRGB(color.type.warning, 0.1)};
      box-shadow: 0 0 0 1px ${color.type.warning};
    `,
    error: css`
      color: ${color.type.error};
      background-color: ${hexToRGB(color.type.error, 0.1)};
      box-shadow: 0 0 0 1px ${color.type.error};
    `,
  },
}

export default styles
