import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-self: center;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  display: block;
  color: #cccccc;
  font-size: 1.4rem;
  margin-bottom: 15px;
`

const Content = styled.div`
  display: block;
`

const BlankPage = (props) => {
  const { title, children } = props
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  )
}

BlankPage.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

BlankPage.defaultProps = {
  title: '',
  children: null,
}

export default BlankPage
