import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color, customIcon } from 'components/theme'

const DialogContainer = styled.div`
  text-align: center;
`
const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`
const Icon = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
  border: solid 10px ${color.type.error};
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &::before {
    display: block;
    width: 70px;
    height: 70px;
    background-color: ${color.type.error};
    content: '';
    mask-image: url(${customIcon('dialogError')});
    mask-size: cover;
    mask-repeat: no-repeat;
  }
`
const Title = styled.span`
  display: block;
  color: ${color.type.error};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 15px;
`
const SubTitle = styled(Title)`
  margin: 5px 0;
  font-size: 1.2rem;
  font-weight: 400;
`
const Message = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 1rem;
  color: #333333;
`

type ErrorContent = {
  title?: string
  message?: string
}

type ErrorDialogProps = {
  show: boolean
  onToggle: () => void
  errorContent?: ErrorContent
  actionLabel?: string
}

const ErrorDialog = (props: ErrorDialogProps) => {
  const { show, onToggle, errorContent, actionLabel } = props

  return (
    <DialogContainer>
      <Dialog centered modal show={show} onClose={onToggle}>
        <Content>
          <Icon />
          <Title>{errorContent?.message || 'เกิดข้อผิดพลาดบางอย่าง'}</Title>
          <Message>{errorContent?.message}</Message>
        </Content>
        <Footer>
          <Button type={Button.Type.Primary} onClick={onToggle}>
            {actionLabel || 'ยืนยัน'}
          </Button>
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default ErrorDialog
