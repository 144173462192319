import PdfRepository from 'domain/repositories/pdf'

import { IConnect } from '../connect'

export default class PdfRepositoryImp implements PdfRepository {
  connect: IConnect

  constructor(connect: IConnect) {
    this.connect = connect
  }
  async GenPdf(template: string, data: any): Promise<string> {
    try {
      const res = await this.connect.post(`/genPdf/${template}`, JSON.stringify(data), true)
      return res
    } catch (error) {
      throw error
    }
  }
  async GenPdfSigning(template: string, data: any): Promise<string> {
    try {
      const resp = await this.connect.post(`/genPdfSigning/${template}`, JSON.stringify(data), false)
      return resp
    } catch (error) {
      throw error
    }
  }
}
