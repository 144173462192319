import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useLogin } from '../hooks/useServices/login'
import styled from '@emotion/styled'

import RouterProvider from '../contexts/router'
import AppProvider from '../contexts/app'

import { HomePage, NewECBRPage, CancelEcbrPage, Login, Error404Page, PendingPage, HistoricalPage , CCPAPage, ATPPage } from '../pages'
import { MainLayout } from '../components/layouts'
import { HealthReportModal } from 'components/modal'
import { PrivateRoute } from './utils'
import { getToggles, getConfig } from '../core/config'
import useGoogleAnalytics from '../hooks/useServices/googleAnalytics'

const Loading = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    font-size: 1.4rem;
  }
`
let loginTimeout
export const AppRouter = () => {
  const { initialized, logout } = useLogin({})
  const { gaTrackingID } = getConfig()
  const { initialiseGA } = useGoogleAnalytics();


  useEffect(() => {
    initialiseGA(gaTrackingID)
    clearTimeout(loginTimeout)
    if (!initialized) {
      loginTimeout = setTimeout(() => {
        logout()
      }, 10000);
    }
    return () => {
      clearTimeout(loginTimeout)
    }
  }, [initialized])

  if (!initialized && !getToggles().ALLOW_GUEST) {
    window.location.href.indexOf('location=mobile') > 0 ? localStorage.setItem('location', 'mobile') : null

    return (
      <Loading>
        <span>Loading...</span>
      </Loading>
    )
  }

  return (
    <RouterProvider>
      <AppProvider>
      <BrowserRouter>
        <MainLayout>
          <HealthReportModal />
          <Switch>
            <PrivateRoute exact path="/" component={HomePage} />
            <PrivateRoute path="/new-ecbr/:step?" component={NewECBRPage} />
            <PrivateRoute path="/pending/:cbrNumber?" component={PendingPage} />
            <PrivateRoute path="/history" component={HistoricalPage} />
            {!getToggles().FEATURE_CANCELATION ? null : <PrivateRoute path="/cancel-ecbr" component={CancelEcbrPage} />}
            {!getToggles().ENABLE_CCPA_ON_ECBR ? null : <PrivateRoute path="/ccpa" component={CCPAPage} />}
            {!getToggles().ENABLE_DA_ECBR ? null : <PrivateRoute path="/atp" component={ATPPage} />}
            <Route path="/login" component={Login} />
            <Route component={Error404Page} />
          </Switch>
        </MainLayout>
        </BrowserRouter>
        </AppProvider>
    </RouterProvider>
  )
}

export default AppRouter
