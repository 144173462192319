import { getConfig } from 'core/config'
import useGoogleAnalytics from 'hooks/useServices/googleAnalytics'
import _ from 'lodash'
import _isEmpty from 'lodash/isEmpty'
import { CcpaCreateLinkPayload, CcpaPaymentStatusResponseType, CreateCcpaLinkResultType } from 'types/plan-code'

import { validatePolicyCCPA } from 'utils/validate'

export type RespType = {
  data?: any
  error?: any
}

export const getPolicyDetail = async (info, options): Promise<RespType> => {
  const { policyNo = '', policyEffectiveDate = '', searchMethod = ' ', planCodeList = [] } = info

  const isSearchByPolicyNumber = searchMethod === 'policyId'

  const getPolicyDetailUrl = `${
    getConfig().api.baseURL
  }/v1/eip/getPolicyDetailWithPolicyNo/${policyNo}?policyEffectiveDate=${policyEffectiveDate}`

  try {
    const policyDetail = await fetch(getPolicyDetailUrl, options)
    const policyDetailJson = await policyDetail.json()

    if (policyDetailJson.RetrievePolicyDetailsResponse?.RLSPolicy) {
      const {
        RLSPolicy: {
          partyRoles,
          policyStatusCode,
          RLSProduct: { productCode },
          policyNumber,
        },
      } = policyDetailJson.RetrievePolicyDetailsResponse
      const userInfo = partyRoles.find((p) => p.RLSParty.partyTypeCode === 'INS')
      if (!isSearchByPolicyNumber) {
        return { data: { ...userInfo.RLSParty, policyNumber, productCode, policyStatusCode }, error: null } //handle when search by personalId and get policy detail success.
      }
      if (isSearchByPolicyNumber && validatePolicyCCPA(policyStatusCode, productCode, planCodeList)) {
        return { data: { ...userInfo.RLSParty, policyNumber, productCode, policyStatusCode }, error: null } //handle when search by policyId and get policy detail success ( valid with condition )
      }
      return { data: null, error: 'POLICY_INVALID' } //handle when get policy detail invalid.
    }
    return { data: null, error: 'POLICY_NOT_FOUND' } //handle when get policy detail not found.
  } catch (error) {
    return { data: null, error: 'POLICY_DETAIL_SERVER_ERROR' }
  }
}

export const getLastCCPA = async (info, options): Promise<RespType> => {
  const { policyNo = '' } = info
  const getLastCCPAUrl = `${getConfig().api.baseURL}/v1/ecbr/payment/ccpa/${policyNo}`
  try {
    const ccpaDetail = await fetch(getLastCCPAUrl, options)
    const ccpaDetailJson = await ccpaDetail.json()
    if (_isEmpty(ccpaDetailJson.error)) {
      return { data: { ...ccpaDetailJson }, error: null } //handle when get last ccpa success.
    }
    return { data: null, error: 'LAST_CCPA_NOT_FOUND' } //handle when get get last ccpa not found.
  } catch (error) {
    return { data: null, error } //handle when get last ccpa server error.
  }
}

export const verifyCCPA = async (info): Promise<RespType> => {
  try {
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const policyDetail = await getPolicyDetail(info, options)
    if (policyDetail.data) {
      //handle when get policy detail success and valid
      const ccpaDetail = await getLastCCPA(info, options)
      if (ccpaDetail.data) {
        return { data: { policyDetail, ccpaDetail, searchStatus: 'CCPA_SUCCESS' }, error: null }
      }
      return { data: { policyDetail, ccpaDetail: null, searchStatus: 'CCPA_NOT_FOUND' }, error: null }
    }
    return { data: { searchStatus: policyDetail.error } } //handle case when get policy detail error.
  } catch (error) {
    return { data: null, error }
  }
}

//! Related to tab 3
type ApiResponseBodyType<T> = {
  data?: T | null
  error?: any
}

//!TODO: need to add real data from tab2
export const createCcpaPaymentLink = async (
  payload: CcpaCreateLinkPayload
): Promise<ApiResponseBodyType<CreateCcpaLinkResultType>> => {
  const url = `${getConfig().api.baseURL}/v1/ecbr/quickpay/ccpa/payment-link`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }

  try {
    const res = await fetch(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData, error: null }
  } catch (error) {
    console.log(error)
    return { data: null, error }
  }
}

export const checkCcpaPaymentStatus = async (
  refToken: string
): Promise<ApiResponseBodyType<CcpaPaymentStatusResponseType>> => {
  const url = `${getConfig().api.baseURL}/v1/ecbr/quickpay/ccpa/status?refToken=${refToken}`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const res = await fetch(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData, error: null }
  } catch (error) {
    return { data: null, error }
  }
}
