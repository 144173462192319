import styled from '@emotion/styled'
import NumberFormat from 'react-number-format'

type InputCustomFormatProps = {
  disabled: boolean
}

const InputCustomFormat = styled(NumberFormat)`
  display: block;
  width: 100%;
  padding: 0.36rem 0.54rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #333333;
  background-color: ${(props) => (props.disabled ? '#eceeef' : '#fff')};
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #333333;
  border-radius: 0;
  cursor: ${(props) => props.disabled && 'not-allowed'};
`

export default InputCustomFormat
