import styled from '@emotion/styled'
import { customIcon } from 'components/theme'

export const IconSuccess = styled.div`
  min-width: 1.8rem;
  height: 35px;
  background-color: #1bc54f;
  mask-image: url(${customIcon('dialogSuccess')});
  mask-size: cover;
  mask-repeat: no-repeat;
`
