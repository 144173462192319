import { getConfig } from 'core/config'
import useGoogleAnalytics from 'hooks/useServices/googleAnalytics'
import _ from 'lodash'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _maxBy from 'lodash/maxBy'
import { clearFormat } from 'utils/string-helper'
import { CcpaCreateLinkPayload, CcpaPaymentStatusResponseType, CreateCcpaLinkResultType } from 'types/plan-code'

import { validatePolicyCCPA, validatePolicyDA } from 'utils/validate'
import { getPolicyDetail, getLastCCPA } from '../../ccpa/hooks/useService'
import { mockPendingDAInfoRes } from '../mock'
import {
  prepareRecordDataFromPolicy,
  prepareRecordDataFromApplication,
  prepareRecordRetrieveDabit,
  extractReasonCode,
} from '../utils'
import { REGISTER_STATUS, UPDATE_TYPES } from '../static-content'
export type RespType = {
  data?: any
  error?: any
}
import apiInstance from '../components/api-instance'

// GIFT : find existing CCPA data
export const verifyCCPAExisting = async (info): Promise<any> => {
  // TODO: return status from this function cannot be 'POLICY_NOT_FOUND'
  try {
    let resultPolicyDetails
    let resultCheckCCPA
    let isCCPAPayment
    let resultSaveData
    let creditCardAuthorization
    if (info.isDataByPolicy == true) {
      resultPolicyDetails = await getEIPPolicyDetails({
        policyEffectiveToDate: info.policyEffectiveDate,
        effectiveDate: info.policyEffectiveDate,
        policyNumber: info.policyNo,
      })
      if (resultPolicyDetails.searchPolicyStatus != 200) {
        return resultPolicyDetails //handle when get policy detail not found.
      }
      const RLSPolicy = resultPolicyDetails.data.policyDetail.RetrievePolicyDetailsResponse.RLSPolicy

      let genData = await prepareRecordDataFromPolicy(null, {
        ...RLSPolicy,
        policyEffectiveToDate: info.policyEffectiveDate,
        effectiveDate: info.policyEffectiveDate,
      })
      // Save Data Recurring-payments
      resultSaveData = await saveRecurringData(genData)
      if (resultSaveData.status !== 200) {
        return { data: { searchStatus: 'SERVER_ERROR' }, error: 'SERVER_ERROR', searchPolicyStatus: 500 }
      }

      creditCardAuthorization = RLSPolicy?.creditCardAuthorization
      const paymentModeCode = RLSPolicy?.paymentModeCode
      isCCPAPayment = ['B', 'V'].some((status) => creditCardAuthorization?.cardStatus === status)
    } else {
      resultPolicyDetails = await getEIPApplicationDetails(info.policyNo)
      if (resultPolicyDetails.searchPolicyStatus >= 400) {
        const policyIdStatus = resultPolicyDetails.searchPolicyStatus
        const statusSearch = [456, 457].includes(policyIdStatus) ? 'POLICY_NOT_FOUND' : 'SERVER_ERROR'
        return { data: { searchStatus: statusSearch }, error: statusSearch, searchPolicyStatus: 400 }
      }
      let genData = await prepareRecordDataFromApplication(null, {
        ...resultPolicyDetails.data.policyDetail.data,
        policyEffectiveToDate: info.policyEffectiveDate,
        effectiveDate: info.policyEffectiveDate,
      })
      // Save Data Recurring-payments
      resultSaveData = await saveRecurringData(genData)
      if (resultSaveData.status !== 200) {
        return { data: { searchStatus: 'SERVER_ERROR' }, error: 'SERVER_ERROR', searchPolicyStatus: 500 }
      }
      const paymentMethod = resultPolicyDetails.data.policyDetail.data.paymentMethod
      isCCPAPayment = ['C'].some((status) => paymentMethod === status)
    }

    if (isCCPAPayment) {
      // Save CCPA to Recurring-payments and Go to form
      return {
        data: {
          resultPolicyDetails,
          resultRecurringPayments: resultSaveData.data,
          ccpaDetail: creditCardAuthorization,
          isDataByPolicy: info.isDataByPolicy,
          searchStatus: 'FOUND_CCPA_EXISTING',
        },
        error: null,
      }
    } else {
      // Get Call EIP Retrieve Debit Authorization - Found Existing DA
      let resultRetrieveDebit = await checkDebitAuthorizationDetails({ policyNumber: info.policyNo })

      if (resultRetrieveDebit.status == 200) {
        const genResultPolicy = {
          resultPolicyDetails,
          RetrieveDebit: resultRetrieveDebit.data,
          resultRecurringPayments: resultSaveData.data,
          ccpaDetail: null,
          isDataByPolicy: info.isDataByPolicy,
          searchStatus: 'FOUND_RETRIEVE_DEBIT',
        }
        return {
          data: genResultPolicy,
          error: null,
        }
      }
      if ([456, 457, 404].includes(resultRetrieveDebit.status) === false) {
        // NOTE:: line 151, 166, 178 return format should be the same
        updateRecurringData({
          updateType: UPDATE_TYPES.UPDATE,
          data: {
            _id: resultSaveData.data.data._id,
            activeRecurring: {
              daEffDate: 'EIP Error',
              debterFiratName: 'EIP Error',
              debterLastName: 'EIP Error',
              activebankNumber: 'EIP Error',
              activeaccountNumber: 'EIP Error',
              activecollectionBank: 'EIP Error',
            },
          },
        })
      }
      return {
        data: {
          resultPolicyDetails,
          resultRecurringPayments: resultSaveData.data,
          ccpaDetail: null,
          isDataByPolicy: info.isDataByPolicy,
          searchStatus: 'NOTFOUND_RETRIEVE_DEBIT',
        },
        error: null,
      }
    }
  } catch (error) {
    console.error('verifyCCPAExisting error', error.message)
    return { data: { searchStatus: 'POLICY_NOT_FOUND' }, error: null } //handle when get policy detail not found.

    // return { data: null, error: 'POLICY_NOT_FOUND', searchPolicyStatus: 457 } //handle when get policy detail not found.
  }
}

// TODO: wait for ECBR DB colllection is ready
export const verifyPendingDA = async (info): Promise<RespType> => {
  // Check DB
  const modifiedPolicyNo = !_isEmpty(info) && clearFormat(info?.policyNo, '')
  let resultRecurringData = await checkRecurringData({ policyId: modifiedPolicyNo })

  // if found pending (from ecbr DB)
  const dataRecurring = _.get(resultRecurringData, 'data.data', null)
  const isPendingDA = resultRecurringData?.status == 200 ? true : false
  if (isPendingDA && !_.isEmpty(dataRecurring)) {
    // Resume case
    const itemAttachments = _.get(resultRecurringData.data, 'data._attachments', null)
    if (!_.isEmpty(itemAttachments)) {
      let resultImage = await getAttachment({
        _attachments: itemAttachments,
      })
      resultRecurringData.data.data._attachments = resultImage.data._attachments
    }

    return {
      data: {
        resultRecurringPayments: resultRecurringData.data,
        searchStatus: 'FOUND_DA_EXISTING',
      },
      error: null,
    }
  } else {
    // no pending da
    const resultVerify = await verifyCCPAExisting(info) // NOTE:: go to read function at line 100
    return resultVerify
  }
}

//! Related to tab 3
type ApiResponseBodyType<T> = {
  data?: T | null
  error?: any
}

//!TODO: need to add real data from tab2
export const createCcpaPaymentLink = async (
  payload: CcpaCreateLinkPayload
): Promise<ApiResponseBodyType<CreateCcpaLinkResultType>> => {
  const url = `${getConfig().api.baseURL}/v1/ecbr/quickpay/ccpa/payment-link`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }

  try {
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData, error: null }
  } catch (error) {
    console.error(`createCcpaPaymentLink ${error.message}`)
    return { data: null, error }
  }
}

export const checkCcpaPaymentStatus = async (
  refToken: string
): Promise<ApiResponseBodyType<CcpaPaymentStatusResponseType>> => {
  const url = `${getConfig().api.baseURL}/v1/ecbr/quickpay/ccpa/status?refToken=${refToken}`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const checkDebitAuthorizationDetails = async (
  payload: any
): Promise<{
  status?: any
  data?: any
  error?: any
}> => {
  try {
    const url = `${getConfig().api.baseURL}/v1/application/atp/debitAuthorizationDetails?policyNumber=${
      payload.policyNumber
    }`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    const responesCode = _.get(jsonData, 'code', null)

    if (jsonData.error || (responesCode !== null && responesCode !== 200)) {
      // if (responesCode === 404) {
      //   return { data: null, error: jsonData, status: responesCode }
      // }

      if (jsonData.details.length > 0) {
        const mapperStatus = extractReasonCode(jsonData.details[0].errorDesc)

        return { data: null, error: jsonData.details[0].exception, status: mapperStatus }
      }

      return { data: null, error: jsonData, status: 400 }
    }

    const maxInnerObject = _.maxBy(jsonData.RetrieveDebitAuthorizationResponse.RLSApplication, 'effectiveDate')

    return {
      data: {
        RetrieveDebitAuthorizationResponse: {
          RLSApplication: maxInnerObject,
        },
      },
      error: null,
      status: 200,
    }
  } catch (error) {
    return { data: null, error, status: 500 }
  }
}

export const saveRecurringData = async (
  payload: any
): Promise<{
  status?: any
  data?: any
  error?: any
}> => {
  try {
    // genData = {...genData,daStatus:'Create'}
    const url = `${getConfig().api.baseURL}/v1/recurring-payments/create`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...payload, daStatus: 'Create' }),
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText, status: res.status }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData, error: null, status: 200 }
  } catch (error) {
    return { data: null, error, status: 500 }
  }
}

export const checkRecurringData = async (
  payload: any
): Promise<{
  status?: any
  data?: any
  error?: any
}> => {
  try {
    const url = `${getConfig().api.baseURL}/v1/recurring-payments/search`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText, status: 500 }
    }

    const jsonData = await res.json()
    const getStatus = _get(jsonData, 'status', 200)
    if (jsonData.error || getStatus != 200) {
      return { data: null, error: jsonData.error }
    }
    if (!_isEmpty(jsonData.data)) {
      return { data: jsonData, error: null, status: 200 }
    } else {
      return { data: null, error: null, status: 500 }
    }
  } catch (error) {
    return { data: null, error, status: 500 }
  }
}

export const getAttachment = async (
  payload: any
): Promise<{
  status?: any
  data?: any
  error?: any
}> => {
  try {
    const url = `${getConfig().api.baseURL}/v1/recurring-payments/getAttachment`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData, error: null, status: 200 }
  } catch (error) {
    return { data: null, error, status: 500 }
  }
}

export const updateRecurringData = async (
  payload: any
): Promise<{
  status?: any
  data?: any
  error?: any
}> => {
  try {
    const url = `${getConfig().api.baseURL}/v1/recurring-payments/update`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData, error: null, status: 200 }
  } catch (error) {
    return { data: null, error, status: 500 }
  }
}

type CheckAccountModel = {
  bankName: String
  bankAccountName: String
  bankAccountNumber: String
}

export const onCheckValueAccountData = async (
  checkAcc_Old: CheckAccountModel,
  checkAcc_New: CheckAccountModel
): Promise<boolean> => {
  try {
    if (
      checkAcc_Old.bankName === checkAcc_New.bankName &&
      checkAcc_Old.bankAccountNumber === checkAcc_New.bankAccountNumber
    ) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

export const getEIPPolicyWithIDList = async (
  payload: any
): Promise<{
  searchPolicyStatus?: any
  data?: any
  error?: any
}> => {
  try {
    let url = `${getConfig().api.baseURL}/v1/policy/list/${payload}`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText, searchPolicyStatus: res.status }
    }

    const jsonData = await res.json()

    const reasonCode = _.get(jsonData, 'data.details[0].exception', null)
    const statusSearch = _.get(jsonData, 'status', null)
    if (statusSearch === 457) {
      return { data: [], error: jsonData.data, searchPolicyStatus: statusSearch }
    }
    if (jsonData.error || reasonCode !== null) {
      return {
        data: null,
        error: jsonData.error ? jsonData.error : reasonCode.reason,
        searchPolicyStatus: statusSearch,
      }
    }

    return {
      data: jsonData.data.RetrievePolicyListResponse.policy.map((p: any) => {
        return {
          policyNumber: p.policyNumber,
          policyEffectiveDate: p.policyEffectiveDate,
          policyExpirationDate: p.policyExpirationDate,
          productCode: p.productCode,
          policyStatusCode: p.policyStatusCode,
          isDataByPolicy: true,
        }
      }),
      error: null,
      searchPolicyStatus: jsonData.status,
    }
  } catch (error) {
    return { data: null, error, searchPolicyStatus: 500 }
  }
}
export const getEIPApplicationList = async (
  payload: any
): Promise<{
  searchPolicyStatus?: any
  data?: any
  error?: any
}> => {
  try {
    let url = `${getConfig().api.baseURL}/v1/application/atp/list`
    if (payload) {
      const queryString = new URLSearchParams(payload).toString()
      url += `?${queryString}`
    }
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText, searchPolicyStatus: res.status }
    }

    const jsonData = await res.json()

    const statusSearch = _get(jsonData, 'data[0].exception', { reasonCode: 0, reason: '' })
    if (parseInt(statusSearch.reasonCode) === 457) {
      return { data: [], error: statusSearch.reason, searchPolicyStatus: parseInt(statusSearch.reasonCode) }
    }
    if (jsonData.error) {
      return { data: null, error: jsonData.error, searchPolicyStatus: 400 }
    }

    return {
      data: jsonData.data.map((p: any) => {
        return {
          policyNumber: p.applicationNumber,
          policyEffectiveDate: p.applicationCaptureDate,
          policyExpirationDate: p.applicationCaptureDate,
          productCode: p.applicationProduct.productCode,
          policyStatusCode: p.applicationStatusCode,
          isDataByPolicy: false,
        }
      }),
      error: null,
      searchPolicyStatus: jsonData.status,
    }
  } catch (error) {
    return { data: null, error, searchPolicyStatus: 500 }
  }
}

export const getEIPPolicyDetails = async (
  payload: any
): Promise<{
  searchPolicyStatus?: any
  data?: any
  error?: any
}> => {
  try {
    const policyEffectiveToDate = payload.policyEffectiveToDate
      ? `?policyEffectiveDate=${payload.policyEffectiveToDate}`
      : ``
    let url = `${getConfig().api.baseURL}/v1/eip/getPolicyDetailWithPolicyNo/${
      payload.policyNumber
    }${policyEffectiveToDate}`

    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: { searchStatus: 'SERVER_ERROR' }, error: res.statusText, searchPolicyStatus: 500 }
    }

    const jsonData = await res.json()

    const reasonCode = _.get(jsonData, 'RetrievePolicyDetailsResponse.exception.reasonCode', null)
    if (jsonData.error || reasonCode !== null) {
      return {
        data: {
          searchStatus: [456, 457].includes(parseInt(reasonCode)) ? 'POLICY_NOT_FOUND' : 'SERVER_ERROR',
        },
        error: jsonData.error,
        searchPolicyStatus: parseInt(reasonCode),
      }
    }

    return {
      data: {
        policyDetail: jsonData,
        searchStatus: 'DA_SUCCESS',
      },
      searchPolicyStatus: 200,
    }
  } catch (error) {
    return { data: null, error, searchPolicyStatus: 500 }
  }
}
export const getEIPApplicationDetails = async (
  payload: any
): Promise<{
  searchPolicyStatus?: any
  data?: any
  error?: any
}> => {
  try {
    let url = `${getConfig().api.baseURL}/v1/application/atp/details/${payload}`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText, searchPolicyStatus: 500 }
    }

    const jsonData = await res.json()

    const statusCheckingClientError = jsonData.status >= 400
    if (jsonData.error || statusCheckingClientError) {
      if (jsonData.data.length > 0) {
        const mapperStatus = extractReasonCode(jsonData.data[0].errorDesc)
        return { data: null, error: jsonData.error, searchPolicyStatus: mapperStatus }
      }

      return { data: null, error: jsonData.error, searchPolicyStatus: 400 }
    }

    return { data: { policyDetail: jsonData, searchStatus: 'DA_SUCCESS' }, searchPolicyStatus: 200 }
  } catch (error) {
    return { data: null, error, searchPolicyStatus: 500 }
  }
}

export const deleteRecurringID = async (
  payload: any
): Promise<{
  searchPolicyStatus?: any
  data?: any
  error?: any
}> => {
  try {
    let url = `${getConfig().api.baseURL}/v1/recurring-payments/clearPolicyId`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText, searchPolicyStatus: 400 }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error, searchPolicyStatus: 400 }
    }

    return { data: { policyDetail: jsonData, searchStatus: 'DA_SUCCESS' }, searchPolicyStatus: 200 }
  } catch (error) {
    return { data: null, error, searchPolicyStatus: 500 }
  }
}

export const checkBankAccount = async (
  payload: any
): Promise<{
  data?: any
  error?: any
}> => {
  try {
    let url = `${getConfig().api.baseURL}/v1/checkAccount`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: {
        checkAccStatus: 'ERROR',
        statusDesc: 'An unknown error occurred',
        accountName: 'An unknown accountName',
      }, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: {
        checkAccStatus: 'ERROR',
        statusDesc: 'An unknown error occurred',
        accountName: 'An unknown accountName',
      }, error: jsonData.error }
    }

    return { data: jsonData }
  } catch (error) {
    return {
      data: {
        checkAccStatus: 'ERROR',
        statusDesc: 'An unknown error occurred',
        accountName: 'An unknown accountName',
      },
    }
  }
}

export const sendEDARegister = async (
  payload: any
): Promise<{
  data?: any
  error?: any
}> => {
  try {
    let url = `${getConfig().api.baseURL}/v1/eda-integration/register`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData }
  } catch (error) {
    return {
      data: {
        accountNo: payload.accountNo,
        policyNo: payload.policyNo,
        statusCode: 'E0001',
        statusDesc: 'ERROR',
      },
    }
  }
}

export const checkEDARegister = async (
  payload: any
): Promise<{
  data?: any
  error?: any
}> => {
  try {
    let url = `${getConfig().api.baseURL}/v1/eda-integration/status`
    const token = localStorage.getItem('ecbr:token')
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
    const res = await apiInstance(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData }
  } catch (error) {
    return {
      data: {
        accountNo: payload.accountNo,
        policyNo: payload.policyNo,
        statusCode: 'E0001',
        statusDesc: 'ERROR',
      },
    }
  }
}

export const onRegisterEDA = async (payload) => {
  try {
    const { accountNo, policyNo } = payload
    const payloadEDARegister = {
      accountNo: accountNo,
      policyNo: policyNo,
    }
    const resultCheckEDA = await sendEDARegister(payloadEDARegister)
    switch (resultCheckEDA.data.statusCode) {
      case 'I0000':
      case 'I1000':
        return {
          status: REGISTER_STATUS.SEND_REQUEST_SUCCESS,
          data: resultCheckEDA.data,
        }
      case 'E1301':
        return {
          status: REGISTER_STATUS.EDA_DUPLICATE,
          data: resultCheckEDA.data,
        }
      case 'I101':
        return {
          status: REGISTER_STATUS.NOT_FIND_RECORD,
          data: resultCheckEDA.data,
        }
      default:
        return {
          status: REGISTER_STATUS.TECH_ISSUE,
          data: null,
        }
    }
  } catch (error) {
    return {
      status: REGISTER_STATUS.TECH_ISSUE,
      data: null,
    }
  }
}

export const onCheckRegisterEDA = async (payload) => {
  try {
    const { accountNo, policyNo } = payload
    const listEDACode = ['I1000', 'I0001']
    const listEDACodePending = ['DB000']
    const listEDACodeBypass = ['E1301']
    const payloadEDARegister = {
      accountNo: accountNo,
      policyNo: policyNo,
    }
    const resultCheckEDA = await checkEDARegister(payloadEDARegister)
    switch (resultCheckEDA.data.statusCode) {
      case 'I0000':
      case 'I1000':
        return {
          status: REGISTER_STATUS.EDA_SUCCESS,
          data: resultCheckEDA.data,
        }
      case 'I0001':
        return {
          status: REGISTER_STATUS.EDA_FAIL,
          data: resultCheckEDA.data,
        }
      default:
        // Error
        return {
          status: REGISTER_STATUS.NO_STATUS,
          data: null,
        }
    }
  } catch (error) {
    return {
      status: REGISTER_STATUS.NO_STATUS,
      data: null,
    }
  }
}
