import React from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import PropTypes from 'prop-types'

const propTypes = {
  taxID: PropTypes.string,
  ref1: PropTypes.string,
  ref2: PropTypes.string,
  amount: PropTypes.number,
  size: PropTypes.number,
}

const defaultProps = {
  taxID: '010755500037601',
  ref1: '5049325664',
  ref2: '200001111',
  amount: 2400000,
  size: 100,
}

function KTAXAQRCodeGenerator(props) {
  const { taxID, ref1, ref2, amount, size } = props
  const amountToInt = amount * 100

  const value = `|${taxID}\n${ref1}\n${ref2}\n${amountToInt}`
  return <QRCodeCanvas size={size} value={value} />
}

KTAXAQRCodeGenerator.propTypes = propTypes
KTAXAQRCodeGenerator.defaultProps = defaultProps

export default KTAXAQRCodeGenerator
