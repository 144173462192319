/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import styles from './tabs-styles'

const TabsPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  active: PropTypes.string,
  onChange: PropTypes.func,
}

const TabsDefaultProps = {
  children: null,
  className: '',
  active: null,
  onChange() {},
}

const TabsItemPropTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
}
const TabsItemDefaultProps = {
  id: '',
  children: null,
  active: false,
  disabled: false,
  className: '',
  onClick() {},
}

function Tabs(props) {
  const { children, className, active, onChange } = props

  const containerRef = useRef()
  const itemsRef = useRef([])
  const [activeTabId, setActiveTabId] = useState(active)

  useEffect(() => {
    scrollItemToView(active)
  }, [active])

  useEffect(() => {
    const handleResize = debounce(() => scrollItemToView(activeTabId), 200)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [activeTabId])

  const doOnChange = (activeTabId) => {
    onChange(activeTabId)
    setActiveTabId(activeTabId)
    scrollItemToView(activeTabId)
  }

  const scrollItemToView = (id) => {
    const el = itemsRef.current.filter((item) => (item ? item.getAttribute('data-id') === id : false))
    if (el.length === 1 && containerRef.current) {
      const nodeEl = el[0]
      const posX = Math.max(get(nodeEl, 'offsetLeft', 0), 0)
      containerRef.current.scrollTo(posX, 0)
    }
  }

  const tabsProps = {}
  if (className) tabsProps.className = className

  return (
    <div {...tabsProps} css={styles.tabs} data-active-id={active}>
      <ul css={styles.tabsList} ref={containerRef}>
        {React.Children.map(children, (child, index) => {
          if (get(child, 'type.displayName') === 'TabsItem') {
            return React.cloneElement(child, {
              ...child.props,
              active: active === child.props.id,
              ref: (el) => (itemsRef.current[index] = el),
              onClick: () => {
                if (child.props.disabled || active === child.props.id) return
                return doOnChange(child.props.id)
              },
            })
          }
          return child
        })}
      </ul>
    </div>
  )
}

const TabsItem = React.forwardRef((props, ref) => {
  const { children, active, disabled, id, className, onClick } = props

  const tabsItemProps = {}
  if (className) tabsItemProps.className = className

  const tabsItemStyles = [styles.tabsItem]
  if (disabled) tabsItemStyles.push(styles.tabItemState.disabled)
  if (active) tabsItemStyles.push(styles.tabItemState.active)

  return (
    <li
      {...tabsItemProps}
      ref={ref}
      css={tabsItemStyles}
      data-id={id}
      data-is-active={active}
      onClick={() => onClick(id)}
    >
      {children}
    </li>
  )
})

Tabs.displayName = 'Tabs'
Tabs.propTypes = TabsPropTypes
Tabs.defaultProps = TabsDefaultProps

TabsItem.displayName = 'TabsItem'
TabsItem.propTypes = TabsItemPropTypes
TabsItem.defaultProps = TabsItemDefaultProps

Tabs.Item = TabsItem

export default Tabs
