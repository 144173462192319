import React, { useEffect, useState } from 'react'
import { Input as RSInput } from 'reactstrap'
import { useForm, useWatch, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useLogin } from '../../../../hooks/useServices/login'
import { toCurrency, numberWords } from 'utils/number-to-thai-words'
import { now, convertMonthToThaiMonth } from 'utils/date'

import { Button } from 'ktaxa-ui-components/index'
import DatePickerComponent from 'components/input/date-picker'
import { SpaceBetweenInput, InputCustomFormat } from 'components/input'
import { IconButton } from 'components/buttons'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { renderErrorMessageBlock } from 'components/message-blocks'
import { SigingModal } from 'components/modal'

import CancelReasonSection from '../cancel-reason'
import SubmitSection from '../submit'

import PreviewDialog from '../../components/preview-dialog'
import { IconSuccess } from 'components/icons'

import { usePrevious } from '../../../../hooks/useServices/utils'
import { useRouterForHandleSummit } from '../../hooks/useRouter'
import { schemCancelFormSection, RenderInputDisabledClear } from '../../hooks/useForm'
import { handleInitAttach, useGetEcbrCancelFrom } from '../../hooks/service'
import staticContentPage from '../../static-content-page'
import { getToggles } from 'core/config'

const CancelFormSection = (props) => {
  const { currentSection, setCurrentSection, state, setState } = props
  const { infoIssue } = state
  const { userInfo } = useLogin({})
  const nowDateString = now().toISOString()

  const { formState, control, setValue, handleSubmit, getValues, reset, trigger } = useForm({
    mode: 'all',
    resolver: yupResolver(schemCancelFormSection),
  })
  const [isChange, setIsChange] = useState<boolean>(true)
  const [snapSign, useSnapSign] = useState({
    sign1: {
      value: [],
      isPortrait: undefined,
    },
    sign2: {
      value: [],
      isPortrait: undefined,
    },
    sign3: {
      value: [],
      isPortrait: undefined,
    },
  })

  const [citizenId, setCitizenId] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const resetObject = {
    [staticContentPage.cancelFormSection.reason.id]: getToggles().ENABLE_ECBRSYS_ECBR ? '1' : '0',
    [staticContentPage.cancelFormSection.reason.ecbrNo.radio[0].textInputs.id]: '',
    [staticContentPage.cancelFormSection.reason.ecbrNo.radio[1].textInputs.id]: '',
    [staticContentPage.cancelFormSection.reason.other.radio[0].textInputs.id]: '',
    [staticContentPage.cancelFormSection.textInputs[0].id]: infoIssue?.agentFirstName,
    [staticContentPage.cancelFormSection.textInputs[1].id]: infoIssue?.agentLastName,
    [staticContentPage.cancelFormSection.textInputs[2].id]: citizenId,
    [staticContentPage.cancelFormSection.textInputs[3].id]: userInfo?.agent_code,
    [staticContentPage.cancelFormSection.textInputs[4].id]: infoIssue?.agentEmail,
    [staticContentPage.cancelFormSection.textInputs[5].id]: phoneNumber,
    [staticContentPage.cancelFormSection.textInputs[6].id]: infoIssue?.cbrNumber,
    [staticContentPage.cancelFormSection.textInputs[7].id]: nowDateString,
    [staticContentPage.cancelFormSection.textInputs[8].id]: `${toCurrency(infoIssue?.paymentAmount)} บาท (${numberWords(
      infoIssue?.paymentAmount
    )})`,
    sign1: '',
    sign2: '',
    sign3: '',
    managerCode: infoIssue?.managerAgentFullCode ? infoIssue?.managerAgentFullCode : infoIssue?.agentFullCode,
    managerFullName: infoIssue?.managerAgentFullName ? infoIssue?.managerAgentFullName : infoIssue?.agentFullName,
  }

  const { errors, dirtyFields } = formState

  const isActive = currentSection === staticContentPage.cancelationSequence[2]

  const prevProps = usePrevious(props)
  const onClick = () => {
    setCurrentSection(staticContentPage.cancelationSequence[4])
    setIsChange(false)
  }

  const onClickBackButton = () => {
    const initAttachmentList = handleInitAttach({ maxAttachment: 4 })
    setState({ ...state, attachment: initAttachmentList })
    setCurrentSection(staticContentPage.cancelationSequence[1])
    setIsChange(true)
  }

  const resetForm = () => {
    reset({
      object: resetObject,
    })
    useSnapSign({
      sign1: {
        value: [],
        isPortrait: undefined,
      },
      sign2: {
        value: [],
        isPortrait: undefined,
      },
      sign3: {
        value: [],
        isPortrait: undefined,
      },
    })
  }
  // initial state
  const conditionInitForm =
    currentSection !== staticContentPage.cancelationSequence[2] &&
    currentSection !== staticContentPage.cancelationSequence[4]

  const newHandleSubmit = async () => {
    const isValid = await trigger()
    const data = getValues()
    return { isValid, data }
  }

  useRouterForHandleSummit(
    currentSection,
    isActive,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <Button onClick={onClickBackButton} type="secondary">
          กลับ
        </Button>
      ),
      extraButton: () => <SubmitSection currentSection={currentSection} handleSubmit={newHandleSubmit} state={state} />,
    }
  )

  const fullName = infoIssue ? infoIssue?.agentName : ''

  const nowDate = now()
  const dateBuddistEra = `${nowDate.format('D')} ${convertMonthToThaiMonth(nowDate.format('M'), true)} ${nowDate.format(
    'BBBB'
  )}`
  const ecbrCurrencyFormat = `${toCurrency(infoIssue?.paymentAmount)} บาท (${numberWords(infoIssue?.paymentAmount)})`

  const [show, setShow] = useState(false)
  const [cancelFormEcbr, useCancelFormEcbr] = useState('')
  const onClickModal = async () => {
    await setShow(!show)
    await useCancelFormEcbr('')
    setIsChange(false)
  }
  const onLoadCancelEcbrForm = async () => {
    await trigger()
    const res = await useGetEcbrCancelFrom(getValues(), state)
    useCancelFormEcbr(res)
    setShow(!show)
    setIsChange(false)
  }

  useEffect(() => {
    if (isChange) {
      resetForm()
    }
  }, [infoIssue, conditionInitForm, reset])

  if (!isActive) return null
  return (
    <ContentContainer>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
          }
        }}
      >
        <h1>{staticContentPage.cancelFormSection.mainTitle}</h1>
        <SectionContainer>
          {staticContentPage.cancelFormSection.textInputs.slice(0, 6).map((ti, index) => {
            return (
              <div key={ti.id} className="name-input" style={{ paddingBottom: 20 }}>
                <label style={{ minWidth: 250 }}>{ti.label}</label>
                {ti.format || ti.thousandSeparator ? (
                  <Controller
                    name={`object.${ti.id}`}
                    control={control}
                    render={({ field }) => {
                      if (ti.id === 'citizenId') {
                        return (
                          <RenderInputDisabledClear
                            ti={ti}
                            field={field}
                            defaultState={citizenId}
                            setState={setCitizenId}
                          />
                        )
                      } else if (ti.id === 'phoneNumber') {
                        return (
                          <RenderInputDisabledClear
                            ti={ti}
                            field={field}
                            defaultState={phoneNumber}
                            setState={setPhoneNumber}
                          />
                        )
                      } else {
                        return (
                          <InputCustomFormat
                            {...field}
                            disabled={getValues()?.object?.[ti.id] && !dirtyFields.object?.[ti.id]}
                            mask={ti.mask}
                            format={ti.format}
                            maxLength={ti.maxLength}
                            thousandSeparator={ti.thousandSeparator}
                            decimalScale={ti.decimalScale}
                            fixedDecimalScale={ti.fixedDecimalScale}
                            isAllowed={ti.isAllowed}
                          />
                        )
                      }
                    }}
                  />
                ) : (
                  <Controller
                    name={`object.${ti.id}`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <RSInput
                          {...field}
                          type="text"
                          id={`object.${ti.id}`}
                          disabled={getValues()?.object?.[ti.id] && !dirtyFields.object?.[ti.id]}
                          onChange={(v) => {
                            v.preventDefault()
                            let value = v.target.value
                            if (ti.condition === 'textOnly') value = value.replace(/[^A-Za-z.\u0E01-\u0E5B]/gi, '')
                            return field.onChange(value)
                          }}
                        />
                      )
                    }}
                  />
                )}
                {renderErrorMessageBlock(errors?.object?.[ti.id])}
              </div>
            )
          })}
          <div className="name-input" style={{ paddingBottom: 20 }}>
            <SpaceBetweenInput
              inputForm1={
                <>
                  <label>{staticContentPage.cancelFormSection.textInputs[6].label}</label>
                  <Controller
                    name={`object.${staticContentPage.cancelFormSection.textInputs[6].id}`}
                    control={control}
                    render={({ field }) => (
                      <InputCustomFormat
                        {...field}
                        disabled={
                          getValues()?.object?.[staticContentPage.cancelFormSection.textInputs[6].id] &&
                          !dirtyFields.object?.[staticContentPage.cancelFormSection.textInputs[6].id]
                        }
                        id={`object.${staticContentPage.cancelFormSection.textInputs[6].id}`}
                        mask={`${staticContentPage.cancelFormSection.textInputs[6].mask}`}
                        format={`${staticContentPage.cancelFormSection.textInputs[6]?.format}`}
                        value={infoIssue.cbrNumber}
                        defaultValue={infoIssue.cbrNumber}
                      ></InputCustomFormat>
                    )}
                  />
                  {renderErrorMessageBlock(errors?.object?.[staticContentPage.cancelFormSection.textInputs[6].id])}
                </>
              }
              inputForm2={
                <>
                  <label style={{ color: '#2425A9' }}>{staticContentPage.cancelFormSection.textInputs[7].label}</label>
                  <Controller
                    name={`object.${staticContentPage.cancelFormSection.textInputs[7].id}`}
                    control={control}
                    defaultValue={`object.${staticContentPage.cancelFormSection.textInputs[7].id}`}
                    render={({ field }) => (
                      <DatePickerComponent
                        {...field}
                        disabled
                        showIcon={false}
                        minDate={new Date().toISOString()}
                        id={staticContentPage.cancelFormSection.textInputs[7].id}
                        dateFormat="DD/MM/YYYY"
                        onChange={(v) => {
                          field.onChange(v)
                        }}
                        readOnly
                        value={nowDateString}
                      />
                    )}
                  />
                  {renderErrorMessageBlock(errors?.object?.[staticContentPage.cancelFormSection.textInputs[7].id])}
                </>
              }
            />
          </div>
          {staticContentPage.cancelFormSection.textInputs.slice(8).map((ti, index) => (
            <div key={ti.id} className="name-input" style={{ paddingBottom: 20 }}>
              <label style={{ color: '#2425A9' }}>{ti.label}</label>
              <Controller
                name={`object.${ti.id}`}
                control={control}
                render={({ field }) => (
                  <RSInput
                    {...field}
                    type="text"
                    id={`object.${ti.id}`}
                    disabled={getValues()?.object?.[ti.id] && !dirtyFields.object?.[ti.id]}
                    value={ecbrCurrencyFormat}
                    defaultValue={ecbrCurrencyFormat}
                    onChange={(v) => {
                      v.preventDefault()
                      let value = v.target.value
                      if (ti.condition === 'textOnly') value = value.replace(/[^A-Za-z.\u0E01-\u0E5B]/gi, '')
                      return field.onChange(value)
                    }}
                  />
                )}
              />
              {renderErrorMessageBlock(errors?.object?.[ti.id])}
            </div>
          ))}
        </SectionContainer>
        <SectionContainer>
          <label>
            <b>กรุณาเลือกสาเหตุในการขอยกเลิกใบรับเงินชั่วคราว</b>
          </label>
          <CancelReasonSection
            state={state}
            errors={errors}
            control={control}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            prevProps={prevProps}
            resetObject={resetObject}
            resetForm={resetForm}
          />
        </SectionContainer>
        {getToggles().ENABLE_ECBRSYS_ECBR && (
          <SectionContainer>
            <div key={'managerFullName'} className="name-input">
              <label style={{ minWidth: 250 }}>{'ชื่อนามสกุลผู้จัดการ'}</label>
              <Controller
                name={`object.managerFullName`}
                control={control}
                render={({ field }) => {
                  return (
                    <RSInput
                      {...field}
                      type="text"
                      id={`object.managerFullName`}
                      onChange={(v) => {
                        v.preventDefault()
                        let value = v.target.value
                        field.onChange(value)
                      }}
                    />
                  )
                }}
              />
              {renderErrorMessageBlock(errors?.object?.['managerFullName'])}
            </div>
          </SectionContainer>
        )}
        {getToggles().ENABLE_ECBRSYS_ECBR && (
          <SectionContainer>
            <div key={'managerCode'} className="name-input" style={{ paddingBottom: 20 }}>
              <label style={{ minWidth: 250 }}>
                {getValues().object.managerCode ? 'รหัสผู้จัดการ' : 'รหัสตัวแทนผู้จัดการ'}
              </label>
              <Controller
                name={`object.managerCode`}
                control={control}
                render={({ field }) => {
                  return (
                    <RSInput
                      {...field}
                      type="text"
                      id={`object.managerCode`}
                      onChange={(v) => {
                        const objTemp = structuredClone(getValues()?.object)
                        objTemp.managerFullName = ''
                        setValue('object', objTemp)
                        v.preventDefault()
                        let value = v.target.value
                        if (value.length === 14) {
                          const managerAgentFullCode = infoIssue?.managerAgentFullCode
                          if (managerAgentFullCode == value) {
                            const managerAgentFullName = infoIssue?.managerAgentFullName
                            const objTemp = structuredClone(getValues()?.object)
                            objTemp.managerFullName = managerAgentFullName
                            setValue('object', objTemp)
                          }
                        }
                        if (value.length < 15) return field.onChange(value)
                      }}
                    />
                  )
                }}
              />
              {renderErrorMessageBlock(errors?.object?.['managerCode'])}
            </div>
          </SectionContainer>
        )}
        <SectionContainer>
          <label>
            <b>ข้าพเจ้าขอรับรองว่าข้อมูลข้างต้นเป็นความจริงทุกประการ</b>
          </label>
          <SectionContainer margin={'10'}>
            <Controller
              name={`object.sign1`}
              control={control}
              render={({ field }) => (
                <SigingModal
                  {...field}
                  idName={'agent'}
                  setSnapSignValueisPortrait={(r) =>
                    useSnapSign({
                      ...snapSign,
                      sign1: {
                        ...snapSign.sign1,
                        isPortrait: r,
                      },
                    })
                  }
                  onSign={(s, snap) => {
                    field.onChange(s)
                    useSnapSign({
                      ...snapSign,
                      sign1: snap,
                    })
                  }}
                  onClear={() => {
                    // console.log('onClear sign1 snapSign', snapSign)
                    // useSnapSign({
                    //   ...snapSign,
                    //   sign1: {
                    //     value: [],
                    //     isPortrait: undefined,
                    //   },
                    // })
                    // setValue(
                    //   'object',
                    //   {
                    //     sign1: '',
                    //   },
                    //   { shouldDirty: true, shouldValidate: true }
                    // )
                  }}
                  value={getValues()?.object?.sign1}
                  snapSignValue={snapSign.sign1}
                  fullName={fullName}
                  position={'ลงนาม ชื่อสกุลตัวแทน'}
                  date={`ลงนามวันที่ ${dateBuddistEra}`}
                  icon="SIGN"
                  text={getToggles().ENABLE_ECBRSYS_ECBR ? 'ลงนามผู้แจ้งขอยกเลิก' : 'ลงนามตัวแทน'}
                />
              )}
            />
            {renderErrorMessageBlock(errors?.object?.['sign1'])}
          </SectionContainer>
          <SectionContainer margin={'10'}>
            <Controller
              name={`object.sign2`}
              control={control}
              render={({ field }) => (
                <SigingModal
                  {...field}
                  idName={'manager'}
                  setSnapSignValueisPortrait={(r) => {
                    return useSnapSign({
                      ...snapSign,
                      sign2: {
                        ...snapSign.sign2,
                        isPortrait: r,
                      },
                    })
                  }}
                  onSign={(s, snap) => {
                    field.onChange(s)
                    useSnapSign({
                      ...snapSign,
                      sign2: snap,
                    })
                  }}
                  onClear={() => {
                    // useSnapSign({
                    //   ...snapSign,
                    //   sign2: {
                    //     value: [],
                    //     isPortrait: undefined,
                    //   },
                    // })
                    // setValue(
                    //   'object',
                    //   {
                    //     sign2: '',
                    //   },
                    //   { shouldDirty: true, shouldValidate: true }
                    // )
                  }}
                  value={getValues()?.object?.sign2}
                  snapSignValue={snapSign.sign2}
                  fullName={''}
                  position={'ลงนาม ผู้จัดการ'}
                  date={`ลงนามวันที่ ${dateBuddistEra}`}
                  icon="SIGN"
                  text={getToggles().ENABLE_ECBRSYS_ECBR ? 'ลงนามผู้จัดการ / เจ้าของสำนักงาน' : 'ลงนามผู้จัดการ'}
                />
              )}
            />
            {renderErrorMessageBlock(errors.object?.['sign2'])}
          </SectionContainer>
          {!getToggles().ENABLE_ECBRSYS_ECBR && (
            <SectionContainer margin={'10'}>
              <Controller
                name={`object.sign3`}
                control={control}
                render={({ field }) => (
                  <SigingModal
                    {...field}
                    idName={'owner'}
                    setSnapSignValueisPortrait={(r) =>
                      useSnapSign({
                        ...snapSign,
                        sign3: {
                          ...snapSign.sign3,
                          isPortrait: r,
                        },
                      })
                    }
                    onSign={(s, snap) => {
                      field.onChange(s)
                      useSnapSign({
                        ...snapSign,
                        sign3: snap,
                      })
                    }}
                    onClear={() => {
                      // console.log('onClear sign2 sign3', snapSign)
                      // useSnapSign({
                      //   ...snapSign,
                      //   sign3: {
                      //     value: [],
                      //     isPortrait: undefined,
                      //   },
                      // })
                      // setValue(
                      //   'object',
                      //   {
                      //     sign3: '',
                      //   },
                      //   { shouldDirty: true, shouldValidate: true }
                      // )
                    }}
                    value={getValues()?.object?.sign3}
                    snapSignValue={snapSign.sign3}
                    fullName={''}
                    position={'ลงนาม เจ้าของสำนักงาน'}
                    date={`ลงนามวันที่ ${dateBuddistEra}`}
                    icon="SIGN"
                    text={getToggles().ENABLE_ECBRSYS_ECBR ? 'ลงนามผู้อนุมัติและรับทราบ' : 'ลงนามเจ้าของสำนักงาน'}
                  />
                )}
              />
              {renderErrorMessageBlock(errors.object?.['sign3'])}
            </SectionContainer>
          )}
        </SectionContainer>
        <SectionContainer>
          <label>
            <b>หมายเหตุ</b>
          </label>
          <div>1. หากลูกค้ายังไม่ได้ชำระเงินให้กับตัวแทน ต้องระบุข้อความนี้ในสำเนาบัตรประชาชนลูกค้าด้วย</div>
          <div>2. กรณีตัวแทนลาออก/ถูกตัดสัญญา/ หรือผู้จัดการเป็นผู้ออก eCBR เจ้าของสำนักงานจะต้องลงนามรับทราบด้วย</div>
        </SectionContainer>
        <IconButton
          OverideIcon={
            state.attachment.find((att) => att.filename)
              ? {
                  Icon: IconSuccess,
                  side: {
                    border: '1px solid #2425aa',
                    background: '#ffffff',
                  },
                }
              : null
          }
          icon="ECBR_ATTACHMENT"
          text="แนบเอกสารเพิ่มเติม (ถ้ามี)"
          onClick={onClick}
        />
        <IconButton
          onClick={onLoadCancelEcbrForm}
          icon="PREVIEW_PDF"
          text="ตรวจทานเอกสารในรูปแบบไฟล์ PDF"
          size="large"
        />
        {cancelFormEcbr !== '' && (
          <PreviewDialog
            show={show}
            onToggle={onClickModal}
            attachItem={{
              src: cancelFormEcbr,
              filename: 'จดหมายแจ้งยกเลิกใบรับเงินชั่วคราวอิเล็กทรอนิกส์',
              base64: cancelFormEcbr,
              type: 'pdf',
            }}
            onClickSubmit={onClickModal}
          />
        )}
      </form>
    </ContentContainer>
  )
}

export default CancelFormSection
