// istanbul ignore file

export default {
  mainTitle: 'ประวัติการออกใบรับเงินชั่วคราว',
  mappingStatus: {
    created: 'CREATED',
    waitingForPay: 'RESERVED',
    waitingForCheckin: 'PENDING',
    paidAndWaitingForVerification: 'PAID',
    cancel: 'CANCELLED',
    receivedPayment: 'USED',
    all: 'ALL',
  },
  searchHistoryDropdown: [
    {
      title: 'ค้นหาจากเลขกรมธรรม์',
      id: 'searchByPolicyNo',
      status: [],
    },
    {
      title: 'ค้นหาจากเลขที่ใบรับเงินชั่วคราว',
      id: 'searchByEcbrNo',
      status: [],
    },
    {
      title: 'ค้นหาจากเลขที่บัตรประชาชน',
      id: 'searchByCitizenId',
      status: [],
    },
    {
      title: 'ค้นหาจากชื่อผู้เอาประกัน',
      id: 'searchByInsuredName',
      status: [],
    },
    {
      title: 'ค้นหาจากสถานะการชำระ',
      id: 'searchByStatus',
      status: [
        {
          title: 'รอลูกค้าชำระเงิน',
          id: 'waitingForPay',
        },
        {
          title: 'รอชำระเงินเข้าบริษัท',
          id: 'waitingForCheckin',
        },
        {
          title: 'ชำระแล้วรอตรวจสอบ',
          id: 'paidAndWaitingForVerification',
        },
        {
          title: 'บริษัทรับชำระเรียบร้อย',
          id: 'receivedPayment',
        },
        {
          title: 'ยกเลิก',
          id: 'cancel',
        },
        {
          title: 'แสดงทุกสถานะ',
          id: 'all',
        },
      ],
    },
  ],
  searchHistoryDropdownNew: [
    {
      title: 'ค้นหาจากเลขกรมธรรม์',
      id: 'searchByPolicyNo',
      status: [],
    },
    {
      title: 'ค้นหาจากเลขที่ใบรับเงินชั่วคราว',
      id: 'searchByEcbrNo',
      status: [],
    },
    {
      title: 'ค้นหาจากเลขที่บัตรประชาชน',
      id: 'searchByCitizenId',
      status: [],
    },
    {
      title: 'ค้นหาจากชื่อผู้เอาประกัน',
      id: 'searchByInsuredName',
      status: [],
    },
    {
      title: 'ค้นหาจากสถานะการชำระ',
      id: 'searchByStatus',
      status: [
        {
          title: 'ชำระแล้วรอตรวจสอบ',
          id: 'created',
        },
        {
          title: 'บริษัทรับชำระเรียบร้อย',
          id: 'receivedPayment',
        },
        {
          title: 'ยกเลิก',
          id: 'cancel',
        },
        {
          title: 'แสดงทุกสถานะ',
          id: 'all',
        },
      ],
    },
  ],
}
