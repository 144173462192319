import React, { useContext } from 'react'
import { Button } from 'ktaxa-ui-components/index'
import { AppContext } from 'contexts/app'

const BackButtonCheckStatusPage = (props) => {
  const { handleSubmitData } = props
  const { isDisabledBackButton } = useContext(AppContext)

  return (
    <>
      <Button onClick={handleSubmitData} type="secondary" disabled={isDisabledBackButton}>
        กลับ
      </Button>
    </>
  )
}

export default BackButtonCheckStatusPage
