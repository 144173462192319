import ReactGA from 'react-ga4';
import { getConfig } from 'core/config'



const useGoogleAnalytics = () => {
  const { appVersion , appName } = getConfig()
  const initialiseGA = (trackingID: string, options: Object = {}) => {
    ReactGA.initialize(trackingID, options)
    ReactGA.set({
      appName,
      appVersion,
    })
  }

  const logPageView = (location: string) => {
    ReactGA.set({ page: location })
    ReactGA.send({ hitType: 'pageview', page: location })
  };


  const sendCustomEvent = (args: any) => {
    ReactGA.event(args)
  };

  return {
    initialiseGA,
    logPageView,
    sendCustomEvent,
  };
};

export default useGoogleAnalytics;
