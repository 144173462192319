import { useSearchEcbr, useSearchEcbrEsys } from '../../../hooks/useServices/ecbr'
import staticContentPage from '../static-content-page'
import { Simulate } from 'react-dom/test-utils'
import error = Simulate.error

export const handlePayload = (val, searchBy) => {
  const payload = {
    cbrStatus: '',
    cbrNumber: '',
    personalId: '',
    insuredFirstName: '',
    insuredLastName: '',
    policyNumber: '',
  }

  switch (searchBy) {
    case staticContentPage.searchHistoryDropdown[0].id: {
      payload.policyNumber = val
      break
    }
    case staticContentPage.searchHistoryDropdown[1].id: {
      payload.cbrNumber = val
      break
    }
    case staticContentPage.searchHistoryDropdown[2].id: {
      payload.personalId = val
      break
    }
    case staticContentPage.searchHistoryDropdown[3].id: {
      payload.insuredFirstName = val
      break
    }
    case staticContentPage.searchHistoryDropdown[4].id: {
      payload.cbrStatus = staticContentPage.mappingStatus[val]
      break
    }
  }
  return payload
}

export const handlePayloadEsys = (valObj, searchBy) => {
  const { createDateFrom, createDateTo, val } = valObj
  const payload: any = {
    ecbrNo: '',
    policyNumber: '',
    createDateFrom,
    createDateTo,
    citizenId: '',
    insuredFullName: '',
    ecbrStatus: [],
  }

  switch (searchBy) {
    case staticContentPage.searchHistoryDropdown[0].id: {
      payload.policyNumber = val
      break
    }
    case staticContentPage.searchHistoryDropdown[1].id: {
      payload.ecbrNo = val
      break
    }
    case staticContentPage.searchHistoryDropdown[2].id: {
      payload.citizenId = val
      break
    }
    case staticContentPage.searchHistoryDropdown[3].id: {
      payload.insuredFullName = val
      break
    }
    case staticContentPage.searchHistoryDropdown[4].id: {
      if (val === 'all') {
        payload.ecbrStatus = ["CREATED", "USED","CANCELLED"]
        break
      }
      payload.ecbrStatus = [staticContentPage.mappingStatus[val]]
      break
    }
  }
  return payload
}

export const useEcbr = (valSearch, searchByVal, useSearchEcbrApi = useSearchEcbr) => {
  const ecbrs = useSearchEcbrApi({ valSearch, searchByVal, handlePayload })
  return ecbrs
}

export const useEcbrSys = (valSearch, searchByVal) => {
  const { ecbrs, error, fetchSearchData,  setError, loading } = useSearchEcbrEsys({ valSearch, searchByVal, handlePayloadEsys })
  return { ecbrs, error, fetchSearchData,  setError, loading }
}
