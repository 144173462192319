import { useState } from 'react'
import _isEmpty from 'lodash/isEmpty'

import { createEcbrPaid } from '../../hooks/service'

import staticContentPage from '../../static-content'

export const useSubmit = (props) => {
  const [modal, setModal] = useState(false)
  const [data, setData] = useState({} as any)
  const [loading, setLoading] = useState(false)
  const { handleSubmit, tabState, state, history } = props

  const toggle = () => setModal(!modal)
  const onSubmitData = async (data) => {
    setLoading(true)
    const payload = {
      ...state,
      [tabState === staticContentPage.tabsProgress[3].id ? 'attachment' : 'summary']: { payload: data?.object },
    }
    const res = await createEcbrPaid(payload)

    setData(res)
    setLoading(false)
    toggle()
  }
  const handleSubmitData = () => {
    return handleSubmit()
  }
  const closeModal = () => {
    toggle()
    history.push('/')
  }

  return { handleSubmitData, closeModal, onSubmitData, data, setData, setLoading, loading, modal, toggle }
}
