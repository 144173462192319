/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'

import { ICON_TYPES, ICON_COMPONENTS } from './icon-list'
import styles from './icons-styles'

/**
 * KTAXA's icons
 */
const propTypes = {
  /**
   * One or more class names to be added to the root element of this component, i.e. `"class-foo class-bar"`.
   */
  className: PropTypes.string,
  /**
   * Icon type
   */
  type: PropTypes.string,
  /**
   * Override the inline-styles of the root element.
   */
  style: PropTypes.object,
}

const defaultProps = {
  className: '',
  type: '',
  style: {},
}

const Icons = (props) => {
  const { className, style, type } = props
  const icon = ICON_COMPONENTS[type] ? ICON_COMPONENTS[type] : <span />
  const iconProps = {}
  if (className) iconProps.className = className

  return (
    <i {...iconProps} style={style} css={styles.icon} data-name={type}>
      {icon}
    </i>
  )
}

Icons.propTypes = propTypes
Icons.defaultProps = defaultProps
Icons.Type = { ...ICON_TYPES }

export default Icons
