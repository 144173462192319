// import ApplicationEntity from '../../../../domain/entities/application'
import ApplicationRepository from '../../../../domain/repositories/application'

import { IConnect } from '../connect'
import { calculatePaymentAmount } from 'utils/helper'

export default class ApplicationMiddlewareRepositoryImp implements ApplicationRepository {
  connect: IConnect

  constructor(connect: IConnect) {
    this.connect = connect
  }
  async GetApplicationByPolicyNo(
    policyNo: string,
    policyEffectiveDate: string,
    agentCode: string
  ): Promise<{
    data: any
    searchPolicyStatus: number
  }> {
    try {
      const jsonData = await this.connect.get(
        `/eip/getPolicyDetailWithPolicyNo/${policyNo}?policyEffectiveDate=${policyEffectiveDate}`
      )
      return {
        data: jsonData.data,
        searchPolicyStatus: jsonData.status
      }
      // need fix
      // if (jsonData.details) {
      //   return {
      //     data: [],
      //     searchPolicyStatus: 457,
      //   }
      // }
      // const { RLSPolicy } = jsonData.RetrievePolicyDetailsResponse
      // const userInfo = RLSPolicy.partyRoles.find((p) => p.RLSParty.partyTypeCode === 'INS')
      // // const productAgentCode = RLSPolicy.producingAgentCode1
      // const productAgentCode = RLSPolicy.actualServicingAgentCode1

      // const isOwner = productAgentCode.substr(productAgentCode.length - 6) === agentCode
      // // if user agent is not owner of policy follow this case !!
      // if (!isOwner) {
      //   return {
      //     data: new ApplicationEntity(
      //       RLSPolicy.policyNumber,
      //       '',
      //       '',
      //       '',
      //       '',
      //       '',
      //       userInfo.RLSParty.partyIdentifiers[0].idDocumentNumber,
      //       '',
      //       '',
      //       isOwner,
      //       '',
      //       ''
      //     ),
      //     searchPolicyStatus: 200,
      //   }
      // }
      // const paymentAmount = calculatePaymentAmount(RLSPolicy)

      // return {
      //   data: new ApplicationEntity(
      //     RLSPolicy.policyNumber,
      //     paymentAmount,
      //     RLSPolicy.paymentModeCode,
      //     RLSPolicy.RLSProduct.productCode,
      //     userInfo.RLSParty.firstName,
      //     userInfo.RLSParty.lastName,
      //     userInfo.RLSParty.partyIdentifiers[0].idDocumentNumber,
      //     '',
      //     '',
      //     isOwner,
      //     RLSPolicy.premiumDueDate,
      //     RLSPolicy.policyStatusCode
      //   ),
      //   searchPolicyStatus: 200,
      // }
    } catch (error) {
      return {
        data: [],
        searchPolicyStatus: 500,
      }
    }
  }

  async GetApplicationByCitizenId(
    citizenId: string,
    filterType: string = ''
  ): Promise<{
    data: []
    searchPolicyStatus: number
  }> {
    try {
      const res = await this.connect.get(`/application/atp/list?idDocumentNumber=${citizenId}`)
      console.log('/application/atp/list', res)
      if (res.status != 200) {
        return {
          data: [],
          searchPolicyStatus: res.status,
        }
      }
      return {
        searchPolicyStatus: res.status,
        // data: res.data,
        data: res.data.map((p: any) => {
          return {
            policyNumber: p.applicationNumber,
            policyEffectiveDate: p.applicationCaptureDate,
            policyExpirationDate: p.applicationCaptureDate,
            productCode: p.applicationProduct.productCode,
            policyStatusCode: p.applicationStatusCode,
          }
        }),
      }
      // if (res.statusCode == 200) {
      //   let policyList = res.data.RetrievePolicyListResponse.policy

      //   return {
      //     data: policyList.map((p: any) => {
      //       return {
      //         policyNumber: p.policyNumber,
      //         policyEffectiveDate: p.policyEffectiveDate,
      //         policyExpirationDate: p.policyExpirationDate,
      //         productCode: p.productCode,
      //         policyStatusCode: p.policyStatusCode,
      //       }
      //     }),
      //     searchPolicyStatus: res.statusCode,
      //   }
      // } else {
      //   return {
      //     data: [],
      //     searchPolicyStatus: res.statusCode,
      //   }
      // }
    } catch (error) {
      console.log('error', error)
      return {
        data: [],
        searchPolicyStatus: 500,
      }
    }
  }
}
