import { css } from '@emotion/react'

import { typography } from './theme'

export const reset = css`
  font-weight: 400;
  font-size: ${typography.fontSize};
  font-family: ${typography.fontFamily};
`

export const disabled = css`
  opacity: 0.4;
  pointer-events: none;
`
