import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import styled from '@emotion/styled'

import { Button } from 'ktaxa-ui-components/index'
import SearchStatusDialog from '../components/search-ccpa-status-dialog'
import PolicyListDialog from '../components/policy-list-dialog'
import LoadingModal from '../components/load-data-dialog'

import staticContentPage from '../static-content'
import { AppContext } from 'contexts/app'

const Loading = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    font-size: 1.4rem;
  }
`

const SubmitSection = (props) => {
  const [loading, setLoading] = useState(false)
  const [showSearchStatusDialog, setShowSearchStatusDialog] = useState(false)
  const [showPolicyListDialog, setShowPolicyListDialog] = useState(false)
  const [selectedAgentIndex, setSelectedAgentIndex] = useState(0)
  const { handleSubmit, tabState, setTabState, setState, state, history, selectAgentPolicy } = props

  const { isVerifyFormValidateError } = useContext(AppContext)

  useEffect(() => {
    const isCCPADetailNotFound = state?.verify?.searchStatus === 'CCPA_NOT_FOUND'
    if (isCCPADetailNotFound) {
      setLoading(false)
      setState({ verify: { ...state.verify, searchStatus: null } })
      setTabState(staticContentPage.tabsProgress[1].id) // redirect
    }
    if (state?.verify?.searchStatus && !isCCPADetailNotFound) {
      toggleSearchStatus()
      setLoading(false)
    }
    if (state?.verify?.agentPolicyList) {
      togglePolicyList()
      setLoading(false)
    }
  }, [])

  const handleSubmitData = async () => {
    handleSubmit()
    setLoading(true)
  }

  const togglePolicyList = () => {
    setShowPolicyListDialog(!showPolicyListDialog)
  }

  const toggleSearchStatus = () => {
    setShowSearchStatusDialog(!showSearchStatusDialog)
  }

  const onCancelSearchStatus = () => {
    toggleSearchStatus()
  }

  const onSubmitSearchStatus = () => {
    toggleSearchStatus()
    if (state?.verify?.searchStatus !== 'POLICY_INVALID') {
      setTabState(staticContentPage.tabsProgress[1].id)
    }
    setState({ verify: { ...state.verify, searchStatus: null } })
  }

  const onSelectPolicyItem = (item) => {
    const index = parseInt(item.value, 10)
    setSelectedAgentIndex(index)
  }

  const onConfirmSelected = () => {
    selectAgentPolicy(state?.verify?.agentPolicyList[selectedAgentIndex])
    togglePolicyList()
  }

  const onConfirmFillIn = () => {
    togglePolicyList()
    setTabState(staticContentPage.tabsProgress[1].id)
    setState({ verify: { ...state.verify, agentPolicyList: null, searchStatus: null } })
  }

  const onCancelFillIn = () => {
    togglePolicyList()
  }

  return (
    <>
      <Button disabled={isVerifyFormValidateError} onClick={handleSubmitData} type="primary">
        ยืนยัน
      </Button>
      <LoadingModal show={loading} />
      <SearchStatusDialog
        ecbrData={state?.verify?.searchCCPAInfo?.data}
        show={showSearchStatusDialog}
        onToggle={onCancelSearchStatus}
        onSubmit={onSubmitSearchStatus}
      />
      <PolicyListDialog
        // this will show policy list after get data from EIP
        show={showPolicyListDialog}
        onToggle={togglePolicyList}
        selectedAgentIndex={selectedAgentIndex}
        onSelectPolicyItem={onSelectPolicyItem}
        onConfirmSelected={onConfirmSelected}
        onConfirmFillIn={onConfirmFillIn}
        onCancelFillIn={onCancelFillIn}
        agentPolicyList={state?.verify?.agentPolicyList}
      />
    </>
  )
}

export default withRouter(SubmitSection)
