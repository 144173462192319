import { useEffect, useContext } from 'react'

import { RouterContext, NavConfigPropsType } from '../../../contexts/router'

type OptionsPropsType = {
  state?: any,
  isDisabledSubmit?: boolean
}

export const useRouterForHandleSubmit = (
  tabState,
  ActiveCondition,
  options: OptionsPropsType,
  navConfig: NavConfigPropsType
) => {
  const { setNavConfigByPage } = useContext(RouterContext)

  useEffect(() => {
    if (ActiveCondition) {
      setNavConfigByPage(navConfig)
    }
  }, [tabState, options?.state, options?.isDisabledSubmit])
}
