import { useEffect, useState } from 'react'
import { useLogin } from '../login'

import { getConfig } from 'core/config'

export type RespType = {
  data?: any
  error?: any
}

export const healthReports = async (token): Promise<RespType> => {
  const url = `${getConfig().api.baseURL}/v1/health-reports`
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const res = await fetch(url, options)
    const jsonData = await res.json()
    // need fix
    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }
    return { data: jsonData, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const useHealthReport = () => {
  const { token } = useLogin({})
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState<RespType>({} as RespType)
  useEffect(() => {
    async function fetchEcbr() {
        const res = await healthReports(token)
        const { data, error } = res
        setState({ data, error })
        setLoading(false)
    }
    if (token) {
      fetchEcbr()
    }
  }, [token])
  return { state, loading }
}
