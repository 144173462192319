import React, { useCallback } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { withKeycloak } from '@react-keycloak/web'

import { BlankPage } from 'components'
import { Button } from 'ktaxa-ui-components'

const LoginPage = withKeycloak(({ keycloak, location }) => {
  const { from } = location.state || { from: { pathname: '/' } }
  if (keycloak && keycloak.authenticated) return <Redirect to={from} />

  const login = useCallback(() => {
    keycloak.login()
  }, [keycloak])

  return (
    <BlankPage title={'กรุณา login เพื่อใช้งานระบบ eCBR'}>
      <Button type={Button.Type.Primary} onClick={login}>
        Login
      </Button>
    </BlankPage>
  )
})

export default withRouter(LoginPage)
