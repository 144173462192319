/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from 'ktaxa-ui-components/components/icons'
import { defaultStyle, largeStyle } from './icon-button-styles'

type propTypes = {
  style?: any
  icon: string
  text: string
  size: string
  onClick?: (v: any) => void
  OverideIcon?: any
}

const defaultProps = {
  icon: 'document',
  text: 'แนบเอกสาร',
  size: 'default',
  onClick: () => {},
}

function IconButton(props: propTypes) {
  const { icon, text, onClick, size, OverideIcon } = props
  if (OverideIcon) {
    return (
      <div css={defaultStyle.container} onClick={onClick}>
        <div css={defaultStyle.side({ ...OverideIcon.side })}>
          <OverideIcon.Icon />
        </div>
        <div css={defaultStyle.description}>{text}</div>
      </div>
    )
  }
  if (size === 'large') {
    return (
      <div css={largeStyle.container} onClick={onClick}>
        <div>
          <Icon css={largeStyle.icon} type={icon} />
        </div>
        <div css={largeStyle.description}>{text}</div>
      </div>
    )
  }
  return (
    <div css={defaultStyle.container} onClick={onClick}>
      <div css={defaultStyle.side}>
        <Icon css={defaultStyle.icon} type={icon} />
      </div>
      <div css={defaultStyle.description}>{text}</div>
    </div>
  )
}

IconButton.displayName = 'Document IconButton'
IconButton.defaultProps = defaultProps

export { IconButton }
