export default {
  tabsProgress: [
    {
      id: 'payments',
      iconId: 'ECBR_PAYMENT',
      title: 'รายการชำระเบี้ย',
    },
    {
      id: 'payment-method',
      iconId: 'ECBR_PAYMENT_SELECTION',
      title: 'ระบุวิธีการชำระ',
    },
    {
      id: 'payment-confirmation',
      iconId: 'ECBR_ISSUE_NEW',
      title: 'ยืนยันข้อมูลการชำระ',
    },
    {
      id: 'payment-attachment',
      iconId: 'ECBR_ATTACHMENT',
      title: 'แนบสำเนาใบนำฝาก',
    },

    {
      id: 'summary',
      iconId: 'ECBR_PAYMENT_SUMMARY',
      title: 'ชำระเบี้ยประกัน',
    },
  ],
  tabsProgressPayment: [
    {
      id: 'info',
      iconId: 'ECBR_PAYMENT_INFO',
      title: 'ระบุข้อมูลการชำระเงิน',
      // disabled: false,
    },
    {
      id: 'process',
      iconId: 'ECBR_PAYMENT_PROCESS',
      title: 'จัดทำใบชำระเงินชั่วคราว',
      // disabled: true,
    },
    {
      id: 'summary',
      iconId: 'ECBR_PAYMENT_SUMMARY',
      title: 'ชำระเบี้ยประกัน',
      // disabled: false,
    },
  ],

  pendingList: {
    title: 'รายการชำระเบี้ย ผลการค้นหาล่าสุด',
    paymentScheduleMethods: [
      {
        text: 'รายเดือน',
        value: 'รายเดือน',
      },
      {
        text: 'รายหกเดือน',
        value: 'รายหกเดือน',
      },
      {
        text: 'รายปี',
        value: 'รายปี',
      },
    ],
  },
  transferInfo: {
    title: 'ใบรับเงินชั่วคราวค้างชำระ',
    paymentScheduleMethods: [
      {
        text: 'รายเดือน',
        value: 'รายเดือน',
      },
      {
        text: 'รายหกเดือน',
        value: 'รายหกเดือน',
      },
      {
        text: 'รายปี',
        value: 'รายปี',
      },
    ],
  },
  paymentMethod: {
    id: 'paymentMethod',
    options: [
      {
        text: 'ตู้เอทีเอ็ม',
        value: 'atm',
        options: [
          {
            text: 'ธนาคารกรุงไทย',
            value: 'ktb',
          },
        ],
      },
      {
        text: 'ธนาคาร',
        value: 'bank',
        options: [
          {
            text: 'ธนาคารกรุงไทย',
            value: 'ktb',
          },
          {
            text: 'ธนาคารกรุงศรีอยุธยา',
            value: 'bay',
          },
          {
            text: 'ธนาคารกรุงเทพ',
            value: 'bbl',
          },
          {
            text: 'ธนาคารทหารไทยธนชาต',
            value: 'tmb',
          },
          {
            text: 'ธนาคารเพื่อการเกษตรและสหกรณ์',
            value: 'ghb',
          },
          {
            text: 'ธนาคารกสิกรไทย',
            value: 'kbank',
          },
          {
            text: 'ธนาคารไทยพาณิชย์',
            value: 'scb',
          },
        ],
      },
      {
        text: 'เคาน์เตอร์สำนักงาน',
        value: 'office',
        options: [
          {
            text: 'เคาเตอร์สำนักงานใหญ่',
            value: 'headoffice',
          },
          {
            text: 'สาขา ขอนแก่น',
            value: 'Khon kaen branch',
          },
          {
            text: 'สาขา หาดใหญ่',
            value: 'Hat yai branch',
          },
          {
            text: 'สาขา สุราษฎร์ธานี',
            value: 'Surat thani branch',
          },
          {
            text: 'สาขา พิษณุโลก',
            value: 'Phitsanulok branch',
          },
          {
            text: 'สาขา เชียงใหม่',
            value: 'Chiang mai branch',
          },
          {
            text: 'สาขา ชลบุรี',
            value: 'Chonburi branch',
          },
        ],
      },
    ],
  },
  pendingSubmenu: [
    {
      id: 'DRAFT',
      title: 'ใบรับเงินชั่วคราวที่สร้างไว้',
    },
    {
      id: 'CREATED',
      title: 'ใบรับเงินชั่วคราวที่รอแนบสลิป',
    },
    {
      id: 'LEGACY',
      title: 'ใบรับเงินชั่วคราวคงค้าง',
    },
  ],
}
