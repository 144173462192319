import React, { useEffect } from 'react'

import { Button as MainButton } from 'ktaxa-ui-components/index'

import { useRouterForHandleSummit } from '../../hooks/useRouter'

import SubmitSection from '../submit'

import staticContentPage from '../../static-content'

export const useHandleDataForm = (props) => {
  const { setState, state, tabState, setTabState } = props
  const { transferInfo } = state

  const onSubmit = () => {
    setState({
      ...state,
      confirmPayment: {
        payload: {},
      },
    })
    setTabState(staticContentPage.tabsProgress[3].id)
  }

  // Reset state when edit tab2.
  useEffect(() => {
    setState({
      ...state,
      confirmPayment: {},
    })
  }, [transferInfo?.payload])

  const ActiveCondition = tabState === staticContentPage.tabsProgress[2].id
  useRouterForHandleSummit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <MainButton onClick={() => setTabState(staticContentPage.tabsProgress[1].id)} type="secondary">
          กลับ
        </MainButton>
      ),
      extraButton: () => <SubmitSection tabState={tabState} handleSubmit={onSubmit} state={state} />,
    }
  )
}
