import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'

import { ContentContainer } from 'components/layouts/content'
import PortalButton from 'components/portal-button'
import { color } from 'components/theme'

import { Spinner } from 'reactstrap'
import { getToggles } from '../../core/config'
import useGoogleAnalytics from '../../hooks/useServices/googleAnalytics'
import { useRouterNav } from './hooks/router'
import { useVerifyBlockIssue } from './hooks/service'
import staticContent from './static-content'

const Header = styled.div`
  text-align: center;
  margin-bottom: 45px;
  color: ${color.type.primary};

  h1 {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 1.4rem;
  }

  h2 {
    font-weight: 400;
    font-size: 1.2rem;
  }
`

const MenuContainer = styled.div`
  max-width: 400px;
  margin: 0 auto 30px;
`

const HomePage = (props) => {
  const { sendCustomEvent } = useGoogleAnalytics()
  const { history } = props

  const { title, menus } = staticContent
  const [{ disabled, msgText }, setBlockingValue] = useState({ disabled: false, msgText: '' })

  if (!getToggles().FEATURE_CANCELATION) {
    delete menus[3] // remove cancelation button
  }

  if (!getToggles().ENABLE_CCPA_ON_ECBR) {
    delete menus[4] // remove ccpa menu button
  }

  for (let i = menus.length - 1; i >= 0; i--) {
    if (!getToggles().ENABLE_DA_ECBR && menus[i].iconId === 'ECBR_DA') {
      menus.splice(i, 1)
    }
  }

  useRouterNav()
  const [blockIssueConfig, loading] = useVerifyBlockIssue()
  const { pendingBlock: isBlock, suspendBlock: isBlockAll, warningMessage } = blockIssueConfig

  useEffect(() => {
    if (isBlock) {
      setBlockingValue({
        disabled: isBlock,
        msgText: warningMessage,
      })
    }
  }, [loading])

  if (loading) {
    //! Basically, this is a loading page and return <Spinner color="primary" />
    return null
  }

  if (isBlockAll) {
    menus.map((m) => (m.disabled = true))
  }

  return (
    <ContentContainer>
      <Header>
        <h1>{title}</h1>
      </Header>
      <MenuContainer>
        {menus.map((mt, i) =>
          i === 0 ? (
            <PortalButton
              key={i}
              iconId={mt.iconId}
              title={mt.title}
              onClick={() => {
                if (!disabled) {
                  history.push(mt.path)
                }
              }}
              disabled={disabled}
              warningText={isBlockAll ? null : msgText}
            />
          ) : (
            <PortalButton
              key={i}
              iconId={mt.iconId}
              title={mt.title}
              onClick={() => {
                if (!mt.disabled || ['ECBR_PAYMENT_CCPA', 'ECBR_DA'].includes(mt.iconId)) {
                  history.push(mt.path)
                }
              }}
              disabled={!['ECBR_PAYMENT_CCPA', 'ECBR_DA'].includes(mt.iconId) ? mt.disabled : false}
              warningText={mt.warningText}
            />
          )
        )}
      </MenuContainer>
      {isBlockAll ? <span>{warningMessage}</span> : null}
    </ContentContainer>
  )
}

export { HomePage }
export default withRouter(HomePage)
