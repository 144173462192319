import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import _isEmpty from 'lodash/isEmpty'
import _isNull from 'lodash/isNull'
import _get from 'lodash/get'

import { Button, Dialog } from 'ktaxa-ui-components/index'
import { useLogin } from '../../../hooks/useServices/login'
import { useHealthReport } from '../../../hooks/useServices/health-reports'
import { getConfig } from 'core/config'
import LoadingModal from '../../../pages/ccpa/components/load-data-dialog'

declare var window: any

const Content = styled.div`
  text-align: center;
`
const WarningContent = styled(Content)`
  color: red;
`
const Footer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

const PATH_REQUIRED_SALESFORCE_SERVICE = ['/new-ecbr', '/pending', '/history']

const HealthReportModal = (props) => {
  const { history } = props
  const location = _get(history, 'location')
  const { userInfo } = useLogin({})
  const [message, setMessage] = useState('')
  const [modal, setModal] = useState(true)

  const { api } = getConfig()
  const isNotPermission = (userInfo) => userInfo.agent_type === 'LB'
  const toggle = () => setModal(!modal)

  const { state: healthReportState, loading } = useHealthReport()
  const { data, error } = healthReportState

  const getDBMessageError = () => {
    if (data?.connection) {
      const { MONGO_CONNECTION, EIP_CONNECTION } = data?.connection
      const canNotConnectDB = !EIP_CONNECTION || !MONGO_CONNECTION
      if (canNotConnectDB) {
        return 'ระบบฐานข้อมูลไม่พร้อมให้บริการ'
      }
    }
    return ''
  }

  const getSaleForceMessageError = () => {
    const currentPath = _get(location, 'pathname', '')
    const isRequiredSF = PATH_REQUIRED_SALESFORCE_SERVICE.some((sfPath) => currentPath.startsWith(sfPath))
    if (isRequiredSF) {
      if (!data?.connection?.SALESFORCE_SERVICE) {
        return 'ระบบ Salesforce ไม่พร้อมให้บริการ'
      }
    }
    return ''
  }

  useEffect(() => {
    if (!loading) {
      const dbMessageError = getDBMessageError()
      const saleForceMessageError = getSaleForceMessageError()

      if (_isNull(error) && _isEmpty(dbMessageError) && _isEmpty(saleForceMessageError)) {
        setModal(false)
      } else {
        let messageError = ''

        if (!_isNull(error)) {
          messageError = 'ระบบไม่พร้อมให้บริการ'
        } else if (!_isEmpty(dbMessageError) && !_isEmpty(saleForceMessageError)) {
          messageError = dbMessageError + 'และ' + saleForceMessageError
        } else {
          messageError = dbMessageError || saleForceMessageError
        }

        if (!_isEmpty(messageError)) {
          setMessage(messageError)
          setModal(true)
        }
      }
    }
  }, [healthReportState, loading, location])

  const handleRetryHealthReport = () => {
    history.go('/')
  }

  const onBackToAZ = () => {
    if (window.cordova) {
      window.close()
    } else {
      window.location.assign(api.advisorZone)
    }
  }

  return isNotPermission(userInfo) ? (
    <Dialog centered show={isNotPermission(userInfo)}>
      <WarningContent>ท่านไม่มีสิทธิ์ในการใช้งานระบบ eCBR</WarningContent>
      <Footer>
        <Button type={Button.Type.Primary} onClick={onBackToAZ}>
          ตกลง
        </Button>
      </Footer>
    </Dialog>
  ) : (
    <Dialog centered modal show={modal} onClose={toggle}>
      {message ? (
        <>
          <WarningContent>{message}</WarningContent>
          <Content>ขออภัยในความไม่สะดวก</Content>
          <Footer>
            <Button onClick={handleRetryHealthReport} type={Button.Type.Primary}>
              ลองใหม่อีกครั้ง
            </Button>
          </Footer>
        </>
      ) : (
        <LoadingModal show={loading} />
      )}
    </Dialog>
  )
}

export default withRouter(HealthReportModal)
