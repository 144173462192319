/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import get from 'lodash/get'

import styles from './radio-styles'

export const UI_STYLE = {
  Radio: 'radio',
  Checked: 'checked',
  ButtonGroup: 'button-group',
  Grid: 'grid',
}

const propTypes = {
  /**
   * Elements to be rendered as children of this component.
   */
  children: PropTypes.node,
  /**
   * One or more class names to be added to the root element of this component, i.e. `"class-foo class-bar"`.
   */
  className: PropTypes.string,
  /**
   * Disables the input if set to true.
   */
  disabled: PropTypes.bool,
  /**
   * Value of checked option
   */
  value: PropTypes.string,
  /**
   * Render as
   *
   * * `radio`
   * * `button-group`
   */
  uiStyle: PropTypes.string,
  /**
   * A callback function, executed when the radio option is changed.
   *
   * ```() => {}```
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  children: null,
  className: '',
  disabled: false,
  value: '',
  uiStyle: UI_STYLE.Radio,
  onChange() {},
}

/**
 * `<Radio>` wrapper
 */
const RadioGroup = (props) => {
  const { className, disabled, children, value, uiStyle, onChange } = props

  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const doOnChange = (option) => {
    if (!disabled) {
      onChange(option)
    }
  }

  const radioGroupStyles = [styles.radioGroup.Radio]
  const radioGroupProps = {}

  if (className) radioGroupProps.className = className
  if (disabled) radioGroupStyles.push(styles.disabled)
  const validUIStyle = Object.values(UI_STYLE).includes(uiStyle) ? uiStyle : UI_STYLE.Radio
  radioGroupStyles.push(styles.radioGroup[validUIStyle])

  return (
    <div {...radioGroupProps} css={className === UI_STYLE.Grid ? [styles.radioGroup.grid] : radioGroupStyles}>
      {React.Children.map(children, (child) => {
        if (get(child, 'type.displayName') === 'Radio') {
          return React.cloneElement(child, {
            ...child.props,
            uiStyle: validUIStyle,
            disabled: disabled ? true : child.props.disabled,
            checked: currentValue === child.props.value,
            onChange: doOnChange,
          })
        }
        return child
      })}
    </div>
  )
}

RadioGroup.displayName = 'RadioGroup'
RadioGroup.propTypes = propTypes
RadioGroup.defaultProps = defaultProps
RadioGroup.UIStyle = { ...UI_STYLE }

export default RadioGroup
