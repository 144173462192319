import React, { useState } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color, customIcon } from 'components/theme'
import { format, date } from 'utils/date'
import LoadingModal from './load-data-dialog'
// import staticContentPage from '../static-content'

const DialogContainer = styled.div`
  text-align: center;
  #modal-dialog-content {
    padding-right: 0;
    padding-left: 0;
  }
`

const Divider = styled.div`
  border: solid 0.5px ${color.element.border};
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`

const Icon = styled.div`
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background-color: ${color.common.lightGray};
  mask-image: url(${customIcon('closeIcon')});
  mask-size: cover;
  mask-repeat: no-repeat;
`
const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 16px;
`

const SubTitle = styled(Title)`
  margin-top: 24px;
  margin-bottom: 0;
  font-size: 1.1rem;
`
const Message = styled.span`
  display: block;
  font-size: 1rem;
  color: #333333;
`

const CreateCCPALinkDialog = (props: any) => {
  const {
    show,
    onToggle,
    isSuccess,
    createCCPALinkData,
    setState,
    tabState,
    setTabState,
    state,
    history,
    setIsShowCcpaLinkDialog,
    onCreateLink,
  } = props
  const linkExpirationDate = date(createCCPALinkData?.expiredDate).toISOString()
  const displayedDate = format(linkExpirationDate, 'DD/MM/YYYY')
  const displayedTime = format(linkExpirationDate, 'HH.mm')

  const [isLoading, setIsLoading] = useState(false)

  const resetForm = async () => {
    // await setState({})
    // await setTabState(staticContentPage.tabsProgress[0].id)
    await onToggle()
    history.push('/ccpa')
  }

  const confirmCreateLinkSuccess = async () => {
    await setState({
      ...state,
      createCCPALinkData: createCCPALinkData,
      isCheckCCPAStatus: true,
    })
    await onToggle()
  }

  const handleConfirm = async () => {
    if (isSuccess) {
      if (tabState === 'status') {
        setIsShowCcpaLinkDialog(false)
      } else {
        setIsLoading(true)
        setTimeout(() => {
          setIsShowCcpaLinkDialog(false)
          setTabState('status')
        }, 2000)
      }
    } else {
      setIsShowCcpaLinkDialog(false)
      await onCreateLink()
    }
  }

  return (
    <DialogContainer>
      <Dialog centered modal show={show}>
        <Title>สมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต</Title>
        {!isSuccess && <Icon onClick={() => setIsShowCcpaLinkDialog(false)} />}
        <Divider />
        {isSuccess ? (
          <Content>
            <Message>บริษัทฯ ได้สร้างลิงก์เพื่อสมัครหักค่าเบี้ยประกันอัตโนมัติ</Message>
            <Message>ผ่านบัตรเครดิตให้กับผู้เอาประกันเรียบร้อยแล้ว</Message>
            <Message>กรุณากด ยืนยัน เพื่อไปยังขั้นตอนถัดไป</Message>
            <SubTitle>
              ลิงก์ในการสมัครของท่านจะหมดอายุภายในวันที่ {displayedDate} เวลา {displayedTime} น.
            </SubTitle>
          </Content>
        ) : (
          <Content>
            <Message>การสร้างลิงก์ไม่สำเร็จ กรุณากด ยืนยัน เพื่อดำเนินการสร้างลิงก์อีกครั้ง</Message>
          </Content>
        )}
        <Footer>
          <Button type={Button.Type.Primary} onClick={handleConfirm}>
            ยืนยัน
          </Button>
        </Footer>
      </Dialog>
      <LoadingModal show={isLoading} />
    </DialogContainer>
  )
}

export default withRouter(CreateCCPALinkDialog)
