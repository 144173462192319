import React, { useState } from 'react'
import { CustomTabs } from 'components/tabs'
import PendingSection from './sections/pending-list'
import TransferInfoSection from './sections/transfer-info'
import ConfirmationSection from './sections/confirmation'
import AttachmentSection from './sections/attachment'
import PaymentInsurancePremiumSection from './sections/payment'
import { useTabItemsPendingPaymentProcess } from './hooks/useTabs'
import { useEcbrByCbrNumber } from './hooks/service'
import staticContentPage from './static-content'
import PaymentLinkCreatingSection from '../new-ecbr/sections/create-link'
import CheckPaymentStatusSection from '../new-ecbr/sections/check-status'
import InformationSection from '../new-ecbr/sections/information'
import SummarySection from '../new-ecbr/sections/summary'

const InsurancePremiumPage = (props) => {
  const [state, setState] = useState({})
  const [tabState, setTabState] = useState(staticContentPage.tabsProgress[0].id)

  /**
   * IMPORTANT: useTabItemsPendingPaymentProcess is used for setting the state
    when user click the item from the Pending tab. If struggle finding the state
    of the application, please take a look in this hook
   */
  const [tabItems] = useTabItemsPendingPaymentProcess({ tabState, state, setState, ...props })
  useEcbrByCbrNumber({ state, setState, setTabState, ...props })
  return (
    <>
      <CustomTabs active={tabState} onChange={(id) => setTabState(id)} items={tabItems} />
      <PendingSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <TransferInfoSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <ConfirmationSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <AttachmentSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <PaymentInsurancePremiumSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <PaymentLinkCreatingSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <CheckPaymentStatusSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <InformationSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <SummarySection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
    </>
  )
}

export default InsurancePremiumPage
