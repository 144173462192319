import React from 'react'

import FormUpload from './form-upload'
import AttachList from './attach-list'

const CancelUploadSection = (props) => {
  return (
    <>
      <FormUpload {...props} />
      <AttachList {...props} />
    </>
  )
}

export default CancelUploadSection
