export const bankMethod = [
  {
    text: 'ธนาคารกรุงไทย',
    value: 'ktb',
    compCode: '6056',
  },
  {
    text: 'ธนาคารกรุงศรีอยุธยา',
    value: 'bay',
    compCode: '001-0-15133-6',
  },
  {
    text: 'ธนาคารกรุงเทพ',
    value: 'bbl',
    compCode: '205',
  },
  {
    text: 'ธนาคารทหารไทยธนชาต',
    value: 'tmb',
    compCode: '668',
  },
  {
    text: 'ธนาคารเพื่อการเกษตรและสหกรณ์',
    value: 'ghb',
    compCode: 'AXA1',
  },
  {
    text: 'ธนาคารกสิกรไทย',
    value: 'kbank',
    compCode: '709-1-03217-6',
  },
  {
    text: 'ธนาคารไทยพาณิชย์',
    value: 'scb',
    compCode: '622',
  },
]

export const officeMethod = [
  {
    text: 'เคาเตอร์สำนักงานใหญ่',
    value: 'headoffice',
  },
  {
    text: 'สาขา ขอนแก่น',
    value: 'Khon kaen branch',
  },
  {
    text: 'สาขา หาดใหญ่',
    value: 'Hat yai branch',
  },
  {
    text: 'สาขา สุราษฎร์ธานี',
    value: 'Surat thani branch',
  },
  {
    text: 'สาขา พิษณุโลก',
    value: 'Phitsanulok branch',
  },
  {
    text: 'สาขา เชียงใหม่',
    value: 'Chiang mai branch',
  },
  {
    text: 'สาขา ชลบุรี',
    value: 'Chonburi branch',
  },
]

export const atmMethod = [
  {
    text: 'ธนาคารกรุงไทย',
    value: 'ktb',
  },
]

export const ChanalMethod = {
  atmMethod,
  officeMethod,
  bankMethod,
}

export const transferMethod = [
  {
    text: 'ตู้เอทีเอ็ม',
    value: 'atm',
  },
  {
    text: 'ธนาคาร',
    value: 'bank',
  },
  {
    text: 'เคาน์เตอร์สำนักงาน',
    value: 'office',
  },
]
