import { useEffect, useState } from 'react'

import { PendingECBRListEntity, ECBRItemEntity } from 'domain/entities/ecbr'
import { IEcbrUseCase } from 'domain/useCases/ecbr'

import { ecbrService } from 'services/ecbr-middleware'
import _ from 'lodash'

export const useECBRPending = (props: { query?: string, ecbrServiceApi?: IEcbrUseCase }) => {
  const { query, ecbrServiceApi = ecbrService } = props
  const [pending, setPending] = useState({
    total: 0,
    data: [],
    isLoaded: false,
  } as PendingECBRListEntity)

  useEffect(() => {
    async function fetchEcbr() {
      const res = await ecbrServiceApi.GetAdvisorPendingECBR(query)
      setPending({
        ...res,
        isLoaded: true,
      })
    }

    fetchEcbr()
  }, [])

  return [pending]
}

export const useSearchEcbr = (props: { valSearch: string, searchByVal: string, handlePayload, isCancelSearch?: boolean, ecbrServiceApi?: IEcbrUseCase }) => {
  const { isCancelSearch, valSearch, searchByVal, handlePayload, ecbrServiceApi = ecbrService } = props
  const [ecbrs, setEcbrs] = useState<ECBRItemEntity[]>([])
  useEffect(() => {
    // Original search
    async function fetchSearchData() {
      if (valSearch.length >= 3) {
        const payload = handlePayload(valSearch, searchByVal)
        const res = await ecbrServiceApi.SearchECBR(JSON.stringify(payload))
        if (res) {
          setEcbrs(res)
        } else {
          setEcbrs([])
        }
      } else {
        setEcbrs([])
      }
    }

    // Search with pending status ecbr
    async function fetchSearchPending() {
      if (valSearch.length >= 3) {
        const payload = handlePayload(valSearch, searchByVal)
        const res = await ecbrService.GetAdvisorPendingECBR(payload)
        if (res) {
          setEcbrs(res.data)
        } else {
          setEcbrs([])
        }
      } else {
        setEcbrs([])
      }
    }

    // TODO: Using original search at Historical search
    // Cancelation use search by GetAdvisorPendingECBR (except search by status using original search)
    if (isCancelSearch && searchByVal !== 'searchByStatus') {
      fetchSearchPending()
    } else {
      fetchSearchData()
    }
    return
  }, [valSearch, searchByVal])

  return ecbrs
}

export const useSearchEcbrEsys = (props: { valSearch: [], searchByVal: string, handlePayloadEsys, ecbrServiceApi?: IEcbrUseCase }) => {
  const { valSearch, searchByVal, handlePayloadEsys, ecbrServiceApi = ecbrService } = props
  const [ecbrs, setEcbrs] = useState<ECBRItemEntity[]>([])
  const [error, setError] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  async function fetchSearchData(valSearch, searchByVal) {
    const payload = handlePayloadEsys(valSearch, searchByVal)
    setLoading(true)
    const { jsonData, error } = await ecbrServiceApi.SearchECBRSys(JSON.stringify(payload))
    if (jsonData) {
      setEcbrs(jsonData)
      if (error) {
        setError(error)
      }
      setLoading(false)
    } else {
      setEcbrs([])
      setLoading(false)
    }
  }
  return { ecbrs, error, fetchSearchData,  setError, loading}
}

export const SendEmailECBRSys = async (payload: any) => {
  return await ecbrService.SendEmailECBRSys(payload)
}

export const SendSmsECBR = async (payload: any) => {
  return await ecbrService.SendSmsECBR(payload)
}
