export const color = {
  common: {
    // Colors
    // Palette https://design.axa.com/webGuidelines/color
    text1: '#333333',
    axaBlue: '#00008f',
    axaRed: '#ff1721',
    burntSienna: '#f07662',
    darkGray: '#333333',
    mediumGray: '#5f5f5f',
    gray: '#7f7f7f',
    lightGray: '#999999',
    grayLighter: '#eceeef',
    silver: '#cccccc',
    mercury: '#e5e5e5',
    wildSand: '#f5f5f5',
    alabaster: '#fafafa',
    white: '#ffffff',
    black: '#000000',
  },
  type: {
    primary: '#2425aa',
    primaryLight: '#3f3fab',
    primaryDark: '#00008f',
    success: '#1bc54f',
    warning: '#fbc02d',
    error: '#e53935',
  },
  element: {
    border: '#e5e5e5',
    overlayBG: '#222222',
  },
}

export const device = {
  screenSize: {
    xs: '480px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
}

export const typography = {
  fontFamily: '"helvethaica", "Open Sans", sans-serif',
  fontSize: '1rem',
}

export const transition = {
  normal: '.5s',
  faster: '.25s',
  fastest: '.12s',
  slower: '.7s',
  slowest: '.85s',
}

export const padding = {
  xs: '15px',
  s: '20px',
  m: '25px',
  l: '50px',
}

export const customIcon = (name) => {
  const icon = {
    checked: encodeURIComponent(
      "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='currentColor' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>"
    ),
  }

  if (icon[name]) {
    return `"data:image/svg+xml;charset=utf8,${icon[name]}"`
  }
  return ''
}

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

export const parseAndCalculate = (originValue, originalUnit, fn) => {
  const [value, unit] = `${originValue}`.replace(new RegExp(originalUnit, 'gi'), `__${originalUnit}__`).split('__')
  return fn ? fn(value, unit) : [value, unit]
}

const theme = {
  color,
  device,
  typography,
  transition,
  padding,
  customIcon,
  hexToRGB,
  parseAndCalculate,
}

export default theme
