import React, { useState } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'

import { UploadImage } from 'components/input'
import { Button } from 'ktaxa-ui-components/index'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { renderInfoMessageBlock, renderErrorMessageBlock } from 'components/message-blocks'
import { format, date } from 'utils/date'
import { getConfig } from 'core/config'
import SubmitSection from './submit'
import _isEmpty from 'lodash/isEmpty'

import staticContent from '../static-content'
import CheckPaymentStatusDialog from '../components/check-payment-status-dialog'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import { checkPaymentStatus } from '../hooks/useService'
import NewECBRCreatedDialog from 'components/modal/ecbr-systemization/ecbr-created-dialog'

const SubTitle = styled.label`
  display: block;
  margin: 4px 0 12px 0;
  font-weight: bold;
`

const ButtonSectionContainer = styled(SectionContainer)`
  display: grid;
  width: fit-content;
  gap: 16px;
  margin: auto;
  margin-bottom: 10px;
`

export const CheckPaymentStatusSection = (props: any) => {
  const { tabState, state, setState, history } = props

  const { limitFileSize } = getConfig()
  const [isOverLimit, setIsOverLimit] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isShowGenLinkButton, setIsShowGenLinkButton] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [checkPaymentStatusData, setCheckPaymentStatusData] = useState<any>(null)
  const [isCheckStatus, setCheckStatus] = useState(false) // This checks if the checkPaymentStatus service has already been called
  const [loading, setLoading] = useState(false)

  const handleCheckPaymentStatus = async (ecbrNumber, receiptId, payerInfo) => {
    setLoading(true)
    const response = await checkPaymentStatus(ecbrNumber, receiptId, payerInfo)

    const data = response?.data || null
    setCheckPaymentStatusData(data)
    setCheckStatus(true)
    setLoading(false)
    setShowDialog(true)
  }

  const handleClickGenLinkButton = async (ecbrNumber, receiptId, payerInfo) => {
    const response = await checkPaymentStatus(ecbrNumber, receiptId, payerInfo)

    const isSuccess = response?.data?.linkStatus === 'SUCCESS'
    if (isSuccess) {
      setCheckPaymentStatusData(response?.data)
      setShowDialog(true)
    } else {
      // go to gen link screen
      setIsShowGenLinkButton(false)
      setState({
        ...state,
        isCheckPaymentStatus: false,
      })
    }
  }

  const onUpload = (img) => {
    setState({
      ...state,
      [staticContent.tabsProgress[2].id]: {
        payload: {
          imgBase64: img,
        },
      },
    })
  }

  const handleSubmit = (callBack) => {
    if (_isEmpty(state?.summary?.payload)) {
      // check data is submitted
      setIsSubmit(true)
      return
    }
    callBack()
  }

  const isCheckPaymentStatus = state?.isCheckPaymentStatus || false
  const ActiveCondition = tabState === staticContent.tabsProgress[2].id && isCheckPaymentStatus

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraButton: () => (
        <SubmitSection
          isCreateEcbrNoButton={true}
          isDisabled={_isEmpty(state?.summary?.payload)}
          tabState={tabState}
          handleSubmit={handleSubmit}
          state={state}
        />
      ),
    }
  )
  if (!ActiveCondition) return null
  const createPaymentLinkData = state?.createPaymentLinkData
  const linkExpirationDate = date(createPaymentLinkData?.expiredDate).toISOString()
  const displayedDate = format(linkExpirationDate, 'DD/MM/YYYY')
  const displayedTime = format(linkExpirationDate, 'HH.mm')
  const payerInfo = state?.process?.payload?.data?.payerInfo || {}
  const handleErrQuickpayStatus = _isEmpty(payerInfo) ? 'ERROR' : 'QP_ERROR' // if systemization will use new error message
  const paymentStatus = checkPaymentStatusData?.linkStatus || handleErrQuickpayStatus
  const createEcbrNoStatus = checkPaymentStatusData?.createEcbrNoStatus
  const downloadEcbrStatus = checkPaymentStatusData?.downloadEcbrStatus
  const sendNotificationsStatus = checkPaymentStatusData?.sendNotificationsStatus
  const isQuickPayServiceError = paymentStatus === 'QP_ERROR' && isCheckStatus // for systemization flow
  const receiptId = state?.process?.payload?.data?.receiptId || ''
  const cbrNumber =
    !_isEmpty(createEcbrNoStatus) && createEcbrNoStatus === 'SUCCESS' // if systemization success must use new cbrNumber
      ? checkPaymentStatusData?.cbrNumber
      : state?.process?.payload?.data?.cbrNumber || ''

  // middleware need to remove prefix number
  const policyNumber = state?.process?.payload?.data?.policyNumber || ''

  const isSuccess = _isEmpty(payerInfo) // for systemization must check createEcbrNoStatus
    ? checkPaymentStatusData?.linkStatus === 'SUCCESS'
    : checkPaymentStatusData?.linkStatus === 'SUCCESS' && checkPaymentStatusData.createEcbrNoStatus === 'SUCCESS'

  return (
    <ContentContainer>
      {isSuccess ? (
        <NewECBRCreatedDialog
          ecbrData={{
            cbrNumber,
            policyNumber,
            downloadEcbrStatus,
            sendNotificationsStatus,
          }}
          show={showDialog}
          onToggle={() => {
            history.push('/')
          }}
        />
      ) : (
        <CheckPaymentStatusDialog
          show={showDialog}
          createEcbrNoStatus={createEcbrNoStatus}
          paymentStatus={paymentStatus}
          onToggle={() => {
            if (paymentStatus !== 'ERROR') {
              setIsShowGenLinkButton(true)
            }
            setShowDialog(!showDialog)
          }}
          date={displayedDate}
          time={displayedTime}
        />
      )}
      <h1>{staticContent?.checkStatusSection.mainTitle.title}</h1>
      <SectionContainer>
        <div
          style={{
            display: 'block',
            background: '#ffffff',
            padding: 20,
          }}
        >
          <SectionContainer>
            <SubTitle>{staticContent?.checkStatusSection.detail.paymentType}</SubTitle>
            <label>{staticContent?.checkStatusSection.detail.ccLink}</label>
          </SectionContainer>

          {isQuickPayServiceError ? (
            <div style={{ width: '100%' }}>
              <UploadImage
                onSelected={onUpload}
                value={state?.summary?.payload?.imgBase64}
                label={'แนบสำเนาใบนำฝาก'}
                setIsOverLimit={setIsOverLimit}
              />
              {isSubmit &&
                !state?.summary?.payload?.imgBase64 &&
                renderErrorMessageBlock({ message: 'โปรดแนบสำเนาใบนำฝาก' })}
              {isOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
            </div>
          ) : (
            <>
              <SectionContainer>
                {renderInfoMessageBlock(staticContent?.checkStatusSection.detail.info(displayedDate, displayedTime))}
              </SectionContainer>
              <ButtonSectionContainer>
                <Button
                  disabled={loading}
                  onClick={() => handleCheckPaymentStatus(cbrNumber, receiptId, payerInfo)}
                  type={Button.Type.Primary}
                >
                  {staticContent?.checkStatusSection.detail.buttonCheckStatus}
                </Button>
                {isShowGenLinkButton && (
                  <Button
                    onClick={() => handleClickGenLinkButton(cbrNumber, receiptId, payerInfo)}
                    type={Button.Type.Secondary}
                  >
                    {staticContent?.checkStatusSection.detail.buttonCreateLink}
                  </Button>
                )}
              </ButtonSectionContainer>
            </>
          )}
        </div>
      </SectionContainer>
    </ContentContainer>
  )
}

export default withRouter(CheckPaymentStatusSection)
