import { getConfig, getToggles } from 'core/config'
import _ from 'lodash'
import useGoogleAnalytics from '../../../hooks/useServices/googleAnalytics'
import staticContentPage from '../static-content'
import { formatQRPaymentRef2 } from 'utils/helper'

export type RespType = {
  data?: any
  error?: any
}
const getProductName = (productCode, planCodeOptions) => {
  const selectedProduct = planCodeOptions.find((x) => x.value === productCode)
  if (selectedProduct) {
    const productName = selectedProduct?.planName
    return productName
  }
  return ''
}

const getPayload = (state, planCodeOptions, payloadType = '') => {
  try {
    const { info } = state
    let payerFirstName, payerLastName, payerCitizenID

    const currentDate = new Date()
    const isQuickPay = info?.payload?.paymentMethod === 'quickpay'
    const ref2 = info?.payload?.paymentMethod === 'ibanking' ? formatQRPaymentRef2(info?.payload?.paymentType) : ''

    const paymentLink = {
      email: info?.payload?.insureEmail || '',
      sms: info?.payload?.insureMobile.replace(/ +/g, ''),
      isSendLink: false,
    }

    if (info?.payload?.payerType === 'insured') {
      payerFirstName = info?.payload?.insuredName
      payerLastName = info?.payload?.insuredSurName
      payerCitizenID = info?.payload?.personalId.replace(/ +/g, '')
    } else if (info?.payload?.payerType === 'owner') {
      payerFirstName = info?.payload?.payerFirstName || info?.payload?.ownerFirstName || ''
      payerLastName = info?.payload?.payerLastName || info?.payload?.ownerLastName || ''
      payerCitizenID = info?.payload?.payerPersonalId?.replace(/ +/g, '') || info?.payload?.ownerPersonalId || ''
    } else if (info?.payload?.payerType === 'other') {
      payerFirstName = info?.payload?.payerFirstName || ''
      payerLastName = info?.payload?.payerLastName || ''
      payerCitizenID = info?.payload?.payerPersonalId?.replace(/ +/g, '') || ''
    }

    const payerInfo = {
      payerType: info?.payload?.payerType || '',
      payerFirstName,
      payerLastName,
      payerCitizenID,
      payerRelationshipDoc: info?.payload?.payerRelationshipDoc || {},
      payerRelationshipOthers:
        info?.payload?.payerRelations !== 'Others'
          ? info?.payload?.payerRelations
          : info?.payload?.payerRelationsOthers || '',
    }

    if (payloadType === 'SYSTEMIZATION') {
      return {
        paymentType: info?.payload?.paymentType,
        personalId: info?.payload?.personalId.replace(/ +/g, ''),
        policyNumber: info?.payload?.policyNo,
        paymentMode: info?.payload?.paymentMode,
        insuredName: info?.payload?.insuredName,
        insuredSurName: info?.payload?.insuredSurName,
        insureMobile: info?.payload?.insureMobile.replace(/ +/g, ''),
        insureEmail: info?.payload?.insureEmail || '',
        paymentAmount: Number(info?.payload?.paymentAmount.replace(/,/g, '')),
        paymentMethod: info?.payload?.paymentMethod,
        productName: getProductName(info?.payload?.productCode, planCodeOptions),
        productCode: info?.payload?.productCode,
        payInDate: currentDate.toISOString(),
        policyEffectiveDate: info?.payload?.policyEffectiveDate || '',
        sumInsuredAmount: info?.payload?.sumInsuredAmount || '',
        //qr
        ref2,
        // cheque
        chequeDate: info?.payload?.chequeDate || '',
        chequeBank: info?.payload?.chequeBank || '',
        chequeBankBranch: info?.payload?.chequeBankBranch || '',
        chequeNumber: info?.payload?.chequeNumber || '',
        chequeImage: info?.payload?.chequeImage ? info?.payload?.chequeImage : null,
        // creditcard
        creditCardNumber: info?.payload?.creditCardNumber || '',
        creditCardImage: info?.payload?.creditCardImage ? info?.payload?.creditCardImage : null,
        authorizedCode: info?.payload?.authorizedCode || '',
        // ccLink
        premiumDueDate: info?.payload?.premiumDueDate || '',
        paymentLink: isQuickPay ? paymentLink : null,
        //payerInfo
        payerInfo: payerInfo,
      }
    } else if (payloadType === 'UPDATE') {
      const qrCodeInfo = !_.isEmpty(info?.payload?.receiptId) ? { ref2: ref2 } : null
      return {
        paymentMethod: info?.payload?.paymentMethod,
        payInDate: currentDate,
        isAllowNotification: true,
        //qr
        ...qrCodeInfo,
        // cheque
        chequeBank: info?.payload?.chequeBank || '',
        chequeBankBranch: info?.payload?.chequeBankBranch || '',
        chequeNumber: info?.payload?.chequeNumber || '',
        chequeImage: info?.payload?.chequeImage ? info?.payload?.chequeImage : null,
        chequeDate: info?.payload?.chequeDate || '',
        // creditcard
        creditCardNumber: info?.payload?.creditCardNumber || '',
        creditCardImage: info?.payload?.creditCardImage ? info?.payload?.creditCardImage : null,
        authorizedCode: info?.payload?.authorizedCode || '',
        // ccLink
        paymentLink: isQuickPay ? paymentLink : null,
      }
    } else {
      return {
        paymentType: info?.payload?.paymentType,
        personalId: info?.payload?.personalId.replace(/ +/g, ''),
        policyNumber: info?.payload?.policyNo,
        paymentMode: info?.payload?.paymentMode,
        insuredName: info?.payload?.insuredName,
        insuredSurName: info?.payload?.insuredSurName,
        insureMobile: info?.payload?.insureMobile.replace(/ +/g, ''),
        insureEmail: info?.payload?.insureEmail || '',
        paymentAmount: Number(info?.payload?.paymentAmount.replace(/,/g, '')),
        paymentMethod: info?.payload?.paymentMethod,
        productCode: info?.payload?.productCode,
        payInDate: currentDate.toISOString(),
        policyEffectiveDate: info?.payload?.policyEffectiveDate || '',
        // cheque
        chequeDate: info?.payload?.chequeDate || '',
        chequeBank: info?.payload?.chequeBank || '',
        chequeBankBranch: info?.payload?.chequeBankBranch || '',
        chequeNumber: info?.payload?.chequeNumber || '',
        chequeImage: info?.payload?.chequeImage ? info?.payload?.chequeImage : null,
        // creditcard
        creditCardNumber: info?.payload?.creditCardNumber || '',
        creditCardImage: info?.payload?.creditCardImage ? info?.payload?.creditCardImage : null,
        authorizedCode: info?.payload?.authorizedCode || '',
        // ccLink
        premiumDueDate: info?.payload?.premiumDueDate || '',
        paymentLink: isQuickPay ? paymentLink : null,
      }
    }
  } catch (error) {
    throw error
  }
}
// create receipt ---> save data to DB receipt collection
// before go to payment tab
export const createIssueEcbr = async (state, planCodeOptions): Promise<RespType> => {
  const { info } = state
  let response

  const isUpdateECBR = info?.payload?.cbrNumber || info?.payload?.receiptId // if want to change payment method from cc-link to other payment
  const isEcbrSystemization = getToggles().ENABLE_ECBRSYS_ECBR && !_.isEmpty(info?.payload?.payerType)

  const url = `${getConfig().api.baseURL}/v1/ecbr`
  const draftUrl = `${getConfig().api.baseURL}/v1/ecbrsys/receipt/draft`
  const esysUrl = `${getConfig().api.baseURL}/v1/ecbrsys/receipt`

  const token = localStorage.getItem('ecbr:token')

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(getPayload(state, planCodeOptions)),
  }
  const systemizationOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(getPayload(state, planCodeOptions, 'SYSTEMIZATION')),
  }
  try {
    if (isUpdateECBR) {
      response = await updatePaymentMethod(state, planCodeOptions)
    } else {
      if (isEcbrSystemization) {
        if (info.payload.paymentMethod === 'cheque') {
          response = await fetch(esysUrl, systemizationOptions)
        } else {
          response = await fetch(draftUrl, systemizationOptions)
        }
      } else {
        response = await fetch(url, options)
      }
    }

    if (!response.ok) {
      return { data: null, error: response.statusText }
    }

    const jsonData = await response.json()
    // need fix
    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData?.receipt ? jsonData?.receipt : jsonData, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

// In case cannot create payment link user can change paymentMethod
export const updatePaymentMethod = async (state, planCodeOptions) => {
  try {
    const { info } = state

    const token = localStorage.getItem('ecbr:token')
    const ecbySysUrl = `${getConfig().api.baseURL}/v1/ecbrsys/receipt/draft/${info?.payload?.receiptId}`
    const legecyUrl = `${getConfig().api.baseURL}/v1/ecbr/${info?.payload?.cbrNumber}`

    const url = info?.payload?.receiptId ? ecbySysUrl : legecyUrl

    const updateOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(getPayload(state, planCodeOptions, 'UPDATE')),
    }

    const response = await fetch(url, updateOptions)
    return response
  } catch (error) {
    return error
  }
}

export const resetPaymentEcbr = async (state): Promise<RespType> => {
  const { process } = state
  const current = new Date()
  const isEnableEcbrSysFeature = !_.isEmpty(process?.payload?.data?.receiptId)

  const payload = {
    paymentMethod: '',
    payInDate: process?.payload?.data?.payInDate || current.toISOString(),
    isAllowNotification: false,
    // cheque
    chequeDate: '',
    chequeBank: '',
    chequeBankBranch: '',
    chequeNumber: '',
    chequeImage: null,
    // creditcard
    creditCardNumber: '',
    creditCardImage: null,
    authorizedCode: '',
    // ccLink
    paymentLink: null,
  }

  const requestUrl = isEnableEcbrSysFeature
    ? `v1/ecbr/${process?.payload?.data?.cbrNumber}?enable_ecbr_sys=true`
    : `v1/ecbr/${process?.payload?.data?.cbrNumber}`

  const url = `${getConfig().api.baseURL}/${requestUrl}`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }
  try {
    const res = await fetch(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()
    // need fix
    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }
    return { data: jsonData?.receipt, error: null }
  } catch (error) {
    return { data: null, error }
  }
}
export const createEcbrPaid = async (state): Promise<RespType> => {
  const { info, process, summary } = state
  const current = new Date()
  const payload = {
    paymentMethod: info?.payload?.paymentMethod || '',
    policyNumber: process?.payload?.data?.policyNumber || '',
    payInDate: current.toISOString(),
    slipImage: summary?.payload?.imgBase64 || '',
  }
  const url = `${getConfig().api.baseURL}/v1/ecbr/${process?.payload?.data?.cbrNumber}/paid`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }
  try {
    const res = await fetch(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()
    // need fix
    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }
    return { data: jsonData?.receipt, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

//ecbr-systemization
export const createEcbrNumber = async (state): Promise<RespType> => {
  const { process, summary, ecbrNoPayCount, pendingList, info } = state
  const payload = {
    slipImage: summary?.payload?.imgBase64 || {},
    ecbrNoPayCount: ecbrNoPayCount || 0,
  }

  const receiptId = info?.payload?.receiptId || process?.payload?.data?.receiptId || pendingList?.receiptId

  const url = `${getConfig().api.baseURL}/v1/ecbrsys/ecbrNumber/receipt/${receiptId}`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }
  try {
    const res = await fetch(url, options)

    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    // need fix
    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }
    return { data: jsonData, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

//ecbr-systemization case premium down || case submit limit exceed
export const updateEcbr = async (state): Promise<RespType> => {
  const { process, summary } = state

  const receiptId = process?.payload?.data?.receiptId
  const slipImage = summary?.payload?.imgBase64 || {}

  if (!receiptId) {
    return { data: null, error: 'Receipt ID is missing' }
  }

  const url = `${getConfig().api.baseURL}/v1/ecbrsys/receipt/${receiptId}`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ slipImage }),
  }
  try {
    const res = await fetch(url, options)

    const jsonData = await res.json()

    if (!res.ok || jsonData.error) {
      const error = jsonData.error || res.statusText
      return { data: null, error }
    }

    return { data: jsonData, error: null }
  } catch (error) {
    return { data: null, error: error.message || 'An unexpected error occurred' }
  }
}

export const createPaymentLink = async (state, formData): Promise<RespType> => {
  const { info, process } = state

  const phone = formData?.genLinkMobile || process?.payload?.data?.insureMobile || ''
  const formettedPhone = phone.replace(/\s/g, '')
  const ecbrNo = process?.payload?.data?.cbrNumber || '' // "200000892819", (2) + cbrNo =====> ['2'+'______'], cbrNo from MW already add '2'
  const receiptId = process?.payload?.data?.receiptId || ''
  const isFirstTimePaymentType = info?.payload?.paymentType === '01'
  const isRenewalPaymentType = info?.payload?.paymentType === '02'
  let policyStatus = info?.payload?.policyStatusCode // if 1st time payment set status code to '00' else use value from policy details
  if (isFirstTimePaymentType) {
    policyStatus = '00'
  } else if (isRenewalPaymentType) {
    policyStatus = '1'
  }

  const isEnableEcbrSysFeature = getToggles().ENABLE_ECBRSYS_ECBR && !_.isEmpty(receiptId)

  const payload = {
    policyNo: process?.payload?.data?.policyNumber || staticContentPage.defaultPolicyNo, // if no policy number fix value to '222-2222222'
    citizenId: process?.payload?.data?.personalId || '',
    planCode: process?.payload?.data?.productCode || '',
    insuredFirstName: process?.payload?.data?.insuredName || '',
    insuredLastName: process?.payload?.data?.insuredSurName || '',
    email: formData?.genLinkEmail || process?.payload?.data?.insureEmail || '',
    premium: process?.payload?.data?.paymentAmount || '',
    phone: formettedPhone,
    agentId: state.agentId,
    channelPrefix: 'CB',
    policyStatus: policyStatus || '',
    ecbrNo: !isEnableEcbrSysFeature ? ecbrNo : undefined, // DIGSALTOOL-3023 for new ecbr sys, there is no need to include ecbrNo in payload
    sendMethod: (formData?.ccLinkMode || '').toUpperCase(),
    premiumDueDate: process?.payload?.data?.premiumDueDate || '',
    payerInfo: process?.payload?.data?.payerInfo || {},
    receiptId: receiptId,
  }

  const url = `${getConfig().api.baseURL}/v1/ecbr/create-payment-link`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }

  try {
    const res = await fetch(url, options)
    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    // need fix
    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    return { data: jsonData, error: null }
  } catch (error) {
    return { data: null, error }
  }

  return {}
}

export const getPlanCode = async (token, type = '', paymentType = ''): Promise<RespType> => {
  const url = `${getConfig().api.baseURL}/v1/ecbr/payment/planCode?type=${type}&paymentType=${paymentType}`

  if (_.isEmpty(token)) {
    token = localStorage.getItem('ecbr:token')
  }
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const res = await fetch(url, options)

    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()
    const plancodeOptions = jsonData.map((item) => ({
      ...item,
      label: `${item.planCode} - ${item.planName}`,
      value: item.planCode,
    }))
    const sortPlancode = _.orderBy(plancodeOptions, 'planCode', 'asc')

    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }
    return { data: sortPlancode, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const checkPaymentStatus = async (ecbrNumber, receiptId, payerInfo): Promise<RespType> => {
  const { sendCustomEvent } = useGoogleAnalytics()
  const url = `${getConfig().api.baseURL}/v1/ecbr/quickpay/payment/status/${ecbrNumber}`
  const esysUrl = `${getConfig().api.baseURL}/v1/ecbrsys/quickpay/payment/status/${receiptId}`
  const token = localStorage.getItem('ecbr:token')
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    let res
    if (_.isEmpty(payerInfo)) {
      res = await fetch(url, options)
    } else {
      res = await fetch(esysUrl, options)
    }

    if (!res.ok) {
      return { data: null, error: res.statusText }
    }

    const jsonData = await res.json()

    // need fix
    if (jsonData.error) {
      return { data: null, error: jsonData.error }
    }

    if (jsonData.linkStatus === 'SUCCESS') {
      sendCustomEvent({
        category: 'QUICKPAY_LINK',
        action: 'PAYMENT_SUCCESS_ECBR',
        label: 'WEB',
      })
    }

    return { data: jsonData, error: null }
  } catch (error) {
    return { data: null, error }
  }
}
