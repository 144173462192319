import React, { useContext } from 'react'
import { Input as RSInput } from 'reactstrap'
import { Controller } from 'react-hook-form'
import _isEmpty from 'lodash/isEmpty'
import _omitBy from 'lodash/omitBy'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _isEqual from 'lodash/isEqual'
import { Radio } from 'ktaxa-ui-components/index'

import { InputCustomFormat, Dropdown } from 'components/input'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { UploadDocument } from 'components/input'
import {
  renderInfoMessageBlock,
  renderErrorMessageBlock,
  renderInfoMessageBlockForSpecialWordBreak,
  renderWarningMessageBlock,
} from 'components/message-blocks'
import { AppContext } from 'contexts/app'
import { getConfig } from 'core/config'

import { personNameRegExp, personNameWithSpaceRegExp } from '../hooks/useForm'
import { BANKS, CHECK_ACCOUNT_STATUS, DA_TYPE, UPDATE_TYPES, UPLOAD_DOC_TYPES } from '../static-content'
import { getStringOfNumberOnly } from '../utils'
import { onCheckValueAccountData } from '../hooks/useService'
import { updateRecurringData } from '../hooks/useService'
import { SelectContainer, MessageBlock } from './registerForm'

const FormContentSection = (props) => {
  const {
    // -- global state
    state,
    // -- useForm
    control,
    getValues,
    setValue,
    resetField,
    errors,
    trigger,
    // -- local variable
    formInfo,
    isPolicyCase,
    isOtherRelationship,
    checkedAccountStatus,
    checkedAccountData,
    // -- local state
    isValidateForm,
    isSelectedEDA,
    setIsSelectedEDA,
    isBookbankOverLimit,
    setIsBookbankOverLimit,
    isRelationDocOverLimit,
    setIsRelationDocOverLimit,
    isManualRelationship,
    setIsManualRelationship,
    selectedPersonalIdType,
    setSelectedPersonalIdType,
    selectedRelationshipType,
    setSelectedRelationshipType,
    setIsCheckedAccount,
    // -- function
    autoSaveDataForm,
    handleSelectedUploadDoc,
    handleRemoveUploadDoc,
  } = props

  const { setIsVerifyFormValidateError, dataCheckAccount, setIsBankAccountVerified } = useContext(AppContext)

  const { limitFileSize } = getConfig()

  return (
    <ContentContainer>
      <SectionContainer />
      <form
        onBlur={(e) => {
          autoSaveDataForm()
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
          }
        }}
      >
        <SectionContainer margin={'20'}>
          <label style={{ display: 'block', fontWeight: 'bold' }}>{formInfo.formType.label}</label>
          <SelectContainer>
            <Controller
              name={formInfo.formType.id}
              control={control}
              render={({ field }) => (
                <Radio.Group
                  uiStyle={Radio.UIStyle.Checked}
                  onChange={async (option) => {
                    const isOnlineForm = option.value === DA_TYPE.ONLINE
                    const getCurrentValues = getValues()
                    // TODO : for now online form accept allow for KTB only
                    if (isOnlineForm) {
                      setValue('bankName', BANKS.KTB.key, { shouldValidate: true, shouldDirty: true })
                    }

                    setIsSelectedEDA(isOnlineForm)
                    resetField(formInfo.formType.id || '')
                    const selectedRecurring = _get(state, 'verify.searchDAInfo', null)
                    const formType = isOnlineForm ? DA_TYPE.ONLINE : DA_TYPE.FORM
                    let payloadUpdate = {
                      _id: selectedRecurring._id,
                      daType: formType,
                    }
                    if (getCurrentValues.bankName !== BANKS.KTB.key && option.value === DA_TYPE.ONLINE) {
                      const daOfPayor = {
                        ...selectedRecurring.daOfPayor,
                        verifyAccStatus: '',
                      }
                      _set(payloadUpdate, 'daOfPayor', daOfPayor)
                      setIsCheckedAccount(false)
                    }

                    await updateRecurringData({
                      updateType: UPDATE_TYPES.UPDATE,
                      data: payloadUpdate,
                    })
                    _set(state, 'verify.searchDAInfo.daType', formType)
                    return field.onChange(option.value)
                  }}
                  value={getValues(formInfo.formType.id)}
                >
                  {formInfo.formType.options.map((opt) => {
                    const isDisabledOptionDA = isPolicyCase && opt.value !== DA_TYPE.ONLINE
                    return <Radio key={opt.value} value={opt.value} label={opt.text} disabled={isDisabledOptionDA} />
                  })}
                </Radio.Group>
              )}
            />
          </SelectContainer>
        </SectionContainer>

        <SectionContainer>
          {formInfo.textInputs.map((ti, key) => {
            if (ti.id === 'bookBank') {
              return (
                <>
                  <div key={ti.id} className="name-input" style={{ paddingBottom: 20 }}>
                    <Controller
                      name={ti.id}
                      control={control}
                      render={({ field }) => (
                        <UploadDocument
                          {...field}
                          onSelected={(img) => handleSelectedUploadDoc(field, img, UPLOAD_DOC_TYPES.BOOKBANK)}
                          value={getValues(ti.id)}
                          title={ti.label}
                          setIsOverLimit={setIsBookbankOverLimit}
                          limitFileSize={limitFileSize}
                          setValue={setValue}
                          onRemoveFile={() => handleRemoveUploadDoc(ti.id, UPLOAD_DOC_TYPES.BOOKBANK)}
                        />
                      )}
                    />
                    {renderErrorMessageBlock(errors[ti.id])}
                    {isBookbankOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
                    <MessageBlock>
                      {isSelectedEDA
                        ? renderWarningMessageBlock(ti.infoMessageEDA || '')
                        : renderInfoMessageBlockForSpecialWordBreak(ti.infoMessageDA || '')}
                    </MessageBlock>
                  </div>
                </>
              )
            } else if (ti.id === 'regRelationship') {
              return (
                <>
                  <div key={ti.id} className="name-input" style={{ paddingBottom: 20 }}>
                    <label style={{ display: 'block' }}>{ti.label}</label>
                    <SelectContainer style={{ marginTop: 6 }}>
                      <Controller
                        name={ti.id}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Radio.Group
                            uiStyle={Radio.UIStyle.Radio}
                            onChange={(option) => {
                              setSelectedRelationshipType(option.value)
                              if (!isManualRelationship) {
                                setIsManualRelationship(true)
                              }
                              return field.onChange(option.value)
                            }}
                            value={selectedRelationshipType}
                          >
                            {(ti.options || []).map((opt) => (
                              <Radio key={opt.value} value={opt.value} label={opt.text} />
                            ))}
                          </Radio.Group>
                        )}
                      />
                    </SelectContainer>
                    {isOtherRelationship && (
                      <>
                        <Controller
                          name={ti.subInputId || ''}
                          control={control}
                          rules={{
                            required: isOtherRelationship,
                          }}
                          render={({ field }) => (
                            <RSInput
                              {...field}
                              style={{ marginTop: 16 }}
                              id={ti.subInputId}
                              innerRef={field.ref}
                              type="text"
                              placeholder={ti.placeholder || ''}
                              maxLength={ti.maxLength}
                              onChange={(v) => {
                                let value = v.target.value
                                if (ti.condition === 'textOnly') value = value.replace(personNameRegExp, '')
                                return field.onChange(value)
                              }}
                            />
                          )}
                        />
                        {renderErrorMessageBlock(errors[ti.subInputId || ''])}
                        <MessageBlock style={{ marginBottom: 0 }}>
                          {renderInfoMessageBlock(ti.infoMessage || '')}
                        </MessageBlock>
                        <div className="name-input" style={{ paddingBottom: 16 }}>
                          <Controller
                            name={ti.additionDocid || ''}
                            control={control}
                            render={({ field }) => (
                              <UploadDocument
                                {...field}
                                onSelected={(img) => handleSelectedUploadDoc(field, img, UPLOAD_DOC_TYPES.RELATIONSHIP)}
                                value={getValues(ti.additionDocid || '')}
                                title={ti.additionDocLabel || ''}
                                setIsOverLimit={setIsRelationDocOverLimit}
                                limitFileSize={limitFileSize}
                                setValue={setValue}
                                onRemoveFile={() =>
                                  handleRemoveUploadDoc(ti.additionDocid || '', UPLOAD_DOC_TYPES.RELATIONSHIP)
                                }
                              />
                            )}
                          />
                          {renderErrorMessageBlock(errors[ti.additionDocid || ''])}
                          {isRelationDocOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )
            }
            return (
              <div key={ti.id} className="name-input" style={{ paddingBottom: 20 }}>
                <label style={{ display: 'block' }}>{ti.label}</label>
                <Controller
                  name={ti.id}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    if (Object.keys(errors).length > 0 || !isValidateForm) {
                      setIsVerifyFormValidateError(true)
                    } else {
                      setIsVerifyFormValidateError(false)
                    }

                    if (ti.id === 'regPersonalIdType') {
                      return (
                        <SectionContainer margin={'0'}>
                          <div
                            style={{
                              display: 'block',
                            }}
                          >
                            <SectionContainer margin={'10'}>
                              <Controller
                                name={ti.id}
                                control={control}
                                render={({ field }) => (
                                  <Radio.Group
                                    uiStyle={Radio.UIStyle.ButtonGroup}
                                    onChange={(option) => {
                                      setSelectedPersonalIdType(option.value)
                                      resetField(ti?.subInputId || '')
                                      return field.onChange(option.value)
                                    }}
                                    value={selectedPersonalIdType}
                                  >
                                    {ti.options?.map((option) => (
                                      <Radio key={option.value} value={option.value} label={option.text} />
                                    ))}
                                  </Radio.Group>
                                )}
                              />
                            </SectionContainer>
                            {selectedPersonalIdType === 'citizen' ? (
                              <Controller
                                // Citizen case
                                name={ti?.subInputId || ''}
                                control={control}
                                key={'citizen'}
                                render={({ field }) => {
                                  return (
                                    <InputCustomFormat
                                      {...field}
                                      getInputRef={field.ref}
                                      mask={ti.mask}
                                      format={ti.format}
                                    />
                                  )
                                }}
                              />
                            ) : (
                              <Controller
                                // Passport case
                                name={ti.subInputId || ''}
                                control={control}
                                key={'passport'}
                                render={({ field }) => {
                                  return <RSInput {...field} innerRef={field.ref} type="text" />
                                }}
                              />
                            )}
                            {renderErrorMessageBlock(errors[ti.id])}
                          </div>
                        </SectionContainer>
                      )
                    } else if (ti.id === 'bankAccountNumber') {
                      return (
                        <InputCustomFormat
                          {...field}
                          id={ti.id}
                          getInputRef={field.ref}
                          mask={ti.mask}
                          format={ti.format}
                          onChange={async (v) => {
                            let value = v.target.value

                            const formValues = getValues()
                            if (!_isEmpty(checkedAccountData)) {
                              let ResultBank = BANKS[formValues.bankName]
                              let resultStatus = await onCheckValueAccountData(checkedAccountData, {
                                ...checkedAccountData,
                                bankName: ResultBank.text,
                                bankAccountNumber: value,
                              })
                              setIsCheckedAccount(resultStatus)
                            }

                            const valueOnlyNumber = getStringOfNumberOnly(value)

                            trigger()
                            // return field.onChange(value)
                            return field.onChange(valueOnlyNumber)
                          }}
                        />
                      )
                    } else if (ti.type === 'select') {
                      const selectedValue = (ti.options || []).find((x) => x.value === getValues(ti.id))
                      return (
                        <>
                          <Dropdown
                            {...field}
                            placeholder={ti.placeholder || ''}
                            onChange={async (option) => {
                              const formValues = getValues()
                              if (
                                ti.id === 'bankName' &&
                                !_isEmpty(checkedAccountData) &&
                                checkedAccountStatus == CHECK_ACCOUNT_STATUS.SUCCESS
                              ) {
                                let ResultBank = BANKS[option?.value]
                                let resultStatus = await onCheckValueAccountData(checkedAccountData, {
                                  ...checkedAccountData,
                                  bankName: ResultBank.text,
                                  bankAccountNumber: formValues.bankAccountNumber,
                                })
                                setIsCheckedAccount(resultStatus)
                              } else if (
                                ti.id === 'bankAccountType' &&
                                !_isEmpty(checkedAccountData) &&
                                checkedAccountStatus == CHECK_ACCOUNT_STATUS.SUCCESS
                              ) {
                                setIsCheckedAccount(false)
                              }
                              return field.onChange(option?.value)
                            }}
                            value={selectedValue}
                            options={ti.options}
                            isDisabled={ti.id === 'bankName' && isSelectedEDA}
                          />
                        </>
                      )
                    } else if (ti.format) {
                      //! case have format
                      return <InputCustomFormat {...field} mask={ti.mask} format={ti.format} />
                    } else {
                      return (
                        <RSInput
                          {...field}
                          id={ti.id}
                          innerRef={field.ref}
                          type="text"
                          maxLength={ti.maxLength}
                          disabled={
                            ti.id === 'bankAccountName' &&
                            _get(dataCheckAccount, 'stausVerify', '') !== CHECK_ACCOUNT_STATUS.NOT_VERIFIED
                          }
                          onChange={(v) => {
                            let value = v.target.value
                            if (ti.condition === 'textOnly') {
                              value = value.replace(personNameRegExp, '')
                            } else if (ti.condition === 'textWithSpaceOnly') {
                              value = value.replace(personNameWithSpaceRegExp, '')
                            }
                            return field.onChange(value)
                          }}
                        />
                      )
                    }
                  }}
                />
                {ti.id === 'regPersonalIdType'
                  ? renderErrorMessageBlock(errors[ti.subInputId || ''])
                  : renderErrorMessageBlock(errors[ti.id])}
              </div>
            )
          })}
        </SectionContainer>
      </form>
    </ContentContainer>
  )
}

export default FormContentSection
