import { payerRelations, payerType } from 'domain/entities/ecbr'

export const formatNumber = (value, sep = ',') => {
  let formattedText
  if (value.toString() === value.toLocaleString()) {
    const parts = value.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep)
    formattedText = parts[1] ? parts.join('.') : parts[0]
  } else {
    formattedText = value.toLocaleString()
  }

  return formattedText
}

export const formatCitizenId = (value) => {
  if (value.length == 13) {
    return value.replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1 $2 $3 $4 $5')
  }
  return value
}

export const clearFormat = (value, sep = ' ') => {
  return value
    .split('')
    .filter((char) => char !== sep)
    .join('')
}

export const getPayerName = (info) => {
  if (!info) return ''

  const { payerType, insuredName, insuredSurName, payerFirstName, payerLastName, ownerFirstName, ownerLastName } = info

  switch (payerType) {
    case 'insured':
      return `${insuredName || ''} ${insuredSurName || ''}`.trim()
    case 'owner':
      return `${payerFirstName || ownerFirstName || ''} ${payerLastName || ownerLastName || ''}`.trim()
    case 'other':
      return `${payerFirstName || ''} ${payerLastName || ''}`.trim()
    default:
      return ''
  }
}

export const getPayerPersonalId = (info) => {
  if (!info) return ''

  const { payerType, personalId, payerPersonalId, ownerPersonalId } = info

  switch (payerType) {
    case 'insured':
      return (personalId || '').replace(/ +/g, '')
    case 'owner':
      return (payerPersonalId || ownerPersonalId || '').replace(/ +/g, '')
    case 'other':
      return (payerPersonalId || '').replace(/ +/g, '')
    default:
      return ''
  }
}

export const getPayerRelations = (info, isResumePayment) => {
  if (!info?.payerType) return ''

  const payerTypeMatch = payerType.find((type) => type.value === info.payerType)

  if (info.payerType === 'other') {
    // For "other", use payerRelationsOthers if payerRelations is "Others"
    if (info.payerRelations === 'Others') {
      return info.payerRelationsOthers || ''
    }

    const relationMatch = payerRelations.find((relation) => relation.value === info.payerRelations)
    if (isResumePayment) {
      // For payment method change and resume payment.
      return relationMatch ? relationMatch.label : info?.payerRelations || ''
    } else {
      // Find the label from payerRelations if not "Others"
      return relationMatch ? relationMatch.label : ''
    }
  }

  // For "insured" or "owner", return the corresponding text directly
  return payerTypeMatch.text || ''
}

export default {
  formatNumber,
  formatCitizenId,
  clearFormat,
  getPayerName,
  getPayerPersonalId,
  getPayerRelations,
}
