import React from 'react'
import styled from '@emotion/styled'
import { KTAXAQRCodeGenerator } from '../../../ktaxa-ui-components/components/qr-code'
import { formatNumber } from 'utils/string-helper'
interface QRCodeContainerProps {
  size?: number
}

type KTAXAQRCodePaymentPropsType = {
  ref1: string
  ref2: string
  amount: number
  size?: number
  showAmount?: boolean
}

const OutSideContainer = styled.div<QRCodeContainerProps>`
  width: ${(props) => `${props.size ? props.size + 30 : 130}px`};
  height: ${(props) => `${props.size ? props.size + 30 : 130}px`};
  padding: 2px;
  margin: 30px 20px 20px;
  position: relative;
  overflow: hidden;
`

const InsideContainer = styled.div<QRCodeContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-shadow: ${(props) =>
    `${props.size ? props.size + 10 : 110}px ${props.size ? props.size + 10 : 110}px #00008f, -${
      props.size ? props.size + 10 : 110
    }px -${props.size ? props.size + 10 : 110}px #00008f, -${props.size ? props.size + 10 : 110}px ${
      props.size ? props.size + 10 : 110
    }px #00008f, ${props.size ? props.size + 10 : 110}px -${props.size ? props.size + 10 : 110}px #00008f;`};
`

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`

const AmountSection = styled.div<QRCodeContainerProps>`
  width: ${(props) => `${props.size ? props.size + 30 : 130}px`};
  text-align: center;
  background: #00008f;
  color: white;
  padding: 0.4rem;
`

const KTAXAQRCodePayment = (props: KTAXAQRCodePaymentPropsType) => {
  const { amount, size, showAmount } = props
  return (
    <MainContainer>
      <OutSideContainer size={size}>
        <InsideContainer size={size}>
          <KTAXAQRCodeGenerator {...props} />
        </InsideContainer>
      </OutSideContainer>
      {showAmount && <AmountSection>{formatNumber(amount)} บาท</AmountSection>}
    </MainContainer>
  )
}

export default KTAXAQRCodePayment
