import _isEqual from 'lodash/isEqual'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _maxBy from 'lodash/maxBy'
import _findIndex from 'lodash/findIndex'
import _isEmpty from 'lodash/isEmpty'

import staticContentPage, { BANKS, DA_TYPE, DA_STATUS, RELATIONS } from './static-content'

const cleanAndSplitName = (name) => {
  let parts = name.trim().split(' ')

  // get array of name part split by space and remove empty index
  return parts.filter((part) => part !== '')
}

const removeWhitespace = (word) => {
  return word.replace(/\s+/g, '')
}

const NORMAL_TITLES = ['นาย', 'นางสาว', 'นาง', 'เด็กชาย', 'เด็กหญิง'] // สำคัญ!!! ** 'นางสาว' ต้องอยู่ก่อน 'นาง' เท่านั้น **

const removeNormalTitle = (nameWithTitle) => {
  // condition set 1
  // ASCII &#3631; Thai character Paiyannoi
  // ASCII &#3647; Thai currency symbol baht

  // consition set 2
  // ASCII &#3653; Thai character Lakkhangyao
  // ASCII &#3662; Thai character Yamakkan

  const foundTitle = NORMAL_TITLES.find((title) => {
    const isStartWithTitle = nameWithTitle.startsWith(title)
    if (!isStartWithTitle) {
      return false
    }

    const namePart = nameWithTitle.slice(title.length)
    const asciiCodeOfFirstChar = namePart.charCodeAt(0)

    const isConditionMatched =
      (asciiCodeOfFirstChar < 3631 || asciiCodeOfFirstChar > 3647) &&
      (asciiCodeOfFirstChar < 3653 || asciiCodeOfFirstChar > 3662)

    return isConditionMatched
  })

  return foundTitle ? nameWithTitle.replace(foundTitle, '') : nameWithTitle
}

const removeShortenedTitle = (name) => {
  const lastPeriodIndex = name.lastIndexOf('.')

  return lastPeriodIndex !== -1 ? name.slice(lastPeriodIndex + 1) : name
}

const removeTitleFromName = (name) => {
  const nameWithoutShortendTitle = removeShortenedTitle(name)
  const nameWithOutNormalTitle = removeNormalTitle(nameWithoutShortendTitle)

  return nameWithOutNormalTitle
}

// all param should trim value before pass to this function
const checkIsSamePerson = (bankAccountFullname, axaName, axaSurname) => {
  const axaSurnameWithoutSpace = removeWhitespace(axaSurname)

  const bankAccountNameList = cleanAndSplitName(bankAccountFullname)
  const axaFullname = `${axaName} ${axaSurname}`
  const axaNameList = cleanAndSplitName(axaFullname)

  let bankAccountNameListWithoutSurname

  // assume as
  // bankName case1 sturcture can be "title_firstName_lastName" lenght will be 3 or over
  // bankName case2 sturcture can be "title+firstName_lastName" lenght will be 2 or over
  // axaName sturcture always be "title+firsName_lastName" lenght will be 2 or over (assume as axa input cant add any space between character)
  // so bankAccountNameList.length > axaNameList.length in every case

  // const lengthDiff = bankAccountNameList.length - axaNameList.length
  if (bankAccountNameList.length > 2) {
    // maybe user have sth special in name such as title > 1 or surname split with space
    // so we will check surname first

    const bankAccountSurnameLength = bankAccountNameList.length - 1
    // remove length of title and firstName (assume as 1 index) [title+firstname, lastname]
    for (let i = bankAccountSurnameLength; i >= 1; i--) {
      const assumedNumberOfSurnameIndexFormLast = -Math.abs(i) // value always be negative
      const assumedBankAccountSurnameList = bankAccountNameList.slice(assumedNumberOfSurnameIndexFormLast) // get array or assumed surname
      const assumedBankAccountSurname = assumedBankAccountSurnameList.join('')

      const isSurnameMatch = _isEqual(assumedBankAccountSurname, axaSurnameWithoutSpace)
      if (isSurnameMatch) {
        bankAccountNameListWithoutSurname = bankAccountNameList.slice(0, assumedNumberOfSurnameIndexFormLast)
        break
      } else if (i === 1) {
        // axaSurname not match with bankAccountSurname last index
        // NOT SAME PERSON
        return false
      }
    }
  } else {
    // this function will check case2 of bankAccountName only!!
    // case2 bankAccountNameList.length === 2 ---> will have structure [title+name, surname]

    // get last index of bankAccountNameList (index 1) to compare with axaSurname
    const bankAccountSurname = bankAccountNameList[1]
    const isSurnameMatch = _isEqual(bankAccountSurname, axaSurnameWithoutSpace)

    if (!isSurnameMatch) {
      // NOT SAME PERSON
      return false
    }

    bankAccountNameListWithoutSurname = bankAccountNameList.slice(0, -1)
  }
  // ------- end part surname check ----------
  // surname is removed!
  // let's check name and title

  const axaNameWithoutSpace = removeWhitespace(axaName)
  const axaFirstNameOnly = removeTitleFromName(axaNameWithoutSpace)

  if (bankAccountNameListWithoutSurname.length > 1) {
    // ex. [title1, title2, name] ---> [Prof., Dr., martin]
    // or [title, name1, name2] -----> [miss, scarlet, ann] ---> !!! not sure fore this case !!!
    // additional case (06/03/2025)
    // or [title, name] ---> [mister, martin]
    // or [title1, title2+name] ---> [Prof., Dr.martin]
    // or [title+name1, name2] -----> [miss, scarlet, ann] ---> !!! not sure fore this case !!!

    const bankAccountNameListWithoutTitle = bankAccountNameListWithoutSurname
    const bankAccountNameListWithoutTitleLength = bankAccountNameListWithoutTitle.length
    for (let i = bankAccountNameListWithoutTitleLength; i >= 1; i--) {
      const assumedNumberOfNameIndexFormLast = -Math.abs(i) // value always be negative
      const assumedBankAccountNameList = bankAccountNameListWithoutTitle.slice(assumedNumberOfNameIndexFormLast) // get array or assumed name
      const assumedBankAccountName = assumedBankAccountNameList.join('')

      const assumedBankAccountNameWithoutTitle = removeTitleFromName(assumedBankAccountName)
      const isNameMatch = _isEqual(assumedBankAccountNameWithoutTitle, axaFirstNameOnly)
      if (isNameMatch) {
        return true
      }
    }
  } else {
    // bankAccountNameListwithoutSurname.length === 1 ---> [title+name]

    const bankAccountNameWithoutSurname = bankAccountNameListWithoutSurname.join('')
    const isNameAndTitleMatched = _isEqual(bankAccountNameWithoutSurname, axaNameWithoutSpace)

    if (isNameAndTitleMatched) {
      // SAME PERSON
      return true
    }

    const bankAccountNameWithoutTitle = removeTitleFromName(bankAccountNameWithoutSurname)

    // check both name matched!!
    return _isEqual(bankAccountNameWithoutTitle, axaFirstNameOnly)
  }

  return false
}

export const validateRelationship = (isCheckedAccount, formValues) => {
  const { insuredName, insuredSurname, payerName, payerSurname, bankAccountName, regRelationship } = formValues

  let newRelationshipType = regRelationship

  if (isCheckedAccount) {
    // get bankAccountName from API
    const isSomeRequiredInputValueEmpty = [insuredName, insuredSurname, payerName, payerSurname, bankAccountName].some(
      (val) => val === ''
    )
    if (!isSomeRequiredInputValueEmpty) {
      const insuredFullname = `${insuredName.trim()} ${insuredSurname.trim()}` // CONCERN: must have 1 space before name
      const payerFullName = `${payerName.trim()} ${payerSurname.trim()}` // CONCERN: must have 1 space before name

      // const isInsuredMatched = bankAccountName.endsWith(insuredFullname)
      // const isPayerMatched = bankAccountName.endsWith(payerFullName)

      // TODO: using checkIsSamePerson function to compare
      const isInsuredMatched = checkIsSamePerson(bankAccountName, insuredName.trim(), insuredSurname.trim())
      const isPayerMatched = checkIsSamePerson(bankAccountName, payerName.trim(), payerSurname.trim())

      if (isInsuredMatched) {
        newRelationshipType = RELATIONS.SELF.value
      } else if (isPayerMatched) {
        newRelationshipType = RELATIONS.PAYER.value
      } else {
        newRelationshipType = RELATIONS.OTHERS.value
      }
    }
  } else {
    const isSomeRequiredInputValueEmpty = [insuredName, insuredSurname, payerName, payerSurname].some(
      (val) => val === ''
    )
    if (!isSomeRequiredInputValueEmpty) {
      const insuredInfo = {
        name: insuredName.trim(),
        lastName: insuredSurname.trim(),
      }

      const payerInfo = {
        name: payerName.trim(),
        lastName: payerSurname.trim(),
      }

      const isInsuredSameAsPayer = _isEqual(insuredInfo, payerInfo)
      newRelationshipType = isInsuredSameAsPayer ? RELATIONS.SELF.value : RELATIONS.PAYER.value
    }
  }

  return newRelationshipType
}

export const createRegisterFormPayload = (formValues, manualRelation, recurringData) => {
  // TODO: policyDetail use data from EIP policy/app
  const policyDetail = {
    ...recurringData.policyDetail,
    insuredName: formValues.insuredName,
    insuredLastname: formValues.insuredSurname,
    ownerName: formValues.payerName,
    ownerLastname: formValues.payerSurname,
  }

  const selectedBank = BANKS[formValues.bankName || '']
  const bankNumber = {
    text: selectedBank?.text || '',
    value: selectedBank?.code || '',
  }
  const accountType = {
    text: formValues.bankAccountType,
    value: formValues.bankAccountType,
  }
  const collectionBank = {
    text: selectedBank?.text || '',
    value: selectedBank?.collection || '',
  }
  const bacnkAccNo = formValues.bankAccountNumber || ''

  const daOfPayer = {
    ...recurringData.daOfPayor,
    bankNumber,
    accountType,
    collectionBank,
    branchNumber: bacnkAccNo.slice(0, 3), // first 3
    accountNumber: bacnkAccNo.slice(3), // last 7
    accountName: formValues.bankAccountName,
    verifyAccStatus: formValues.verifyAccStatus, // default
    payorIdType: formValues.regPersonalIdType,
    payorId: formValues.regPersonalId.replace(/\s+/g, ''),
    payorRelationship: formValues.regRelationship,

    relationshipRemark: formValues.regRelationshipRemark,
    manualRelation: manualRelation,

    payorMobile: formValues.regMobile.replace(/\s+/g, ''),
    email: formValues.regEmail,
  }

  // confirm -> loading -> show success -> click confirm -> loading -> redirect
  const payload = {
    policyDetail,
    daOfPayor: daOfPayer,
    daStatus: DA_STATUS.PENDING,
    daType: formValues.daFormType,
    policyId: recurringData.policyId,
    _id: recurringData._id,
  }

  return payload
}

export const prepareRecordDataFromPolicy = (userInfo, dataRLSPolicy) => {
  const partyRoles = _get(dataRLSPolicy, 'partyRoles', [])
  const insuredData = partyRoles.find((roleData) => _get(roleData, 'RLSParty.partyTypeCode', '') === 'INS')
  const ownerData = partyRoles.find((roleData) => _get(roleData, 'RLSParty.partyTypeCode', '') === 'OWN')
  // const ownerIdNumber = _get(ownerData, 'partyIdentifiers.idDocumentNumber', '')
  const ownerIdNumber = _get(ownerData, 'RLSParty.partyIdentifiers[0].idDocumentNumber', '')
  const insuredIdNumber = _get(insuredData, 'RLSParty.partyIdentifiers[0].idDocumentNumber', '')

  const policyDetail = {
    insuredName: _get(insuredData, 'RLSParty.firstName', ''),
    insuredLastname: _get(insuredData, 'RLSParty.lastName', ''),
    ownerName: _isEmpty(ownerIdNumber)
      ? _get(insuredData, 'RLSParty.firstName', '')
      : _get(ownerData, 'RLSParty.firstName', ''),
    ownerLastname: _isEmpty(ownerIdNumber)
      ? _get(insuredData, 'RLSParty.lastName', '')
      : _get(ownerData, 'RLSParty.lastName', ''),
    planCode: _get(dataRLSPolicy, 'RLSProduct.productCode', ''),
    planName: _get(dataRLSPolicy, 'RLSProduct.productName', ''),
    paymentMode: _get(dataRLSPolicy, 'paymentModeCode'),
    paymentMethod: _get(dataRLSPolicy, 'premiumPaymentMethod'),
    policyStatus: _get(dataRLSPolicy, 'policyStatusCode'),
    policyYear: 'RNW',
    insuredCitizenId: insuredIdNumber,
  }

  const formValues = preparePolicyDetailForValidateRelationShip(policyDetail)
  const payorRelationship = validateRelationship(false, formValues)

  const recordData = {
    policyId: _get(dataRLSPolicy, 'policyNumber'),
    // TODO: [DIGSALTOOL-2954] need to add agent code and anme from API ADVISOR (do it at MW)
    policyDetail,
    policyEffectiveToDate: _get(dataRLSPolicy, 'policyEffectiveToDate'),
    effectiveDate: _get(dataRLSPolicy, 'effectiveDate'),
    daOfPayor: {
      manualRelation: false,
      payorRelationship,
    },
    daType: 'ONLINE',
  }

  return recordData
}

export const prepareRecordDataFromApplication = (userInfo, dataRLSApplication) => {
  const partyRoles = _get(dataRLSApplication, 'partyRoles', [])
  const insuredData = partyRoles.find((res) => res.partyRoleCode === 'INS')
  const ownerData = partyRoles.find((res) => res.partyRoleCode === 'OWN')
  const isOwner = ['P', 'S'].some((flag) => flag === _get(ownerData, 'RLSParty.ownerFlag', ''))

  const partyIdentifiers = _get(insuredData, 'RLSParty.partyIdentifiers', [])
  const insuredCitizenId = partyIdentifiers.length > 0 ? partyIdentifiers[0].idDocumentNumber : ''
  const policyDetail = {
    insuredName: _get(insuredData, 'RLSParty.firstName', ''),
    insuredLastname: _get(insuredData, 'RLSParty.lastName', ''),
    ownerName: isOwner ? _get(ownerData, 'RLSParty.firstName', '') : _get(insuredData, 'RLSParty.firstName', ''),
    ownerLastname: isOwner ? _get(ownerData, 'RLSParty.lastName', '') : _get(insuredData, 'RLSParty.lastName', ''),
    planCode: _get(dataRLSApplication, 'applicationRiders[0].product.productCode', ''),
    planName: _get(dataRLSApplication, 'applicationRiders[0].product.productName', ''),
    paymentMode: _get(dataRLSApplication, 'paymentModeCode'),
    paymentMethod: _get(dataRLSApplication, 'paymentMethod'),
    policyStatus: _get(dataRLSApplication, 'applicationStatusCode'),
    policyYear: 'NB',
    insuredCitizenId: insuredCitizenId,
  }

  const formValues = preparePolicyDetailForValidateRelationShip(policyDetail)
  const payorRelationship = validateRelationship(false, formValues)

  const recordData = {
    policyId: _get(dataRLSApplication, 'applicationNumber'),
    // TODO: [DIGSALTOOL-2954] need to add agent code and anme from API ADVISOR (do it at MW)
    policyDetail,
    policyEffectiveToDate: _get(dataRLSApplication, 'policyEffectiveToDate'),
    effectiveDate: _get(dataRLSApplication, 'effectiveDate'),
    daOfPayor: {
      manualRelation: false,
      payorRelationship,
    },
    daType: 'ONLINE',
  }

  return recordData
}

export const prepareRecordRetrieveDabit = (req) => {
  let genDataRLS = req.RetrieveDebitAuthorizationResponse.RLSApplication
  const recordData = {
    daEffDate: _get(genDataRLS, 'effectiveDate', null),
    debterFirstName: _get(genDataRLS, 'debtorFirstName', null),
    debterLastName: _get(genDataRLS, 'debtorLastName', null),
    activebankNumber: _get(genDataRLS, 'bankNumber', null),
    activebranchNumber: _get(genDataRLS, 'branchNumber', null),
    activeaccountNumber: _get(genDataRLS, 'accountNumber', null),
    activecollectionBank: _get(genDataRLS, 'collectionBank', null),
  }

  return recordData
}

export const formatBankAccountNumber = (bankAccountNo) => {
  if (!!bankAccountNo && bankAccountNo.length === 10) {
    return bankAccountNo.replace(/^(\d{3})(\d{1})(\d{5})(\d{1})$/, '$1-$2-$3-$4')
  }
  return ''
}

export const preparePolicyDetailForValidateRelationShip = (policyDetail) => {
  return {
    insuredName: policyDetail.insuredName || '',
    insuredSurname: policyDetail.insuredLastname || '',
    payerName: policyDetail.ownerName || '',
    payerSurname: policyDetail.ownerLastname || '',
    bankAccountName: '',
    regRelationship: '',
  }
}

export const getBankNameByCode = (bankCode, isKeyNeeded = false) => {
  const bankData = Object.values(BANKS).find((value) => value.code === bankCode) || BANKS.KTB

  return isKeyNeeded ? bankData.key : bankData.text
}

export const getStringOfNumberOnly = (input) => {
  // Using regular expression to remove all non-digit characters
  return input.replace(/\D/g, '')
}

export const getIsShowReviewForm = (tabState, isSelectedEDA, defaultFormType, daStatus) => {
  const initDaFormType = isSelectedEDA ? DA_TYPE.ONLINE : DA_TYPE.FORM
  const defaultDaFormType = defaultFormType || initDaFormType
  const activeShowReviewForm =
    tabState === staticContentPage.tabsProgress[2].id &&
    defaultDaFormType === DA_TYPE.FORM &&
    daStatus === DA_STATUS.PENDING

  return activeShowReviewForm
}

export const extractReasonCode = (errorDesc) => {
  if (!errorDesc) {
    return 400
  }
  const matchError = errorDesc.match(/"({.*})"/)
  if (matchError && matchError[1]) {
    try {
      const jsonObject = JSON.parse(matchError[1])
      const reasonCode = jsonObject.exception?.reasonCode
      return reasonCode ? parseInt(reasonCode) : 400
    } catch (error) {
      return 400
    }
  }
}
