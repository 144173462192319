import React, { useState, useRef, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input as RSInput } from 'reactstrap'

import { Radio } from 'ktaxa-ui-components/index'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { InputCustomFormat } from 'components/input'
import { PropsSectionType } from '../type'
import { renderInfoMessageBlock, renderErrorMessageBlock } from 'components/message-blocks'
import staticContent from '../static-content'
import { schemaInformationSection } from '../hooks/useForm'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import { createPaymentLink } from '../hooks/useService'
import CreatePaymentLinkDialog from '../components/create-payment-link-dialog'
import useGoogleAnalytics from '../../../hooks/useServices/googleAnalytics'
import _isEmpty from 'lodash/isEmpty'

import SubmitSection from './submit'

const PaymentLinkCreatingSection = (props: PropsSectionType) => {
  const { sendCustomEvent } = useGoogleAnalytics()
  const { tabState, state, setState, setTabState } = props

  // in case change payment method
  const receiptId = state?.pendingList?.receiptId || state?.info?.payload?.receiptId
  const cbrNumber = state?.pendingList?.cbrNumber || state?.info?.payload.cbrNumber
  const isResumePayment = !_isEmpty(receiptId) || !_isEmpty(cbrNumber) || false

  const { formState, control, reset, handleSubmit, getValues, setValue, watch } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaInformationSection(isResumePayment)),
  })
  const { errors } = formState

  const defaultCCLinkMode = 'sms'
  const [selectedCreateLinkMode, setSelectedCreateLinkMode] = useState(defaultCCLinkMode)
  const [showDialog, setShowDialog] = useState(false)
  const [createPaymentLinkData, setCreatePaymentLinkData] = useState<any>(null)

  const infoData = {
    ...state.info?.payload,
  }

  const { insureEmail, insureMobile, paymentMethod } = infoData

  const { ccLinkSection } = staticContent
  const insureMobileInfo = ccLinkSection.genLinkMobile
  const insureEmailInfo = ccLinkSection.genLinkEmail

  const isInitial = useRef(false)
  if (!isInitial.current && (insureEmail || insureMobile)) {
    reset({
      ...state?.info?.payload,
      ccLinkMode: defaultCCLinkMode,
    })
    watch(insureEmailInfo.id)

    if (insureEmail && !getValues(insureEmailInfo.id)) {
      setValue(insureEmailInfo.id, insureEmail, { shouldValidate: true, shouldDirty: true })
    }

    if (insureMobile && !getValues(insureMobileInfo.id)) {
      setValue(insureMobileInfo.id, insureMobile, { shouldValidate: true, shouldDirty: true })
    }
    isInitial.current = true
  }

  const onSubmit = async (data) => {
    const response = await createPaymentLink(state, data)
    sendCustomEvent({
      category: 'QUICKPAY_LINK',
      action: 'SEND_QUICKPAY_LINK_ECBR',
      label: 'WEB',
    })
    if (response?.data) {
      setCreatePaymentLinkData(response?.data)
    }
    setShowDialog(true)
  }

  const isQuickPay = paymentMethod === 'quickpay'
  const isCheckPaymentStatus = state?.isCheckPaymentStatus || false
  const ActiveCondition = tabState === staticContent.tabsProgress[2].id && isQuickPay && !isCheckPaymentStatus

  const isDisabledSubmitButton =
    (selectedCreateLinkMode === 'sms' && !getValues(insureMobileInfo.id)) ||
    (selectedCreateLinkMode === 'email' && !getValues(insureEmailInfo.id))

  const isCreatePaymentLinkSuccess = createPaymentLinkData !== null

  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state, isDisabledSubmit: isDisabledSubmitButton },
    {
      showFooter: true,
      extraButton: () => (
        <SubmitSection
          isDisabled={isDisabledSubmitButton}
          tabState={tabState}
          handleSubmit={handleSubmit(onSubmit)}
          state={state}
        />
      ),
    }
  )
  if (!ActiveCondition) return null

  return (
    <ContentContainer>
      <CreatePaymentLinkDialog
        show={showDialog}
        isSuccess={isCreatePaymentLinkSuccess}
        onToggle={() => {
          setShowDialog(!showDialog)
          setSelectedCreateLinkMode(defaultCCLinkMode)
          setCreatePaymentLinkData(null)
        }}
        createPaymentLinkData={createPaymentLinkData}
        setState={setState}
        setTabState={setTabState}
        state={state}
      />
      <form>
        <h1>{ccLinkSection.mainTitle.title}</h1>
        <SectionContainer>
          <div
            style={{
              display: 'block',
              background: '#ffffff',
              padding: 20,
            }}
          >
            <SectionContainer margin={'10'}>
              <label>{ccLinkSection.ccLinkMode.title}</label>
              <Controller
                name={ccLinkSection.ccLinkMode.id}
                control={control}
                render={({ field }) => (
                  <Radio.Group
                    uiStyle={Radio.UIStyle.ButtonGroup}
                    onChange={(option) => {
                      setSelectedCreateLinkMode(option.value)
                      return field.onChange(option.value)
                    }}
                    value={selectedCreateLinkMode}
                  >
                    {ccLinkSection.ccLinkMode.options.map((option) => (
                      <Radio key={option.value} value={option.value} label={option.text} />
                    ))}
                  </Radio.Group>
                )}
              />
            </SectionContainer>
            {selectedCreateLinkMode === 'sms' ? (
              <Controller
                // SMS case
                name={insureMobileInfo.id}
                control={control}
                key={'sms'}
                render={({ field }) => {
                  return (
                    <InputCustomFormat
                      {...field}
                      getInputRef={field.ref}
                      id={insureMobileInfo.id}
                      data-testid={insureMobileInfo.id}
                      mask={insureMobileInfo.mask}
                      format={insureMobileInfo.format}
                      maxLength={insureMobileInfo.maxLength}
                      thousandSeparator={insureMobileInfo.thousandSeparator}
                      decimalScale={insureMobileInfo.decimalScale}
                      fixedDecimalScale={insureMobileInfo.fixedDecimalScale}
                      isAllowed={insureMobileInfo.isAllowed}
                      onValueChange={(numberFormatValues) => field.onChange(numberFormatValues.value)}
                    />
                  )
                }}
              />
            ) : (
              <Controller
                // Email case
                name={insureEmailInfo.id}
                control={control}
                key={'email'}
                render={({ field }) => {
                  return (
                    <RSInput
                      {...field}
                      innerRef={field.ref}
                      id={insureEmailInfo.id}
                      data-testid={insureEmailInfo.id}
                      type="email"
                      maxLength={insureEmailInfo.maxLength}
                    />
                  )
                }}
              />
            )}
            {renderErrorMessageBlock(
              errors[getValues(ccLinkSection.ccLinkMode.id) === 'sms' ? insureMobileInfo.id : insureEmailInfo.id]
            )}
            {renderInfoMessageBlock('ท่านสามารถเลือกช่องทางในการส่งลิงก์ผ่านช่องทางที่ท่านต้องการ')}
          </div>
        </SectionContainer>
      </form>
    </ContentContainer>
  )
}

export default PaymentLinkCreatingSection
