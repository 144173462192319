import React from 'react'
import styled from '@emotion/styled'

import { Dialog, Button } from 'ktaxa-ui-components/index'
import { color, customIcon } from 'components/theme'
import staticContentPage from '../static-content-page'
import { getToggles } from 'core/config'

const DialogContainer = styled.div`
  text-align: center;
`
const Content = styled.div`
  padding: 15px 0;
`
const Footer = styled.div`
  text-align: center;
  margin: 30px 0 10px;
`
const Icon = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
  border: solid 10px ${color.type.success};
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &::before {
    display: block;
    width: 70px;
    height: 70px;
    background-color: ${color.type.success};
    content: '';
    mask-image: url(${customIcon('dialogSuccess')});
    mask-size: cover;
    mask-repeat: no-repeat;
  }
`
const WarningIcon = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;

  &::before {
    display: block;
    width: 120px;
    height: 120px;
    background-image: url(${customIcon('dialogWarning')});
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
  }
`

const ErrorIcon = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
  border: solid 10px ${color.type.error};
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &::before {
    display: block;
    width: 70px;
    height: 70px;
    background-color: ${color.type.error};
    content: '';
    mask-image: url(${customIcon('dialogError')});
    mask-size: cover;
    mask-repeat: no-repeat;
  }
`
const Title = styled.span`
  display: block;
  color: ${color.type.primary};
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
`
const SubTitle = styled(Title)`
  margin: 5px 0;
  font-size: 1rem;
  font-weight: 400;
`
const Message = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 1rem;
  color: #333333;
`

type ECBRData = {
  cbrNumber: string
  policyNumber: string
  status: string
}

type CanceledDialogProps = {
  show: boolean
  onToggle: () => void
  ecbrData: ECBRData
}

const CanceledDialog = (props: CanceledDialogProps) => {
  const { show, onToggle, ecbrData } = props
  if (getToggles().ENABLE_ECBRSYS_ECBR) {
    return (
      <DialogContainer>
        <Dialog centered modal show={show} onClose={onToggle}>
          <Content>
            {ecbrData.status === 'SUCCESS' && <Icon />}
            {ecbrData.status === 'PENDING' && <WarningIcon/>}
            {(ecbrData.status !== 'PENDING' && ecbrData.status !== 'SUCCESS') && <ErrorIcon/>}
            <Title>{staticContentPage.cancelEsysDialog.title[ecbrData.status] || 'เกิดข้อผิดพลาดบางอย่าง'}</Title>
            {(ecbrData.status === 'PENDING' || ecbrData.status === 'SUCCESS') && <SubTitle>เลขที่ {ecbrData?.cbrNumber} เรียบร้อย</SubTitle>}
            <Message>{staticContentPage.cancelEsysDialog.detail[ecbrData.status]}</Message>
          </Content>
          <Footer>
            <Button type={Button.Type.Primary} onClick={onToggle}>
              กลับไปหน้าแรก
            </Button>
          </Footer>
        </Dialog>
      </DialogContainer>
    )
  }
  return (
    <DialogContainer>
      <Dialog centered modal show={show} onClose={onToggle}>
        <Content>
          <Icon />
          <Title>{staticContentPage.cancelDialog.title}</Title>
          <SubTitle>เลขที่ {ecbrData?.cbrNumber} เรียบร้อย</SubTitle>
          <Message>{staticContentPage.cancelDialog.detail}</Message>
        </Content>
        <Footer>
          <Button type={Button.Type.Primary} onClick={onToggle}>
            กลับไปหน้าแรก
          </Button>
        </Footer>
      </Dialog>
    </DialogContainer>
  )
}

export default CanceledDialog
