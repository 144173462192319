export default class EcbrEntity {
  policyNumber: string
  paymentAmount: string
  paymentMode: string
  productCode: string
  firstName: string
  lastName: string
  nationalId: string
  mobileNumber: string
  email: string
  isOwner: Boolean
  premiumDueDate: string
  policyStatusCode: string
  status?: string
  statusEcbr?: string
  paymentNo?: string
  createdAt?: Date
  daySinceCreated?: number
  policyEffectiveDate?: string
  hasPayer?: Boolean
  sumInsuredAmount?: string
  ownerFirstName?: string
  ownerLastName?: string
  ownerPersonalId?: string

  constructor(
    policyNumber: string,
    paymentAmount: string,
    paymentMode: string,
    productCode: string,
    firstName: string,
    lastName: string,
    nationalId: string,
    mobileNumber: string,
    email: string,
    isOwner: Boolean,
    premiumDueDate: string,
    policyStatusCode: string,
    status?: string,
    statusEcbr?: string,
    paymentNo?: string,
    createdAt?: Date,
    daySinceCreated?: number,
    policyEffectiveDate?: string,
    hasPayer?: Boolean,
    sumInsuredAmount?: string,
    ownerFirstName?: string,
    ownerLastName?: string,
    ownerPersonalId?: string
  ) {
    this.policyNumber = policyNumber
    this.paymentAmount = paymentAmount
    this.paymentMode = paymentMode
    this.productCode = productCode
    this.firstName = firstName
    this.lastName = lastName
    this.nationalId = nationalId
    this.mobileNumber = mobileNumber
    this.email = email
    this.isOwner = isOwner
    this.premiumDueDate = premiumDueDate
    this.policyStatusCode = policyStatusCode
    this.status = status
    this.statusEcbr = statusEcbr
    this.paymentNo = paymentNo
    this.createdAt = createdAt
    this.daySinceCreated = daySinceCreated
    this.policyEffectiveDate = policyEffectiveDate
    this.policyStatusCode = policyStatusCode
    this.hasPayer = hasPayer
    this.sumInsuredAmount = sumInsuredAmount
    this.ownerFirstName = ownerFirstName
    this.ownerLastName = ownerLastName
    this.ownerPersonalId = ownerPersonalId
  }
}

type PaymentLinkEntity = {
  sms: string
  email: string
  linkmethod?: string
  isSendLink?: boolean
  refToken?: string
  expiredDatetime?: Date
}

export type ECBRItemEntity = {
  premiumDueDate?: string
  paymentLink?: PaymentLinkEntity
  authorizedCode: string
  chequeBank: string
  chequeBankBranch: string
  chequeDate: string
  chequeImage: object
  chequeNumber: string
  creditCardImage: string
  creditCardNumber: string
  insureEmail: string
  insureMobile: string
  insuredName: string
  insuredSurName: string
  payInDate: string
  paymentAmount: number
  paymentMethod: string
  paymentMode: string
  paymentType: string
  personalId: string
  policyNumber: string
  productCode: string
  cbrNumber: string
  cbrStatus: string
  status: string
  createdAt: Date
  caseNumber: string
  iProStatus: string
  daySinceCreated: number
  policyEffectiveDate?: string
  ref2: string
  payerInfo: {
    payerType: string
    payerFirstName: string
    payerLastName: string
    payerCitizenID: string
    payerRelationshipOthers: string
  }
  receiptId: string
}

export type PendingECBRListEntity = {
  isLoaded: boolean
  total: number
  data: ECBRItemEntity[]
}

export type VerifyBlockIssue = {
  pendingBlock: boolean
  suspendBlock: boolean
  warningMessage: string
}

export type SalesforceCreateCaseEntity = {
  caseId: string
  agentType?: string
  agentSubType?: string
  agentCode?: string
  ecbrNumber?: string
  caseOrigin: string
  subject: string
  source: string
  description: string
  policyId: string
  party: {
    firstName: string
    lastName: string
  }
  contactChannel: string
  contactMethods: object
}

export type CreateEcbrPaidEntity = {
  paymentMethod: string
  channelMethod?: string
  policyNumber: string
  payInDate: string
  slipImage: string
}

export type SalesforceUploadDoucmentEntity = {
  Title: string
  PathOnClient: string
  ContentLocation: string
  OwnerId: string
  VersionData: string
  Visibility: string
}

export type ErrorSalesforce = {}

export const paymentType = [
  {
    value: '01',
    text: 'เบี้ยงวดแรก',
  },
  {
    value: '02',
    text: 'เบี้ยงวดต่ออายุ',
  },
  {
    value: '05',
    text: 'ชำระคืนเงินกู้',
  },
  {
    value: '06',
    text: 'ค่าธรรมเนียมออกเล่ม กธ. ใหม่',
  },
  {
    value: '07',
    text: 'Top up ILP',
  },
]

export const paymentMethod = [
  {
    text: 'QR Code',
    value: 'ibanking',
  },
  {
    text: 'บัตรเครดิต (ชำระผ่านลิงก์)',
    value: 'quickpay',
  },
  {
    text: 'บัตรเครดิต M-POS/EDC',
    value: 'creditcard',
  },
  {
    text: 'เช็ค',
    value: 'cheque',
  },
  {
    text: 'เงินสด',
    value: 'cash',
  },
]

export const paymentMode = [
  {
    text: 'รายเดือน',
    value: 'M' || 'm',
  },
  {
    text: 'ราย 6 เดือน',
    value: 'S' || 's',
  },
  {
    text: 'รายปี',
    value: 'A' || 'a',
  },
]

export const linkPaymentMethod = [
  {
    text: 'อีเมล',
    value: 'email',
  },
  {
    text: 'SMS',
    value: 'sms',
  },
]

export const personalIdType = [
  { text: 'เลขประจำตัวประชาชน', value: 'citizen' },
  { text: 'เลขหนังสือเดินทาง', value: 'passport' },
]

export const payerIdType = [
  { label: 'บัตรประจำตัวประชาชน', value: 'citizen' },
  { label: 'หนังสือเดินทาง', value: 'passport' },
]

export const payerRelations = [
  { label: 'บิดามารดา', value: 'Parents' },
  { label: 'คู่สมรส', value: 'Couple' },
  { label: 'บุตรธิดา', value: 'Child' },
  { label: 'อื่นๆ', value: 'Others' },
]

export const payerType = [
  {
    value: 'insured',
    text: 'ผู้เอาประกัน',
  },
  {
    value: 'owner',
    text: 'ผู้ชำระเบี้ย (บิดามารดา สามีภรรยา)',
  },
  {
    value: 'other',
    text: 'บุคคลอื่น (โปรดระบุ ชื่อนามสกุล หมายเลขบัตรประชาชน และความสัมพันธ์)',
  },
]

export const qrBillerId = '0107555000376'

export const qrSuffix = '01'
