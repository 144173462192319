import React, { useEffect, useState, useContext } from 'react'
import { CustomTabs } from 'components/tabs'
import VerifyPolicySection from './sections/verify-policy'
import RegisterFormSection from './sections/registerForm'
import CheckPaymentStatusSection from './sections/check-status'
import { AppContext } from 'contexts/app'
import _ from 'lodash'
import { updateRecurringData, saveRecurringData } from './hooks/useService'
import { useTabItemsProcess } from './hooks/handleTab'
import staticContentPage, { UPDATE_TYPES } from './static-content'
import { useLogin } from '../../hooks/useServices/login'
import RegistrationResultDialog from './components/da-registration-result-dialog'
import { REGISTER_STATUS, DA_STATUS, DA_TYPE } from './static-content'
// 'src/hooks/useServices/login/index.tsx'

const ATPPage = (props) => {
  const { history } = props
  const { userInfo } = useLogin({})

  const [state, setState] = useState<any>({})
  const [tabState, setTabState] = useState(staticContentPage.tabsProgress[0].id)


  const [tabItems] = useTabItemsProcess({
    tabState,
    state,
    setState,
    ...props,
  })

  const [isSuccessCcpaStatus, setIsSuccessCcpaStatus] = useState(false) // basically, there are 2 cases, IN PROGRESS and SUCCESS
  const [isNeedToReload, setIsNeedToReload] = useState(false) // reload page when user success register and come back from tab3
  // TODO ; create state to keep data from selected policy
  const [selectedRecurringInfo, setSelectedRecurringInfo] = useState<any>()
  const [showRegisterResultDialog, setShowRegisterResultDialog] = useState(false)
  const [isProcess, setIsProcess] = useState(false)

  const { registrationEDA, tabDAState, setTabDaState } = useContext(AppContext)


  const regStatus = state?.regStatus
  useEffect(() => {
    if (!!regStatus) {
      setShowRegisterResultDialog(true)
    }
  }, [regStatus])

  const onBypass = async () => {
    try {
      if (regStatus === REGISTER_STATUS.EDA_DUPLICATE) {
        const recurringData = _.get(state, 'verify.searchDAInfo', null)
        await setIsProcess(true)
        const payload = {
          _id: recurringData._id,
          policyId: recurringData.policyId,
          daStatus: DA_STATUS.BYPASS
        }
        await updateRecurringData({
          updateType: UPDATE_TYPES.UPDATE,
          data: {
            ...payload,
            edaResponseDateTime: registrationEDA.applyDtm,
            edaRequestDateTime: registrationEDA.applyDtm,
          },
        })
        await updateRecurringData({
          updateType: UPDATE_TYPES.BYPASS,
          data: payload,
        })

        await setIsNeedToReload(true)

        await setShowRegisterResultDialog(false)
        await setState({ ...state, regStatus: '' })
        await setState({})
        await setTabState(staticContentPage.tabsProgress[0].id)
      }
    } catch (error) {
      console.error(`onBypass ${error.message}`)
      await setShowRegisterResultDialog(false)
      await setState({ ...state, regStatus: '' })
    }
  }

  const checkStatusBypass = async () => {
    try {
      const dataClone = _.clone(state.verify.searchDAInfo)
      let payload = dataClone

      const dataBookbank = _.get(payload, '_attachments.bookbank', null)
      const dataRelationship = _.get(payload, '_attachments.relationship', null)
      let imageBookbank
      let imageRelationship
      if (dataBookbank) {
        imageBookbank = payload._attachments.bookbank.image
        delete payload._attachments.bookbank.image
      }

      if (dataRelationship) {
        imageRelationship = payload._attachments.relationship.image
        delete payload._attachments.relationship.image
      }

      delete payload._id
      payload = {...payload,
        edaRequestDateTime: '',
        edaResponseDateTime: ''
      }
      const resultCreate = await saveRecurringData(payload)

      await setShowRegisterResultDialog(false)

      await setState({
        ...state,
        info: {
          payload: {
            ...state.info.payload,
            _id: resultCreate.data.data._id
          }
        },
        verify: {
          searchStatus: state.verify.searchStatus,
          searchDAInfo: {
            ...state.verify.searchDAInfo,
            _id: resultCreate.data.data._id,
          }
        }
      })
      if (dataBookbank) await _.set(state, 'verify.searchDAInfo._attachments.bookbank.image', imageBookbank)
      if (dataRelationship) await _.set(state, 'verify.searchDAInfo._attachments.relationship.image', imageRelationship)

      await setTabState(staticContentPage.tabsProgress[1].id)

    } catch (error) {
      await setShowRegisterResultDialog(false)
      await setState({ ...state, regStatus: '' })
    }
  }

  const onSuccessEDA = async () => {
    try {
      const recurringData = _.get(state, 'verify.searchDAInfo', null)
      await setIsProcess(true)
      let payload = {
        _id: recurringData._id,
        daStatus: regStatus === REGISTER_STATUS.EDA_SUCCESS ? DA_STATUS.SUCCESS : DA_STATUS.BYPASS,
      }
      await updateRecurringData({
        updateType: UPDATE_TYPES.UPDATE,
        data: {
          ...payload,
          edaResponseDateTime: recurringData.edaResponseDateTime,
        },
      })
      const response = await updateRecurringData({
        updateType: regStatus === REGISTER_STATUS.EDA_SUCCESS ? UPDATE_TYPES.SUCCESS : UPDATE_TYPES.BYPASS,
        data: payload,
      })
      await setShowRegisterResultDialog(false)
      await setState({})
      await setTabState(staticContentPage.tabsProgress[0].id)
      await setIsNeedToReload(true)
    } catch (error) {
      await setState({})
      await setTabState(staticContentPage.tabsProgress[0].id)
      await setIsNeedToReload(true)
    }
  }

  return (
    <>
      <CustomTabs active={tabState} onChange={(id) => setTabState(id)} items={tabItems} />
      <VerifyPolicySection
        setTabState={setTabState}
        setState={setState}
        state={state}
        tabState={tabState}
        isShowNavBar={tabState === staticContentPage.tabsProgress[0].id}
        isNeedToReload={isNeedToReload}
        selectedRecurringInfo={selectedRecurringInfo}
        setSelectedRecurringInfo={setSelectedRecurringInfo}
      />
      <RegisterFormSection
        setTabState={setTabState}
        setState={setState}
        state={state}
        tabState={tabState}
        isSuccessCcpaStatus={isSuccessCcpaStatus}
        setIsSuccessCcpaStatus={setIsSuccessCcpaStatus}
        isPageReview={_.get(state, 'verify.searchDAInfo.daStatus', null) === DA_STATUS.PENDING && _.get(state, 'verify.searchDAInfo.daType', null) === DA_TYPE.FORM}
        recurringInfo={selectedRecurringInfo}
        onDAFormSuccess={async() => {
          await setState({})
          await setTabState(staticContentPage.tabsProgress[0].id)
          await setIsNeedToReload(true)
        }}
      />
      <CheckPaymentStatusSection
        setTabState={setTabState}
        setState={setState}
        state={state}
        tabState={tabState}
        activeType={_.get(state, 'verify.searchDAInfo.daType', null) === DA_TYPE.ONLINE}
        ActiveCondition ={tabState === staticContentPage.tabsProgress[2].id}
        isShowMoreOption={[REGISTER_STATUS.NO_STATUS, REGISTER_STATUS.TECH_ISSUE].some(
          (status) => status === _.get(state, 'checkEDAStatus', '')
        )}
        onDAFormSuccess={async() => {
          await setState({})
          await setTabState(staticContentPage.tabsProgress[0].id)
          await setIsNeedToReload(true)
        }}
      />
      <RegistrationResultDialog
        // show, onToggle, onConfirm, data
        show={showRegisterResultDialog}
        onToggle={() => {
          setShowRegisterResultDialog(false)
          setState({ ...state, regStatus: '' })
        }}
        onConfirm={async() => {
          if (regStatus === REGISTER_STATUS.SEND_REQUEST_SUCCESS) {
            await setTabState(staticContentPage.tabsProgress[2].id)
            await setState({...state,checkEDAStatus:''})
            await setShowRegisterResultDialog(false)
          } else if (regStatus === REGISTER_STATUS.TECH_ISSUE) {
            await setShowRegisterResultDialog(false)
            await setState({ ...state, regStatus: '' })
          } else if (regStatus === REGISTER_STATUS.EDA_FAIL) {
            checkStatusBypass()
          } else if ([REGISTER_STATUS.EDA_SUCCESS, REGISTER_STATUS.EDA_SUCCESS_MANUAL].includes(regStatus)) {
            onSuccessEDA()
          } else if (![REGISTER_STATUS.EDA_SUCCESS, REGISTER_STATUS.EDA_SUCCESS_MANUAL].includes(regStatus)) {
            await setTabState(staticContentPage.tabsProgress[2].id)
            await setState({...state,checkEDAStatus:regStatus})
            await setShowRegisterResultDialog(false)
          } else {
            await setState({})
            await setTabState(staticContentPage.tabsProgress[0].id)
            await setIsNeedToReload(true)
          }
        }}
        data={state}
        status={regStatus}
        onBypass={onBypass}
        isProcess={isProcess}
        // setIsNeedToReload={setIsNeedToReload}
        // setIsShowGenLinkDialogResult={setIsShowGenLinkDialogResult}
        // dialogType={dialogType}
        // ccpaPaymentStatus={ccpaPaymentStatus}
      />
    </>
  )
}

export default ATPPage
