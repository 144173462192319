import React, { useEffect } from 'react'

import { Button as MainButton } from 'ktaxa-ui-components/index'

import { useRouterForHandleSummit } from '../../hooks/useRouter'

import SubmitSection from '../submit'

import staticContentPage from '../../static-content'

export const useHandleDataForm = (props) => {
  const { setState, state, tabState, setTabState, reset, handleSubmit, isDirty } = props
  const { pendingList } = state
  const onSubmit = (data) => {
    reset(data)
    setState({
      ...state,
      transferInfo: {
        payload: data?.object,
      },
    })
    setTabState(staticContentPage.tabsProgress[2].id)
  }

  useEffect(() => {
    if (isDirty) {
      setState({
        ...state,
        transferInfo: {
          isDirty,
        },
      })
    }
  }, [isDirty])

  // Reset state when edit tab1.
  useEffect(() => {
    reset({
      object: {
        payBy: '',
        payByService: '',
      },
    })
    setState({
      ...state,
      transferInfo: {},
    })
  }, [pendingList])

  const ActiveCondition = tabState === staticContentPage.tabsProgress[1].id
  useRouterForHandleSummit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <MainButton onClick={() => setTabState(staticContentPage.tabsProgress[0].id)} type="secondary">
          กลับ
        </MainButton>
      ),
      extraButton: () => <SubmitSection tabState={tabState} handleSubmit={handleSubmit(onSubmit)} state={state} />,
    }
  )
}
