import React, { useEffect } from 'react'

import { Button as MainButton } from 'ktaxa-ui-components/index'

import { useRouterForHandleSummit } from '../../hooks/useRouter'

import SubmitSection from '../submit'

import staticContentPage from '../../static-content'

export const useHandleDataForm = (props) => {
  const { setState, state, tabState, setTabState, reset, handleSubmit } = props
  const { pendingList } = state
  // Reset state when edit tab1.
  useEffect(() => {
    reset({
      object: {
        imgBase64: {},
      },
    })
    setState({
      ...state,
      summary: {},
    })
  }, [pendingList])

  const ActiveCondition = tabState === staticContentPage.tabsProgress[4].id
  useRouterForHandleSummit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: true,
      extraBackButton: () => (
        <MainButton onClick={() => setTabState(staticContentPage.tabsProgress[0].id)} type="secondary">
          กลับ
        </MainButton>
      ),
      extraButton: () => (
        <SubmitSection tabState={tabState} handleSubmit={handleSubmit} state={state} setState={setState} />
      ),
    }
  )
}
