import React, { useEffect, useState } from 'react'

import { CustomTabs } from 'components/tabs'
import InformationSection from './sections/information'
import SummarySection from './sections/summary'
import PaymentSection from './sections/payment'
import PaymentLinkCreatingSection from './sections/create-link'
import CheckPaymentStatusSection from './sections/check-status'

import { useTabItemsProcess } from './hooks/handleTab'
import { useVerifyBlockIssue } from '../home/hooks/service'
import staticContentPage from './static-content'

const PaymentPage = (props) => {
  const { history } = props
  const [state, setState] = useState({})
  const [tabState, setTabState] = useState(staticContentPage.tabsProgress[0].id)
  // const [ccLinkStep, setCcLinkStep] = useState(1)
  const [tabItems] = useTabItemsProcess({
    tabState,
    state,
    setState,
    ...props,
  })
  const [blockIssueConfig] = useVerifyBlockIssue()
  const { pendingBlock, suspendBlock } = blockIssueConfig

  useEffect(() => {
    if (pendingBlock || suspendBlock) {
      history.replace('/')
    }
  }, [pendingBlock, suspendBlock])

  return (
    <>
      <CustomTabs active={tabState} onChange={(id) => setTabState(id)} items={tabItems} />
      <InformationSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <SummarySection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <PaymentSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <PaymentLinkCreatingSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
      <CheckPaymentStatusSection setTabState={setTabState} setState={setState} state={state} tabState={tabState} />
    </>
  )
}

export default PaymentPage
