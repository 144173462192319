import { useState, useEffect } from 'react'

import { useTabStateActive, reStateAfterTabActive, TabeItemsType, initFirstTab } from '../../../hooks/useTabs'

import staticContentPage from '../static-content'
import { PropsSectionType } from '../type'
import { getToggles } from 'core/config'
import _isEmpty from 'lodash/isEmpty'

export const useTabItemsPendingPaymentProcess = (props: PropsSectionType) => {
  const { tabsProgress, tabsProgressPayment } = staticContentPage
  const { state, tabState, setState } = props

  const tabsProgressWithCondition = state.isChangePaymentMethod ? tabsProgressPayment : tabsProgress
  const { firstTabWithPayment, initTabsProcessWithOutPaymentTab, firstTab, initTabsProcess } =
    initFirstTab(tabsProgressWithCondition)

  //start 1 tab
  const [tabItems, setTabItems] = useState<TabeItemsType[]>(firstTab)
  useEffect(() => {
    // enable next tab
    if (state?.pendingList) {
      const tabCondition =
        state?.pendingList.cbrStatus === 'RESERVED' ||
        state?.pendingList?.status === 'DRAFT' ||
        state?.pendingList.cbrStatus === 'CREATED'

      if (tabCondition) {
        const newTabItems = useTabStateActive(staticContentPage.tabsProgress[1].id, firstTabWithPayment)
        setTabItems(newTabItems)
      } else {
        const newTabItems = useTabStateActive(staticContentPage.tabsProgress[1].id, initTabsProcessWithOutPaymentTab)
        setTabItems(newTabItems)
      }
    }
  }, [state?.pendingList])

  useEffect(() => {
    // enable next tab
    if (state?.process?.payload && state?.isChangePaymentMethod) {
      // const disabledAllTab = initTabsProcess.map((tab) => ({ ...tab, disabled: true }))
      const newTabItems = useTabStateActive(staticContentPage.tabsProgress[0].id, initTabsProcess)
      setTabItems(newTabItems)
    }
    // disable tab next tab active
  }, [state?.process])

  useEffect(() => {
    // enable next tab
    if (state?.transferInfo?.payload) {
      const newTabItems = useTabStateActive(staticContentPage.tabsProgress[2].id, tabItems)
      setTabItems(newTabItems)
    }
    // disable tab next tab active
    if (state?.transferInfo?.isDirty) {
      const newTabItems = reStateAfterTabActive(staticContentPage.tabsProgress[1].id, tabItems)
      setTabItems(newTabItems)
    }
  }, [state?.transferInfo])

  useEffect(() => {
    // enable next tab
    if (state?.confirmPayment?.payload) {
      const newTabItems = useTabStateActive(staticContentPage.tabsProgress[3].id, tabItems)
      setTabItems(newTabItems)
    }
    // disable tab next tab active
  }, [state?.confirmPayment])

  useEffect(() => {
    if (tabState === staticContentPage.tabsProgress[0].id) {
      // for quickpay process when change tab back to pending list should clear data in state
      const tempData = state.createPaymentLinkData
        ? {
            isCheckPaymentStatus: state.isCheckPaymentStatus,
            info: state.info,
            createPaymentLinkData: state.createPaymentLinkData,
            process: state.process,
          }
        : undefined

      setState({
        ...state,
        isCheckPaymentStatus: undefined,
        info: undefined,
        createPaymentLinkData: undefined,
        process: undefined,
        tempData: tempData,
      })
    }

    const isQuickPay = state.pendingList?.paymentMethod === 'quickpay'
    if (
      tabState === staticContentPage.tabsProgress[4].id &&
      isQuickPay &&
      state.tempData &&
      state.isCheckPaymentStatus === undefined
    ) {
      // DIGSALTOOL-2885 if ENABLE_ECBRSYS_ECBR === true, then we need to add more state object for resume case

      const isEcbrSystemization = getToggles().ENABLE_ECBRSYS_ECBR && !_isEmpty(state.pendingList.receiptId)

      const legacyProcessData = state.tempData?.process
      const newProcessDataForEcbrSys = {
        payload: {
          data: {
            payerInfo: state.pendingList.payerInfo,
            policyNumber: state.pendingList.policyNumber,
            cbrNumber: state.pendingList.cbrNumber,
            receiptId: state.pendingList.receiptId,
            personalId: state.pendingList.personalId,
            productCode: state.pendingList.productCode,
            insuredName: state.pendingList.insuredName,
            insuredSurName: state.pendingList.insuredSurName,
            paymentAmount: state.pendingList.paymentAmount,
          },
        },
      }

      const processingDataForEcbrSys = isEcbrSystemization ? newProcessDataForEcbrSys : legacyProcessData

      setState({
        ...state,
        isCheckPaymentStatus: state.tempData?.isCheckPaymentStatus, // state.tempData?.info
        info: isEcbrSystemization
          ? {
              payload: {
                ...state.tempData?.info.payload,
                payerType: state?.pendingList?.payerInfo?.payerType,
              },
            }
          : state.tempData?.info,
        createPaymentLinkData: state.tempData?.createPaymentLinkData,
        process: processingDataForEcbrSys,
        tempData: undefined,
      })
    }
  }, [tabState])

  return [tabItems]
}
