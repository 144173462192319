import PolicyRepository from '../../../../domain/repositories/policy'
import PolicyEntity from '../../../../domain/entities/policy'

import { IConnect } from '../connect'
import { calculatePaymentAmount } from 'utils/helper'

export default class PolicyMiddlewareRepositoryImp implements PolicyRepository {
  connect: IConnect

  constructor(connect: IConnect) {
    this.connect = connect
  }
  async GetPolicyByPolicyNo(policyNo: string, policyEffectiveDate: string, agentCode: string): Promise<PolicyEntity> {
    try {
      const jsonData = await this.connect.get(
        `/eip/getPolicyDetailWithPolicyNo/${policyNo}?policyEffectiveDate=${policyEffectiveDate}`
      )
      // need fix
      if (jsonData.details) {
        return {} as PolicyEntity
      }
      const { RLSPolicy } = jsonData.RetrievePolicyDetailsResponse
      const insuredDetail = RLSPolicy.partyRoles.find((p) => p.RLSParty.partyTypeCode === 'INS')
      // const productAgentCode = RLSPolicy.producingAgentCode1
      const productAgentCode = RLSPolicy.actualServicingAgentCode1
      const ownerDetail = RLSPolicy.partyRoles.find((p) => p.RLSParty.partyTypeCode === 'OWN')

      const isOwner = productAgentCode.substr(productAgentCode.length - 6) === agentCode
      const hasPayer =
        ownerDetail.RLSParty.partyIdentifiers[0].idDocumentNumber !== '' &&
        ownerDetail.RLSParty.partyIdentifiers[0].idDocumentNumber !==
          insuredDetail.RLSParty.partyIdentifiers[0].idDocumentNumber

      // if user agent is not owner of policy follow this case !!
      if (!isOwner) {
        return new PolicyEntity(
          RLSPolicy.policyNumber,
          '',
          '',
          '',
          '',
          '',
          insuredDetail.RLSParty.partyIdentifiers[0].idDocumentNumber,
          '',
          '',
          isOwner,
          '',
          '',
          hasPayer,
          RLSPolicy.sumInsuredAmount,
          ownerDetail.RLSParty.firstName || '',
          ownerDetail.RLSParty.lastName || '',
          ownerDetail.RLSParty.partyIdentifiers[0].idDocumentNumber || ''
        )
      }
      const paymentAmount = calculatePaymentAmount(RLSPolicy)

      return new PolicyEntity(
        RLSPolicy.policyNumber,
        paymentAmount,
        RLSPolicy.paymentModeCode,
        RLSPolicy.RLSProduct.productCode,
        insuredDetail.RLSParty.firstName,
        insuredDetail.RLSParty.lastName,
        insuredDetail.RLSParty.partyIdentifiers[0].idDocumentNumber,
        '',
        '',
        isOwner,
        RLSPolicy.premiumDueDate,
        RLSPolicy.policyStatusCode,
        hasPayer,
        RLSPolicy.sumInsuredAmount,
        ownerDetail.RLSParty.firstName || '',
        ownerDetail.RLSParty.lastName || '',
        ownerDetail.RLSParty.partyIdentifiers[0].idDocumentNumber || ''
      )
    } catch (error) {
      return {} as PolicyEntity
    }
  }

  async GetPolicyByCitizenId(
    citizenId: string,
    filterType: string = ''
  ): Promise<
    {
      policyNumber: string
      policyEffectiveDate: string
      policyExpirationDate: string
      productCode: string
      policyStatusCode: string
    }[]
  > {
    try {
      const res = await this.connect.get(`/eip/getPolicyListWithIdNumber/${citizenId}`)
      if (res.details) {
        return [] as {
          policyNumber: string
          policyEffectiveDate: string
          policyExpirationDate: string
          productCode: string
          policyStatusCode: string
        }[]
      }

      let policyList = res.RetrievePolicyListResponse.policy
      if (filterType === 'new-ecbr') {
        // map ins policy (only new-ecbr feature)
        policyList.filter((p: any) => {
          return p.partyRoles[0].party.partyId.substr(p.partyRoles[0].party.partyId.length - 3) === 'INS'
        })
      }
      return policyList.map((p: any) => {
        return {
          policyNumber: p.policyNumber,
          policyEffectiveDate: p.policyEffectiveDate,
          policyExpirationDate: p.policyExpirationDate,
          productCode: p.productCode,
          policyStatusCode: p.policyStatusCode,
        }
      })
    } catch (error) {
      return [] as {
        policyNumber: string
        policyEffectiveDate: string
        policyExpirationDate: string
        productCode: string
        policyStatusCode: string
      }[]
    }
  }
}
