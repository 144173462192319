import React from 'react'
import { Input } from 'reactstrap'

import { BlockText, ContentContainer, SectionContainer } from 'components/layouts'
import { KTAXAQRCodePayment } from 'components/ktaxa-qr-code'
import { Cards } from 'components'
import _isEmpty from 'lodash/isEmpty'

import AmountBox from '../../components/amount-box'
import { ecbrService, paymentService } from 'services/ecbr-middleware'
import { clearFormat, formatCitizenId } from 'utils/string-helper'

import staticContentPage from '../../static-content'
import { PropsSectionType } from '../../type'

import { useHandleDataForm } from './hook'

const { ECBRCard } = Cards

const ConfirmPaymentSection = (props: PropsSectionType) => {
  const { state, tabState } = props
  const { pendingList, transferInfo } = state

  const paymentType = ecbrService.getPaymentTypeByPamentTypeCode(pendingList?.paymentType)
  const paymentMethod = ecbrService.getPaymentMethodByPaymentMethodCode(pendingList?.paymentMethod)
  const paymentMode = ecbrService.getPaymentModeByPaymentModeCode(pendingList?.paymentMode)
  const modifiedPolicyNumber = pendingList ? clearFormat(pendingList?.policyNumber, '-') : ''
  const modifiedPersonalId = pendingList ? clearFormat(pendingList?.personalId, ' ') : ''
  const ref1 = pendingList?.policyNumber || pendingList?.personalId
  const ref2 = !_isEmpty(pendingList?.ref2) ? pendingList?.ref2 : pendingList?.cbrNumber + pendingList?.paymentType

  const payBy = paymentService.getTransferMethodByTransferMethodCode(transferInfo?.payload?.payBy)
  const payByService = paymentService.getChanalMethodByTransferMethodCodeAndChanalMethodCode(
    transferInfo?.payload?.payBy,
    transferInfo?.payload?.payByService
  )
  const compCode = paymentService.getCompCodeByBankMethod(transferInfo?.payload?.payByService)

  useHandleDataForm({ ...props })
  const ActiveCondition = tabState === staticContentPage.tabsProgress[2].id

  if (!ActiveCondition) return null
  const qrProps = {
    ref1: modifiedPolicyNumber || modifiedPersonalId,
    ref2: ref2,
    amount: pendingList?.paymentAmount,
    size: 200,
  }

  return (
    <ContentContainer>
      <h1>ยืนยันข้อมูลการชำระ</h1>
      <SectionContainer>
        <ECBRCard
          name={`${pendingList?.insuredName} ${pendingList?.insuredSurName}`}
          policyNumber={pendingList?.policyNumber}
          amount={pendingList?.paymentAmount}
          status={pendingList?.cbrStatus}
          createdAt={pendingList?.payInDate}
          daySinceCreated={pendingList?.daySinceCreated}
          paymentNo={pendingList?.cbrNumber}
        />
      </SectionContainer>

      <SectionContainer>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <BlockText>ประเภทการชำระเงิน</BlockText>
          <BlockText right>
            {paymentType}/{paymentMode}/{paymentMethod}
          </BlockText>

          <BlockText>เลขที่กรมธรรม์</BlockText>
          <BlockText right>{pendingList?.policyNumber}</BlockText>

          <BlockText>เลขที่บัตรประชาชนผู้เอาประกัน</BlockText>
          <BlockText right>{formatCitizenId(pendingList?.personalId)}</BlockText>

          {transferInfo?.payload?.payBy === 'bank' && (
            <>
              <BlockText>Comp. Code</BlockText>
              <BlockText right>{compCode}</BlockText>
            </>
          )}
        </div>
      </SectionContainer>

      <SectionContainer>
        <AmountBox title={'จำนวนเงินที่ชำระ'} amount={pendingList?.paymentAmount} />
      </SectionContainer>

      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 20 }}>
          <h2>วิธีการชำระเงิน</h2>
          <Input type="select" className="custom-select form-control" disabled>
            <option value="">{payBy}</option>
          </Input>
        </div>
      </SectionContainer>
      <SectionContainer>
        <div className="name-input" style={{ paddingBottom: 5 }}>
          <h2>{transferInfo?.payload?.payBy === 'เคาเตอร์สำนักงาน' ? 'เคาเตอร์ผู้รับชำระ' : 'ธนาคารผู้รับชำระ'}</h2>
          <Input type="select" className="custom-select form-control" disabled>
            <option value="">{payByService}</option>
          </Input>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            background: '#ffffff',
            padding: 20,
          }}
        >
          <KTAXAQRCodePayment {...qrProps} />
          <BlockText>Ref 1:</BlockText>
          <BlockText right>{ref1}</BlockText>
          <BlockText>Ref 2:</BlockText>
          <BlockText right>{ref2}</BlockText>
        </div>
      </SectionContainer>
    </ContentContainer>
  )
}

export default ConfirmPaymentSection
