import dayjs from 'dayjs'
require('dayjs/locale/th')
const utc = require('dayjs/plugin/utc')
const buddhistEra = require('dayjs/plugin/buddhistEra')
const timezone = require('dayjs/plugin/timezone')
const customParseFormat = require('dayjs/plugin/customParseFormat')

const monthConvert = [
  {
    value: '1',
    to: 'มกราคม',
    toab: 'ม.ค.',
  },
  {
    value: '2',
    to: 'กุมภาพันธ์',
    toab: 'ก.พ.',
  },
  {
    value: '3',
    to: 'มีนาคม',
    toab: 'มี.ค.',
  },
  {
    value: '4',
    to: 'เมษายน',
    toab: 'เม.ย.',
  },
  {
    value: '5',
    to: 'พฤษภาคม',
    toab: 'พฤ.ค.',
  },
  {
    value: '6',
    to: 'มิถุนายน',
    toab: 'มิ.ย.',
  },
  {
    value: '7',
    to: 'กรกฎาคม',
    toab: 'ก.ค.',
  },
  {
    value: '8',
    to: 'สิงหาคม',
    toab: 'ส.ค.',
  },
  {
    value: '9',
    to: 'กันยายน',
    toab: 'ก.ย.',
  },
  {
    value: '10',
    to: 'ตุลาคม',
    toab: 'ต.ค.',
  },
  {
    value: '11',
    to: 'พฤศจิกายน',
    toab: 'พ.ย.',
  },
  {
    value: '12',
    to: 'ธันวาคม',
    toab: 'ธ.ค.',
  },
]

export const convertMonthToThaiMonth = (month, ab) => {
  const resp = monthConvert.find((m) => m.value === month)
  return ab ? resp?.toab : resp?.to
}

const FORMAT = {
  DEFAULT: 'YYYY-MM-DD',
  SALE_PROPOSAL: 'D MMMM BBBB',
}

export const toDayjs = (value, format) => {
  return dayjs(value, format)
}

export const formatDate = (date, format) => {
  return dayjs(date).format(format)
}

dayjs.locale('th')
dayjs.extend(utc)
dayjs.extend(buddhistEra)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.tz.setDefault('Asia/Bangkok')

export const getFormats = () => Object.keys(FORMAT)
export const getFormat = (type) => FORMAT[type]
export const format = (dateString, format = FORMAT.DEFAULT) => {
  const inputType = typeof dateString
  if (!dateString || inputType !== 'string') return now().format(format)
  return dayjs(dateString).format(format)
}

export const lastXDay = (day) => dayjs().subtract(day, 'day')
export const nextXDay = (day) => dayjs().add(day, 'day')

// Return relative date
export const now = () => dayjs()
export const last7Days = () => lastXDay(7)
export const last30Days = () => lastXDay(30)
export const next30Days = () => nextXDay(30)
export const date = (dateInput) => dayjs(dateInput)

export const dayDiffWithNow = (date) => dayjs().diff(date, 'day')
export const yearDiff = (date1, date2) => date2.diff(date1, 'year')
export const isAfterDate = (targetDate) => {
  const currentDateTime = dayjs().utc()
  const configDateTimeUTC = dayjs(targetDate).utc()
  return currentDateTime.isAfter(configDateTimeUTC)
}

export default {
  getFormats,
  getFormat,
  format,
  now,
  lastXDay,
  last7Days,
  last30Days,
  nextXDay,
  next30Days,
  dayDiffWithNow,
  toDayjs,
  yearDiff,
  convertMonthToThaiMonth,
  date,
  dayjs,
  isAfterDate,
  formatDate,
}
