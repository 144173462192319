import PolicyEntity from '../../entities/policy'
import PolicyRepository from '../../repositories/policy'

export default class PolicyUseCase implements IPolicyUseCase {
  policyRepo: PolicyRepository

  constructor(po: PolicyRepository) {
    this.policyRepo = po
  }
  async GetPolicyByCitizenId(
    citicenId: string,
    filterType: string
  ): Promise<
    {
      policyNumber: string
      policyEffectiveDate: string
      policyExpirationDate: string
      productCode: string
      policyStatusCode: string
    }[]
  > {
    return this.policyRepo.GetPolicyByCitizenId(citicenId, filterType)
  }

  async GetPolicyByPolicyNo(policyNo: string, policyEffectiveDate: string, agentCode: string): Promise<PolicyEntity> {
    return this.policyRepo.GetPolicyByPolicyNo(policyNo, policyEffectiveDate, agentCode)
  }
}

export interface IPolicyUseCase {
  GetPolicyByCitizenId(
    citicenId: string,
    filterType: string
  ): Promise<
    {
      policyNumber: string
      policyEffectiveDate: string
      policyExpirationDate: string
      productCode: string
      policyStatusCode: string
    }[]
  >
  GetPolicyByPolicyNo(policyNo: string, policyEffectiveDate: string, agentCode: string): Promise<PolicyEntity>
}
