import EcbrEntity, { VerifyBlockIssue } from '../../entities/ecbr'
import {
  PendingECBRListEntity,
  paymentType,
  ECBRItemEntity,
  paymentMethod,
  paymentMode,
  SalesforceCreateCaseEntity,
  CreateEcbrPaidEntity,
} from '../../entities/ecbr'
import EcbrRepository from '../../repositories/ecbr'
import { getToggles } from 'core/config'

export default class EcbrUseCase implements IEcbrUseCase {
  ecbrRepo: EcbrRepository

  constructor(er: EcbrRepository) {
    this.ecbrRepo = er
  }

  async CreateEcbrPaid(payload: { paymentMethod: string, payBy: string, channelMethod: string, policyNumber: string, payInDate: string, slipImage: string, cbrStatus: string, cbrNumber: string, imgBase64: string }): Promise<{ data: any; error: any }> {
    let payloadRequest: CreateEcbrPaidEntity = {
      paymentMethod: payload?.payBy || '',
      channelMethod: payload?.channelMethod || '',
      policyNumber: payload?.policyNumber || '',
      payInDate: payload?.payInDate || '',
      slipImage: payload?.slipImage || '',
    }
    if (payload?.cbrStatus === 'RESERVED') {
      const current = new Date()
      payloadRequest = {
        paymentMethod: payload?.paymentMethod || '',
        policyNumber: payload?.policyNumber || '',
        payInDate: current.toISOString(),
        slipImage: payload?.imgBase64 || '',
      }
    }
    return this.ecbrRepo.CreateEcbrPaid(payloadRequest, payload?.cbrNumber)
  }

  async GetAdvisorPendingECBR(query?: string): Promise<PendingECBRListEntity> {
    return this.ecbrRepo.GetPendingECBR(query)
  }

  async getEcbrByCbrNumber(cbrNumber: string): Promise<{ data: ECBRItemEntity; error: any }> {
    return this.ecbrRepo.GetEcbrByCbrNumber(cbrNumber)
  }

  getPaymentTypeByPamentTypeCode(paymentTypeCode: string): string {
    return paymentType.find((p) => p.value === paymentTypeCode)?.text as string
  }


  getPaymentMethodByPaymentMethodCode(paymentMethodCode: string): string {
    const paymentMethodWithToggle = getToggles().HIDE_UNUSED_PAYMENT? paymentMethod
    .map((p) => p.value === 'creditcard' ? { ...p, text: 'บัตรเครดิต EDC' } : p) : paymentMethod
    return paymentMethodWithToggle.find((p) => p.value === paymentMethodCode)?.text as string
  }

  getPaymentModeByPaymentModeCode(paymentModeCode: string): string {
    return paymentMode.find((p) => p.value === paymentModeCode)?.text as string
  }
  async SearchECBR(payload: any): Promise<ECBRItemEntity[]> {
    return this.ecbrRepo.SearchECBR(payload)
  }

  async SearchECBRSys(payload: any): Promise<{ jsonData: ECBRItemEntity[]; error: any }> {
    return this.ecbrRepo.SearchECBRSys(payload)
  }

  async SendEmailECBRSys(payload: any): Promise<{ resp: any }> {
    return this.ecbrRepo.SendEmailECBRSys(payload)
  }

  async SendSmsECBR(payload: any): Promise<{ resp: any }> {
    return this.ecbrRepo.SendSmsECBR(payload)
  }

  async VerifyBlockIssue(): Promise<VerifyBlockIssue> {
    return this.ecbrRepo.VerifyBlockIssueECBR()
  }

  async CreateCase(data: any): Promise<{ data: SalesforceCreateCaseEntity; error: any }> {
    return this.ecbrRepo.CreateCase(data)
  }
  async UploadDocument(data: any): Promise<{ data: any; error: any }> {
    return this.ecbrRepo.UploadDocument(data)
  }
  async RetrieveCase(): Promise<{ data: any; error: any }> {
    return this.ecbrRepo.RetrieveCase()
  }

  async UseUpdateStatusEcbrSys(payload: any): Promise<{ data: any; error: any }> {
    return this.ecbrRepo.UseUpdateStatusEcbrSys(payload)
  }
}

export interface IEcbrUseCase {
  CreateEcbrPaid(payload: { paymentMethod: string, channelMethod: string, policyNumber: string, payInDate: string, slipImage: string, cbrStatus: string, cbrNumber: string, imgBase64: string }): Promise<{ data: any; error: any }>
  GetAdvisorPendingECBR(query?: string): Promise<PendingECBRListEntity>
  getEcbrByCbrNumber(cbrNumber: string): Promise<{ data: ECBRItemEntity; error: any }>
  getPaymentTypeByPamentTypeCode(paymentTypeCode: string): string
  getPaymentMethodByPaymentMethodCode(paymentMethodCode: string): string
  getPaymentModeByPaymentModeCode(paymentModeCode: string): string
  SearchECBR(payload: any): Promise<ECBRItemEntity[]>
  SearchECBRSys(payload: any): Promise<{ jsonData: ECBRItemEntity[]; error: any }>
  SendEmailECBRSys(payload: any): Promise<{ resp: any }>
  SendSmsECBR(payload: any): Promise<{ resp: any }>
  VerifyBlockIssue(): Promise<VerifyBlockIssue>
  CreateCase(data: any): Promise<{ data: SalesforceCreateCaseEntity; error: any }>
  UploadDocument(data: any): Promise<{ data: any; error: any }>
  RetrieveCase(): Promise<{ data: any; error: any }>
  UseUpdateStatusEcbrSys(payload: any): Promise<{ data: any; error: any }>
}
