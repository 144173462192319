import { css } from '@emotion/react'

import { color, transition, customIcon, hexToRGB } from '../utils/theme'
import { reset, disabled } from '../utils/state'

const boxSize = '22px'
const checkedSize = '12px'

export default {
  checkboxContainer: css`
    display: inline-block;
  `,
  checkbox: css`
    ${reset};
    position: relative;
    display: inline-flex;
    margin-right: 15px;

    input {
      position: absolute;
      left: 0;
      z-index: -1;
      opacity: 0;

      &:active + [data-ui-type],
      &:focus + [data-ui-type] {
        border-color: transparent;
        box-shadow: 0 0 0 1px ${color.common.white}, 0 0 0 3px ${hexToRGB(color.type.primary)};
      }
    }
  `,
  ui: css`
    position: relative;
    display: inline-block;
    width: ${boxSize};
    height: ${boxSize};
    cursor: pointer;
    background-color: ${color.common.white};
    border: solid 1px ${color.element.border};
    transition-duration: ${transition.fastest};
    transition-property: box-shadow, background-color;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      content: '';
    }

    &::after {
      background-color: ${color.common.white};
      visibility: hidden;
      mask-image: url(${customIcon('checked')});
      mask-size: cover;
      mask-repeat: no-repeat;
    }
    &:hover {
      border-color: ${color.type.primaryDark};
    }
    &[data-ui-type='filled'][data-checked='true'] {
      &::before {
        width: ${checkedSize};
        height: ${checkedSize};
        background-color: ${color.type.primaryDark};
      }
    }
    &[data-ui-type='checked'][data-checked='true'] {
      &::before {
        width: ${boxSize};
        height: ${boxSize};
        background-color: ${color.type.primaryDark};
      }
      &::after {
        width: ${checkedSize};
        height: ${checkedSize};
        visibility: visible;
      }
    }
  `,
  label: css`
    display: inline-block;
    flex: 1;
    padding: 0 10px;
  `,
  disabled: css`
    ${disabled}
  `,
}
