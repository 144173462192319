import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import _isEmpty from 'lodash/isEmpty'

import { UploadImage } from 'components/input'
import { KTAXAQRCodePayment } from 'components/ktaxa-qr-code'
import { BlockText, ContentContainer, SectionContainer } from 'components/layouts'
import { clearFormat, formatCitizenId } from 'utils/string-helper'
import { renderErrorMessageBlock } from 'components/message-blocks'
import { ecbrService } from 'services/ecbr-middleware'

import { schemaPaymentSection } from '../../hooks/useForm'
import staticContentPage from '../../static-content'
import { PropsSectionType } from '../../type'
import { getConfig, getToggles } from 'core/config'
import { qrBillerId, qrSuffix } from 'domain/entities/ecbr'

import { useHandleDataForm } from './hook'

const PaymentInsurancePremiumSection = (props: PropsSectionType) => {
  const { state, tabState, setState } = props
  const { pendingList, process, info } = state
  const data = pendingList || process?.payload?.data
  const isQuickPay = info?.payload.paymentMethod === 'quickpay' || data?.paymentMethod === 'quickpay'
  const ActiveCondition = tabState === staticContentPage.tabsProgress[4].id && !isQuickPay
  if (!ActiveCondition) return null
  const [isOverLimit, setIsOverLimit] = useState(false)
  const { limitFileSize } = getConfig()
  const { formState, control, reset, handleSubmit, getValues } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaPaymentSection),
  })
  const { errors } = formState
  const isEnabledSystemization = getToggles().ENABLE_ECBRSYS_ECBR && !_isEmpty(data.receiptId)

  const ref2 = !_isEmpty(data?.ref2) ? data?.ref2 : data?.cbrNumber + data?.paymentType
  const dateFormat = dayjs().format('DD/MM/YYYY')
  const paymentType = ecbrService.getPaymentTypeByPamentTypeCode(data?.paymentType)
  const paymentMode = ecbrService.getPaymentModeByPaymentModeCode(data?.paymentMode)
  const modifiedPolicyNumber = data ? clearFormat(data?.policyNumber, '-') : ''
  const qrProps = {
    ref1: modifiedPolicyNumber || data?.personalId,
    ref2,
    amount: data?.paymentAmount,
    size: 200, // size of qrCode
    showAmount: true,
  }

  useHandleDataForm({ ...props, reset, handleSubmit })

  const handleUploadImageForEcbrSys = (imgBase64, imgHookFormField) => {
    if (isEnabledSystemization) {
      setState({
        ...state,
        [staticContentPage.tabsProgress[4].id]: {
          payload: {
            imgBase64,
          },
        },
      })
    } else {
      imgHookFormField.onChange(imgBase64)
    }
  }

  const isShowAttachmentSection = !isEnabledSystemization || data?.cbrStatus === 'CREATED' || state.isUpdateQrSlipImg

  const renderAttachSlipCondition = () => {
    if (isShowAttachmentSection) {
      return (
        <div style={{ width: '100%' }}>
          <Controller
            name={'object.imgBase64'}
            control={control}
            render={({ field }) => {
              return (
                <UploadImage
                  label={'แนบสำเนาใบนำฝาก'}
                  onSelected={(imgBase64) => handleUploadImageForEcbrSys(imgBase64, field)}
                  value={getValues('object.imgBase64')}
                  setIsOverLimit={setIsOverLimit}
                />
              )
            }}
          />
          {errors?.object?.['imgBase64'] && renderErrorMessageBlock(errors?.object?.['imgBase64']?.message)}
          {isOverLimit && renderErrorMessageBlock(`ขนาดไฟล์ต้องไม่เกิน ${limitFileSize} MB`)}
        </div>
      )
    }
  }

  const renderInformation = () => {
    return isEnabledSystemization ? ( //for systemization
      <>
        <BlockText>Biller ID:</BlockText>
        <BlockText right>{qrBillerId}</BlockText>
        <BlockText>Suffix:</BlockText>
        <BlockText right>{qrSuffix}</BlockText>
        <BlockText>Ref 1:</BlockText>
        <BlockText right>{data?.policyNumber || data?.personalId}</BlockText>
        <BlockText>Ref 2:</BlockText>
        <BlockText right>{ref2}</BlockText>
      </>
    ) : (
      <>
        <BlockText>ประเภทการชำระเงิน</BlockText>
        <BlockText right>
          {paymentType}/ {paymentMode}/ QR Code
        </BlockText>
        <BlockText>เลขที่บัตรประชาชนผู้เอาประกันภัย</BlockText>
        <BlockText right>{formatCitizenId(data?.personalId)}</BlockText>
        <BlockText>ชื่อ-สกุลผู้เอาประกัน</BlockText>
        <BlockText right>{`${data?.insuredName} ${data?.insuredSurName}`}</BlockText>
        <BlockText>Ref 1:</BlockText>
        <BlockText right>{data?.policyNumber || data?.personalId}</BlockText>
        <BlockText>Ref 2:</BlockText>
        <BlockText right>{ref2}</BlockText>
        <BlockText>วันที่ชำระ</BlockText>
        <BlockText right>{dateFormat}</BlockText>
      </>
    )
  }

  return (
    <ContentContainer>
      <h1>ชำระเงินผ่าน QR Code</h1>
      <SectionContainer>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            background: '#ffffff',
            padding: 20,
          }}
        >
          <KTAXAQRCodePayment {...qrProps} />
          {renderInformation()}
          {renderAttachSlipCondition()}
        </div>
      </SectionContainer>
    </ContentContainer>
  )
}

export default PaymentInsurancePremiumSection
