import React, { useState } from 'react'
import ButtonWithIcon from './button-with-icon'
import { Input as RSInput } from 'reactstrap'
import { getConfig } from 'core/config'
import styled from '@emotion/styled'
import { cancelState, useUploadAttachment } from '../hooks/service'
import { renderErrorMessageBlock } from 'components/message-blocks'
import staticContentPage from '../static-content-page'

const ButtonUploadContainer = styled.div`
  position: relative;
  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  margin-bottom: 1rem;
`
const SideUpload = styled.div`
  display: flex;
`

type AttachUploadProp = {
  disabled?: boolean
  setState: Function
  onChange: (v: any) => void
  item: any
  attachName: string
  state: cancelState
  setAttachName: Function
}

const AttachUpload = (props: AttachUploadProp) => {
  const { disabled, onChange, setState, state, item, attachName, setAttachName } = props
  const { attachment } = state
  const [base64, setBase64] = useState('')
  const [errorLimit, setErrorLimit] = useState('')
  const [{ alt, src, type }, setImg] = useState({ src: '', alt: '', type: '' })
  const { cancelAttachSection } = staticContentPage
  const { limitFileSizeCancelEcbr } = getConfig()

  useUploadAttachment({ base64, attachment, item, attachName, alt, src, type, setState, state, setAttachName })

  const _handleReaderLoaded = (e) => {
    const binaryString = e.target.result
    setBase64(btoa(binaryString))
  }
  const handleImg = (e) => {
    const file = e.target.files[0]
    if (file) {
      const maximumSize = limitFileSizeCancelEcbr * 1024 * 1024
      if (file.size > maximumSize) {
        console.log('Error maximum file:', file)
        setErrorLimit(`ขนาดไฟล์ต้องไม่เกิน 500 KB`)
      } else {
        const url = URL.createObjectURL(file)
        setImg({
          src: url,
          alt: file.name,
          type: file.type,
        })
        const reader = new FileReader()
        reader.onload = _handleReaderLoaded
        reader.readAsBinaryString(file)
      }
    }
  }

  return (
    <Container>
      <SideUpload>
        <RSInput
          type="text"
          name="attachment-name"
          placeholder={cancelAttachSection.textInput[0].placeHolder}
          onChange={onChange}
          value={attachName}
          maxLength={cancelAttachSection.textInput[0].maxLength}
        />
        <ButtonUploadContainer>
          <ButtonWithIcon icon={'attach'} disabled={disabled} />
          <input
            disabled={disabled}
            type="file"
            accept="image/png, image/jpeg, image/gif, .pdf"
            id="upload-image"
            onChange={handleImg}
          />
        </ButtonUploadContainer>
      </SideUpload>
      {renderErrorMessageBlock(errorLimit)}
    </Container>
  )
}

export default AttachUpload
