import React from 'react'
import styled from '@emotion/styled'

import { Button, Dialog } from 'ktaxa-ui-components/index'
import { closeModal } from 'utils/modal'

const Content = styled.div`
  padding: 15px 0;
  text-align: center;
`
const Footer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

// const ErrorDialog = (props) => {
//   const { show, onToggle, onClick } = props
//   return (
//     <Dialog centered modal show={show} onClose={onToggle}>
//       <Content>เลขที่กรมธรรม์ไม่ถูกต้อง หรือไม่มีสิทธิ์เข้าถึงข้อมูล</Content>
//       <Footer>
//         <Button type={Button.Type.Primary} onClick={onClick}>
//           ตกลง
//         </Button>
//       </Footer>
//     </Dialog>
//   )
// }

export const NewErrorDialog = (props) => {
  const { show, onToggle, onClick, onLoadData } = props
  return (
    <Dialog centered modal show={show} onClose={onToggle}>
      <Content>ระบบไม่สามารถแสดงข้อมูลได้ ท่านต้องการกรอกข้อมูลลูกค้าด้วยตนเองหรือไม่</Content>
      <Footer>
        <Button type={Button.Type.Secondary} onClick={onClick || onToggle}>
          ยกเลิก
        </Button>
        <Button type={Button.Type.Primary} onClick={onLoadData}>
          ต้องการ
        </Button>
      </Footer>
    </Dialog>
  )
}

export const ConfirmOwnerDialog = (props) => {
  const { show, onToggle, onLoadData, onClick } = props

  return (
    <Dialog centered modal show={show} onClose={onToggle}>
      <Content>เนื่องจากไม่ใช่ลูกค้าของท่าน ท่านต้องการดำเนินการต่อหรือไม่</Content>
      <Footer>
        <Button
          type={Button.Type.Secondary}
          onClick={() => {
            if (!!onClick) {
              onClick()
            } else {
              onToggle()
            }
            closeModal()
          }}
        >
          ยกเลิก
        </Button>
        <Button type={Button.Type.Primary} onClick={onLoadData}>
          ต้องการ
        </Button>
      </Footer>
    </Dialog>
  )
}

const FoundPolicyDialog = (props) => {
  const { show, onToggle, onLoadData } = props
  return (
    <Dialog centered modal show={show} onClose={onToggle}>
      <Content>ค้นหาเลขที่กรมธรรม์สำเร็จ</Content>
      <Footer>
        <Button type={Button.Type.Primary} onClick={onLoadData}>
          ตกลง
        </Button>
      </Footer>
    </Dialog>
  )
}

const WarningIsOwnerModal = (props) => {
  const { show, onToggle, content } = props
  const { isError, isLoaded, isOwner } = content

  let ShowDialog = (
    <Dialog centered modal show={show} onClose={onToggle}>
      <Content>กำลังค้นหาข้อมูล</Content>
    </Dialog>
  )

  if (isLoaded && isOwner) {
    ShowDialog = <FoundPolicyDialog {...props} />
  }

  if (isLoaded && !isOwner) {
    ShowDialog = <ConfirmOwnerDialog {...props} />
  }

  if (isError) {
    // ShowDialog = <ErrorDialog {...props} />
    ShowDialog = <NewErrorDialog {...props} />
  }

  return ShowDialog
}

export default WarningIsOwnerModal
