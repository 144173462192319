import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { ContentContainer, SectionContainer } from 'components/layouts'
import { renderInfoMessageBlock } from 'components/message-blocks'
import { Button, Icons } from 'ktaxa-ui-components/index'
import staticContent, { REGISTER_STATUS, DA_STATUS, UPDATE_TYPES } from '../static-content'
import styled from '@emotion/styled'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { onCheckRegisterEDA, updateRecurringData, onRegisterEDA } from '../hooks/useService'
import { useRouterForHandleSubmit } from '../hooks/useRouter'
import staticContentPage from '../static-content'
import LoadingModal from '../components/load-data-dialog'
import { DA_TYPE } from '../static-content'
import dayjs from 'dayjs'
import SubmitCheckStatus from './submitCheckStatus'

type Props = {
  state: any
  setState: Dispatch<SetStateAction<any>>
  tabState: string
  setTabState: Dispatch<SetStateAction<string>>
  isShowMoreOption: boolean
  ActiveCondition: boolean
  activeType: boolean
  onDAFormSuccess: Function
}

const StatusBox = styled.div`
  padding: 0.875rem;
  background-color: white;

  .sub-heading {
    display: block;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
  }

  .icon {
    margin-right: 8px;
    padding-top: 3px;
  }

  .info-message {
    display: flex;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0 1.15rem;

  .btn-opt {
    width: 100%;
    max-width: 208px;
    margin-bottom: 12px;
    padding: 8px;
  }
`

const CheckPaymentStatusSection = (props: Props) => {
  const { tabState, state, setState, setTabState, isShowMoreOption, ActiveCondition, activeType, onDAFormSuccess } = props
  // const [, setCheckPaymentStatusData] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  // const [, setShowDialog] = useState(false)

  const defaultPolicyDaType = _get(state, 'verify.searchDAInfo.daType', null)
  const registerPayloadDaType = _get(state, 'info.payload.daType', null)
  const isEDA = registerPayloadDaType
    ? registerPayloadDaType === DA_TYPE.ONLINE
    : defaultPolicyDaType === DA_TYPE.ONLINE

  const isShowNavBar = defaultPolicyDaType === DA_TYPE.FORM && _get(state, 'info.payload.daStatus', null) === DA_STATUS.PENDING && tabState === staticContentPage.tabsProgress[2].id


  useRouterForHandleSubmit(
    tabState,
    ActiveCondition,
    { state },
    {
      showFooter: _get(state, 'verify.searchDAInfo.daType', null)===DA_TYPE.FORM,
      extraButton: () => (
        <SubmitCheckStatus
          state={state}
          onDAFormSuccess={onDAFormSuccess}
        />
      ),
    }
  )

  const [isStatusExpired, setIsStatusExpired] = useState(false)

  const onClickCheckStatus = async () => {
    try {
      await setLoading(true)

      await setState({
        ...state,
        regStatus:''
      })
      const payload = {
        policyNo: state.info.payload.policyId,
        accountNo: state.info.payload.daOfPayor.branchNumber + state.info.payload.daOfPayor.accountNumber,
      }
      const resultCheckRegisterEDA = await onCheckRegisterEDA(payload)
      const regStatus = resultCheckRegisterEDA.status
      const dateNow = dayjs()
      if (regStatus === REGISTER_STATUS.EDA_FAIL) {
        //Updata staus Reject
        const recurringData = _get(state, 'verify.searchDAInfo', null)
        const payloadUpdateSubmit = {
          _id: recurringData._id,
          edaResponseDateTime: dateNow,
          daStatus: DA_STATUS.REJECT,
        }
        await updateRecurringData({
          updateType: UPDATE_TYPES.UPDATE,
          data: payloadUpdateSubmit,
        })
      }
      if (regStatus === REGISTER_STATUS.EDA_SUCCESS) {
        const recurringData = _get(state, 'verify.searchDAInfo', null)
        const payloadUpdateSubmit = {
          _id: recurringData._id,
          edaResponseDateTime: dateNow,
          daStatus: DA_STATUS.SUCCESS,
        }
        await updateRecurringData({
          updateType: UPDATE_TYPES.UPDATE,
          data: payloadUpdateSubmit,
        })
      }
      await _set(state, 'verify.searchDAInfo.edaResponseDateTime',dateNow)
      await setState({
        ...state,
        regStatus
      })
      await setLoading(false)
    } catch (error) {
      console.error(`error onClickCheckStatus`, error.message)
    }
  }

  const onClickResendForm = async () => {
    try {
      await setLoading(true)
      // check eda Status Register
      let dataInfo = _get(state, 'info.payload')
      const payloadEDARegister = {
        accountNo: dataInfo.daOfPayor.branchNumber + dataInfo.daOfPayor.accountNumber,
        policyNo: dataInfo.policyId,
      }
      const policyStatus = await onRegisterEDA(payloadEDARegister)

      let regStatus = policyStatus.status
      if (regStatus !== REGISTER_STATUS.TECH_ISSUE && regStatus !== REGISTER_STATUS.EDA_DUPLICATE) {
        // if pass go to reviw / check status
        const payloadUpdateSubmit = {
          _id: dataInfo._id,
          edaResponseDateTime: policyStatus.data.applyDtm,
          edaRequestDateTime: policyStatus.data.applyDtm,
          daStatus: DA_STATUS.PENDING,
        }
        await updateRecurringData({
          updateType: 'UPDATE',
          data: payloadUpdateSubmit,
        })
      }
      await setState({
        ...state,
        regStatus,
      })
      await setLoading(false)
    } catch (error) {
      await setLoading(false)
    }
  }

  const onClickChangeMethod = async() => {
    try {
      await setState({
        ...state,
        isEditForm: true,
      })

      let dataInfo = _get(state, 'info.payload')
      const payloadUpdateSubmit = {
        _id: dataInfo._id,
        daStatus: DA_STATUS.CREATE,
      }
      await updateRecurringData({
        updateType: 'UPDATE',
        data: payloadUpdateSubmit,
      })
      await _set(state,'verify.searchDAInfo.daStatus', DA_STATUS.CREATE)
      await setTabState(staticContentPage.tabsProgress[1].id)
    } catch (error) {
      console.error(`error onClickChangeMethod`, error.message)
      await setTabState(staticContentPage.tabsProgress[1].id)

    }
  }

  const onClickByPass = async () => {
    try {
      const dateNow = dayjs()
      const regStatus = REGISTER_STATUS.EDA_SUCCESS_MANUAL

      await setState({
        ...state,
        verify: {
          ...state.verify,
          searchDAInfo:{
            ...state.verify.searchDAInfo,
            edaResponseDateTime: dateNow
          }
        },
        regStatus: regStatus,
      })
    } catch (error) {
      console.error(`error onClickByPass`, error.message)
    }
  }

  if (!ActiveCondition) return null
  // if (!ActiveCondition || activeType === false) return null

  return (
    activeType==true?
    <ContentContainer>
      <h2 style={{ margin: '0.75rem 0' }}>สมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร</h2>
      <SectionContainer>
        <StatusBox>
          <div className="sub-heading">ตรวจสอบสถานะผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร</div>
          <div className="info-message">
            <div className="icon">
              <Icons type="CHECK_FILL_CIRCLE" />
            </div>
            กรุณาตรวจสอบแอปพลิเคชั่นธนาคารที่ต้องการตัดบัญชีธนาคารอัตโนมัติ
          </div>
          <ButtonContainer>
            {!isStatusExpired && (
              <Button className="btn-opt" type="primary" onClick={onClickCheckStatus}>
                ตรวจสอบสถานะ
              </Button>
            )}
            {(isStatusExpired || isShowMoreOption) && (
              <Button className="btn-opt" type={isStatusExpired ? 'primary' : 'secondary'} onClick={onClickResendForm}>
                สมัครอีกครั้ง
              </Button>
            )}
            {(isStatusExpired || isShowMoreOption) && (
              <Button className="btn-opt" type="secondary" onClick={onClickChangeMethod}>
                เปลี่ยนวิธีการสมัคร
              </Button>
            )}
            {(isStatusExpired || isShowMoreOption) && (
              <Button className="btn-opt" type="secondary" onClick={onClickByPass}>
                อัพเดทสถานะด้วยตนเอง
              </Button>
            )}
          </ButtonContainer>
          {renderInfoMessageBlock('กรุณาทำรายการสมัครผ่านแอปพลิเคชั่นของทางธนาคารให้แล้วเสร็จภายใน 5 นาที')}
        </StatusBox>
      </SectionContainer>
      <LoadingModal show={loading} />
    </ContentContainer>
    :
    <ContentContainer></ContentContainer>
  )
}

export default CheckPaymentStatusSection
