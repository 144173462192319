import React from 'react'
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';
import 'ktaxa-ui-components/assets/styles/date-rannge-picker.css'
import { allowedRange } from 'rsuite/cjs/DateRangePicker/disabledDateUtils'
import dayjs from 'dayjs'

type DateRangePickerProps = {
  createDateFrom?: string,
  createDateTo?: string,
  handleDateChange?: any
}

const DateRangePickerComponent = (props: DateRangePickerProps) => {
  const { handleDateChange, createDateFrom, createDateTo } = props
  const currentDate: Date = new Date()
  let lastThreeMonthtDate: Date = new Date();
  lastThreeMonthtDate = new Date(lastThreeMonthtDate.setMonth(lastThreeMonthtDate.getMonth() - 3))

  return (
    <DateRangePicker
      format="dd/MM/yyyy"
      ranges={[]}
      shouldDisableDate={allowedRange(lastThreeMonthtDate, currentDate)}
      defaultValue={[dayjs(createDateFrom, 'DD/MM/YYYY').toDate(), dayjs(createDateTo, 'DD/MM/YYYY').toDate()]}
      size="lg"
      character=" – "
      placeholder="วันที่ออกใบ"
      editable={false}
      showHeader={false}
      cleanable={false}
      onOk={handleDateChange}
    />
  )
}

export default DateRangePickerComponent
