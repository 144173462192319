import CheckAccountRepository from '../../repositories/checkAccount'

export default class CheckAccountUseCase implements ICheckAccountUseCase {
  CheckAccountRepo: CheckAccountRepository

  constructor(po: CheckAccountRepository) {
    this.CheckAccountRepo = po
  }

  async postCheckAccount(data: any): Promise<
    {
      data: any
      searchPolicyStatus: number
    }
  > {
    return this.CheckAccountRepo.postCheckAccount(data)
  }

}

export interface ICheckAccountUseCase {
  postCheckAccount(data: any): Promise<
    {
      data: any,
      searchPolicyStatus: number
    }
  >
}
