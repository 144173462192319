import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Card } from 'reactstrap'
import { Icons, Button } from 'ktaxa-ui-components/index'
import { Theme } from 'components/index'
import PreviewDialog from './preview-dialog'
import _isEmpty from 'lodash/isEmpty'

const Side = styled.div`
  min-width: 4rem;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background: ${Theme.color.type.primary};

  &:hover {
    background: ${Theme.color.type.primaryDark};
  }
`

const UploadCard = styled(Card)`
  border: 1px solid ${Theme.color.type.primary};
  display: flex;
  height: 80px;
  align-items: center;
  background: ${Theme.color.common.white};
  margin-top: 20px;
`

const UploadText = styled.span`
  margin-left: 20px;
  color: ${Theme.color.type.primary};
`

const LeftSide = styled.div`
  margin-left: auto;
`
const SuccessCard = styled(Card)`
  border: none;
  display: flex;
  height: 80px;
  align-items: center;
  margin-top: 20px;
`

const SuccessIcon = styled.div`
  min-width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  img {
    width: 40px;
    height: 40px;
  }
`
const SuccessText = styled.span`
  margin-left: 20px;
  color: ${Theme.color.common.black};
`

const CheckedCard = ({ title, onClick }) => {
  return (
    <SuccessCard>
      <SuccessIcon>
        <Icons style={{ width: '35px', height: 'auto' }} type="CHECK" />
      </SuccessIcon>
      <SuccessText>{title}</SuccessText>
      <LeftSide>
        <Button type="secondary" onClick={onClick}>
          ดู
        </Button>
      </LeftSide>
    </SuccessCard>
  )
}

const NotCheckedCard = ({ title, onClick }) => {
  return (
    <UploadCard onClick={onClick}>
      <Side>แนบรูป</Side>
      <UploadText>{title}</UploadText>
    </UploadCard>
  )
}

const UploadDocumnet = (props) => {
  const {
    title,
    onSelected,
    value,
    setIsOverLimit,
    limitFileSize,
    setValue,
    onRemoveFile,
    viewOnly = false,
    isPDF = false,
  } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [img, setImg] = useState({})
  const fileInputRef = useRef(null)

  useEffect(() => {
    if (value) {
      setImg(value)
    }
  }, [value])

  const onSetFileData = (fileData) => {
    onSelected(fileData)
    setImg(fileData)
  }

  const handleAttachClick = () => {
    // Trigger click on hidden file input
    fileInputRef.current.click()
  }

  const handleRemove = () => {
    // setValue('payerRelationshipDoc', {})
    onRemoveFile()
    setImg({})
    setModalOpen(false)
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Reset the input to allow re-selecting the same file
    }
  }

  const toggleModal = () => {
    console.log(`Uploadimage :: `, img)
    setModalOpen(!modalOpen)
  }

  const handleImg = async (e) => {
    const file = e.target.files[0]
    const fileSize = file.size
    const fileName = file.name
    const contentType = file.type
    const url = URL.createObjectURL(file)
    const maximumSize = limitFileSize * 1024 * 1024
    if (file) {
      if (file.size > maximumSize) {
        setIsOverLimit(true)
      } else {
        const reader = new FileReader()
        setIsOverLimit(false)
        reader.onload = (e) => {
          const binaryString = e.target.result
          const base64String = btoa(binaryString)
          const fileData = {
            base64: base64String,
            contentType,
            size: fileSize,
            src: url,
            alt: fileName,
          }
          onSetFileData(fileData)
        }
        reader.readAsBinaryString(file)
      }
    }
  }

  return (
    <div>
      <input
        id="upload-document"
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImg}
      />
      {!_isEmpty(img) ? (
        <CheckedCard title={title} onClick={toggleModal} />
      ) : (
        <div>
          <NotCheckedCard title={title} onClick={handleAttachClick} />
        </div>
      )}
      <PreviewDialog
        show={modalOpen}
        toggle={toggleModal}
        title={title}
        imageSrc={
          isPDF == true
            ? `data:application/pdf;base64,${img?.base64}`
            : !_isEmpty(img)
            ? `data:image/png;base64,${img?.base64}`
            : img?.src
        }
        alt={!_isEmpty(img) ? 'uploadfile' : img?.alt}
        onRemove={handleRemove}
        isPDF={isPDF}
        viewOnly={viewOnly}
      />
    </div>
  )
}

UploadDocumnet.propTypes = {
  onSelected: PropTypes.func,
  value: PropTypes.object,
  setIsOverLimit: PropTypes.func,
  title: PropTypes.string,
  limitFileSize: PropTypes.number,
  setValue: PropTypes.func,
  onRemoveFile: PropTypes.func,
  viewOnly: PropTypes.bool,
  isPDF: PropTypes.bool,
}

export default UploadDocumnet
