import { css } from '@emotion/react'

import { color, transition, padding, hexToRGB } from '../utils/theme'
import { reset, disabled } from '../utils/state'

export default {
  button: css`
    ${reset};
    display: inline-block;
    margin-right: 4px;
    padding: calc(${padding.m} / 4) ${padding.m};
    color: ${color.common.text1};
    background-color: ${color.common.white};
    border: solid 1px transparent;
    outline: none;
    cursor: pointer;
    transition-duration: ${transition.fastest};
    transition-property: box-shadow, background-color, border-color, color;

    &[disabled] {
      ${disabled}
    }

    &:hover,
    &:focus {
      &:not([disabled]) {
        outline: none;
      }
    }
  `,
  type: {
    button: css`
      &:active {
        background: darken(${color.common.white}, 0.4);
        box-shadow: 0 0 0 2px ${hexToRGB(color.common.gray, 0.4)};
      }
    `,
    primary: css`
      color: ${color.common.white};
      background-color: ${color.type.primary};
      border-color: ${color.type.primary};

      &:hover,
      &:focus {
        &:not([disabled]) {
          background: ${color.type.primaryDark};
        }
      }

      &:active {
        background: ${color.type.primaryDark};
        box-shadow: 0 0 0 2px ${hexToRGB(color.type.primary, 0.4)};
      }
    `,
    secondary: css`
      color: ${color.type.primary};
      background: ${color.common.white};
      border-color: ${color.type.primary};

      &:hover,
      &:focus {
        &:not([disabled]) {
          color: ${color.common.white};
          background: ${color.common.axaBlue};
        }
      }

      &:active {
        box-shadow: 0 0 0 2px ${hexToRGB(color.type.primary, 0.4)};
      }
    `,
  },
  size: {
    xs: css`
      padding: calc(${padding.xs} / 6) ${padding.xs};
      font-size: 0.65rem;
      border-width: 1px;
    `,
    s: css`
      padding: calc(${padding.s} / 5) ${padding.s};
      font-size: 0.75rem;
      border-width: 1px;
    `,
    l: css`
      padding: calc(${padding.l} / 4.5) ${padding.l};
      font-size: 1.15rem;
    `,
    custom: css`
    padding: calc(${padding.m} / 4.5) ${padding.m+5};
    border-width: 1px;
    `
  },
  label: css`
    display: block;
  `,
}
