

import React from 'react';
import Select from 'react-select';



const dropdownStyles = {
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      border: '1px solid #333333',
      borderRadius: '0px',
      padding: '0.15rem 0.27rem',
      backgroundColor: isDisabled
        ? '#eceeef' : '#fff',
      textAlign: 'center'
     }
  },
  // option: (styles) => ({...styles,textAlign: 'center' }),
  clearIndicator: styles => ({
    ...styles,
    color: '#333333'
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: '#333333'
  }),

  valueContainer: (styles) => ({ ...styles,  padding: '0'  }),
  singleValue: (styles) => ({ ...styles, color: '#333333'  }),
};

export default (props) => (
  <Select
    {...props}
    styles={dropdownStyles}
  />
);
